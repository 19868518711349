import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles
} from "@material-ui/core";

import FolderIcon from "@material-ui/icons/Folder";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import AddBoxIcon from "@material-ui/icons/AddBox";
import MinusBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import DeviceIcon from "@material-ui/icons/SettingsInputComponent";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const NODE_TYPES_ICONS = {
  objects: {
    default: <FolderIcon />,
    current: <FolderOpenIcon />
  },
  hub: {
    default: <AddBoxIcon />,
    current: <MinusBoxIcon />
  },
  devices: {
    default: <DeviceIcon />,
    current: <DeviceIcon />
  }
};

export const TreeNode = props => {
  const { item, resource, handleClick, isParent, isNested, isDisabled } = props;
  const classes = useStyles();
  const isHub = resource === 'devices' && item.children && item.children.length;

  return (
    <ListItem
      key={item.uuid || item.name}
      button
      onClick={() => handleClick(resource, item.uuid, item)}
      className={isNested && classes.nested}
      disabled={isDisabled}
    >
      <ListItemIcon>
        {NODE_TYPES_ICONS[isHub ? 'hub': resource][isParent ? "current" : "default"]}
      </ListItemIcon>
      <ListItemText primary={item.name} />
    </ListItem>
  );
};
