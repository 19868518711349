import React from "react";
import {
  AutocompleteInput,
  Filter,
  SearchInput,
  withDataProvider,
} from "react-admin";
import ReferenceInputWithFilter from "../../custom/ReferenceInputWithFilter";
import { filterStyles } from "../../custom/StyledSearchFilter";

const DeviceListFilter = ({ dataProvider, ...props }) => {
  const classes = filterStyles();

  return (
    <Filter {...props}>
      <SearchInput
        source="name, model_name"
        alwaysOn
        className={classes.input}
      />
      <ReferenceInputWithFilter
        alwaysOn
        label="app.labels.client"
        source="clientUuid"
        reference="clients"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInputWithFilter>
      <ReferenceInputWithFilter
        alwaysOn
        label="app.labels.devices"
        source="deviceModelUuid"
        reference="devices-models"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInputWithFilter>
    </Filter>
  );
};

export default withDataProvider(DeviceListFilter);
