import React, { useState, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    showNotification,
    Button,
    SimpleForm,
    SelectInput,
    BooleanInput,
    TextInput,
    Toolbar,
    withDataProvider,
    useTranslate
} from 'react-admin';
import { crudUpdate as crudUpdateAction } from 'ra-core';
import IconEdit from '@material-ui/icons/Edit';
// import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { SaveButton } from '../custom/WithUndoable'; // T5095

const styles = {
  dialog: {
    zIndex: 1
  }
};

const EditUserFormToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton />
      {/* TODO: add Cancel button */}
    </Toolbar>
  );
};

const EditUserButton = props => {
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  const { classes, record } = props;

  const handleClick = () => setShowDialog(true);

  const handleCloseClick = () => setShowDialog(false);

  const handleSubmit = values => {
    const { dispatchCrudUpdate, record, basePath } = props;

    const data = {
      clientUuid: values.UsersToClients.clientUuid,
      userId: values.id,
      type: values.UsersToClients.type,
      isActive: values.UsersToClients.isActive,
      description: values.UsersToClients.description
    };
    dispatchCrudUpdate('users-to-clients', record.UsersToClients.id, data, data, basePath, `/clients/${data.clientUuid}/users`);
    setShowDialog(false);
  };

  return (
    <Fragment>
      <Button onClick={handleClick} label="ra.action.edit">
        <IconEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="resources.clients.pages.clientUserUpdate"
        className={classes.dialog}
      >
        <DialogTitle>
          {translate('resources.clients.pages.clientUserUpdate')}
        </DialogTitle>
        <DialogContent>
          <SimpleForm
            record={record}
            resource="users"
            save={handleSubmit}
            toolbar={<EditUserFormToolbar />}
          >
            <BooleanInput
              source="UsersToClients.isActive"
              label="app.labels.isActive"
            />
            <TextInput fullWidth source="login" label="app.labels.user" disabled={true} />
            <SelectInput
              fullWidth
              label="app.labels.type"
              source="UsersToClients.type"
              choices={[
                { id: 'administrator', name: 'app.roles.administrator' },
                { id: 'owner', name: 'app.roles.owner' },
                { id: 'developer', name: 'app.roles.developer' },
                { id: 'manager', name: 'app.roles.manager' },
                { id: 'tester', name: 'app.roles.tester' }
              ]}
            />
            <TextInput
              fullWidth
              source="UsersToClients.description"
              label="app.labels.description"
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Fragment>
  );

};

const mapDispatchToProps = {
  dispatchCrudUpdate: crudUpdateAction, // T5352
  showNotification
};

export default 
  connect( null,mapDispatchToProps)(
    withDataProvider(
      withStyles(styles)(EditUserButton)));
