import React, { useState } from 'react';
import {
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useForm } from 'react-final-form';
import {
  useTranslate,
  GET_LIST,
  BooleanInput,
  withDataProvider,
} from "react-admin";

import { GroupsFilterForm } from "../custom/GroupsFilterForm";
import RuleAssignmentsTab from "../custom/RuleAssignmentsTab";
import { useEffectOnce } from "../custom/utils";


const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  tglText: {
    height: "38px",
    lineHeight: "38px",
    marginRight: "10px"
  },
  toggle: {
    height: "38px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
});

const DeviceRuleAssignments = ({dataProvider, record, basePath, location, ...props}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const queryName = 'groups';
	const search = location.search;
	const params = new URLSearchParams(search);
	const groupsNames = params.get(queryName);
	let initialFilter = true;
	if (groupsNames && groupsNames.split(',').length) {
		initialFilter = groupsNames.split(',')
  }

	const [groupsFilter, setGroupsFilter] = useState(initialFilter);
	const [allGroups, setAllGroups] = useState([]);
  const form = useForm();

	useEffectOnce(() => {
		dataProvider(GET_LIST, 'rules-assignments-groups', {
			sort: {field: 'updatedAt', order: 'desc'},
		}).then(result => setAllGroups(result.data.map(group => ({'text': group.name, 'id': group.uuid}))));
  });

  const saveManagementLog = (value) => {
    form.change('managementOff', value);
  }

	const handleCreateRule = (id) => {
		return {
			class: "device",
			executionType: 'before',
			deviceUuid: id,
			rule: null,
			groups: [],
			priority: null,
			isActive: true,
			dryRun: true,
			uuid: null,
			quickActionName: null,
		}
	};

	const handleAssignmentsFilter = (id) => {
		return {
			deviceUuid: id,
			embedded: {
				rule: true,
				groups: typeof groupsFilter === 'boolean' ? groupsFilter : {filter: {name: {'$in': groupsFilter}}},
				schedule: true
			}
		}
	}

	return (
    <Grid
      justify="flex-start"
      container={true}
      spacing={2}
      className={classes.root}
    >
      <Grid container={true} item lg={12}>
        <div className={classes.tglText}>
          {translate("resources.devices.tabs.management")}
        </div>
        <div className={classes.tglText}>
          {translate("resources.devices.management.switchOff")}
        </div>
        <div>
          <BooleanInput
            onChange={(value) => saveManagementLog(value)}
            resource="devices"
            label="resources.devices.management.switchOn"
            source="managementOff"
          />
        </div>
      </Grid>
      <Grid item lg={12} md={12}>
				<GroupsFilterForm
					location={location}
					allGroups={allGroups}
					setAllGroups={setAllGroups}
					groupsFilter={groupsFilter}
					setGroupsFilter={setGroupsFilter}
					queryName={queryName}
					selectId={"device-rule-assignments-list-filter"}
					selectPlaceholder={"resources.devices-groups.titles.selectGroups"}
				/>
				<RuleAssignmentsTab
					handleCreateRule={handleCreateRule}
					handleAssignmentsFilter={handleAssignmentsFilter}
					withDeviceSelect={false}
					deviceId={record.uuid}
					withGroupSelect={true}
					allGroups={allGroups}
					{...props}
				/>
      </Grid>
    </Grid>
	)
};

export default withDataProvider(DeviceRuleAssignments)
