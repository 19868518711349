import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import {
    MuiThemeProvider,
    createMuiTheme,
    withStyles,
    createStyles
} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import { Notification, useTranslate } from 'react-admin';

// import Notification from '../../layout/Notification';
import LoginForm from './LoginForm';
import AppVersion from '../custom/AppVersion';

const styles = theme =>
  createStyles({
    actions: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    card: {
      minWidth: 300,
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center'
    },
    expand: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      }),
      marginRight: '8px'
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    },
    icon: {
      backgroundColor: theme.palette.secondary[500]
    }
  });

const Login = props => {
  const {
    backgroundImage,
    classes,
    className,
    loginForm,
    staticContext,
    theme,
    ...rest
  } = props;
  const [expanded, setExpanded] = useState(false);
  const muiTheme = createMuiTheme(theme);
  const containerRef = React.createRef();
  const translate = useTranslate();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <MuiThemeProvider theme={muiTheme}>
      <div
        className={classnames(classes.main, className)}
        {...rest}
        ref={containerRef}
      >
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <Avatar className={classes.icon}>
                <LockIcon />
              </Avatar>
            </div>
            {loginForm}
            <CardActions className={classes.actions}>
              <Typography variant="caption">
                {translate('app.labels.version')}
              </Typography>
              <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: expanded
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show version"
                label="app.labels.version"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Grid container justify="center">
                  <AppVersion variant="caption" />
                </Grid>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>
        <Notification />
      </div>
    </MuiThemeProvider>
  );
}

const EnhancedLogin = withStyles(styles)(Login);

EnhancedLogin.propTypes = {
    loginForm: PropTypes.element,
    theme: PropTypes.object,
    staticContext: PropTypes.object,
};

EnhancedLogin.defaultProps = {
    loginForm: <LoginForm />,
};

export default EnhancedLogin;
