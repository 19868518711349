import React, { useState, Fragment } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
  required,
  showNotification,
  TextInput,
  ReferenceInput,
  Button,
  SimpleForm,
  SelectInput,
  BooleanInput,
  Toolbar,
  AutocompleteInput,
  withDataProvider,
  useTranslate
} from 'react-admin';

import { crudCreate as crudCreateAction } from 'ra-core';

import PersonAdd from '@material-ui/icons/PersonAdd';
// import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { SaveButton } from '../custom/WithUndoable'; // T5095

const crudCreate = (
  resource,
  data,
  basePath,
  redirectTo
) => {
  const action = crudCreateAction(resource, data, basePath, redirectTo);
  action['meta']['onSuccess']['refresh'] = true;
  return action
};

const styles = theme =>
  createStyles({
    button: {
      marginTop: '1em'
    }
  });

const AddUserFormToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton />
      {/* TODO: add Cancel button */}
    </Toolbar>
  );
};

const AddUserButton = props => {
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  const { classes } = props;

  const handleClick = () => setShowDialog(true);

  const handleCloseClick = () => setShowDialog(false);

  const handleSubmit = values => {
    const { record, basePath, dispatchCrudCreate } = props;

    const data = {
      clientUuid: record.uuid,
      userId: values.userId,
      type: values.type,
      isActive: values.isActive,
      description: values.description,
    };

    dispatchCrudCreate('users-to-clients', data, basePath, `/clients/${data.clientUuid}/users`);
    setShowDialog(false);

  };

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label="resources.clients.buttons.addUser"
        className={classes.button}
      >
        <PersonAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="resources.clients.pages.clientUserAdd"
      >
        <DialogTitle>
          {translate('resources.clients.pages.clientUserAdd')}
        </DialogTitle>
        <DialogContent>
          <SimpleForm
            resource="users"
            save={handleSubmit}
            toolbar={<AddUserFormToolbar />}
          >
            <BooleanInput
              source="isActive"
              label="app.labels.isActive"
              isRequired={true}
            />
            <ReferenceInput
              label="app.labels.user"
              source="userId"
              reference="users"
              validate={required()}
              filterToQuery={text => text && ({ login: { $iLike: '%' + text + '%' } })}
            >
              <AutocompleteInput
                options={{
                  suggestionsContainerProps: {
                    style: { zIndex: 1300 }
                  }
                }}
                optionText="login"
                isRequired={true}
              />
            </ReferenceInput>
            <SelectInput
              label="app.labels.type"
              isRequired={true}
              source="type"
              choices={[
                { id: 'administrator', name: 'app.roles.administrator' },
                { id: 'owner', name: 'app.roles.owner' },
                { id: 'developer', name: 'app.roles.developer' },
                { id: 'manager', name: 'app.roles.manager' },
                { id: 'tester', name: 'app.roles.tester' }
              ]}
            />
            <TextInput source="description" label="app.labels.description" />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

const mapDispatchToProps = {
  dispatchCrudCreate: crudCreate, // T5352
  showNotification,
};

export default connect(null,mapDispatchToProps)(withDataProvider(withStyles(styles)(AddUserButton)));
