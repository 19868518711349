import IconList from "@material-ui/icons/List";
import ViewAgenda from "@material-ui/icons/ViewAgenda";
import { refreshView as refreshViewAction } from "ra-core";
import React, { useState } from "react";
import {
  Button,
  CreateButton,
  ExportButton,
  ReferenceField,
  TextField,
  TopToolbar,
} from "react-admin";
import { connect } from "react-redux";
import CardDatagrid from "../custom/CardDatagrid";
import ChildrenButton from "../custom/ChildrenButton";
import Datagrid from "../custom/ClickableDatagrid"; // T5113
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import CustomTitle from "../custom/CustomTitle";
import UuidNameTextField from "../custom/UuidNameTextField";
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import DeviceListFilter from "./helpers/DeviceListFilter";
import rowStyle from '../styling/rowStyle';
import { withStyles } from '@material-ui/core/styles';
import DeviceStatusField from "../custom/DeviceStatusField";

const enhanceDeviceListAction = connect(null, {
  refreshView: refreshViewAction,
});


const styles = theme => ({
  narrow: {
    maxWidth: '2em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  normal: {
    maxWidth: '6em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  wide: {
    maxWidth: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

const DeviceListActions = enhanceDeviceListAction(
  ({
    basePath,
    currentSort,
    exporter,
    filterValues,
    resource,
    total,
    viewType,
    setViewType,
    refreshView,
    ...props
  }) => {
    const handleClick = (event) => {
      event.preventDefault();
      viewType = viewType === "list" ? "cards" : "list";
      setViewType(viewType);
      refreshView();
    };

    return (
      <TopToolbar>
        <Button color="primary" label="app.labels.view" onClick={handleClick}>
          {viewType === "list" ? <IconList /> : <ViewAgenda />}
        </Button>
        <CreateButton basePath={basePath} />
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
        />
      </TopToolbar>
    );
  }
);

const DeviceList = ({ classes, filterValues, ...props }) => {
  const [viewType, setViewType] = useState("list");
  const [recordsParams, setRecordsParams] = useState({});

  return (
    <ListWithBreadcrumbs
      title={<CustomTitle titleKey="app.labels.devices" {...props} />}
      bulkActionButtons={<BulkDeleteAction />}
      filters={<DeviceListFilter />}
      filter={{
        embedded: { 
          children: true, 
          rules: true, 
          model: true, 
          parent: true, 
          /*stats: [
            "d5581197-b461-4619-a524-737df4b4777a",
            "b6b9272e-8c4b-494c-b4ac-083bbf86cd5c",
            "0f6c310e-1eb1-4d65-8b4f-6e1e14898f50",
            "b312fc90-1d9d-49da-88c2-1e251ddd94ad"
          ]*/ 
        },
        withOnlineFlag: true,
      }}
      sort={{ field: "createdAt", order: "DESC" }}
      actions={
        <DeviceListActions
          filterValues={filterValues}
          viewType={viewType}
          setViewType={setViewType}
        />
      }
      {...props}
    >
      {viewType === "list" ? (
        <Datagrid editBtnDisable={true} rowStyle={rowStyle()}>
          <ReferenceField
            label="app.labels.object"
            source="objectUuid"
            reference="objects"
            cellClassName={classes.wide}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="app.labels.client"
            source="clientUuid"
            reference="clients"
            cellClassName={classes.normal}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="app.labels.deviceModel"
            source="deviceModelUuid"
            reference="devices-models"
            cellClassName={classes.narrow}
          >
            <TextField source="name" />
          </ReferenceField>
          <UuidNameTextField 
            source="parentUuid" 
            label="app.labels.parent" 
            cellClassName={classes.narrow} />
          <DeviceStatusField 
            label="app.labels.status" 
            class="normal" 
            cellClassName={classes.normal} />
          <ChildrenButton label="app.labels.children" />
        </Datagrid>
      ) : (
        <CardDatagrid
          recordsParams={recordsParams}
          setRecordsParams={setRecordsParams}
          {...props}
        />
      )}
    </ListWithBreadcrumbs>
  );
};

//export default DeviceList;
export default withStyles(styles)(DeviceList);
