import React, { useState, useEffect } from "react";
import {
  AutocompleteInput,
} from 'react-admin';

import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920

export const SelectDeviceInput = props => {
  const { history, location, dryRun, setDryRun, record } = props;
  const [value, setValue] = useState(null);

  const handleChange = selectedUuid => {
    location.search = `?deviceUuid=${selectedUuid}`;
    history.push('/dry-run' + location.search);
    dryRun.deviceUuid = selectedUuid;
    setDryRun({ ...dryRun }); // Force to fire parent's useEffect() hook
  };

  const handleInput = value => {
    setValue(value);
    return true;
  }

  useEffect(() => {
    setValue((record && record.name) || "");
  }, [setValue, record])

  return (
    <ReferenceInputWithFilter
      label="app.labels.selectDevice"
      resource="rules-assignments"
      source="deviceUuid"
      reference="devices"
      filter={{}}
      onChange={handleChange}
    >
      <AutocompleteInput
        optionText="name"
        options={{
          value: value,
          fullWidth: true
        }}
        shouldRenderSuggestions={handleInput}
      />
    </ReferenceInputWithFilter>
  );
};
