import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  SimpleShowLayout,
  Datagrid, TextField, DateField, ArrayField, ReferenceField
} from 'react-admin';
import PreField from '../../custom/PreField';
import rowStyle from '../../styling/rowStyle';

const styles = {
  narrow: {
    maxWidth: '2em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  normal: {
  	maxWidth: '4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  wide: {
  	maxWidth: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

// TODO: switch to tabbed layout with first tab for fields, second for original message and 
// syncWithLocation={false} after depatching and migration to 3.15 (not availible for 3.5)
const DeviceLogsDetails = ({ record, classes, basePath, extended, ...props }) => {
  return (
    <SimpleShowLayout record={record} basePath={basePath}  className="ra-two-column-grid" >
    	<TextField source="id" style={{ gridColumn: 1 }} />
      <TextField source="source" label="app.labels.source" style={{ gridColumn: 1 }} />
  		<DateField source="createdAt" label="app.labels.datetime" showTime={true} style={{ gridColumn: 1 }} />
      {extended &&
        <ReferenceField label="app.labels.object" source="objectId" reference="objects" style={{ gridColumn: 1 }} >
          <TextField source="name" />
        </ReferenceField>
      }
      {extended &&
        <ReferenceField label="app.labels.device" source="deviceId" reference="devices" style={{ gridColumn: 1 }} >
          <TextField source="name" />
        </ReferenceField>
      }
      <PreField label="app.labels.original" style={{ gridColumn: 2, gridRowStart: 1, gridRowEnd: 6, 
        maxHeight: '20em', maxWidth: '45%', overflow: 'scroll', marginLeft: '2em'}} />
      <ArrayField source="rulesProcessed" className="childs" label="app.labels.childs"
        style={{ gridColumns: '1/2' }} >
  		  <Datagrid rowStyle={rowStyle('status')} >
  		  	<DateField showTime={true} source="startedAt" label="app.labels.datetime"
  		  		 cellClassName={classes.normal}/>
  	  		<TextField source="source" label="app.labels.source"
  		    	cellClassName={classes.narrow} />
  		    <TextField source="ruleName" label="app.labels.name"
  		    	cellClassName={classes.normal} />
          <TextField source="outputLogs" label="app.labels.output"
  		    	  cellClassName={classes.wide} />
  		  </Datagrid>
  		</ArrayField>
    </SimpleShowLayout>);
}


export default withStyles(styles)(DeviceLogsDetails);
