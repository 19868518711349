import React from "react";
import {
  TextInput,
  SelectInput,
  useTranslate,
} from "react-admin";

import CustomTitle from '../custom/CustomTitle';
import SchemaEditForm from "../custom/SchemaForm"; // T5722
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';

const placeholderMessageTypeSchema = {
  "$id": "https://example.com/schemas/v01/example.json",
  "type": "object",
  "title": "Title",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "required": [],
  "properties": {},
  "definitions": {},
  "description": "description"
};

const MessageTypeCreate = props => {
  const translate = useTranslate();

  return (
    <CreateWithBreadcrumbs
      title={
        <CustomTitle
          titleKey="resources.messages-types.titles.newMessageType"
          {...props}
        />
      }
      record={{}}
      {...props}
    >
      <SchemaEditForm
        schemaName="messageSchema"
        schemaLabel="app.labels.messageSchema"
        ignoreState={true}
        inlineChildren={true}
        schemaPlaceholder={placeholderMessageTypeSchema}
      >
        <TextInput
          source="name"
          label="app.labels.name"
          fullWidth
        />
        <SelectInput
          source="direction"
          label="app.labels.direction"
          choices={[
            {
              id: "in",
              name: translate(
                "resources.messages-types.directions.in"
              )
            },
            {
              id: "out",
              name: translate(
                "resources.messages-types.directions.out"
              )
            }
          ]}
          fullWidth
        />
      </SchemaEditForm>
    </CreateWithBreadcrumbs>
  );
};

export default MessageTypeCreate;
