import React, { useState } from 'react';
import {
  TextInput,
  BooleanInput,
  FormTab,
  TabbedForm,
  ReferenceField,
  ReferenceManyField,
  Datagrid,
  DateField,
  TextField,
  ImageInput,
  usePermissions,
  useTranslate,
  ImageField,
} from 'react-admin';
import { useForm } from 'react-final-form';

import {
  Grid,
  Paper,
  Link,
  Box,
  Typography,
  Button,
  createMuiTheme
} from "@material-ui/core";

import { makeStyles, useTheme } from "@material-ui/styles";

import AddUserButton from './AddUserButton';
import EditUserButton from './EditUserButton';
import DeleteUserButton from './DeleteUserButton';
import { withSource } from '../custom/isActiveRowStyle'; // T5113
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs";
// import {changeSettings, getSettings} from "../custom/utils"; // T6336

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const dynamicStyles = makeStyles(theme => ({
  previewInputs: {
    display: 'flex',
    flexDirection: 'column'
  },
  previewFormPaper: {
    overflow: 'hidden',
    marginTop: '8px'
  },
  previewFormHeader : props => ({
    display: 'block',
    backgroundColor: props.headerBackground, // theme.palette.background.default,
    color: props.headerTextColor, // theme.palette.text.primary,
    margin: 0,
    padding: '1em'
  }),
  previewFormContent: props => ({
    backgroundColor: props.pageBackground, // theme.palette.background.paper,
    color: props.pageTextColor  // theme.palette.text.secondary,
  }),
  previewFormLink: props => ({
    color: props.linkTextColor // theme.palette.info.main,
  }),
  previewFormButton: props => ({
    backgroundColor: props.linkTextColor,
    color: props.pageBackground
  })
}));

const fixSymbols = (value) => {
  if (value.length === 4 && value[0] === '#') {
    return `${value[0]}${value[1]}${value[1]}${value[2]}${value[2]}${value[3]}${value[3]}`;
  }
  return value;
};

export const ThemePreview = props => {
  const form = useForm();
  const formData = form.getState().values;
  const translate = useTranslate();
  const theme = useTheme();
  const muiTheme = createMuiTheme(theme);
  const getField = (d, f) => d.themeSettings && d.themeSettings[f];
  const [themeData, setThemeData] = useState({
    headerBackground: getField(formData, 'headerBackground') || fixSymbols(muiTheme.palette.background.default),
    headerTextColor: getField(formData, 'headerTextColor') || fixSymbols(muiTheme.palette.text.primary),
    pageBackground: getField(formData, 'pageBackground') || fixSymbols(muiTheme.palette.background.paper),
    pageTextColor: getField(formData, 'pageTextColor') || fixSymbols(muiTheme.palette.text.secondary),
    linkTextColor: getField(formData, 'linkTextColor') || fixSymbols(muiTheme.palette.info.main)
  });

  const handleResetClick = () => {
    form.change('themeSettings', null);
    form.change('logoFile', null);
    setThemeData({...themeData});
  };

  const themeFields = Object.keys(themeData);
  const dynamicClasses = dynamicStyles(themeData);

  let downloadHref;
  let mimeType;
  if (formData.logoFile && formData.logoFile.src) {
    function toBase64(arr) {
      return btoa(
          arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
    }
    const extension = (formData.themeSettings || {}).logoFileExtension || 'png';
    if (formData.logoFile.src.type === "Buffer" && formData.logoFile.src.data.length > 0) {
      if (extension === 'jpeg' || extension === 'jpg') {
        mimeType = "image/jpeg"
      } else if (extension === 'png') {
        mimeType = "image/png"
      } else if (extension === 'svg') {
        mimeType = "image/svg+xml"
      }
      if (!mimeType) {
        console.error('wrong file extension', formData.logoFile.title);
        return;
      }
      // const blob = new Blob([Buffer.from(formData.logoFile.src.data)], {type: mimeType});
      downloadHref = `data:${mimeType};base64,${toBase64(formData.logoFile.src.data)}`;
    }
  }

  const getUpdateFieldHandler = (fieldName) => (event) => {
    setThemeData({...themeData, [fieldName]: event.target.value});
  }

  return (
    <Grid container={true} spacing={2}>
      <Grid item className={dynamicClasses.previewInputs} lg={6}>
        <TextInput source="themeSettings.brandName" label="resources.clients.inputs.brandName" fullWidth />
        <TextInput source="themeSettings.logoFileExtension" label="resources.clients.inputs.logoFileExtension" fullWidth />
        {downloadHref && <img width="96px" height="25px" src={downloadHref} alt="logo" />}
        <ImageInput source="logoFile" label="Related pictures" accept="image/*">
          <ImageField source="src" title="title" style={{display: 'none'}}/>
        </ImageInput>
        {themeFields.map(fieldName => (
          <TextInput
            type="color"
            source={`themeSettings.${fieldName}`}
            value={formData[fieldName] || themeData[fieldName]}
            key={`themePreview-${fieldName}`}
            label={`resources.clients.inputs.${fieldName}`}
            onChange={getUpdateFieldHandler(fieldName)}
            fullWidth
          />
        ))}
        <Button
          variant="contained"
          onClick={handleResetClick}
        >
          {translate('clear')}
        </Button>
      </Grid>
      <Grid item className={dynamicClasses.previewForm} lg={6}>
        <Paper className={dynamicClasses.previewFormPaper}>
          <h3 className={dynamicClasses.previewFormHeader}>Some title</h3>
          <Box m={1} p={1} className={dynamicClasses.previewFormContent}>
            <Typography>
              Some content text, some content text, some content text, some content text
              some content text, some content text, some content text
            </Typography>
            <Box mt={1} mb={1}>
              <Link className={dynamicClasses.previewFormLink}>Link text</Link>
            </Box>
            <Button variant="contained" color="primary" className={dynamicClasses.previewFormButton}>Button text</Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
};


const ClientEdit = props => {
  const { permissions } = usePermissions();
  const classes = useStyles();
  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.client" {...props} />}
      {...props}
    >
      <TabbedForm redirect="edit">
        <FormTab label="resources.clients.tabs.client">
          <SanitizedGrid
            justify="flex-start"
            container={true}
            spacing={2}
            className={classes.root}
          >
            <SanitizedGrid item lg={6} md={12}>
              <TextInput fullWidth disabled source="uuid"/>
              <BooleanInput source="isActive" label="app.labels.isActive"/>
              <TextInput fullWidth source="name"/>
              <TextInput fullWidth source="description"/>
              <DateField source="createdAt"/>
              <DateField source="updatedAt"/>
            </SanitizedGrid>
          </SanitizedGrid>
        </FormTab>
        { permissions && permissions['clients.users.list'] &&
        <FormTab label="resources.clients.tabs.users" path="users">
          <ReferenceManyField
            addLabel={false}
            sort={{field: 'createdAt', order: 'DESC'}}
            reference="clients"
            target="users"
          >
            <Datagrid rowStyle={withSource("UsersToClients")}>
              <ReferenceField
                label="app.labels.user"
                source="id"
                reference="users"
              >
                <TextField source="login"/>
              </ReferenceField>
              <TextField source="UsersToClients.type" label="app.labels.type"/>
              <TextField
                source="UsersToClients.description"
                label="app.labels.description"
              />
              <EditUserButton/>
              <DeleteUserButton/>
            </Datagrid>
          </ReferenceManyField>
          <AddUserButton/>
        </FormTab>
        }
        <FormTab label="resources.clients.tabs.theme" path="theme">
          <ThemePreview source="theme" />
        </FormTab>
      </TabbedForm>
    </EditWithBreadcrumbs>
  )
};

export default ClientEdit;
