import ClientIcon from '@material-ui/icons/RecentActors';
import ClientList from './ClientList';
import ClientEdit from './ClientEdit';
import ClientCreate from './ClientCreate';

const resources = {
  list: ClientList,
  edit: ClientEdit,
  create: ClientCreate,
  icon: ClientIcon
};

export default resources;
