import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SignalWifi0BarIcon from "@material-ui/icons/SignalWifi0Bar";
import SignalWifi4BarIcon from "@material-ui/icons/SignalWifi4Bar";
import { makeStyles } from "@material-ui/styles";
import React, { Fragment, useState } from "react";
import { EditButton, ReferenceField, TextField } from "react-admin";
import { Link } from "react-router-dom";
import { dateFormatter } from "../../custom/utils";
import { ObjectsListCounter } from "./ObjectsTableButtons";

const mapStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    height: "100%",
  },
  cardHeader: {
    "& .MuiCardHeader-action": {
      alignSelf: "center",
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
}));

const renderDevice = (objectId, device) => {
  // const translate = useTranslate();

  return (
    <Fragment>
      <TableRow key={`object-${objectId}-${device.uuid}-info`}>
        <TableCell colSpan={3}>
          <Box>
            {device.isOnline ? <SignalWifi4BarIcon /> : <SignalWifi0BarIcon />}
            <Link
              to={`/devices/${device.uuid}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              {device.name}
            </Link>
          </Box>
          {device.lastPacketReceivedAt ? (
            <Typography variant="caption" gutterBottom>
              {dateFormatter(new Date(device.lastPacketReceivedAt))}
            </Typography>
          ) : null}
        </TableCell>
      </TableRow>
      {(device.incidents || []).map((i) => (
        <TableRow key={`object-${objectId}-${device.uuid}-incident`}>
          <TableCell>{i.eventName}</TableCell>
          <TableCell>{dateFormatter(new Date(i.dt))}</TableCell>
          <TableCell>{i.type}</TableCell>
        </TableRow>
      ))}
    </Fragment>
  );
};

const ObjectMapCard = ({ item, ...props }) => {
  console.log(item);
  const classes = mapStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        avatar={<ObjectsListCounter count={item.incidents || 0} />}
        action={
          <IconButton
            className={`${classes.expand} ${
              expanded ? classes.expandOpen : null
            }`}
            aria-expanded={false}
            aria-label="show more"
            onClick={() => setExpanded(!expanded)}
          >
            <ExpandMoreIcon />
          </IconButton>
        }
        title={<TextField record={item} source="name" />}
        subheader={
          <ReferenceField
            record={item}
            basePath={"/clients"}
            label="app.labels.client"
            source="clientUuid"
            reference="clients"
          >
            <TextField source="name" />
          </ReferenceField>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {item && item.devices && item.devices.length ? (
            <Table>
              <TableBody>
                {item.devices.map((device) => renderDevice(item.id, device))}
              </TableBody>
            </Table>
          ) : null}
          <CardActions>
            <EditButton
              resource="objects"
              basePath={props.basePath}
              record={item}
              label="перейти к объекту"
            />
          </CardActions>
        </CardContent>
      </Collapse>
    </Card>
  );
};

export default ObjectMapCard;
