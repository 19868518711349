import React from 'react';
import {
  SimpleForm,
  TextInput,
  DateField,
  BooleanInput
} from 'react-admin';
import { makeStyles } from "@material-ui/styles";

import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  hidden: {
    display: "none"
  }
});

export const PermissionEdit = props => {
  const { record, resource, save } = props;
  const classes = useStyles();

  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.permission" {...props} />}
      {...props}
    >
      <SimpleForm
        record={record}
        resource={resource}
        submitOnEnter={false}
        redirect="edit"
        save={save}
      >
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            {record && record.id ? <TextInput fullWidth disabled source="id" /> : ''}
            <BooleanInput source="hidden" label="app.labels.hidden" />
            <BooleanInput source="editable" label="app.labels.editable" className={classes.hidden} />
            <TextInput fullWidth source="name" label="app.labels.name" />
            <TextInput fullWidth source="description" label="app.labels.description" />
            <DateField source="createdAt" label="app.labels.createdAt" />
            <DateField source="updatedAt" label="app.labels.updatedAt" />
          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </EditWithBreadcrumbs>
  );
};
