import DeviceModelIcon from '@material-ui/icons/BubbleChart';
import DeviceModelList from './DeviceModelList';
import DeviceModelEdit from './DeviceModelEdit';
import DeviceModelCreate from './DeviceModelCreate';

const resources = {
  list: DeviceModelList,
  edit: DeviceModelEdit,
  create: DeviceModelCreate,
  icon: DeviceModelIcon
};

export default resources;
