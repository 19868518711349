// import { stringify, parse } from "query-string";
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from "react-admin";
import { isUuid, httpClient } from "../components/custom/utils";

import * as _ from "lodash";

export const UPDATE_REFERENCES = "UPDATE_REFERENCES";
export const GET_REFERENCES = "GET_REFERENCES";
export const UPDATE_NO_ID = "UPDATE_NO_ID";
export const GET_CUSTOM = "GET_CUSTOM";
export const UPDATE_CUSTOM = "UPDATE_CUSTOM";

export const CLEAN_NULL_PROPS = ["quickActionPayload"];

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  }).then((file64) => ({
    src: file64,
    title: file.title,
  }));

export const cleanNulls = _.pickBy;

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?{"embedded":{"users":true},"limit":20,"offset":0,"order":[["createdAt","ASC"]]}:
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_CUSTOM   => GET http://my.api.url/project/version?iamdevelop
 * GET_MANY     => GET http://my.api.url/posts?{"filter":{"id":[123,456,789]}}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 * GET_MANY_REFERENCE => GET http://my.api.url/posts/123/comments?{"filter":{"id":[123,456,789]}}
 */

export default (apiUrl) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = async (type, resource, params) => {
    let url = "";
    const newFiles = [];
    const newMapping = {};
    const options = {};

    // remove empty filter values
    params.filter = _.pickBy(params.filter);

    switch (type) {
      case GET_LIST: {
        /*
        filter
         ├── search (condition)                    
         ├──   ├── field A ┓ or         ┓ and
         ├──   └── field B ┛ condition  | condition
         ├── xyzUuid (optional filter)  ┛
         ├── embedded (omit)
         └── withOnlineFlag (omit)
         */
        let page = null,
          perPage = null,
          field = null,
          order = null;

        const query = {
          count: true,
        };

        if (params.pagination) {
          if (params.pagination.page !== undefined) {
            page = params.pagination.page;
          }
          if (params.pagination.perPage !== undefined) {
            perPage = params.pagination.perPage;
          }
        }

        if (params.sort) {
          field = params.sort.field;
          order = params.sort.order;
        }

        if (page !== null && perPage !== null) {
          query.offset = (page - 1) * perPage;
        }

        if (perPage !== null) {
          query.limit = perPage;
        }

        if (field !== null && order !== null) {
          // query.order = [[field, order]];
          const zip = (rows) =>
            rows[0].map((_, c) => rows.map((row) => row[c]));
          query.order = zip([field.split(","), order.split(",")]);
        }

        let condition = (key, value) => {

          if(key.endsWith('_gte')) {
            key = key.trim().replaceAll('_gte','').replaceAll('_','.');
            value = { $gte: value };
          } else if(key.endsWith('_gt')) {
            key = key.trim().replaceAll('_gt','').replaceAll('_','.');
            value = { $gt: value };
          } else if(key.endsWith('_lte')) {
            key = key.trim().replaceAll('_lte','').replaceAll('_','.');
            value = { $lte: value };
          } else if(key.endsWith('_lt')) {
            key = key.trim().replaceAll('_lt','').replaceAll('_','.');
            value = { $lt: value };
          } else {
            key = (key.indexOf('_') > -1 ? `$${key.trim().replaceAll('_','.')}$` : key.trim());
          }

          switch(typeof value) {
            case "undefined":
              return undefined;
            case "boolean": 
              return { [key] : value};
            case "number" : 
              return { [key] : value};
            case "string" : 
              return { // check if we got find by uuid query
                [key] : RegExp('^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$')
                                .test(value) ? value : { $iLike: `%${value}%`}
              };
            default: return { // object like {$ne: "aeed04e7-4eb9-47fd-8a37-db464d4243bc"}
              [key]: value
            };
          }
        };

        let conditions = Object.keys(_.omit(params.filter, ["embedded","withOnlineFlag"]))
          .map( fname => fname.indexOf(',') > -1 ? 
            { $or: fname.split(',').map(string => condition(string, params.filter[fname]))} 
            : condition(fname, params.filter[fname]));

        query.filter = conditions.length > 1 ? { $and: conditions } : conditions[0];
        query.embedded = _.omit(params.filter.embedded, ["children","rules"]);

        delete params.filter.embedded;
        delete params.filter.withOnlineFlag;

        url = `${apiUrl}/${resource}`;
        if (Object.keys(query).length) {
          url = `${url}?${encodeURIComponent(JSON.stringify(query))}`;
        }

        break;
      }
      case GET_ONE:
        const query = {};

        if (params.filter && params.filter.embedded) {
          query.embedded = params.filter.embedded;
          delete params.filter.embedded;
        }

        if (params.filter) {
          query.filter = _.cloneDeep(params.filter);
        }

        if (params.filter && params.filter.withOnlineFlag !== undefined) {
          query.withOnlineFlag = params.filter.withOnlineFlag;
          delete params.filter.withOnlineFlag;
        }

        url = `${apiUrl}/${resource}/${params.id}`;
        if (Object.keys(query).length) {
          url = `${url}?${encodeURIComponent(JSON.stringify(query))}`;
        }
        break;
      case GET_MANY: {
        // FIXME: get rid off the hack, implementing UUID or ID differentiate logic
        const primaryKeyName = isUuid(params.ids[0]) ? "uuid" : "id";
        const query = {
          count: true,
          filter:
            params.ids && params.ids.length > 0 && params.ids[0]
              ? { [primaryKeyName]: params.ids }
              : {},
        };

        url = `${apiUrl}/${resource}?${encodeURIComponent(
          JSON.stringify(query)
        )}`;
        break;
      }
      case GET_CUSTOM: {
        const query = params.query;

        url = `${apiUrl}/${resource}/${params.uri}`;
        if (query) {
          url = `${url}?${encodeURIComponent(JSON.stringify(query))}`;
        }
        break;
      }
      case GET_MANY_REFERENCE: {
        let page = null,
          perPage = null,
          field = null,
          order = null;
        const query = {
          count: true,
        };

        if (params.pagination) {
          if (params.pagination.page !== undefined) {
            page = params.pagination.page;
          }
          if (params.pagination.perPage !== undefined) {
            perPage = params.pagination.perPage;
          }
        }

        if (params.sort) {
          field = params.sort.field;
          order = params.sort.order;
        }

        if (page !== null && perPage !== null) {
          query.offset = (page - 1) * perPage;
        }

        if (perPage !== null) {
          query.limit = perPage;
        }

        if (field !== null && order !== null) {
          query.order = [[field, order]];
        }

        if (params.filter && params.filter.embedded) {
          query.embedded = params.filter.embedded;
          delete params.filter.embedded;
        }

        [
          "useLogs",
          "timeParameterPath",
          "parameters",
          "messageTypeUuid",
          "reportTemplateUuid",
          "devices",
          "aggInterval",
          "token",
        ].forEach((name) => {
          if (params[name]) {
            query[name] = params[name];
          }
        });

        if (params.fromTime) {
          query.begin = params.fromTime;
        }

        if (params.toTime) {
          query.end = params.toTime;
        }

        if (params.id) {
          url = `${apiUrl}/${resource}/${params.id}/${params.target}`;
        } else {
          url = `${apiUrl}/${resource}/${params.target}`;
        }
        if (Object.keys(query).length) {
          url = `${url}?${encodeURIComponent(JSON.stringify(query))}`;
        }

        options.method = params.method || "GET";
        if (["PUT", "POST"].indexOf(options.method) >= 0) {
          options.body = JSON.stringify(query);
        }
        break;
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "PUT";
        for (let propName in params.data) {
          const propObj = params.data[propName];
          if (CLEAN_NULL_PROPS.indexOf(propName) > -1) {
            params.data[propName] = cleanNulls(propObj);
          }
          if (propObj && propObj.rawFile && propObj.rawFile instanceof File) {
            newFiles.push(propObj);
            newMapping[propObj.title] = propName;
          }
          if (
            propObj &&
            propObj.title &&
            propObj.src &&
            propObj.src.type === "Buffer"
          ) {
            const buff = new Buffer(propObj.src);
            params.data[propName] = {
              src: buff.toString("base64"),
              title: propObj.title,
            };
          }
        }
        if (newFiles) {
          await Promise.all(newFiles.map(convertFileToBase64)).then(
            (transformedNewFiles) =>
              transformedNewFiles.forEach((fileObj) => {
                const propName = newMapping[fileObj.title];
                params.data[propName] = fileObj;
              })
          );
        }
        options.body = JSON.stringify(params.data);
        break;
      case UPDATE_NO_ID:
        url = `${apiUrl}/${resource}`;
        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      case UPDATE_REFERENCES:
        url = `${apiUrl}/${resource}/${params.id}/${params.target}`;
        options.method = "PUT";
        [
          "useLogs",
          "timeParameterPath",
          "parameters",
          "messageTypeUuid",
          "reportTemplateUuid",
          "devices",
          "aggInterval",
          "token",
        ].forEach((name) => {
          if (!params.data[name]) {
            delete params.data[name];
          }
        });
        options.body = JSON.stringify(params.data);
        break;
      case GET_REFERENCES:
        url = `${apiUrl}/${resource}/${params.id}/${params.target}`;
        break;
      case UPDATE_CUSTOM: {
        const query = params.query;

        url = `${apiUrl}/${resource}/${params.uri}`;
        if (query && query.length) {
          url = `${url}?${encodeURIComponent(query)}`;
        }
        for (let propName in params.data) {
          const propObj = params.data[propName];
          if (CLEAN_NULL_PROPS.indexOf(propName) > -1) {
            params.data[propName] = cleanNulls(propObj);
          }
        }

        options.method = "PUT";
        options.body = JSON.stringify(params.data);
        break;
      }
      case CREATE:
        url = `${apiUrl}/${resource}`;
        options.method = "POST";
        for (let propName in params.data) {
          const propObj = params.data[propName];
          if (propObj && propObj.rawFile && propObj.rawFile instanceof File) {
            newFiles.push(propObj);
            newMapping[propObj.title] = propName;
          }
        }
        if (newFiles) {
          await Promise.all(newFiles.map(convertFileToBase64)).then(
            (transformedNewFiles) =>
              transformedNewFiles.forEach((fileObj) => {
                const propName = newMapping[fileObj.title];
                params.data[propName] = fileObj;
              })
          );
        }
        options.body = JSON.stringify(params.data);
        break;
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`;
        options.method = "DELETE";
        break;
      default:
        throw new Error(`Unsupported fetch action type ${type}`);
    }
    return { url, options };
  };

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response;
    // TODO: replace this if with case GET_FILE and use it when needed

    switch (type) {
      case GET_LIST:
      case GET_MANY:
      case UPDATE_REFERENCES:
      case GET_MANY_REFERENCE:
        if (!headers.has("content-range")) {
          console.warn(`The Content-Range header is missing in the HTTP Response. 
            The simple REST data provider expects responses for lists of resources 
            to contain this header with the total number of results to build the 
            pagination. If you are using CORS, did you declare Content-Range in 
            the Access-Control-Expose-Headers header?`);
          return {
            total: 0,
            data: json.map((resource) => ({...resource, id: resource.id || resource.uuid}))
          }; 
        }

        try {
          return {
            total: parseInt(headers.get("content-range").split("/").pop(), 10),
            data: json.map((resource) => ({...resource, id: resource.id || resource.uuid}))
          };
        } catch(err) {
          console.error(err);
          return {
            total: 0,
            data: json.map((resource) => ({...resource, id: resource.id || resource.uuid}))
          };            
        }

      case CREATE:
        return { data: { ...params.data, id: json.id || json.uuid } };
      case DELETE:
        // T4711 see https://marmelab.com/admin-on-rest/RestClients.html#writing-your-own-rest-client
        return { data: { ...params } };
      case GET_REFERENCES:
      case UPDATE_NO_ID:
        return json;
      default:
        return {
          data: { ...json, id: json.id || json.uuid },
        };
    }
  };

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (reqType, reqResource, params) => {
    let type = reqType;
    let resource = reqResource;

    if (params && params.filter && params.filter.forward) {
      resource = params.filter.forward.resource;
      type = params.filter.forward.type;
      Object.assign(params, params.filter.forward.params || {});
    }

    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: "DELETE",
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }));
    }

    return convertDataRequestToHTTP(type, resource, params).then((res) => {
      const { url, options } = res;
      return httpClient(url, options)
        .then((response) => {
          return convertHTTPResponse(response, type, resource, params);
        })
        .catch((error) => {
          // T5289 pass error source to input params
          if (params && error.errorSource) {
            params.errorSource = error.errorSource;
          }
          throw error;
        });
    });
  };
};
