import React, { useState } from "react";
import { useDispatch } from "react-redux";

import {
  useTranslate,
  Button,
  showNotification
} from "react-admin";

import { UPDATE_CUSTOM } from "../../providers/dataProvider";
import IconRun from "@material-ui/icons/PlayCircleOutline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const handleRun = (event, dispatch, dryRunOn, setDeviceLockedBy, props) => {
  const {
    selectedIds,
    dryRun,
    setDryRun,
    dataProvider,
    deviceDataRef,
    setPollingToken,
  } = props;
  let { pollingToken } = props;

  dryRun.queue = selectedIds;
  dryRun.payload = dryRun.message;
  setDryRun(dryRun);

  let params = {
    uri: `${dryRun.deviceUuid}/rules/run`,
    data: {
      payload: dryRun.payload,
      queue: dryRun.queue,
      dryRun: dryRunOn
    }
  };

  return dataProvider(UPDATE_CUSTOM, "devices", params)
    .then(response => {
      if (response && response.data && response.data.token) {
        localStorage.setItem("pollingToken", response.data.token);
        pollingToken = response.data.token;
        setPollingToken(pollingToken);

        setDeviceLockedBy(null);

        setTimeout(() => {
          deviceDataRef && deviceDataRef.startCheckingData();
        }, 1000);
      }
    })
    .catch(error => {
      if (params.errorSource && params.errorSource.lockMeta) {
        const lockMeta = params.errorSource.lockMeta;
        const username =
          (lockMeta.user && lockMeta.user.login) || lockMeta.scheduler || "n/a";
        setDeviceLockedBy(username);
      }

      dispatch(
        showNotification(
          (error && error.message) || "errors.some_error",
          "warning"
        )
      );
    });
};

export const RulesListBulkActionButtons = props => {
  const { selectedIds } = props;
  const dispatch = useDispatch();
  let [deviceLockedBy, setDeviceLockedBy] = useState(null);
  let [dryRunOn, setDryRun] = useState(false);
  const translate = useTranslate();

  return (
    <div style={{ backgroundColor: 'rgb(234, 236, 247)', color: '#3f51b5', padding: '1rem' }}>
      {deviceLockedBy && (
        <span>
          {translate("app.labels.deviceLockedBy")} {":"} <b>{deviceLockedBy}</b>
        </span>
      )}
      <span style={{ marginLeft: "0.5em", marginRight: "0.5rem" }}>
        { selectedIds.length } {translate('app.labels.itemsSelected')}
      </span>
      <FormControlLabel
        control={
          <Checkbox
            checked={dryRunOn}
            onChange={e => setDryRun(e.target.checked)}
            color="primary"
          />
        }
        label="dryRun"
      />
      <Button
        label="app.labels.run"
        onClick={event => handleRun(event, dispatch, dryRunOn, setDeviceLockedBy, props)}
      >
        <IconRun />
      </Button>
    </div>
  );
};
