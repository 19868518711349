import React from 'react';
import PropTypes from 'prop-types';
import {
  TextField, BooleanField, ReferenceField
} from 'react-admin';
import {makeStyles} from "@material-ui/styles";
import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";
import rowStyle from '../styling/rowStyle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Typography from '@material-ui/core/Typography';


const GRAFANA_HOME_PAGE = window.sprutConfig.SPRUT_GRAFANA_HOME_PAGE || process.env.REACT_APP_GRAFANA_HOME_PAGE || '/grafana';

const DashboardLinkField = ({ source, className, record = {} }) => {
  let dashboardUrl = record[source] || '';
  if (!dashboardUrl.startsWith('http')) {
    dashboardUrl = `${GRAFANA_HOME_PAGE}${dashboardUrl}`;
  }

  return (<Typography className={className}>
            <a href={dashboardUrl} target="_blank" rel="noopener noreferrer" >
              <DashboardIcon />
            </a>
          </Typography>);
};

DashboardLinkField.propTypes = {
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  uuidField: {
    minWidth: '250px',
    display: 'inline-block'
  },
  field: {
    width: '120px',
    display: 'inline-block'
  },
  linkField: {
    width: '200px',
    display: 'inline-block',
    '& > svg': {
      width: '0.75rem',
      height: '0.75rem'
    }
  },
  narrow: {
    maxWidth: '2em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  normal: {
    maxWidth: '4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  wide: {
    maxWidth: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
});

const DashboardList = props => {
  const classes = useStyles();
  return (
    <ListWithBreadcrumbs
      title={<CustomTitle titleKey="app.labels.dashboards" {...props} />}
      bulkActionButtons={<BulkDeleteAction />}
      filters={<StyledSearchFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      {...props}
    >
      <Datagrid uuidClassName={classes.uuidField} rowStyle={rowStyle('isActive')}
        editBtnDisable={true} >
        <TextField source="type" label="app.labels.type" className="narrow" />
        <ReferenceField label="app.labels.client" source="clientUuid" reference="clients">
          <TextField source="name" />
        </ReferenceField>
        <BooleanField source="isDefault" label="app.labels.isDefault" className={classes.field} />
        <BooleanField source="isPersonal" label="app.labels.isPersonal" className={classes.field} />
        <DashboardLinkField source="url" label="app.labels.url" className={classes.linkField} />
      </Datagrid>
    </ListWithBreadcrumbs>
  )
};

export default DashboardList;
