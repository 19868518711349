import React from 'react';
import { Route } from 'react-router-dom';
import { DryRun } from '../components/dry-run';
import { Tree } from '../components/tree';
import OpenDashboard from "../components/dashboards/OpenDashboard";
import { Settings } from '../components/settings';
import JournalList from "../components/journals/JournalList";
import BaseReport from "../components/reports/BaseReport";

export default [
  <Route exact path="/dry-run" component={DryRun} />,
  <Route exact path="/tree" component={Tree} />,
  <Route exact path="/" component={OpenDashboard} />,
  <Route exact path="/base-report" component={BaseReport} />,
  <Route exact path="/journals" component={JournalList} />,
  <Route exact path="/settings" component={Settings} />,
];
