import React from 'react';
import {
  SimpleForm,
  TextInput,
  DateField
} from 'react-admin';
import {makeStyles} from "@material-ui/styles";

import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const VendorEdit = props => {
  const classes = useStyles();
  return (
  <EditWithBreadcrumbs
    title={<CustomEditTitle titleKey="app.labels.vendor" {...props} />}
    {...props}
  >
    <SimpleForm redirect="edit">
      <SanitizedGrid
        justify="flex-start"
        container={true}
        spacing={2}
        className={classes.root}
      >
        <SanitizedGrid item lg={6} md={12}>
          <TextInput fullWidth source="name" label="app.labels.name" />
          <TextInput fullWidth source="description" label="app.labels.description" />
          <DateField source="createdAt" label="app.labels.createdAt" />
          <DateField source="updatedAt" label="app.labels.updatedAt" />
        </SanitizedGrid>
      </SanitizedGrid>
    </SimpleForm>
  </EditWithBreadcrumbs>
)};

export default VendorEdit;
