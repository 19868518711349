import VendorIcon from '@material-ui/icons/LocalShipping';
import VendorList from './VendorList';
import VendorEdit from './VendorEdit';
import VendorCreate from './VendorCreate';

const resources = {
  list: VendorList,
  edit: VendorEdit,
  create: VendorCreate,
  icon: VendorIcon
};

export default resources;
