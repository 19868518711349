import React from 'react';
import {
  BooleanField,
  TextField,
  ReferenceField,
  NumberField,
  FunctionField,
  Datagrid,
} from 'react-admin';

import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import RuleAssignmentNameLink from '../custom/RuleAssignmentNameLink';
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";
import { makeStyles } from "@material-ui/styles";

const scheduleStyles = makeStyles({
  item: {
    margin: '0 0 16px 0',
    '&:last-child': {
      margin: '0',
    }
  },
  name: {
    margin: '0 0 4px 0'
  },
  schedule: {
    margin: '0'
  }
});

const ScheduleField = ({...props}) => {
  const classes = scheduleStyles();
  return (
    <FunctionField
      {...props}
      render={(record, source) =>
        record[source].map(schedule => (
          <div className={classes.item}>
            <p className={classes.name}>{schedule.name}</p>
            <p className={classes.schedule}>{schedule.schedule}</p>
          </div>
        ))
      }
    />);
};

const RuleList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.ruleAssignments" {...props} />}
    bulkActionButtons={<BulkDeleteAction />}
    filters={<StyledSearchFilter />}
    filter={{
      embedded: {}
    }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <RuleAssignmentNameLink />
      <BooleanField source="isActive" label="app.labels.isActive" />
      <BooleanField source="dryRun" label="app.labels.dryRun" />
      <ReferenceField
        label="app.labels.rule"
        source="ruleUuid"
        reference="rules"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="app.labels.mode" source="mode" />
      <TextField label="app.labels.class" source="class" />
      <TextField label="app.labels.executionType" source="executionType" />
      <NumberField source="priority" label="app.labels.priority" />
      <ScheduleField source="schedule" label="app.labels.schedule" />
      <ReferenceField
        label="app.labels.deviceType"
        source="deviceTypeUuid"
        reference="devices-types"
        allowEmpty={true}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="app.labels.deviceModel"
        source="deviceModelUuid"
        reference="devices-models"
        allowEmpty={true}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="app.labels.device"
        source="deviceUuid"
        reference="devices"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default RuleList;
