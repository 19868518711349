import ObjectTypeIcon from '@material-ui/icons/Category';
import ObjectTypeList from './ObjectTypeList';
import ObjectTypeEdit from './ObjectTypeEdit';
import ObjectTypeCreate from './ObjectTypeCreate';

const resources = {
  list: ObjectTypeList,
  edit: ObjectTypeEdit,
  create: ObjectTypeCreate,
  icon: ObjectTypeIcon
};

export default resources;
