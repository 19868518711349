import React from "react";
import { Link } from 'react-router-dom';
import {makeStyles} from "@material-ui/styles";

import IconChildren from "@material-ui/icons/AccountTree";

import VerticalButton from '../custom/VerticalButton'; // T5113

const buttonWithIcon = makeStyles({
	verticalButton: {
		padding: 0,
		'& span': {
			lineHeight: '11px'
		}
	}
});

const ChildrenButton = ({ record, ...props }) => {
	const classes = buttonWithIcon();

	return record && record.children && record.children.length ? (
		<VerticalButton
			variant="text"
			className={classes.verticalButton}
			component={Link}
			to={`/devices/${record.uuid}/children`}
			label="app.labels.children"
		>
			<IconChildren />
		</VerticalButton>
	) : null;
};

export default ChildrenButton;
