import React, {useEffect, useState, Fragment} from 'react';
import 'react-select2-wrapper/css/select2.css';
import {
  ReferenceField,
  TextField,
  BooleanField,
  List,
  withDataProvider,
  GET_LIST, TopToolbar, ExportButton,
} from 'react-admin';
import { makeStyles } from "@material-ui/styles";

import isActiveRowStyle from '../custom/isActiveRowStyle'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import UuidNameTextField from '../custom/UuidNameTextField'; // T5113
import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { GroupsFilterForm } from "../custom/GroupsFilterForm";
import { InlineArrayEditField } from "../custom/InlineArrayEditFIeld";
import { OnlineField } from "../custom/OnlineField";
import ChildrenButton from "../custom/ChildrenButton";
import {withStyles} from "@material-ui/core/styles";

const StyledToolbar = withStyles({
  root: {
    padding: 0,
    justifyContent: 'space-between',
    width: '100%'
  },
})(TopToolbar);

export const ListActionsWithGroupsFilterForm = ({children, ...rest}) => {
  return (
    <StyledToolbar>
      {rest.filters && React.cloneElement(rest.filters, {
        resource: rest.resource,
        showFilter: rest.showFilter,
        displayedFilters: rest.displayedFilters,
        filterValues: rest.filterValues,
        context: 'button',
      }) }
      <ExportButton
        disabled={rest.total === 0}
        resource={rest.resource}
        sort={rest.currentSort}
        filter={rest.filterValues}
        exporter={rest.exporter}
      />
    </StyledToolbar>
  );
}

const tableStyles = makeStyles({
  booleanCell: {
    padding: "6px",
  }
});

const ObjectDevicesList = ({dataProvider, record, basePath, location, match, filterValues, ...props }) => {
  const [allDeviceGroups, setAllDeviceGroups] = useState([]);
  const search = location.search;
  const queryName = 'groups';
  const params = new URLSearchParams(search);
  const groupsNames = params.get(queryName);
  let initialFilter = true;
  if (groupsNames && groupsNames.split(',').length) {
    initialFilter = groupsNames.split(',')
  }
  const [groupsFilter, setGroupsFilter] = useState(initialFilter);

  const classes = tableStyles();
  useEffect(() => {
    dataProvider(GET_LIST, 'devices-groups', {
      sort: {field: 'updatedAt', order: 'desc'},
    }).then(result => setAllDeviceGroups(result.data.map(group => ({'text': group.name, 'id': group.uuid}))));
  }, [setAllDeviceGroups, dataProvider]);

  return (
    <Fragment>
      <List
        bulkActionButtons={<BulkDeleteAction />}
        sort={{ field: 'createdAt', order: 'DESC' }}
        filter={{
          objectUuid: record.id,
          embedded: {
            groups: typeof groupsFilter === 'boolean' ? groupsFilter : {filter: {name: {'$in': groupsFilter }}}
          }
        }}
        filters={
          <GroupsFilterForm
            location={location}
            allGroups={allDeviceGroups}
            groupsFilter={groupsFilter}
            setGroupsFilter={setGroupsFilter}
            queryName={queryName}
            selectId={"object-devices-list-filter"}
          />
        }
        actions={
          <ListActionsWithGroupsFilterForm filterValues={filterValues} />
        }
        hasCreate={false}
        hasEdit={true}
        hasList={false}
        hasShow={false}
        match={match}
        title=" "
        style={{ width: '100%' }}
        resource={'devices'}
        basePath={basePath}
        location={location}
        perPage={25}
      >
        <Datagrid rowStyle={isActiveRowStyle} editBtnDisable={true}>
          <OnlineField source="isOnline" label="app.labels.isOnline" />
          <BooleanField
            source="isActive"
            label="app.labels.isActive"
            cellClassName={classes.booleanCell}
            headerClassName={classes.booleanCell}
          />
          <ReferenceField
            label="app.labels.client"
            source="clientUuid"
            reference="clients"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="app.labels.deviceModel"
            source="deviceModelUuid"
            reference="devices-models"
          >
            <TextField source="name" />
          </ReferenceField>
          <UuidNameTextField source="parentUuid" label="app.labels.parent" />
          <ChildrenButton />
          <InlineArrayEditField
            dataProvider={dataProvider}
            setAllGroups={() => {console.log('setAllDeviceGroups')}}
            allGroups={allDeviceGroups}
            modelName={"devices-groups"}
          />
        </Datagrid>
      </List>
    </Fragment>
  );
}

export default withDataProvider(ObjectDevicesList)
