import { makeStyles } from "@material-ui/styles";
import React, { Fragment } from "react";
import { Link } from 'react-router-dom';

const uuidNameStyles = makeStyles({
	link: {
		textDecoration: 'none',
		color: '#3f51b5',
		'&:hover, &:visited, &:active': {
			textDecoration: 'none',
			color: '#3f51b5',
		},
	},
	uuid: {
		fontSize: '11px',
		color: 'gray',
		marginTop: '4px'
	}
});

const RuleAssignmentNameLink = ({
	record,
	resource
}) => {
	const classes = uuidNameStyles();
	const name = record.quickActionName ? record.quickActionName : (record.rule) ? record.rule.name : null;
	return (
		<Fragment>
			<Link className={classes.link} to={`/${resource}/${record.uuid || record.id}`}>
        <span className={classes.container}>
          <span className={classes.name}>
            {name ? name : record.uuid}
          </span>
        </span>
			</Link>
			{name ?
				<div>
					<span className={classes.uuid}>{record.uuid}</span>
				</div> : null
			}
		</Fragment>
	);
};

export default RuleAssignmentNameLink;
