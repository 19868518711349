import React, {Fragment} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import {
  SelectInput,
  AutocompleteInput,
  Labeled,
  translate
} from 'react-admin';

import DeviceData, { RANGE_LEVEL_OK, RANGE_LEVELS } from '../devices/DeviceData';
import DeviceRawData from '../devices/DeviceRawData';
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920

import { EditorContainer } from '../custom/CodeEditor';

const AGG_TYPE_LAST = 'last';
const AGG_TYPE_SUM = 'sum';
const AGG_TYPE_AVG = 'avg';
const AGG_TYPE_MIN = 'min';
const AGG_TYPE_MAX = 'max';
const AGG_TYPE_COUNT = 'count';
const AGG_TYPE_CALC = 'calc';
const AGG_TYPES = [AGG_TYPE_LAST, AGG_TYPE_SUM, AGG_TYPE_MIN, AGG_TYPE_MAX, AGG_TYPE_AVG, AGG_TYPE_COUNT, AGG_TYPE_CALC];

const postProcessJsPlaceHolder = 'value';

const rangePanel = {
  marginBottom: '20px',
  padding: '10px',
  boxShadow: '0 0 10px rgba(0,0,0,0.2)',
  borderRadius: '6px',
  backgroundColor: 'white'
};

const heading = {
  flexBasis: '33.33%',
  flexShrink: 0
};

const secondaryHeading = {
  color: 'rgba(0, 0, 0, 0.54)',
  paddingLeft: '16px'
};

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0, v = (c === 'x' ? r : ((r & 0x3) | 0x8));
    return v.toString(16);
  });
}

const addIdToItem = item => {
  if (item.id === undefined) {
    item.id = uuidv4();
  }
  return item;
}

const configuratorRangeFactory = name => {
  return {
    name,
    id: uuidv4(),
    conditionJs: 'return value;',
    level: RANGE_LEVEL_OK
  };
};

const configuratorParamFactory = name => {
  return {
    name,
    id: uuidv4(),
    description: '',
    path: 'payload',
    aggType: 'last',
    measureUnitId: null,
    postProcessJs: null,
    ranges: [],
  };
};

const unitChoiceText = unit => <div>{ unit.name }{' '}<span dangerouslySetInnerHTML={{__html: unit.symbol}}></span></div>;


class ValueRanges extends React.Component {
  constructor(props) {
    super(props);
    this.handleNewItem = this.handleNewItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.handleChangeRange = this.handleChangeRange.bind(this);
    this.handleChangeRangeCondition = this.handleChangeRangeCondition.bind(this);
    this.state = {
      list: (props.items || []).map(addIdToItem),
    }
  }

  handleNewItem(e) {
    e.preventDefault();
    let list = this.state.list.map(addIdToItem).slice();
    let current = this.props.itemFactory(`range - ${list.length}`);
    list = list.concat(current);
    this.props.onChange(list);
    this.setState({ list });
  }

  handleRemoveItem(item, e) {
    e.preventDefault();
    let list = this.state.list.map(addIdToItem).filter( i => i.id !== item.id);
    this.props.onChange(list);
    this.setState({ list });
  }

  handleChangeRange(item, e) {
    e.preventDefault();
    const field = e.target.name;
    const value = e.target.value;
    let list = this.state.list.map(addIdToItem).slice();
    list.forEach(i => {
      if (i.id === item.id) {
        i[field] = value;
      }
    });
    this.props.onChange(list);
    this.setState({ list });
  }

  handleChangeRangeCondition(item, value) {
    const field = 'conditionJs';
    let list = this.state.list.map(addIdToItem).slice();
    list.forEach(i => {
      if (i.id === item.id) {
        i[field] = value;
      }
    });
    this.props.onChange(list);
    this.setState({ list }
    );
  }

  getSnapshotBeforeUpdate(prevProps) {
    return null;
  }

  componentDidUpdate(prevProps) {
    if( prevProps.items !== this.props.items) {
      this.setState({list: this.props.items.map(addIdToItem)});
    }
  }

  render() {
    const { state } = this;
    return (
      <Fragment>
        { state.list.map(r => (
          <Box key={r.id} component="div" display="inline">
            <Grid container spacing={0} justify="flex-start" style={rangePanel}>
              <Grid container item lg={10} md={10} xs={10}>
                <Grid container item lg={12} md={12} xs={12} spacing={2}>
                  <Grid item lg={6} md={6} xs={12}>
                    <Labeled fullWidth label="app.configurator.range.level">
                      <Select
                        value={r.level || RANGE_LEVEL_OK}
                        onChange={ e => this.handleChangeRange(r, e) }
                        inputProps={{ name: 'level' }}
                      >
                        {RANGE_LEVELS.map( (level, i) => (
                          <MenuItem key={level} value={level}>{level}</MenuItem>
                        ))}
                      </Select>
                    </Labeled>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Labeled fullWidth label="app.configurator.range.name">
                      <TextField value={r.name} name="name" onChange={ e => this.handleChangeRange(r, e) } />
                    </Labeled>
                  </Grid>
                </Grid>
                <Grid container item lg={12} md={12} xs={10} style={{marginTop: '10px'}}>
                  <EditorContainer
                    name="conditionJs"
                    label="app.configurator.range.conditionJs"
                    placeholder={''}
                    mode="javascript"
                    height="30px"
                    input={{value: r.conditionJs || ''}}
                    callback={ v => this.handleChangeRangeCondition(r, v) }
                    meta={{}}
                  />
                </Grid>
              </Grid>
              <Grid item lg={2} md={2}>
                <Grid container justify="center" alignItems="center" style={{height: "100%"}}>
                  <IconButton edge="end" onClick={ (e) => this.handleRemoveItem(r, e)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          )
        )}
        <br />
        <IconButton edge="end" onClick={this.handleNewItem}>
          <AddIcon />
        </IconButton>
      </Fragment>
    );
  }
}

class ListWithAddItem extends React.Component {
  constructor(props) {
    super(props);
    this.handleNewItem = this.handleNewItem.bind(this);
  }

  handleNewItem(e) {
    e.preventDefault();
    this.props.onChange(e);
  }

  render() {
    return (
      <Fragment>
        <List component="nav">
          <ListItem
            button
            onClick={this.handleNewItem}
          >
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={this.props.translate('resources.messages-types.paramsPage.add')} />
          </ListItem>
        </List>
        <Divider />
      </Fragment>
    );
  }
}

class Configurator extends React.Component {

  constructor(props) {
    super(props);

    this.createNewItem = this.createNewItem.bind(this);
    this.handleParametersListChange = this.handleParametersListChange.bind(this);
    this.handleRangeListChange = this.handleRangeListChange.bind(this);
    this.handleOnDeviceChange = this.handleOnDeviceChange.bind(this);
    this.handleParameterChange = this.handleParameterChange.bind(this);
    this.handlePanelChange = this.handlePanelChange.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);

    this.state = {
      data: props.data,
      parameters: (props.data && props.data.parameters ? props.data.parameters : []).map(addIdToItem),
      deviceUuid: undefined,
      expanded: null
    };

    // pass _hackDeviceUuid to form to allow ReferenceInput work
    this.props.form.change('_hackDeviceUuid', null);
  }

  handleParametersListChange(parameters) {
    const data = { ...this.state.data, parameters: parameters };

    this.setState({ data });

    // update form field value
    this.props.form.change('configurator', data);
  }

  handleRangeListChange(ranges, current) {
    let parameters = this.state.data.parameters.slice();
    parameters.forEach( p => {
      if (p.id === current.id) {
        p.ranges = ranges;
      }
    });

    this.handleParametersListChange(parameters);
  }

  handleOnDeviceChange(deviceUuid) {
    this.setState({ deviceUuid });
  }

  handleParameterChange(e, index, fieldName, fieldValue) {

    const field = fieldName || (e.target && e.target.name);
    const value = fieldValue || (e.target && e.target.value);

    let current = {...this.state.data.parameters[index]};
    let data = {...this.state.data};

    data.parameters = data.parameters.map( p => {
      if (p.id === current.id) {
        p[field] = value;
      }
      return p;
    });

    this.setState({
      data
    });

    // update form field value
    this.props.form.change('configurator', data);
  }

  createNewItem(e) {
    e.preventDefault();

    let data = {...this.state.data};

    let list = [];

    if (data.parameters && data.parameters.length) {
      list = data.parameters.map(addIdToItem).slice();
    }

    let current = configuratorParamFactory(`New param - ${list.length}`);
    list = list.concat(current);
    this.handleParametersListChange(list);
  }

  handleRemoveItem(item, e) {
    e.preventDefault();
    let parameters = this.state.data.parameters.map(addIdToItem).filter( i => i.name !== item.name);
    this.handleParametersListChange(parameters);
    this.setState({expanded: null});
  }

  handlePanelChange(panel, index, e) {
    const { expanded } = this.state;
    if (expanded === panel) {
      this.setState({expanded: null});
    } else {
      this.setState({expanded: panel});
    }
  };

  render () {
    const {state, props} = this;
    return (
      <Grid container>
        <Grid item lg={6} md={12}>
          {state.data && state.data.parameters && state.data.parameters.map((parameter, index) => (
            <ExpansionPanel
              key={parameter.id}
              expanded={state.expanded === `panel${index}`}
              onChange={e => this.handlePanelChange(`panel${index}`, index, e)}
              style={{backgroundColor: '#f5f5f5'}}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Typography style={heading}>
                  {parameter.name}
                </Typography>
                <Typography style={secondaryHeading}>
                  {parameter.path}
                </Typography>
                <Typography style={secondaryHeading}>
                  {parameter.aggType}
                </Typography>
              </ExpansionPanelSummary>

              <Grid item lg={12} md={12} style={{padding: '20px', backgroundColor: '#ffffff'}}>
                <Grid container item lg={12} md={12} spacing={2} justify="flex-end">
                  <IconButton edge="end" onClick={ (e) => this.handleRemoveItem(parameter, e)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid container item lg={12} md={12} spacing={2}>
                  <Labeled fullWidth label="app.configurator.parameters.path">
                    <TextField value={parameter.path} name="path" onChange={e => this.handleParameterChange(e, index)} />
                  </Labeled>
                  <Labeled fullWidth label="app.configurator.parameters.postProcess">
                    <TextField value={parameter.postProcessJs} name="postProcessJs" placeholder={postProcessJsPlaceHolder} helperText={props.translate('app.configurator.parameters.postProcessHelperText')} onChange={e => this.handleParameterChange(e, index)} />
                  </Labeled>
                </Grid>
                <Grid container item lg={12} md={12} alignItems="flex-end" style={{paddingTop: '20px'}}>
                  <Grid item lg={3} md={3} xs={12}>
                    <Labeled fullWidth label="app.configurator.parameters.name">
                      <TextField value={parameter.name} name="name" onChange={e => this.handleParameterChange(e, index)} />
                    </Labeled>
                  </Grid>
                  <Grid item lg={1} md={1} xs={12}> &nbsp;
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Labeled fullWidth label="app.configurator.aggType">
                      <Select
                        name="aggType"
                        value={parameter.aggType || AGG_TYPE_LAST}
                        onChange={(e, item) => this.handleParameterChange(e, index, 'aggType', item.key)}
                        inputProps={{ name: 'aggType' }}
                      >
                        {AGG_TYPES.map( aggType => (
                          <MenuItem key={aggType} value={aggType || ''}>{aggType}</MenuItem>
                        ))}
                      </Select>
                    </Labeled>
                  </Grid>
                  <Grid item lg={1} md={1} xs={12}> &nbsp;
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <ReferenceInputWithFilter
                      label="app.configurator.chooseMeasureUnit"
                      source="measureUnitId"
                      reference="measure-units"
                      value={parameter.measureUnitId || ''}
                      onChange={(e, id) => this.handleParameterChange(e, index, 'measureUnitId', id)}
                    >
                      <SelectInput
                        optionText={unitChoiceText}
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} style={{paddingTop: '10px'}}>
                  <Labeled fullWidth label="app.configurator.parameters.description">
                    <TextField value={parameter.description} name="description" multiline={true} onChange={e => this.handleParameterChange(e, index)} />
                  </Labeled>
                </Grid>
                <Grid item lg={12} md={12} style={{paddingTop: '20px'}}>
                  <Typography variant="body1" component="div">
                    {props.translate('app.configurator.ranges')}
                  </Typography>
                </Grid>
                <Grid item lg={12} md={12} style={{paddingTop: '20px'}}>
                  <ValueRanges
                    onChange={list => this.handleRangeListChange(list, parameter)}
                    itemFactory={configuratorRangeFactory}
                    items={parameter.ranges} />
                </Grid>
              </Grid>
            </ExpansionPanel>
          ))}
          <Grid item lg={12} md={12}>
            {state.data && (<ListWithAddItem
              onChange={this.createNewItem}
              classes={props.classes}
              translate={props.translate}
            />)}
          </Grid>

          {state.data && state.data.parameters && (
            <Grid item lg={12} md={12} xs={12}>
              <ReferenceInputWithFilter
                label="app.configurator.chooseDevice"
                resource={props.resource}
                source="_hackDeviceUuid"
                reference="devices"
                filter={{ isActive: true, deviceModelUuid: props.deviceModelUuid }}
                onChange={this.handleOnDeviceChange}
              >
                <AutocompleteInput
                  optionText="name"
                  options={{
                    fullWidth: true
                  }}
                />
              </ReferenceInputWithFilter>
              <br />
              {state.deviceUuid !== undefined && <DeviceRawData deviceUuid={state.deviceUuid} messageTypeUuid={props.messageTypeUuid} tableView={false} /> }
              <br />
              {state.deviceUuid !== undefined && <DeviceData deviceUuid={state.deviceUuid} messageTypeUuid={props.messageTypeUuid} parameters={state.data.parameters} perPage={10} source="stats"  tableView={false} {...props}/>}
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default translate(Configurator);
