import DeviceTypeIcon from '@material-ui/icons/Category';
import DeviceTypeList from './DeviceTypeList';
import DeviceTypeEdit from './DeviceTypeEdit';
import DeviceTypeCreate from './DeviceTypeCreate';

const resources = {
  list: DeviceTypeList,
  edit: DeviceTypeEdit,
  create: DeviceTypeCreate,
  icon: DeviceTypeIcon
};

export default resources;
