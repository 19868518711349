import React from 'react';
import {
  TextField,
} from 'react-admin';

import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";

const VendorList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.vendors" {...props} />}
    bulkActionButtons={<BulkDeleteAction />}
    filters={<StyledSearchFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="description" label="app.labels.description" />
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default VendorList;
