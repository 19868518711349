import React  from "react";
import {
  TextInput
} from 'react-admin';
import { makeStyles } from "@material-ui/styles";

import CustomTitle from '../custom/CustomTitle'; // T5275
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';
import SanitizedGrid from "../custom/SanitizedGrid";
import SchemaEditForm from "../custom/SchemaForm";

const placeholderItemParamsSchema = {
  "$id": "https://example.com/schemas/v01/example.json",
  "type": "object",
  "title": "Title",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "required": [],
  "properties": {},
  "definitions": {},
  "description": "description"
};

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const TypicalSchemaCreate = props => {
  const classes = useStyles();
  return (
    <CreateWithBreadcrumbs
      title={
        <CustomTitle
          titleKey="resources.typical-schemas.titles.newTypicalSchema"
          {...props}
        />
      }
      record={{}}
      {...props}
    >
      <SchemaEditForm
        schemaName="schema"
        flattenSchemaName="flattenSchema"
        schemaLabel="app.labels.schema"
        ignoreState={true}
        schemaPlaceholder={placeholderItemParamsSchema}
      >
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
          style={{display: 'none'}}
        >
          <TextInput fullWidth disabled source="name" />
        </SanitizedGrid>
      </SchemaEditForm>
    </CreateWithBreadcrumbs>
  );
};

export default TypicalSchemaCreate;
