import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'react-admin';
import {createMuiTheme} from "@material-ui/core/styles";

import AppBar from './AppBar';
import AppMenu from './AppMenu';
import LoadingShirma from '../components/custom/LoadingShirma';
import { darkTheme, lightTheme } from './themes';
import { getSettings } from '../components/custom/utils';


const CustomLayout = ({isLoading, ...props}) => {
    return (
      <div>
        {isLoading === true ? ( <LoadingShirma /> ) : null}
        <Layout {...props} menu={AppMenu} appBar={AppBar} />
      </div>
    );
}


const getCustomTheme = () => {
  const settings = getSettings();

  if (['light', 'dark'].indexOf(settings.theme) >= 0) {
    return settings.theme === 'dark' ? darkTheme : lightTheme;
  }

  const themeParams = settings.customTheme;
  return createMuiTheme({
    palette: {
      ...lightTheme.palette,
      background: {
        default: themeParams.headerBackground || lightTheme.palette.background.default,
        paper: themeParams.pageBackground || lightTheme.palette.background.paper,
      },
      text : {
        primary: themeParams.headerTextColor || lightTheme.palette.text.primary,
        secondary: themeParams.pageTextColor || lightTheme.palette.text.secondary,
      },
      info: {
        main: themeParams.linkTextColor || lightTheme.palette.info.main,
      }
    }
  });
}

const mapStateToProps = state => {
  const settings = getSettings();
  const currentTheme = settings ? settings.theme : null;

  if (currentTheme) {
    state.theme = currentTheme;
  }

  return {
    isLoading: state.admin.loading > 0,
    theme: getCustomTheme()
  }
}

export default connect(
  mapStateToProps,
  {}
)(CustomLayout);
