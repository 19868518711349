import React, { useCallback } from "react";
import { Toolbar } from "react-admin";
import { useForm, useFormState } from 'react-final-form';
import { showNotification } from 'ra-core';

import {DeleteButton, SaveButton} from "./WithUndoable";

const SaveWithSchemaToolbar = props => {
	const { schemaFieldName, handleSubmitWithRedirect, ...rest } = props;
	const formState = useFormState();
	const form = useForm();

	const handleClick = useCallback(
		redirect => {
			if (redirect === void 0) {
				redirect = 'edit';
			}

			if (!formState.valid) {
				showNotification('ra.message.invalid_form', 'warning');
				return;
			}

			const formValues = formState.values;
			form.change(schemaFieldName, formValues[schemaFieldName]);
			handleSubmitWithRedirect('edit');
		},
		[form, schemaFieldName, formState, handleSubmitWithRedirect]
	);

	return (
    <Toolbar
      children={[
        <SaveButton handleSubmitWithRedirect={handleClick} />,
        <DeleteButton />
      ]}
      {...rest}
    />
  );
};

export default SaveWithSchemaToolbar;
