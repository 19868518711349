import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  SimpleShowLayout,
  TextField, 
  DateField, 
  ReferenceField,
  FunctionField
} from 'react-admin';

const styles = {
  narrow: {
    maxWidth: '2em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  normal: {
  	maxWidth: '4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  wide: {
  	maxWidth: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

// TODO: switch to tabbed layout with first tab for fields, second for original message and 
// syncWithLocation={false} after depatching and migration to 3.15 (not availible for 3.5)
const JournalDetails = ({ record, ...props }) => {
  return (
    <SimpleShowLayout record={record} basePath={"/logs"}>
    	<TextField source="id" />
      <TextField source="source" label="app.labels.source" />
  		<DateField source="createdAt" label="app.labels.datetime" showTime={true} />
			<ReferenceField label="app.labels.client" source="clientId" basePath={"/clients"} reference="clients" >
      	<TextField source="name" />
			</ReferenceField>
      <ReferenceField label="app.labels.object" source="objectId" basePath={"/objects"} reference="objects" >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="app.labels.device" source="deviceId" basePath={"/devices"} reference="devices" >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="serie" label="app.labels.serie" />
      <FunctionField label="app.labels.output" render={
	      	record => `${record.rulesProcessed
			      .filter(rule => rule.outputLogs.length > 0)
			      .map(rule => JSON.stringify(rule.outputLogs)) }`
		    } style={{maxWidth: '100%', overflow: 'scroll'}} />
    </SimpleShowLayout>);
}


export default withStyles(styles)(JournalDetails);
