import React from 'react';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  AutocompleteInput,
  useTranslate,
} from 'react-admin';
import { Button } from '@material-ui/core';
import IconLaunch from '@material-ui/icons/Launch';
import { makeStyles } from "@material-ui/styles";

import CustomTitle from '../custom/CustomTitle'; // T5275
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';

const GRAFANA_HOME_PAGE = window.sprutConfig.SPRUT_GRAFANA_HOME_PAGE || process.env.REACT_APP_GRAFANA_HOME_PAGE || '/grafana';
// const GRAFANA_HOME_PAGE = "https://webhooks1.webpasta.ru/"

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const DashboardCreate = props => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <CreateWithBreadcrumbs
      title={<CustomTitle titleKey="resources.dashboards.titles.newDashboard" {...props} />}
      record={{}}
      {...props}
    >
      <SimpleForm>
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            <Button target="_blank" rel="noopener noreferrer" href={GRAFANA_HOME_PAGE}><IconLaunch/> Grafana</Button>
            <BooleanInput
              source="isActive"
              label="app.labels.isActive"
            />
            <BooleanInput
              source="isDefault"
              label="app.labels.isDefault"
            />
            <BooleanInput
              source="isPersonal"
              label="app.labels.isPersonal"
            />
            <SelectInput source="type" choices={[
                { id: 'client', name: 'Client' },
                { id: 'object', name: 'Object' },
                { id: 'model', name: 'Device model' },
                { id: 'messageType', name: 'Messsage type' },
                { id: 'device', name: 'Device' },
            ]} />
            <TextInput fullWidth source="name" label="app.labels.name" />
            <TextInput fullWidth source="url" label="app.labels.url" helperText={translate('app.labels.dashboardUrlHelperText')} />
            <ReferenceInputWithFilter
              label="app.labels.client"
              source="clientUuid"
              reference="clients"
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
            <ReferenceInputWithFilter
              label="app.labels.device"
              source="deviceUuid"
              reference="devices"
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
            <ReferenceInputWithFilter
              label="app.labels.object"
              source="objectUuid"
              reference="objects"
              filter={{ }}
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  );
}

export default DashboardCreate;
