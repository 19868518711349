import React from "react";
import { ReferenceInput } from "react-admin";

const ReferenceInputWithFilter = (props) => {
  const queryFunc = (text) => text && { name: { $iLike: "%" + text + "%" } };
  return (
    <ReferenceInput
      sort={props.sort || { field: "createdAt", order: "DESC" }}
      perPage={props.perPage || 25}
      filter={
        props.filter || {
          isActive: true,
        }
      }
      filterToQuery={props.filterToQuery || queryFunc}
      {...props}
    >
      {props.children}
    </ReferenceInput>
  );
};

export default ReferenceInputWithFilter;
