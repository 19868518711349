import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  BooleanField,
  TextField,
  EditButton,
  DateField,
} from 'react-admin';

import { BulkDeleteAction } from '../custom/WithUndoable';  // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";


const UnitSymbolField = ({ source, record = {} }) => <span dangerouslySetInnerHTML={{__html: record[source]}}></span>;

UnitSymbolField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

const MeasureUnitList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.measureUnits" {...props} />}
    bulkActionButtons={<BulkDeleteAction />}
    filters={<StyledSearchFilter />}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="uuid" />
      <BooleanField source="isActive" label="app.labels.isActive" />
      <TextField source="name" label="app.labels.name" />
      <UnitSymbolField source="symbol" label="app.labels.symbol" />
      <TextField source="description" label="app.labels.description" />
      <DateField source="createdAt" label="app.labels.createdAt" />
      <DateField source="updatedAt" label="app.labels.updatedAt" />
      <EditButton />
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default MeasureUnitList;
