import React from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { DeleteWithConfirmButton } from 'react-admin';

const styles = theme =>
  createStyles({
    deleteButton: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    }
  });

const setProps = (props) => {
  const {undoable, label = 'ra.action.delete', record, resource, basePath, className, crudDelete, classes = {}, ...rest} = props;
  return {
    className: classnames('ra-delete-button', classes.deleteButton, className),
    record: {id: record.UsersToClients.id },
    basePath: basePath,
    resource: 'users-to-clients',
    label: label,
    redirect: `/clients/${record.UsersToClients.clientUuid}/users`,
    undoable: 'false',
    ...rest
  }
};

const DeleteUserButton = (props) => (<DeleteWithConfirmButton {...setProps(props)}></DeleteWithConfirmButton>); // T5352

export default withStyles(styles)(DeleteUserButton);
