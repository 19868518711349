import React, { Fragment, useState } from "react";
import {
  Button,
  SimpleForm,
  TextInput,
  Toolbar,
  BooleanInput,
  showNotification,
  withDataProvider,
  useTranslate,
  fetchEnd,
  fetchStart,
  CREATE,
  GET_LIST
} from 'react-admin';
import FileCopy from '@material-ui/icons/FileCopy';
// import IconCancel from '@material-ui/icons/Cancel';

import { connect } from 'react-redux';
import { startUndoable as startUndoableAction } from 'ra-core';
import { parse } from "query-string";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import { SaveButton } from '../custom/WithUndoable'; // T5095


const omitOld = ({ uuid, createdAt, ...rest }) => rest;

const CloneDialogToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveButton />
      {/* TODO: add Cancel button */}
    </Toolbar>
  );
};

// T5032
const CloneInstanceButton = props => {
  const {
    referenceLabel,
    referenceModel,
    relatedReferenceId,
    relatedReferenceModel,
    relatedReferenceLabel,
    record,
    location,
    dataProvider,
    fetchStart,
    fetchEnd,
    showNotification
  } = props;
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const translate = useTranslate();

  const handleClick = () => setShowDialog(true);

  const handleCloseClick = () => setShowDialog(false);

  // FIXME: REFACTOR: translate to react-admin 3.0 native methods using this guide: https://github.com/marmelab/react-admin/blob/next/UPGRADE.md#custom-form-toolbar-or-buttons-must-use-new-handlesubmit-signature
  const handleSubmit = values => {
    setIsLoading(true);
    const { reference_id: reference_id_string } = parse(location.search);

    const data = record;
    data['name'] = values['name'];
    data['referenceId'] = reference_id_string ? parseInt(reference_id_string, 10) : '';

    // Dispatch an action letting react-admin know a API call is ongoing
    fetchStart();

    // As we want to know when the new object has been created in order to close the modal, we use the
    // dataProvider directly
    const existingRecordId = record.id;
    dataProvider(CREATE, referenceModel, { data: omitOld(data) })
      .then(({ data }) => {
        if (!values[relatedReferenceModel]) {
          return
        }
        const filter = {};
        const newRecordId = data.id;
        filter[relatedReferenceId] = existingRecordId;
        return dataProvider(GET_LIST, relatedReferenceModel, {
          id: existingRecordId,
          target: referenceModel,
          pagination: { perPage: -1 },
          filter: filter
        })
        .then(response => {
          if (response.total > 0) {
            return Promise.all(
              response.data.map((item, index) => {
                item[relatedReferenceId] = newRecordId;
                return dataProvider(CREATE, relatedReferenceModel, { data: omitOld(item) })
              })
            )
          }
        })
      })
      .then(() => {
        showNotification('common.done', 'info');
        return true;
      })
      .catch(error => {
        showNotification(error.message, 'error');
      })
      .finally(() => {
        setIsLoading(false);
        setShowDialog(false);
        // Dispatch an action letting react-admin know a API call has ended
        fetchEnd();
      });
  };

  return (
    <Fragment>
      <Button onClick={handleClick} label={referenceLabel}>
        <FileCopy />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={referenceLabel}
      >
        <DialogTitle>{translate(referenceLabel)}</DialogTitle>
        <DialogContent>
          <SimpleForm
            redirect={false}
            resource={referenceModel}
            save={handleSubmit}
            saving={isLoading}
            toolbar={<CloneDialogToolbar />}
          >
            <TextInput source="name" label="app.labels.name" />
            <BooleanInput
              source={relatedReferenceModel}
              label={relatedReferenceLabel}
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}

const mapDispatchToProps = {
  startUndoable: startUndoableAction,
  showNotification,
  fetchEnd,
  fetchStart
};

export default withDataProvider(connect(
    null,
    mapDispatchToProps
  )(CloneInstanceButton));
