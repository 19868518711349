import ReportTemplateIcon from '@material-ui/icons/AssignmentLate';
import ReportTemplateList from './ReportTemplateList';
import ReportTemplateEdit from './ReportTemplateEdit';
import ReportTemplateCreate from './ReportTemplateCreate';

const resources = {
  list: ReportTemplateList,
  edit: ReportTemplateEdit,
  create: ReportTemplateCreate,
  icon: ReportTemplateIcon
};

export default resources;
