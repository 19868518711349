import React from "react";
import {Filter, SearchInput} from "react-admin";
import {makeStyles} from "@material-ui/core";

export const stretchFilterStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		justifyContent: 'flex-end'
	},
	input: {
		width: '100%',
		'& .MuiFilledInput-root': {
			borderRadius: '4px',
			'&:before': {
				display: 'none'
			}
		}
	}
}));

export const filterStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		justifyContent: 'flex-end'
	},
	input: {
		'& .MuiFilledInput-root': {
			borderRadius: '4px',
			'&:before': {
				display: 'none'
			}
		}
	}
}));

const StyledSearchFilter = ({
	source, resource, hideFilter, setFilters, filterValues, parentClass, stretch, initialValues
}) => {
	const sourceFields = (source) ? source : "name";
	const classes = stretch ? stretchFilterStyles() : filterStyles();
	return (
		<Filter
			resource={resource}
			hideFilter={hideFilter}
			setFilters={setFilters}
			filterValues={filterValues}
			initialValues={initialValues}
			className={`${parentClass} ${classes.root}`}
		>
			<SearchInput source={sourceFields} alwaysOn className={classes.input} />
		</Filter>
	)
}

export default StyledSearchFilter;
