import React, { useState } from "react";
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  GET_ONE, withDataProvider, SelectInput,
} from 'react-admin';
import { Grid } from "@material-ui/core";

import CustomTitle from '../custom/CustomTitle';

import SchemaParamsForm from '../custom/SchemaParamsForm';
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import SaveWithSchemaToolbar from "../custom/SaveWithSchemaToolbar";
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';
import {AGG_MEASUREMENT_CHOICES} from "../custom/utils";

const generateOptionText = record => {
  if (record.vendor && record.vendor.name) {
    return `${record.vendor.name} - ${record.name}`;
  } else {
    return `${record.name}`;
  }
};

const DeviceCreate = ({dataProvider, ...props}) => {
  const [deviceModel, setDeviceModel] = useState(null);
  const handleModelChange = (value) => {
    if (!value) {
      if (deviceModel) {
        setDeviceModel(null);
      }
      return;
    }
    if (deviceModel && deviceModel.uuid === value) {
      return;
    }
    dataProvider(GET_ONE, 'devices-models', {
      id: value,
    }).then(result => {
      setDeviceModel(result.data);
    });
  }

  return (
    <CreateWithBreadcrumbs
      title={
        <CustomTitle titleKey="resources.devices.titles.newDevice" {...props} />
      }
      {...props}
    >
      <SimpleForm
        toolbar={
          <SaveWithSchemaToolbar
            schemaFieldName="modelSchemaParams"
          />
        }
      >
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          fullWidth
        >
          <SchemaParamsForm
            schemaType="model"
            schemaName="modelSchemaParams"
            schemaSource={deviceModel}
            {...props}
          />
          <Grid item lg={6} md={6} xs={12}>
            <BooleanInput
              source="isActive"
              label="app.labels.isActive"
            />
            <BooleanInput
              source="isVirtual"
              label="app.labels.isVirtual"
            />
            <TextInput source="uuid" fullWidth />
            <TextInput
              source="name"
              label="app.labels.name"
              fullWidth
            />
            <SelectInput
              source="messagesFreq"
              label="app.labels.messagesInterval"
              fullWidth
              choices={AGG_MEASUREMENT_CHOICES} />
            <ReferenceInputWithFilter
              label="app.labels.client"
              source="clientUuid"
              reference="clients"
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>

            <ReferenceInputWithFilter
              label="app.labels.deviceModel"
              source="deviceModelUuid"
              reference="devices-models"
              filter={{ isActive: true, embedded: { vendor: true } }}
              onChange={handleModelChange}
              filterToQuery={searchText => searchText && ({
                $or: [
                  {
                    name: {
                      $iLike: `%${searchText}%`
                    }
                  },
                  {
                    "$vendor.name$": {
                      $iLike: `%${searchText}%`
                    }
                  }
                ]
              })}
            >
              <AutocompleteInput
                optionText={generateOptionText}
                optionValue="uuid"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
            <ReferenceInputWithFilter
              label="app.labels.object"
              source="objectUuid"
              reference="objects"
              filter={{}}
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
            <ReferenceInputWithFilter
              label="app.labels.parentDevice"
              source="parentUuid"
              reference="devices"
              filter={{}}
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  );
};

export default withDataProvider(DeviceCreate);
