import React, { useState } from 'react';
import { connect } from 'react-redux';
import AccessIcon from '@material-ui/icons/VpnKey';
import { withRouter } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import {
  useTranslate,
  MenuItemLink,
  Responsive,
  usePermissions
} from 'react-admin';

import rules from '../components/rules';
import clients from '../components/clients';
import objects from '../components/objects';
import objectsTypes from '../components/objects-types';
import devices from '../components/devices';
import devicesTypes from '../components/devices-types';
import devicesModels from '../components/devices-models';
import vendors from '../components/vendors';
import reportTemplates from '../components/report-templates';
import reports from '../components/reports';
import measureUnits from '../components/measure-units';
import permissionsPage from '../components/permissions';
import journals from '../components/journals';
import roles from '../components/roles';
import users from '../components/users';
import dashboards from '../components/dashboards';
import usersLogs from '../components/users-logs';
import AppSubMenu from './AppSubMenu';
import { SettingsIcon } from '../components/settings'
import { DryRunIcon } from '../components/dry-run'
import { TreeIcon } from '../components/tree'
import typicalSchemas from '../components/typical-schemas'


const DashboardMenuItem = ({locale, onClick, ...props}) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      onClick={onClick}
      to="/"
      primaryText={translate(`resources.dashboards.titles.main`)}
      leftIcon={<HomeIcon />}
      exact
      {...props}
    />
  )
}

const Menu = props => {
  const [state, setState] = useState({
    menuObjects: false,
    menuDevices: false,
    menuRules: false,
    menuReports: false,
    menuAdministration: false
  });
  const { onMenuClick, open, logout } = props;
  const translate = useTranslate();
  const { permissions } = usePermissions();

  const handleToggle = menu => {
    state[menu] = !state[menu];
    setState({...state});
  };

  return (
    <div>
      {' '}
      <DashboardMenuItem onClick={onMenuClick} />
      <MenuItemLink
        to={`/objects`}
        primaryText={translate(`resources.objects.name`, {
          smart_count: 2
        })}
        leftIcon={<objects.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/journals`}
        primaryText={translate(`resources.journals.name`, {
          smart_count: 2
        })}
        leftIcon={<journals.icon />}
        onClick={onMenuClick}
      />
      <MenuItemLink
        to={`/devices`}
        primaryText={translate(`resources.devices.name`, {
          smart_count: 2
        })}
        leftIcon={<devices.icon />}
        onClick={onMenuClick}
      />
      { permissions && (permissions['reports.list'] || permissions['me.reports.list'])
        ? <AppSubMenu
          handleToggle={() => handleToggle('menuReports')}
          isOpen={state.menuReports}
          sidebarIsOpen={open}
          name="app.labels.reports"
          icon={<reports.icon />}
        >
          { permissions['report-templates.create'] || permissions['me.report-templates.create'] ?
          <MenuItemLink
            to={`/report-templates`}
            primaryText={translate(`resources.report-templates.name`, {
              smart_count: 2
            })}
            leftIcon={<reportTemplates.icon />}
            onClick={onMenuClick}
          /> : null }
          <MenuItemLink
            to={`/reports`}
            primaryText={translate(`resources.reports.name`, {
              smart_count: 2
            })}
            leftIcon={<reports.icon />}
            onClick={onMenuClick}
          />
          <MenuItemLink
            to={`/base-report`}
            primaryText={translate(`resources.reports.titles.baseReport`, {
              smart_count: 2
            })}
            leftIcon={<reports.baseIcon />}
            onClick={onMenuClick}
          />
        </AppSubMenu>
        : null
      }
      <AppSubMenu
        handleToggle={() => handleToggle('menuRules')}
        isOpen={state.menuRules}
        sidebarIsOpen={open}
        name="app.labels.management"
        icon={<rules.icon />}
      >
        <MenuItemLink
          to={`/rules`}
          primaryText={translate(`resources.rules.name`, {
            smart_count: 2
          })}
          leftIcon={<rules.icon />}
          onClick={onMenuClick}
        />

        { permissions && (permissions['devices.rules.run'] || permissions['me.rules-assignments.create'])
          ? <MenuItemLink
            to={`/dry-run`}
            primaryText={translate(`resources.dry-run.name`)}
            leftIcon={<DryRunIcon />}
            onClick={onMenuClick}
          />
          : null
        }

      </AppSubMenu>
      <AppSubMenu
        handleToggle={() => handleToggle('menuDevices')}
        isOpen={state.menuDevices}
        sidebarIsOpen={open}
        name="app.labels.configurator"
        icon={<devices.icon />}
      >
        { permissions && permissions['devices.stats.get'] && false // T6438 temporary disabled
          ? <MenuItemLink
            to={`/tree`}
            primaryText={translate(`resources.tree.name`)}
            leftIcon={<TreeIcon />}
            onClick={onMenuClick}
          />
          : null
        }

        <MenuItemLink
          to={`/devices-models`}
          primaryText={translate(`resources.devices-models.name`, {
            smart_count: 2
          })}
          leftIcon={<devicesModels.icon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={`/devices-types`}
          primaryText={translate(`resources.devices-types.name`, {
            smart_count: 2
          })}
          leftIcon={<devicesTypes.icon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={`/objects-types`}
          primaryText={translate(`resources.objects-types.name`, {
            smart_count: 2
          })}
          leftIcon={<objectsTypes.icon />}
          onClick={onMenuClick}
        />
        <MenuItemLink
          to={`/vendors`}
          primaryText={translate(`resources.vendors.name`, {
            smart_count: 2
          })}
          leftIcon={<vendors.icon />}
          onClick={onMenuClick}
        />
      </AppSubMenu>
      <AppSubMenu
        handleToggle={() => handleToggle('menuAdministration')}
        isOpen={state.menuAdministration}
        sidebarIsOpen={open}
        name="app.labels.administration"
        icon={<AccessIcon />}
      >

        { permissions && permissions['permissions.list']
          ? <MenuItemLink
            to={`/permissions`}
            primaryText={translate(`resources.permissions.name`, {
              smart_count: 2
            })}
            leftIcon={<permissionsPage.icon />}
            onClick={onMenuClick}
          />
          : null
        }

        { permissions && permissions['roles.list']
          ? <MenuItemLink
            to={`/roles`}
            primaryText={translate(`resources.roles.name`, {
              smart_count: 2
            })}
            leftIcon={<roles.icon />}
            onClick={onMenuClick}
          />
          : null
        }

        { permissions && permissions['users.list']
          ? <MenuItemLink
            to={`/users`}
            primaryText={translate(`resources.users.name`, {
              smart_count: 2
            })}
            leftIcon={<users.icon />}
            onClick={onMenuClick}
          />
          : null
        }

        { permissions && permissions['administration.users-logs.list']
          ? <MenuItemLink
            to={`/administration/users-logs`}
            primaryText={translate(`resources.users-logs.name`)}
            leftIcon={<usersLogs.icon />}
            onClick={onMenuClick}
          />
          : null
        }

        { permissions && (permissions['measure-units.create'] || permissions['me.measure-units.create']) ?
        <MenuItemLink
          to={`/measure-units`}
          primaryText={translate(`resources.measure-units.name`, {
            smart_count: 2
          })}
          leftIcon={<measureUnits.icon />}
          onClick={onMenuClick}
        /> : null}

        { permissions && (permissions['clients.create'] || permissions['me.clients.create']) ?
        <MenuItemLink
          to={`/clients`}
          primaryText={translate(`resources.clients.name`, {
            smart_count: 2
          })}
          leftIcon={<clients.icon />}
          onClick={onMenuClick}
        /> : null}

        { permissions && (permissions['dashboards.create'] || permissions['me.dashboards.create']) ?
        <MenuItemLink
          to={`/dashboards`}
          primaryText={translate(`resources.dashboards.name`, {
            smart_count: 2
          })}
          leftIcon={<dashboards.icon />}
          onClick={onMenuClick}
        /> : null}
        { permissions && permissions['typical-schemas.list']
          ? <MenuItemLink
            to={`/typical-schemas`}
            primaryText={translate(`resources.typical-schemas.name`, {
              smart_count: 2
            })}
            leftIcon={<typicalSchemas.icon />}
            onClick={onMenuClick}
          />
          : null
        }

        { permissions && permissions['administration.objects-dashboard.list'] && false // T6438 temporary disabled
          ? <MenuItemLink
            to={`administration/objects-dashboard`}
            primaryText={translate(`app.labels.myObjects`)}
            leftIcon={<dashboards.icon />}
            onClick={onMenuClick}
          />
          : null
        }
      </AppSubMenu>
      <Responsive
        xsmall={
          <MenuItemLink
            to="/settings"
            primaryText={translate('app.labels.settings')}
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
          />
        }
        medium={null}
      />
      <Responsive
        small={logout}
        medium={null} // Pass null to render nothing on larger devices
      />
    </div>
  );
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  theme: state.theme
});

const AppMenu = connect( mapStateToProps, null)(withRouter(Menu));

export default AppMenu;
