import React, { useState, useEffect, useCallback } from 'react';
import { withDataProvider } from 'react-admin';
import { GET_CUSTOM } from '../../providers/dataProvider';
import Typography from '@material-ui/core/Typography';

const AppVersion = props => {
  const [version, setVersion] = useState(false);
  const { dataProvider, variant, style } = props;

  const fetchCoreVersion = useCallback(() => {
    return dataProvider(GET_CUSTOM, 'project', {
      uri: 'version',
      query: { iamdeveloper: true }
    }).then(response => {
      return setVersion(response.data);
    }).catch((e) => {
      // TODO: поменять на нотис
      console.error(e);
    });
  }, [setVersion, dataProvider]);

  useEffect(() => {
    fetchCoreVersion();
  }, [fetchCoreVersion]);

  return (
    <div>
      <Typography variant={variant}>
        App Version: {`${process.env.REACT_APP_VERSION}`}
      </Typography>
      {version && (
        <div>
          <Typography variant={variant}>
            Core Version:{' '}
            <span style={style}>
              {version.coreVersion}
            </span>
          </Typography>
          <br />
          <Typography variant={variant}>
            Backend Version:{' '}
            <span style={style}>{version.version}</span>
          </Typography>
          <br />
          <Typography variant={variant}>
            Rest Version:{' '}
            <span style={style}>
              {version.restVersion}
            </span>
          </Typography>
        </div>
      )}
    </div>
  );
}

export default withDataProvider(AppVersion);