import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core/styles";

const styles = {
  loading: {
    position: "absolute",
    display: "flex",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 100,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255,255,255,0)"
  },
  background: {
    width: 100,
    height: 100,
    display: "flex",
    boxShadow: "0 0 30px rgba(0,0,0,0.6)",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 50,
    backgroundColor: "rgba(255, 255, 255, 0.4)"
  }
};

const LoadingShirma = ({classes, ...props}) => (
  <div className={classes.loading}>
    <div className={classes.background}>
      <CircularProgress />
    </div>
  </div>
);

export default withStyles(styles)(LoadingShirma);
