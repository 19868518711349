import { useEffect } from "react";
import { useTranslate } from "react-admin";


const setTitle = ({titleKey, translate}) => translate(titleKey);

const CustomTitle = (props) => {
	const translate = useTranslate();

	useEffect(() => {
		document.title = setTitle({...props, translate});
	});
	return setTitle({ ...props, translate });
};

const setEditTitle = ({ record, titleKey, translate }) => {
  return record && record.name
    ? `${translate(titleKey)} "${record.name}"`
    : translate(titleKey);
};

export const CustomEditTitle = (props) => {
	const translate = useTranslate();

	useEffect(() => {
		document.title = setEditTitle({...props, translate});
	});
	return setEditTitle({ ...props, translate });
};

export default CustomTitle;
