import React, { Fragment } from "react";
import {
  AutocompleteInput,
  FormDataConsumer,
  SimpleForm,
} from "react-admin";

import {makeStyles} from "@material-ui/styles";

import CustomEditTitle from "../custom/CustomTitle"; // T5275

import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  formPreview: {
    border: "1px solid rgba(0, 0, 0, 0.09)",
    borderRadius: "4px",
    paddingTop: "10px",
    paddingLeft: "10px",
    boxSizing: "border-box"
  }
});

const ReportEdit = props => {
  const classes = useStyles();

  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.reports" {...props} />}
      {...props}
    >
      <SimpleForm ignoreState={true}>
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            <FormDataConsumer>
              {({ formData }) => {
                return (<Fragment>
                  <ReferenceInputWithFilter
                    label="app.labels.reportTemplate"
                    source="reportTemplateUuid"
                    reference="report-templates"
                    filter={{}}
                  >
                    <AutocompleteInput
                      optionText="name"
                      options={{
                        fullWidth: true
                      }}
                    />
                  </ReferenceInputWithFilter>
                </Fragment>
              )}}
            </FormDataConsumer>
          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </EditWithBreadcrumbs>
  );
}

export default ReportEdit;
