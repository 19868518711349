import { Grid, ListItem, makeStyles } from "@material-ui/core";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet/dist/leaflet.js";
import React, { useState } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import "react-leaflet-markercluster/dist/styles.min.css";
import ObjectMapCard from "../helpers/ObjectMapCard";

const isCorrectLocation = (location) => {
  if (!location) {
    return false;
  }
  let lat = location.lat;
  let lng = location.lng;
  if (typeof lat === "undefined" || typeof lng === "undefined") {
    return false;
  }
  if (
    (typeof lat === "string" && isNaN(lat)) ||
    (typeof lng === "string" && isNaN(lng))
  ) {
    return false;
  }
  if (lat === null || lng === null) {
    return false;
  }
  if (!isFinite(lat) || Math.abs(lat) > 90) {
    return false;
  }
  if (!isFinite(lng) || Math.abs(lng) > 180) {
    return false;
  }
  return true;
};

const mapStyles = makeStyles((theme) => ({
  cardList: {
    height: "calc(100vh - 160px)",
    padding: 0,
    overflowY: "scroll",
  },
  leafletContainer: {
    "& .leaflet-container": {
      height: "calc(100vh - 160px)",
      width: "100%",
      overflow: "hidden",
    },
  },
}));

const iconOptions = {
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  iconSize: [17, 30],
  iconAnchor: [8, 30],
};

const iconRegular = L.icon({
  ...Object.assign(iconOptions, {}),
});

const iconSelected = L.icon({
  ...Object.assign(iconOptions, {}),
});

export const ObjectsListMap = ({ ...props }) => {
  const classes = mapStyles();
  // eslint-disable-next-line 
  const [selectedObjects, setSelectedObjects] = useState([]);

  return (
    <Grid container={true} spacing={0}>
      <Grid item md={4}>
        <div className={classes.cardList}>
          {Object.values(props.data)
            .filter((item) => props.ids.indexOf(item.id) !== -1)
            .map((item) => (
              <ListItem
                key={`objects-map-listitem-${item.uuid}`}
                className={null}
              >
                <ObjectMapCard
                  item={item}
                  key={`objects-map-listitem-${item.uuid}`}
                />
              </ListItem>
            ))}
        </div>
      </Grid>
      <Grid item md={8}>
        <div className={classes.leafletContainer}>
          <Map
            zoom={12}
            center={[55.7539303, 37.620795]}
            preferCanvas={true}
            onMoveend={(e) => true}
          >
            <TileLayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png" />

            {Object.values(props.data)
              .filter((item) => props.ids.indexOf(item.id) !== -1)
              .map((item) => {
                return item &&
                  item.address &&
                  isCorrectLocation(item.address) ? (
                  <Marker
                    icon={
                      selectedObjects.indexOf(item.uuid) === -1
                        ? iconRegular
                        : iconSelected
                    }
                    key={`objects-map-listitem-${item.uuid}`}
                    position={[item.address.lat, item.address.lng]}
                  >
                    <Popup>{item.name}</Popup>
                  </Marker>
                ) : null;
              })}
          </Map>
        </div>
      </Grid>
    </Grid>
  );
};

export default ObjectsListMap;
