import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import { Form } from "react-final-form";
import HighchartsReact from 'highcharts-react-official'
import {
	AutocompleteInput,
	Button,
	useTranslate,
	withDataProvider,
	GET_LIST
} from "react-admin";
import {
	Paper,
	List,
	ListItem,
	ListItemText,
	Table,
	TableHead,
	TableBody,
	TableCell,
	Grid,
	Box,
	TableRow
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Print } from '@material-ui/icons';

import { useEffectOnce } from '../custom/utils';
import { GET_REFERENCES } from "../../providers/dataProvider";

const useStyles = makeStyles({
	printButtonBox: {
		margin: '20px 0 0 0'
	},
	clientLogo: {
		height: '88vh',
		textAlign: 'center'
	},
	smallClientLogo: {
		textAlign: 'right',
		paddingTop: '10px',
		marginLeft: '-10px'
	},
	paperTitle: {
		justifyContent: 'center',
		fontSize: '42pt',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center'
	},
	contentPage: {
		padding: '0 50px',
		margin: '20px 0 0 0'
	},
	boldText: {
		flexGrow: '0',
		paddingRight: '10px',
		'& > span': {
			fontWeight: 'bold'
		}
	},
	halfChart: {
		marginBottom: '20px',
		'&:nth-child(2)': {
			marginBottom: 0
		}
	},
	listItemDefault: {
		padding: 0
	},
	listItemWithNested: {
		padding: 0,
		flexDirection: 'column',
		alignItems: 'flex-start',
		justifyContent: 'flex-start'
	},
	nestedListBox: {
		flexGrow: '1',
	},
	nestedListText: {
		marginLeft: '20px'
	},
	footnotes: {
		marginLeft: '30px'
	},
	firstPaperTitle: {
		fontSize: '32pt',
		lineHeight: '32pt',
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center',
		padding: '0'
	},
	totalsContainer: {
		flexDirection: 'column',
	},
	totalsConsumption: {
		fontSize: '36px',
		textAlign: 'center'
	},
	totalsAmount: {
		fontSize: '72px',
		margin: '20px 0',
		textAlign: 'center'
	},
	totalsPrevious: {
		fontSize: '26px',
		textAlign: 'center'
	},
	chartsContainer: {
		'& .highcharts-root .highcharts-credits': {
			display: 'none'
		}
	}
});

const prepareNumber = (number) => {
	let res = Math.floor(number).toString().split(/(?=(?:...)*$)/);
	if (res.length < 2) {
		return res;
	}
	if (res[0].length === 1) {
		const first = res.shift();
		const second = res.shift();
		res.unshift(`${first}${second}`);
	}
	return res.join(' ');
};

const getMonth = (dateString) => {
	const objDate = new Date(dateString);
	const month = objDate.toLocaleString('ru-RU', { month: "long" });
	const year = objDate.getFullYear();
	return `${month} ${year}`;
};

const getEventName = (name) => {
	if (name.indexOf('"ru":"') > -1 ) {
		const parts = name.split('"ru":"');
		if (parts[1].indexOf('","en":"') > -1) {
			return parts[1].split('","en":"')[0];
		}
	}
	return name;
}

const REPORT_DATA = (response) => {
	const object = (response || {}).object || {typeSchemaParams: {}};
	const voltageQuality = (response || {}).voltageQuality || {};
	const consumption = (response || {}).consumption || [
		{
			month: "2020-07-01T00:00:00.000Z",
			value: 17734.122960000004,
			holod: 3651.8895,
			other: 14660.234080000002
		},
		{
			month: "2020-06-01T00:00:00.000Z",
			value: 18484.420039999997,
			holod: 3491.40024,
			other: 14993.019799999998
		},
	];
	const objectName = object.name ? object.name.split('Проспект')[0] : '“Магнит у дома”';
	const incidentsData = (response || {}).eventsStats || null;
	const currentMonth = getMonth(consumption[0].month);
	const prevMonth = getMonth(consumption[1].month);
	const pointStart = new Date(consumption[0].month);
	const pointStartTimestamp = pointStart.getTime();
	const holodConsumption = prepareNumber(consumption[0].holod);
	const holodConsumptionPercent = Math.floor(consumption[0].holod / (consumption[0].holod + consumption[0].other) * 100);
	const otherConsumption = prepareNumber(consumption[0].other);
	const currentIncidentsData = incidentsData ? incidentsData.per_month.buckets[0] : {};
	const prevIncidentsData = incidentsData ? incidentsData.per_month.buckets[1] : {};
	const currentIncidents = currentIncidentsData.doc_count || 65;
	const prevIncidents = prevIncidentsData.doc_count || 7;
	const incidentsBarData = {};
	const incidentsTotalData = {};
  const indisQuality = (response || {}).indisQuality || {series: false, categories: false};
  const consumptionMetrics = (response || {}).consumptionMetrics || {
    holod: [205, 210, 220, 210, 205, 200, 195, 190, 185, 180],
    other: [490, 550, 550, 520, 510, 450, 490, 490, 510, 530],
    weather: [-5, -3, -1, 1, -1, -3, -7, -11, -14, -16]
  };
  const eventsHolod = ((response || {}).eventsHolod || []).map(i => {
    return [
      i.dt.split('T')[0].split('-').join('.'),
      i.eventName,
      '+',
      '',
      '+',
      'Средняя',
      '+',
      ''
    ]
  }).slice(10);
  const eventsOther = ((response || {}).eventsOther || []).map(i => {
    return [
      i.dt.split('T')[0].split('-').join('.'),
      i.eventName,
      '+',
      '',
      '+',
      'Средняя',
      '+',
      ''
    ]
  }).slice(10);
	if (!incidentsData) {
		return false;
	}
	incidentsData.per_month.buckets.filter(item => item.key >= pointStartTimestamp).forEach((item, dayNum) => {
		item.per_event.buckets.forEach(event => {
			const name = getEventName(event.key);
			if (!incidentsTotalData[name]) {
				incidentsTotalData[name] = {
					name: `${name}: ${event.doc_count}`,
					y: Math.floor(event.doc_count / currentIncidents * 100),
					sliced: true
				}
			}
		})
	});
	Object.keys(incidentsTotalData).forEach(name => {
		incidentsBarData[name] = {data: [], name: name, type: 'column', yAxis: 1, lastDay: -1};
	});
	incidentsData.per_day.buckets.sort((a, b) => {
		if (a.key < b.key) {
			return -1
		}
		if (a.key > b.key) {
			return 1;
		}
		return 0;
	});
	incidentsData.per_day.buckets.filter(item => item.key >= pointStartTimestamp).forEach((item, dayNum) => {
		item.per_event.buckets.forEach(event => {
			const name = getEventName(event.key);
			incidentsBarData[name].data.push(event.doc_count);
			incidentsBarData[name].lastDay = dayNum;
		});
		Object.keys(incidentsBarData).forEach(name => {
			if (incidentsBarData[name].lastDay < dayNum) {
				incidentsBarData[name].data.push(0);
				incidentsBarData[name].lastDay = dayNum;
			}
		})
	})
	const incidentsPieData = Object.values(incidentsTotalData);

	return {
		title: {
			text: `Отчёт по магазину ${objectName}`,
			address: object.typeSchemaParams.address,
			date: currentMonth || 'Январь 2020'
		},
		description: {
			title: 'Описание объекта',
			parameters: [
				{
					name: 'Владелец объекта',
					value: 'ЗАО “Тандер”'
				},
				{
					name: 'Адрес объекта',
					value: `${object.typeSchemaParams.city} ${object.typeSchemaParams.address}`
				},
				{
					name: 'Описание объекта',
					value: object.typeSchemaParams.description || 'Гастроном эконом-класса, 630 м2',
				},
				{
					name: 'Состав оборудования',
					value: object.typeSchemaParams.equipment || '2 агрегата ЦХМ, 7 единиц холодильного оборудования ЦХ, 7 автономных ларей',
				},
				{
					name: 'Цель проекта',
					value: 'Телеметрия, энергосбережение'
				},
				{
					name: 'Старт проекта',
					value: 'Октябрь 2019',
				},
				{
					name: 'Отчетный период',
					value: currentMonth,
				},
				{
					name: 'Среднесуточное энергопотребление',
					value: [
						{
							name: 'инженерного оборудования',
							values: [
								'до оптимизации - 323 кВт*ч',
								`${currentMonth} - ${Math.trunc(consumptionMetrics.other.reduce((x, y) => x + y, 0) / consumptionMetrics.other.length)} кВт*ч`
							]
						},
						{
							name: 'холодильного оборудования',
							values: [
								'до оптимизации - 323 кВт*ч',
                `${currentMonth} - ${Math.trunc(consumptionMetrics.holod.reduce((x, y) => x + y, 0) / consumptionMetrics.holod.length)} кВт*ч`
							]
						}
					]
				}
			],
			table: {
				head: [
					'',
					'Инвестировано, рублей',
					'Сэкономлено, кВт*ч',
					'Сэкономлено, рублей',
					'Выгода, р',
				],
				body: [
					[
						`За ${currentMonth}`,
						'5 000 ₽ *',
						'- кВт*ч',
						'- ₽',
						'- ₽ *'
					],
					[
						'Итого за проект',
						'- ₽ *',
						'- кВт*ч',
						'- ₽',
						'- ₽ *'
					]
				]
			},
			footnotes: [
				'* - при условии оплаты услуг ПАО”Ростелеком” в размере 5000 ₽ ежемесячно, начиная с ноября 2019г.'
			]
		},
		energetics: {
			title: 'Структура энергопотребления',
			barChart: {
				credits: {
					enabled: false
				},
				chart: {
					zoomType: 'xy'
				},
				title: {
					text: `${object.name || '“Магнит у дома”\r\nПроспект Мира, 182'} - ${currentMonth}`
				},
				xAxis: {
					type: 'datetime',
					labels: {
						formatter: function() {
							return Highcharts.dateFormat('%d.%m.%Y', this.value);
						}
					},
					allowDecimals: false
				},
				plotOptions: {
					series: {
            pointStart: Date.UTC(pointStart.getFullYear(), pointStart.getMonth(), 1),
						pointInterval: 24 * 3600 * 1000, // one day
						marker: {
							enabled: false,
							symbol: 'circle',
							radius: 2,
							states: {
								hover: {
									enabled: true
								}
							}
						}
					}
				},
				yAxis: [{
					labels: {
						format: '{value}°C',
						style: {
							color: Highcharts.getOptions().colors[8]
						}
					},
					title: {
						text: '˚С',
						rotation: 0,
						style: {
							color: Highcharts.getOptions().colors[8]
						}
					},
					opposite: true

				}, {
					gridLineWidth: 0,
					title: {
						text: '',
						style: {
							color: Highcharts.getOptions().colors[0]
						}
					},
					labels: {
						format: '{value} кВт*ч',
						style: {
							color: Highcharts.getOptions().colors[0]
						}
					}
				}],
				tooltip: {
				},
				series: [{
					name: 'Температура, ˚С',
					type: 'spline',
					dashStyle: 'dash',
					tooltip: {
						valueSuffix: '˚С'
					},
					color: Highcharts.getOptions().colors[8],
					data:consumptionMetrics.weather,
				}, {
					name: 'Энергопотребление "Холод", кВт*ч',
					type: 'area',
					yAxis: 1,
					data: consumptionMetrics.holod,
				}, {
					name: 'Энергопотребление "Прочее", кВт*ч',
					type: 'area',
					yAxis: 1,
					data: consumptionMetrics.other,
				}]
			},
			pieChart: {
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie'
				},
				credits: {
					enabled: false
				},
				title: {
					text: ''
				},
				series: [{
					name: `Общее энергопотребление за ${currentMonth}`,
					colorByPoint: true,
					tooltip: {
						pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
					},
					accessibility: {
						point: {
							valueSuffix: '%'
						}
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: 'pointer',
							dataLabels: {
								enabled: true,
								format: '<b>{point.name}</b>: {point.percentage:.1f} %'
							}
						}
					},
					data: [
						{name: `Энергопотребление "Холод" ${holodConsumption} кВт*ч`, y: holodConsumptionPercent, sliced: true, selected: true},
						{name: `Энергопотребление "Прочее": ${otherConsumption} кВт*ч`, y: 100 - holodConsumptionPercent, sliced: true}
					]
				}]
			}, totals: { consumption: `Общее энергопотребление за ${currentMonth}`,
				amount: `${prepareNumber(consumption[0].value)} кВт*ч`,
				previous: `Общее энергопотребление за ${prevMonth}: ${prepareNumber(consumption[1].value)} кВт*ч`,
			}
		},
		groceryQuality: {
			title: 'Качество хранения продуктов',
			percentChart: {
				chart: {
					type: 'bar'
				},
				credits: {
					enabled: false
				},
				title: {
					text: `Проспект мира - ${currentMonth}`
				},
				xAxis: {
					categories: indisQuality.categories || [
						'Камера',
						'Рыба',
						'Колбаса',
						'Сыр',
						'Молоко',
						'Йогурт',
						'Овощи'
					]
				},
				yAxis: {
					min: 0,
					reversed: true,
					title: {
						text: 'Качество хранения продуктов'
					}
				},
				tooltip: {
					pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
					shared: true
				},
				plotOptions: {
					series: {
						stacking: 'percent'
					}
				},
				series: indisQuality.series || [
					{
						name: 'Меньше -8˚',
						data: [3, 0, 2, 0, 1, 0.5]
					},
					{
						name: 'От -8 до -5˚',
						data: [1, 0, 0, 0, 3, 6]
					},
					{
						name: 'От -5˚ до -2˚',
						data: [85, 65, 52, 57, 4.5, 5.5]
					},
					{
						name: 'От -2˚ до 2˚',
						data: [12, 25, 38, 35, 2, 6]
					},
					{
						name: 'От 2˚ до 5˚',
						data: [1, 3, 4.5, 23, 2, 7]
					},
					{
						name: 'От 5˚ до 8˚',
						data: [0.5, 6, 5.5, 30, 4, 10]
					},
					{
						name: 'Больше 8˚',
						data: [0, 0, 56, 32, 6, 6]
					}
				]
			}
		},
		incidents: {
			title: 'Инциденты',
			barChart: {
				credits: {
					enabled: false
				},
				chart: {
					zoomType: 'xy'
				},
				title: {
					text: `Проспект мира - ${currentMonth}`
				},
				xAxis: {
					type: 'datetime',
					labels: {
						formatter: function() {
							return Highcharts.dateFormat('%d.%m', this.value);
						}
					},
					allowDecimals: false
				},
				plotOptions: {
					series: {
            pointStart: Date.UTC(pointStart.getFullYear(), pointStart.getMonth(), 1),
						pointInterval: 24 * 3600 * 1000, // one day
						marker: {
							enabled: false,
							symbol: 'circle',
							radius: 2,
							states: {
								hover: {
									enabled: true
								}
							}
						}
					}
				},
				yAxis: [{
					labels: {
						format: '{value} шт',
						style: {
							color: Highcharts.getOptions().colors[2]
						}
					},
					title: {
						text: '',
						rotation: 0,
						style: {
							color: Highcharts.getOptions().colors[2]
						}
					},
					opposite: true
				}, {
					gridLineWidth: 0,
					title: {
						text: '',
						style: {
							color: Highcharts.getOptions().colors[0]
						}
					},
					labels: {
						format: '{value} кВт*ч',
						style: {
							color: Highcharts.getOptions().colors[0]
						}
					}
				}],
				tooltip: {
				},
				series: [{
					name: 'Энергопотребление "Холод", кВт*ч',
					type: 'area',
					data: consumptionMetrics.holod,
				}, ...Object.values(incidentsBarData)]
			},
			pieChart: {
				chart: {
					plotBackgroundColor: null,
					plotBorderWidth: null,
					plotShadow: false,
					type: 'pie'
				},
				credits: {
					enabled: false
				},
				title: {
					text: ''
				},
				series: [{
					name: `Общее количество инцидентов за ${currentMonth}`,
					colorByPoint: true,
					tooltip: {
						pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
					},
					accessibility: {
						point: {
							valueSuffix: '%'
						}
					},
					plotOptions: {
						pie: {
							allowPointSelect: true,
							cursor: 'pointer',
							dataLabels: {
								enabled: true,
								format: '<b>{point.name}</b>: {point.percentage:.1f} %'
							}
						}
					},
					data: incidentsPieData
				}]
			},
			totals: {
				consumption: `Общее количество инцидентов за ${currentMonth}`,
				amount: `${currentIncidents} шт`,
				previous: `Общее количество инцидентов за ${prevMonth}: ${prevIncidents} шт.`
			}
		},
		incidentsTable1: {
			title: 'Инциденты с холодильным оборудованием',
			table: {
				head: [
					'Дата',
					'Название',
					'Влияние на:',
					'Влияние на:',
					'Влияние на:',
					'Критичность',
					'Эскалировано',
					'Комментарий'
				],
				subHead: [
					'',
					'',
					'Качество продуктов',
					'Энерго- потребление',
					'Ресурс оборудования',
					'',
					'',
					''
				],
				body: eventsHolod.length ? eventsHolod : [
					[
						'2020.01.01',
						'Alarm: compressor 3',
						'+',
						'',
						'+',
						'Средняя',
						'+',
						''
					],
					[
						'2020.01.02',
						'LP discharge alarm',
						'+',
						'+',
						'+',
						'Высокая',
						'+',
						''
					]
				]
			}
		},
		incidentsTable2: {
			title: 'Инциденты с инженерными системами',
			table: {
				head: [
					'Дата',
					'Название',
					'Влияние на:',
					'Влияние на:',
					'Влияние на:',
					'Критичность',
					'Эскалировано',
					'Комментарий'
				],
				subHead: [
					'Дата',
					'Название',
					'Качество продуктов',
					'Энерго- потребление',
					'Ресурс оборудования',
					'Критичность',
					'Эскалировано',
					'Комментарий'
				],
				body: eventsOther.length ? eventsOther : [
					[
						'2020.01.01',
						'Alarm: автомат',
						'',
						'+',
						'+',
						'Средняя',
						'+',
						''
					],
					[
						'2020.01.16',
						'Alarm: потребление',
						'',
						'+',
						'+',
						'Средняя',
						'+',
						''
					],
					[
						'2020.01.19',
						'Alarm: выше нормы',
						'+',
						'+',
						'+',
						'Высокая',
						'+',
						''
					],
					[
						'2020.01.25',
						'Alarm: потребление',
						'',
						'+',
						'+',
						'Высокая',
						'+',
						''
					]
				]
			}
		},
		energyQuality: {
			title: 'Качество электрооэнергии',
			barChart1: {
				chart: {
					type: 'column'
				},
				credits: {
					enabled: false
				},
				title: {
					text: 'Электрический ввод'
				},
				xAxis: {
					categories: voltageQuality.categories
				},
				yAxis: {
					min: 0,
					title: {
						text: 'Электрический ввод (%)'
					}
				},
				plotOptions: {
					column: {
						pointPadding: 0.2,
						borderWidth: 0
					}
				},
				series: voltageQuality.series
			},
		},
		logo: '/base-reports/logo.png',
		clientLogo: '/base-reports/clientLogo.png',
		smallLogo: '/base-reports/smallLogo.png',
		smallClientLogo: '/base-reports/smallClientLogo.png',
	};
};

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const PaperHeader = ({data, title, ...props}) => {
	const classes = useStyles();
	return (
		<Grid container spacing={0}>
			<Grid item className={classes.smallLogo} xs={1}>
				<img src={data.smallLogo} width='100%' alt='' />
			</Grid>
			<Grid item className={classes.paperTitle} xs={10}>
				{title}
			</Grid>
			<Grid item className={classes.smallClientLogo} xs={1}>
				<img src={data.smallClientLogo} width='100%' alt='' />
			</Grid>
		</Grid>
	)
}

const BaseReport = ({dataProvider, ...props}) => {
	const classes = useStyles();
	const translate = useTranslate();
	const [objectUuid, setObjectUuid] = useState(null);
	const [data, setData] = useState(REPORT_DATA());
	const [objects, setObjects] = useState([]);

	useEffectOnce(() => {
		dataProvider(GET_LIST, 'objects', {pagination: { perPage: -1 }}).then(response => {
			if (response.data.length < 1) {
				console.error('no objects to fetch data');
			} else {
        // show only Magnit objects
        setObjects(response.data.filter(i => ['b8b1a27f-7e10-44a0-91b7-8f6a4ee1d5c4', '4e056b34-2e26-49ff-8cfa-39ab42a6ff4e'].indexOf(i.clientUuid)));
      }
		})
	});

	useEffect(() => {
    if (!objectUuid) {
      return;
    }
		dataProvider(GET_REFERENCES, 'reports', {
			id: objectUuid,
			target: 'base',
		})
		.then(response => {
			if (response) {
				setData(REPORT_DATA(response));
			} else {
				console.error('no data to render');
			}
		})
		.catch(err => {
			console.error(err);
		});
	}, [objectUuid, dataProvider]);

	const renderDescriptionValue = (data) => {
		if (typeof data === 'string') {
			return data;
		}
		if (Array.isArray(data)) {
			return (
				<Box className={classes.nestedListBox}>
					<List>
						{data.map((pair, num) => (
							<ListItem className={classes.listItemWithNested} key={`nestedPair-${pair.name}-${num}`}>
								<ListItemText className={classes.boldText}>{pair.name}:</ListItemText>
								<br/>
								{pair.values.map(value =>
									<ListItemText
										key={`nestedPair-${pair.name}-${num}-${value}`}
										className={classes.nestedListText}
									>
										{value}
									</ListItemText>
								)}
							</ListItem>
						))}
					</List>
				</Box>
			);
		}
		return ''
	};

	const handleObjectChange = (uuid) => {
		setObjectUuid(uuid);
	};

	const handleSubmit = () => {};

	return (
		<Form
			onSubmit={handleSubmit}
			render={({ handleSubmit }) => (
				<form>
					<Box className={`${classes.objectSelectBox} hiddenOnPrint`}>
						<AutocompleteInput
							label={translate('app.labels.object')}
							source="object"
							value={objectUuid}
							choices={objects.map(object => ({id: object.uuid, name: object.name}))}
							optionText={objectRecord => objectRecord.name}
							onChange={handleObjectChange}
              options={{
                fullWidth: true
              }}
						/>
					</Box>
					{(objectUuid && data) ? <Box className='printContainer'>
						<Box className={`${classes.printButtonBox} hiddenOnPrint`}>
							<Button
								variant="contained"
								color="primary"
								size="large"
								className={classes.button}
								onClick={event => {
									window.print();
									return false;
								}}
								label={translate('app.labels.print')}
								startIcon={<Print />}
							/>
						</Box>
						<Paper className={`${classes.contentPage} printPage`}>
							<Grid container spacing={0}>
								<Grid item xs={12} className={classes.clientLogo}>
									<img src={data.clientLogo} height='680px' alt='' />
								</Grid>
								<Grid item xs={4} className={classes.logo}>
									<img src={data.logo} width='300px' alt='' />
								</Grid>
								<Grid item xs={8} className={classes.firstPaperTitle}>
									{data.title.text}
									<br/>
									{data.title.address}
									<br/>
									{data.title.date}
								</Grid>
							</Grid>
						</Paper>
						<Paper className={`${classes.contentPage} printPage`}>
							<PaperHeader data={data} title={data.description.title} />
							<List>
								{data.description.parameters.map((param, num) => (
									<ListItem
										className={Array.isArray(param.value) ? classes.listItemWithNested : classes.listItemDefault}
										key={`description-list-${num}`}
									>
										<ListItemText className={classes.boldText}>{param.name}:</ListItemText>
										{renderDescriptionValue(param.value)}
									</ListItem>
								))}
							</List>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										{data.description.table.head.map(
											name => <TableCell key={`description-head-${name}`} align="center">{name}</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.description.table.body.map((row, rowNum) => (
										<StyledTableRow key={`description-table-body-row-${rowNum}`}>
											{row.map((value, num) =>
												<TableCell
													key={`description-table-body-cell-${value}-${num}`}
													align={num === 0 ? "left" : "center"}
												>
													{value}
												</TableCell>
											)}
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
							<List className={classes.footnotes}>
								{data.description.footnotes.map((footnote, num) =>
									<ListItem key={`footnote-${num}`}
									>
										{footnote}
									</ListItem>
								)}
							</List>
						</Paper>
						<Paper className={`${classes.contentPage} printPage`}>
							<PaperHeader data={data} title={data.energetics.title} />
							<Grid container spacing={0}>
								<Grid item xs={12} className={classes.halfChart}>
									<HighchartsReact
										highcharts={Highcharts}
										options={data.energetics.barChart}
									/>
								</Grid>
								<Grid item xs={6} className={classes.chartsText}>
									<Grid container spacing={0} className={classes.totalsContainer}>
										<Grid item xs={12} className={classes.totalsConsumption}>
											{data.energetics.totals.consumption}
										</Grid>
										<Grid item xs={12} className={classes.totalsAmount}>
											{data.energetics.totals.amount}
										</Grid>
										<Grid item xs={12} className={classes.totalsPrevious}>
											{data.energetics.totals.previous}
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6} className={classes.pieChart}>
									<HighchartsReact
										highcharts={Highcharts}
										options={data.energetics.pieChart}
									/>
								</Grid>
							</Grid>
						</Paper>
						<Paper className={`${classes.contentPage} printPage`}>
							<PaperHeader data={data} title={data.groceryQuality.title} />
							<Box>
								<HighchartsReact
									highcharts={Highcharts}
									options={data.groceryQuality.percentChart}
								/>
							</Box>
						</Paper>
						<Paper className={`${classes.contentPage} printPage`}>
							<PaperHeader data={data} title={data.energyQuality.title} />
							<Grid container spacing={0}>
								<Grid item xs={12} className={classes.halfChart}>
									<HighchartsReact
										highcharts={Highcharts}
										options={data.energyQuality.barChart1}
									/>
								</Grid>
							</Grid>
						</Paper>
						<Paper className={`${classes.contentPage} printPage`}>
							<PaperHeader data={data} title={data.incidents.title} />
							<Grid container spacing={0}>
								<Grid item xs={12} className={classes.halfChart}>
									<HighchartsReact
										highcharts={Highcharts}
										options={data.incidents.barChart}
									/>
								</Grid>
								<Grid item xs={6} className={classes.chartsText}>
									<Grid container spacing={0} className={classes.totalsContainer}>
										<Grid item xs={12} className={classes.totalsConsumption}>
											{data.incidents.totals.consumption}
										</Grid>
										<Grid item xs={12} className={classes.totalsAmount}>
											{data.incidents.totals.amount}
										</Grid>
										<Grid item xs={12} className={classes.totalsPrevious}>
											{data.incidents.totals.previous}
										</Grid>
									</Grid>
								</Grid>
								<Grid item xs={6} className={classes.pieChart}>
									<HighchartsReact
										highcharts={Highcharts}
										options={data.incidents.pieChart}
									/>
								</Grid>
							</Grid>
						</Paper>
						<Paper className={`${classes.contentPage} printPage`}>
							<PaperHeader data={data} title={data.incidentsTable1.title} />
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										{data.incidentsTable1.table.head.map(
											(name, num) => {
												if (!data.incidentsTable1.table.subHead[num]) {
													return <TableCell
														align="center"
														rowSpan={2}
														key={`incidents-table-1-head-cell-${num}`}
													>
														{name}
													</TableCell>
												}
												if (!data.incidentsTable1.table.subHead[num - 1]) {
													return <TableCell
														align="center"
														colSpan={3}
														key={`incidents-table-1-head-cell-${num}`}
													>
														{name}
													</TableCell>
												}
												return null
											}
										)}
									</TableRow>
									<TableRow>
										{data.incidentsTable1.table.head.map(
											(name, num) => {
												if (!data.incidentsTable1.table.subHead[num]) {
													return null
												}
												return (
													<TableCell
														align="center"
														key={`incidents-table-1-subhead-cell-${num}`}
													>
														{data.incidentsTable1.table.subHead[num]}
													</TableCell>
												)
											}
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.incidentsTable1.table.body.map((row, rowNum) => (
										<StyledTableRow key={`incidents-table-1-body-row-${rowNum}`}>
											{row.map((value, num) =>
												<TableCell
													align={num === 0 ? "left" : "center"}
													key={`incidents-table-1-body-cell-${num}-${value}`}
												>
													{value}
												</TableCell>
											)}
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</Paper>
						<Paper className={`${classes.contentPage} printPage`}>
							<PaperHeader data={data} title={data.incidentsTable2.title} />
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										{data.incidentsTable2.table.head.map(
											(name, num) => {
												if (!data.incidentsTable2.table.subHead[num]) {
													return <TableCell
														align="center"
														rowSpan={2}
														key={`incidents-table-2-head-cell-${num}`}
													>
														{name}
													</TableCell>
												}
												if (!data.incidentsTable2.table.subHead[num - 1]) {
													return <TableCell
														align="center"
														key={`incidents-table-2-head-cell-${num}`}
														colSpan={3}
													>
														{name}
													</TableCell>
												}
												return null
											}
										)}
									</TableRow>
									<TableRow>
										{data.incidentsTable2.table.head.map(
											(name, num) => {
												if (!data.incidentsTable2.table.subHead[num]) {
													return null
												}
												return (
													<TableCell
														align="center"
														key={`incidents-table-2-subhead-cell-${num}`}
													>
														{data.incidentsTable2.table.subHead[num]}
													</TableCell>
												)
											}
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{data.incidentsTable2.table.body.map((row, rowNum) => (
										<StyledTableRow key={`incidents-table-2-body-row-${rowNum}`}>
											{row.map((value, num) =>
												<TableCell
													align={num === 0 ? "left" : "center"}
													key={`incidents-table-2-body-cell-${num}-${value}`}
												>
													{value}
												</TableCell>
											)}
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</Paper>
					</Box> : null}
				</form>
			)}
		/>
	);
};

export default withDataProvider(BaseReport)
