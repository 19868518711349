import RoleIcon from '@material-ui/icons/AssignmentInd';
import RoleList from './RoleList';
import RoleEdit from './RoleEdit';
import RoleCreate from './RoleCreate';

const resources = {
  list: RoleList,
  edit: RoleEdit,
  create: RoleCreate,
  icon: RoleIcon
};

export default resources;
