import DashboardIcon from '@material-ui/icons/Dashboard';
import DashboardList from './DashboardList';
import DashboardEdit from './DashboardEdit';
import DashboardCreate from './DashboardCreate';

const resources = {
  list: DashboardList,
  edit: DashboardEdit,
  create: DashboardCreate,
  icon: DashboardIcon
};

export default resources;
