import React from "react";
import {Button, useTranslate, TextField, ReferenceField} from "react-admin";
import {
  List,
  ListSubheader,
  CircularProgress,
  makeStyles, Table, TableHead, TableRow, TableCell, TableBody
} from "@material-ui/core";
import { Link } from 'react-router-dom';

import { BackToPreviousNode } from "./BackToPreviousNode";
import { TreeNode } from "./TreeNode";
import { TreePagination } from "./TreePagination";
import IconChildren from "@material-ui/icons/AccountTree";

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  icon: {
    marginLeft: "1em"
  },
  marginHorizontal: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const ListSubHeader = props => {
  const translate = useTranslate();

  return (
    <ListSubheader component="div" id="nested-list-subheader">
      {translate("resources.tree.labels.objectsList")}
    </ListSubheader>
  );
};

export const TreeView = props => {
  const {
    record,
    source,
    isLoading,
    handleBackClick,
    handleClick,
    navEvent,
    tableView,
    basePath,
    pagination: {
      currentPage,
      perPage,
      setPage,
      total
    }
  } = props;
  const classes = useStyles();
  const item = record && record[source] ? record[source] : null;
  const isItemsList = Array.isArray(item);
  const translate = useTranslate();
  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={tableView ? null : <ListSubHeader />}
    >
      {isLoading ? (
        <CircularProgress className={classes.icon} size={30} thickness={2} />
      ) : (
        <div>
          {item && total ? (
            <div
              className={classes.marginHorizontal}
            >
              <TreePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                page={currentPage}
                perPage={perPage}
                setPage={page =>
                  setPage(source, isItemsList ? null : item.uuid, page)
                }
                total={total}
              />
            </div>
          ) : null}
          {item && (
            <BackToPreviousNode
              record={item}
              callback={handleBackClick}
              navEvent={navEvent}
            />
          )}
          {tableView ? (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">{translate(`app.labels.name`)}</TableCell>
                  <TableCell align="left">{translate(`app.labels.children`)}</TableCell>
                  <TableCell align="left">{translate(`app.labels.client`)}</TableCell>
                  <TableCell align="left">{translate(`app.labels.objectType`)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isItemsList ? (
                  item.map(_item => (
                    <TableRow key={_item.uuid}>
                      <TableCell>
                        <Button
                          variant="text"
                          component={Link}
                          to={`/${source}/${_item.id}`}
                          label={_item.name}
                        >
                        </Button>
                      </TableCell>
                      <TableCell>
                        {_item.children && _item.children.length ? (
                          <Button
                            variant="text"
                            onClick={() => handleClick(source, _item.uuid, _item)}
                            label="app.labels.children"
                          >
                            <IconChildren />
                          </Button>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <ReferenceField
                          label="app.labels.client"
                          source="clientUuid"
                          reference="clients"
                          basePath={basePath}
                          record={_item}
                        >
                          <TextField source="name" />
                        </ReferenceField>
                      </TableCell>
                      <TableCell>
                        <ReferenceField
                          label="app.labels.objectType"
                          source="objectTypeUuid"
                          reference="objects-types"
                          basePath={basePath}
                          record={_item}
                        >
                          <TextField source="name" />
                        </ReferenceField>
                      </TableCell>
                    </TableRow>
                  ))
                ) : item && item.children && item.children.length ? (
                  item.children.map(_item => (
                    <TableRow key={_item.uuid}>
                      <TableCell>
                        <Button
                          variant="text"
                          component={Link}
                          to={`/${source}/${_item.id}`}
                          label={_item.name}
                        >
                        </Button>
                      </TableCell>
                      <TableCell>
                        {_item.children && _item.children.length ? (
                          <Button
                            variant="text"
                            onClick={() => handleClick(source, _item.uuid, _item)}
                            label="app.labels.children"
                          >
                            <IconChildren />
                          </Button>
                        ) : null}
                      </TableCell>
                      <TableCell>
                        <ReferenceField
                          label="app.labels.client"
                          source="clientUuid"
                          reference="clients"
                          basePath={basePath}
                          record={_item}
                        >
                          <TextField source="name" />
                        </ReferenceField>
                      </TableCell>
                      <TableCell>
                        <ReferenceField
                          label="app.labels.objectType"
                          source="objectTypeUuid"
                          reference="objects-types"
                          basePath={basePath}
                          record={_item}
                        >
                          <TextField source="name" />
                        </ReferenceField>
                      </TableCell>
                    </TableRow>
                  ))
                ) : null}
              </TableBody>
            </Table>
          ) : (
            <List component="div">
              {isItemsList ? (
                item.map(_item => (
                  <TreeNode
                    key={_item.uuid}
                    item={_item}
                    resource={_item.resource}
                    handleClick={handleClick}
                  />
                ))
              ) : item ? (
                <div key={item.uuid}>
                  {/* PARENT NODE */}
                  <TreeNode
                    item={item}
                    resource={item.resource}
                    handleClick={handleClick}
                    // isNested={true}
                    isParent={true}
                    isDisabled={true}
                  />
                  {/* CHILDREN NODES */}
                  <List component="div" disablePadding>
                    {item.children &&
                    item.children.length > 0 &&
                    item.children.map(_item => (
                      <TreeNode
                        key={_item.uuid}
                        item={_item}
                        resource={_item.resource}
                        handleClick={handleClick}
                        isNested={true}
                      />
                    ))}
                  </List>
                </div>
              ) : null}
            </List>
          )}
          {item && total ? (
            <div
              className={classes.marginHorizontal}
            >
              <TreePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                page={currentPage}
                perPage={perPage}
                setPage={page =>
                  setPage(source, isItemsList ? null : item.uuid, page)
                }
                total={total}
              />
            </div>
          ) : null}
        </div>
      )}
    </List>
  );
};
