import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
	List,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  DateTimeInput,
  Filter,
  SearchInput,
  BooleanInput
} from 'react-admin';
import rowStyle from '../../styling/rowStyle';
import DeviceLogsDetails from './DeviceLogsDetails';

const styles = {
  narrow: {
    maxWidth: '4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  normal: {
  	maxWidth: '6em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  wide: {
  	maxWidth: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

const DeviceLogsList = ({ classes, basePath, deviceUuids, ...props }) => {

	return (
		<List resource='logs'
				basePath={basePath}
				style={{ width: "100%" }} 
				sort={{ field: "indexed_at", order: "DESC" }}
				hasShow={false} hasEdit={false} hasCreate={false} hasList={false} 
				filters={
          <Filter {...props} resource='logs' style={{width:'80%'}}>
            <SearchInput source="rulesProcessed_outputLogs_message" alwaysOn className={classes.input} />
            <BooleanInput label="collapse" source="collapse" />
            <DateTimeInput source="mqttReceivedAt_gte" label="app.labels.from" options={{mode: "portrait", locale: "ru"}} />
            <DateTimeInput source="mqttReceivedAt_lte" label="app.labels.to" options={{mode: "portrait", locale: "ru"}} />
          </Filter>
      	}
				filter={{  deviceUuid: deviceUuids }}>
		  <Datagrid rowStyle={rowStyle('status')} expand={<DeviceLogsDetails extended={false} />} >
		  	<TextField source="id" cellClassName={classes.narrow} sortable={false}/>
		  	<DateField showTime={true} source="createdAt" 
		  		label="app.labels.datetime" cellClassName={classes.normal}/>
		    <TextField source="source" label="app.labels.source"
		    	cellClassName={classes.narrow} />
        <FunctionField label="app.labels.output" cellClassName={classes.wide}
            render={record => `${record.rulesProcessed
              .filter(rule => rule.outputLogs.length > 0)
              .map(rule => JSON.stringify(rule.outputLogs)) }`}/>
		  </Datagrid>
		</List>);
};

export default withStyles(styles)(DeviceLogsList);