// Modules
import React from 'react';
import { Admin, Resource } from 'react-admin';

// Styles
import './App.css';

// Routes
import customRoutes from './routes/customRoutes';

//Reducers
import theme from './reducers/themeReducer';

// Components
import { AppLayout } from './layout';
import { ObjectsDashboard } from './components/objects/ObjectsDashboard';
import { LogoutButton } from './components/custom/LogoutButton';
import AppLoginPage from './components/login';
import rules from './components/rules';
import rulesAssignments from './components/rules-assignments';
import objects from './components/objects';
import objectsTypes from './components/objects-types';
import devices from './components/devices';
import devicesTypes from './components/devices-types';
import devicesModels from './components/devices-models';
import vendors from './components/vendors';
import reportTemplates from './components/report-templates';
import reports from './components/reports';
import measureUnits from './components/measure-units';
import messagesTypes from './components/messages-types';
import permissions from './components/permissions';
import roles from './components/roles';
import users from './components/users';
import journals from './components/journals';
import usersLogs from './components/users-logs';
import clients from './components/clients';
import dashboards from './components/dashboards';
import typicalSchemas from './components/typical-schemas';

// Providers
import dataProviderWrapper from './providers/dataProviderWrapper';
import { authProvider } from './providers/authProvider';
import i18nProvider from './i18n';



const appDataProvider = dataProviderWrapper(window.sprutConfig.SPRUT_API_URL || process.env.REACT_APP_API_URL);

// T7577
const deleteDoneReducer = (previousState = false, { type, payload }) => {
    if (type === 'RA/CRUD_DELETE_MANY_SUCCESS') {
        return true;
    } else if (type === 'RA/FETCH_END') {
        return false;
    }
    return previousState;
}

// App
const App = () => (
  <Admin
    customReducers={{ theme, deleteDone: deleteDoneReducer }}
    title="app.name"
    layout={AppLayout}
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    dataProvider={appDataProvider}
    customRoutes={customRoutes}
    loginPage={AppLoginPage}
    logoutButtton={LogoutButton}
  >
    {perms => [
      <Resource name="rules" {...rules} />,
      <Resource name="rules-assignments" {...rulesAssignments} />,
      <Resource name="objects" {...objects} />,
      <Resource name="objects-types" {...objectsTypes} />,
      <Resource name="devices" {...devices} />,
      <Resource name="devices-groups" />,
      <Resource name="devices-models" {...devicesModels} />,
      <Resource name="devices-types" {...devicesTypes} />,
      <Resource name="vendors" {...vendors} />,
      <Resource name="logs" />,
      <Resource name="data" />,
      <Resource name="journals" {...journals} />,

      perms['typical-schemas.list']
        ? <Resource name="typical-schemas" {...typicalSchemas} />
        : null,

      perms['report-templates.list']
        ? <Resource name="report-templates" {...reportTemplates} />
        : null,

      perms['reports.list']
        ? <Resource name="reports" {...reports} />
        : null,

      <Resource name="measure-units" {...measureUnits} />,
      <Resource name="messages-types" {...messagesTypes} />,

      perms['permissions.list']
        ? <Resource name="permissions" {...permissions} />
        : null,

      perms['roles.list']
        ? <Resource name="roles" {...roles} />
        : null,

      <Resource name="users" {...users} />,

      perms['administration.users-logs.list']
        ? <Resource name="administration/users-logs" {...usersLogs} />
        : null,

      perms['administration.objects-dashboard.list']
        ? <Resource name="administration/objects-dashboard" list={ObjectsDashboard} />
        : null,

      <Resource name="clients" {...clients} />,
      <Resource name="dashboards" {...dashboards} />,
      <Resource name="forward:resource" />,
      <Resource name="configurator-parameters" />,
    ]}
  </Admin>
);

export default App;
