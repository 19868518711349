import React, {useState} from "react";
import {Grid} from "@material-ui/core";
import { useForm, useFormState } from 'react-final-form';

import { ToggleParamsEditorViewButton } from "./ToggleParamsEditorViewButton";
import { EditorWrapper } from "./JSONEditor";
import { setDefaultValues } from '../custom/utils';

import ParamsForm from "./ParamsForm";

const placeholderModelSchemaParams = {
	"param": "example"
};

const SchemaParamsForm = ({schemaSource, schemaName, schemaType, ...props}) => {
	const form = useForm();

	const record = useFormState().values;

  // T6447 ugly hack to save schema...
  if (record[schemaType] && !form._recordSchemaType) {
    form._recordSchemaType = record[schemaType];
  }
  record[schemaType] = record[schemaType] || form._recordSchemaType;

	if (schemaSource && (!record[schemaType] || record[schemaType].uuid !== schemaSource.uuid)) {
		let properties = {};
		if (schemaSource) {
			if (schemaType === "messageSchema") {
				properties = schemaSource.messageSchema.properties;
			} else if (schemaSource.itemParamsSchema.properties) {
				properties = schemaSource.itemParamsSchema.properties;
			}
		}
		const generatedSchemaValues = setDefaultValues(properties);
		record[schemaName] = generatedSchemaValues;
		record[schemaType] = schemaSource;
	}

	const [paramsEditorView, setParamsEditorView] = useState('form');
	const handleChange = (value, record, source) => {
		updateEditor(value);
		form.change(source, value);
	}

	let [editorRef, setEditorRef] = useState(null);

	const updateEditor = (data, force) => {
		if (editorRef && (paramsEditorView === 'form' || force)) {
			editorRef.jsonEditor.set(data);
		}
	};

	return record && record[schemaType] ? (
		<Grid item lg={6} md={6} xs={12}>
			<ToggleParamsEditorViewButton
				value={paramsEditorView}
				handleStateFn={setParamsEditorView}
			/>
			{paramsEditorView === "json" ? (
				<EditorWrapper
					schemaName={schemaName}
					record={record}
					innerRef={setEditorRef}
					defaultValue={placeholderModelSchemaParams}
				/>
			) : (
				<ParamsForm
					record={record}
					callback={handleChange}
					source={schemaName}
					label={`app.labels.${schemaName}`}
					type={schemaType}
					{...props}
				/>
			)}
		</Grid>
	) : null;
}

export default SchemaParamsForm
