import { httpClient, changeSettings } from '../components/custom/utils';

const apiUrl = window.sprutConfig.SPRUT_API_URL || process.env.REACT_APP_API_URL;

const removeCredentials = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('permissions');
  localStorage.removeItem('roles');
}

export const authProvider = {
    login: params => loginFn(params),
    logout: params => logoutFn(params),
    checkAuth: params => checkAuthFn(params),
    checkError: error => checkErrorFn(error),
    getPermissions: params => getPermissionsFn(params)
};

// called when the user attempts to log in
const loginFn = params => {
  const { username, password } = params;

  return httpClient(`${apiUrl}/auth/localByLogin`, {
      method: 'POST',
      body: JSON.stringify({
        login: username,
        password: password,
        generateToken: true
      })
    })
    .then(response => response.json)
    .then(({ sessionToken }) => {
      localStorage.setItem('token', sessionToken);
      return httpClient(`${apiUrl}/me?embedded=profile,settings,roles`, {
        method: 'GET'
      });
    })
    .then(response => response.json)
    .then(async (user) => {
      if (user.settings instanceof Object) {
        await changeSettings(user.settings.general);
      }

      if (user.roles && user.roles.length) {
        const roles = user.roles.map(role => role.name);
        localStorage.setItem('roles', JSON.stringify(roles));
      }

      localStorage.setItem('user', JSON.stringify(user));
      return Promise.resolve(true);
    })
    .catch(err => {
      return Promise.reject(err);
    });

}

// called when the user clicks on the logout button
const logoutFn = params => {
  removeCredentials();
  return Promise.resolve();
}

// called when the user navigates to a new location
const checkAuthFn = params => {
  return new Promise((resolve, reject) => {
    const err = new Error('No token');
    err.status = 401;
    return localStorage.getItem('token') ? resolve() : reject(err);
  });
}

// called when the API returns an error
const checkErrorFn = error => {
  const { status } = error;

  if (!status) {
    console.warn('auth_error_custom', error);
  }
  return Promise.resolve();
}

// called when app checking user's permissions
const getPermissionsFn = params => {
  return httpClient(`${apiUrl}/me/permissions`, {
    method: 'GET'
  })
    .then(response => response.json)
    .then(permissions => {
      let err = new Error('No permissions');
      err.status = 401;
      return permissions ? Promise.resolve(permissions) : Promise.reject(err);
    })
    .catch(err => {
      throw err;
    });
}
