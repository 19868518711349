import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  withDataProvider,
  Loading,
  translate,
  Labeled,
  showNotification
} from 'react-admin';
import {Grid, FormControlLabel, IconButton, Tooltip} from "@material-ui/core";
import GridIcon from "@material-ui/icons/GridOn";
import TreeIcon from "@material-ui/icons/AccountTree";
import {withStyles} from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { UPDATE_REFERENCES } from '../../providers/dataProvider'
import { renderCompactTableValue } from '../custom/utils';

export const RANGE_LEVEL_OK = 'RANGE_LEVEL_OK';
export const RANGE_LEVEL_WARN = 'RANGE_LEVEL_WARN';
export const RANGE_LEVEL_CRIT = 'RANGE_LEVEL_CRIT';
export const RANGE_LEVELS = [
  RANGE_LEVEL_OK,
  RANGE_LEVEL_WARN,
  RANGE_LEVEL_CRIT
];

const AGG_INTERVAL_FORMAT_VALUE = {
  '1y': v => v.slice(0, 4),
  '1q': v => v.slice(0, 7),
  '1M': v => v.slice(0, 7),
  '1w': v => v.slice(0, 10),
  '1d': v => v.slice(0, 10),
  '1h': v => v.slice(0, 13).replace('T', ' '),
  '1m': v => v.slice(0, 7),
  '1s': v => v.slice(0, 19).replace('T', ' '),
}
const AGG_INTERVALS = ['all'].concat(Object.keys(AGG_INTERVAL_FORMAT_VALUE));
const DEFAULT_AGG_INTERVAL = '1d';

const MAX_FETCH_COUNT = 10;

const defaultFromTime = () => {
  return (new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000)).toISOString().slice(0, 16)
};

const styles = {
  root: {
    width: "100%"
  },
  cell: {
    '& .statusOk': {
      color: 'green'
    },
    '& .statusError': {
      color: 'red'
    }
  },
  headerCell: {
    fontWeight: 'bold'
  }
};

class DevicePreviewData extends React.Component {
  constructor(props) {
    super(props);
    this.debounceDelay = props.debounceDelay || 600;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.optionsDateFormat = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    this.handleChangeAggInterval = this.handleChangeAggInterval.bind(this);
    this.handleChangeFromTime = this.handleChangeFromTime.bind(this);
    this.handleChangeToTime = this.handleChangeToTime.bind(this);

    this.state = {
      isLoading: false,
      page: 0,
      total: 0,
      tableView: props.tableView,
      aggInterval: props.messageTypeUuid ? DEFAULT_AGG_INTERVAL : 'all',
      fromTime: defaultFromTime(),
      toTime: null,
      data: [],
      parametersJSON: JSON.stringify(props.parameters),
    };
  }

  handleChangePage (e, page) {
    this.setState({page});
    this.fetchDataDebounce();
  }

  prepareRanges() {
    const { props } = this;

    // T5385 postprocess on backend side
    if (false) {
      // compile post process functions
      this.postProcessFunctions = {};
      props.parameters.forEach(p => {
        if (p.postProcessJs) {
          let func;
          try {
            func = new Function('value', 'context', p.postProcessJs); // eslint-disable-line no-new-func
          } catch (err) {
            console.log('Error on function compile', err);
            func = () => `Error on function compile ${err}`;
          }
          this.postProcessFunctions[p.name] = (value, context) => {
            try {
              return func()(value, context);
            } catch (err) {
              return `Error on function call ${err}`;
            }
          };
        }
      });
    }

    // compile level functions
    this.rangeLevelTestFunctions = {};
    props.parameters.forEach(p => {
      if (p.ranges && p.ranges.length > 0) {
        this.rangeLevelTestFunctions[p.name] = p.ranges.map(r => {
          let testFunc;
          try {
            testFunc = new Function('value', 'context', r.conditionJs || 'return false;'); // eslint-disable-line no-new-func
          } catch (err) {
            console.log('Error on function compile', r, err);
            testFunc = () => false;
          }
          return {
            range: r,
            testFunc
          };
        });
      }
    });

    this.valueRenderComponentByLevel = this.props
      .valueRenderComponentByLevel || {
      [RANGE_LEVEL_OK]: v => <span style={{ color: 'green' }}>{v}</span>,
      [RANGE_LEVEL_WARN]: v => <span style={{ color: '#ffb41f' }}>{v}</span>,
      [RANGE_LEVEL_CRIT]: v => <span style={{ color: 'red' }}>{v}</span>
    };
  }

  reset() {
    this.startForCheckingMls = 0;
    this.setState({ isLoading: false });
  };

  startCheckingData() {
    this.startForCheckingMls = Date.now();
    let dataFetched = false;
    let fetchCount = 0;
    const fetchInterval = setInterval(() => {
      if (dataFetched) {
        clearInterval(fetchInterval);
        this.reset();
        return;
      }
      if (fetchCount > MAX_FETCH_COUNT) {
        clearInterval(fetchInterval);
        this.reset();
        return;
      }
      fetchCount++;
      this._fetchData()
      .then(response => {
        if (response.data.length > 0 && !dataFetched) {
          clearInterval(fetchInterval);
          dataFetched = true;
          this.reset();
        }
      });
    }, 1000);
  }

  fetchDataDebounce() {
    if (this.debounceId) {
      clearTimeout(this.debounceId);
    }
    this.debounceId = setTimeout(() =>
      this._fetchData()
      .then(response => {
        if (response.data.length > 0 ){
          this.setState({ data: response.data, total: response.total });
        }

        if (!this.startForCheckingMls) {
          this.setState({ isLoading: false });
        }
      })
      .catch(err => {
        showNotification(err.message);
        throw err;
      }), this.debounceDelay);
  }

  _fetchData() {
    const { page, aggInterval, fromTime, toTime } = this.state;
    const {
      useLogs,
      timeParameterPath,
      perPage,
      dataProvider,
      deviceUuid,
      parameters,
      messageTypeUuid,
      source,
      sort,
      token
    } = this.props;
    if (deviceUuid === undefined) {
      return;
    }
    this.setState({ isLoading: true });
    return dataProvider(UPDATE_REFERENCES, 'devices', {
      id: deviceUuid,
      target: source || 'stats',
      data: {
        useLogs,
        timeParameterPath,
        aggInterval: aggInterval !== 'all' ? aggInterval : null,
        begin: fromTime ? Date.parse(fromTime) : null,
        end: toTime ? Date.parse(toTime) : null,
        offset: page * perPage,
        limit: perPage,
        messageTypeUuid: messageTypeUuid,
        order:  sort || {
          field: 'receivedAt',
          order: 'asc'
        },
        parameters: parameters.map(p => {
          return {
            name: p.name,
            path: p.path,
            aggType: p.aggType,
            postProcessJs: p.postProcessJs
          };
        }),
        token: token
      }
    })
    .catch(err => {
      showNotification(err.message);
      throw err;
    });
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.deviceUuid !== prevProps.deviceUuid) {
      this.setState({ page: 0 });
      this.prepareRanges();
      this.fetchDataDebounce();
      return null;
    }

    if (this.props.messageTypeUuid !== prevProps.messageTypeUuid) {
      this.setState({ page: 0, aggInterval: DEFAULT_AGG_INTERVAL });
      this.prepareRanges();
      this.fetchDataDebounce();
      return null;
    }

    const parametersJSON = JSON.stringify(this.props.parameters)
    if ( parametersJSON !== this.state.parametersJSON) {
      this.setState({ page: 0, parametersJSON });
      this.prepareRanges();
      this.fetchDataDebounce();
      return null;
    }
    return null;
  }

  doRefresh(options) {
    this._fetchData().then(response => {
      if (response.data.length > 0 ){
        this.setState({ data: response.data, total: response.total });
      }

      if (!this.startForCheckingMls) {
        this.setState({ isLoading: false });
      }
    });
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.prepareRanges();
    this.fetchDataDebounce();

    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  compactData(data) {
    const res = [];
    data.forEach(item => {
      const tasksByRule = {};

      item.tasks.forEach(task => {
        if (tasksByRule[task.taskProperties.source.invokedByRuleName]) {
          tasksByRule[task.taskProperties.source.invokedByRuleName].push(task);
        } else {
          tasksByRule[task.taskProperties.source.invokedByRuleName] = [task];
        }
      });

      item.rulesProcessed.forEach(rule => {

        res.push({
          id: item.id,
          source: rule.source,
          status: rule.status,
          deviceId: item.deviceId,
          timestamp: rule.startedAt,
          duration: rule.executionTime,
          name: rule.ruleName,
          output: rule.outputLogs && rule.outputLogs.map(i => `[${i.status}] ${i.message}`).join('\n'),
          payload: '',
        });

        const tasks = tasksByRule[rule.ruleName];
        if (tasks) {
          tasks.forEach(task => {
            res.push({
              id: item.id,
              source: task.source,
              status: task.status,
              deviceId: item.deviceId,
              timestamp: rule.startedAt,
              duration: rule.executionTime,
              name: task.taskName,
              output: '',
              payload: task.taskProperties.payload,
            });
          });
        }
      });

      const started = new Date(item.createdAt).getTime();
      const finished = new Date(item.finishedAt).getTime();

      res.push({
        id: item.id,
        status: item.status,
        source: item.source,
        deviceId: item.deviceId,
        objectId: item.objectId,
        timestamp: finished,
        duration: finished - started,
        name: 'engine output',
        output: item.outputLogs && item.outputLogs.map(i => `[${i.status}] ${i.message}`).join('\n'),
        payload: '',
      });

    });
    return res;
  }

  renderAggKeyValue(row, aggInterval) {
    const f = AGG_INTERVAL_FORMAT_VALUE[aggInterval]
    if (f !== undefined && row.aggKey) {
      return f(row.aggKey);
    }
    return row.aggKey;
  }

  logEntryToTable(row) {
  }

  renderValue(row, parameter) {
    let value = row[parameter.name];

    // T5385 postprocess on backend side
    if (false) {
      const postProcessFunc = this.postProcessFunctions[parameter.name];
      if (postProcessFunc) {
        const context = { row, parameter };
        value = postProcessFunc(value, context);
      }
    }

    if (typeof value === 'object') {
      return (
        <pre>
          <code>{JSON.stringify(value, null, 4)}</code>
        </pre>
      );
    }

    if (value && parameter.type === 'timestamp' && parameter.formatTimestamp) {
      return (
        <pre>
          <code>{new Date(value).toISOString()}</code>
        </pre>
      );
    }

    // determine level
    const ranges = this.rangeLevelTestFunctions[parameter.name];
    if (ranges && ranges.length > 0) {
      const passed = ranges.find(item => {
        const context = { row, parameter, range: item.range };
        try {
          if (item.testFunc(value, context)) {
            return true;
          }
        } catch (err) {
          console.log('Error on test range level', value, context, err);
        }
        return false;
      });

      if (passed) {
        const componentFunc = this.valueRenderComponentByLevel[
          passed.range.level
        ];
        if (componentFunc) {
          return componentFunc(value, { row, parameter });
        }
      }

      return value;
    }

    return value;
  }

  handleChangeAggInterval(e, item) {
    this.setState({ aggInterval: item.key }, () => {
      this.doRefresh();
    });
  }

  handleChangeFromTime(selectedDate) {
    this.setState({ fromTime: selectedDate }, () => {
      this.doRefresh();
    });
  }

  handleChangeToTime(selectedDate) {
    this.setState({ toTime: selectedDate }, () => {
      this.doRefresh();
    });
  }

  render() {
    const { data, total, page, aggInterval, fromTime, toTime } = this.state;
    const { parameters, newParameters, perPage, messageTypeUuid, translate, classes } = this.props;

    const compactData = this.state.tableView ? this.compactData(data) : [];

    return (
      <Fragment>
        {newParameters ? (<FormControlLabel
          value="table"
          control={
            <IconButton onClick={(e) => {
              e.preventDefault();
              const view = !this.state.tableView;
              this.setState({tableView: view});
            }}>
              {this.state.tableView ? (
                <Tooltip title="Table">
                  <GridIcon />
                </Tooltip>
              ) : (
                <Tooltip title="Tree">
                  <TreeIcon />
                </Tooltip>
              )}
            </IconButton>
          }
        />) : null}
        { messageTypeUuid ? (
          <Labeled label="app.configurator.aggInterval">
            <Select
              name="aggType"
              value={aggInterval || ''}
              onChange={this.handleChangeAggInterval}
              inputProps={{ name: 'aggType' }}
            >
              {AGG_INTERVALS.map( item => (
                <MenuItem key={item} value={item || ''}>{item ? translate(`app.configurator.aggIntervalChoices.${item}`) : null }</MenuItem>
              ))}
            </Select>
          </Labeled>
        ) : null }
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <Grid
            justify="flex-start"
            container={true}
            spacing={2}
            className={classes.root}
          >
            <Grid item lg={6} md={12}>
              <Labeled label="app.configurator.fromTime" fullWidth>
                <DateTimePicker value={fromTime} format="yyyy.MM.dd HH:mm" onChange={this.handleChangeFromTime} />
              </Labeled>
            </Grid>
            <Grid item lg={6} md={12}>
              <Labeled label="app.configurator.toTime" fullWidth>
                <DateTimePicker value={toTime} format="yyyy.MM.dd HH:mm" onChange={this.handleChangeToTime} />
              </Labeled>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        {this.state.isLoading ? (
          <Loading />
        ) : ((this.state.tableView === false) ? (
          <Table>
            <TableHead>
              <TableRow>
                { aggInterval !== 'all' ? <TableCell align="left">{translate(`app.configurator.aggIntervalChoices.${aggInterval}`)}</TableCell> : null}
                { aggInterval === 'all' && messageTypeUuid
                  ? <TableCell align="left">{translate(`app.configurator.receivedAt`)}</TableCell>
                  : null
                }
                {parameters.map(p => (
                  <TableCell key={p.name} align="left">
                    {aggInterval !== 'all' ? `${p.name} (${p.aggType || 'last'})` : p.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <TableRow key={`${i}-${row.uuid}`}>
                  { aggInterval !== 'all' ? <TableCell key="aggKey" align="left">{this.renderAggKeyValue(row, aggInterval)}</TableCell> : null }
                  { aggInterval === 'all' && messageTypeUuid
                    ? <TableCell key="aggKey" align="left"><pre>
                      <code>{new Date(row['receivedAt']).toLocaleDateString('ru-RU', this.optionsDateFormat)}</code>
                    </pre></TableCell>
                    : null
                  }
                  {parameters.map(p => (
                    <TableCell style={{maxWidth: '300px', overflow: 'auto'}} align="left" key={p.name}>
                      {this.renderValue(row, p)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={total}
                  page={page}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[]}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={
                    aggInterval !== 'all'
                    ? ({ from, to, count }) => `${from}-${to === -1 ? count : to}`
                    : ({ from, to, count }) => `${from}-${to === -1 ? count : to} of ${count}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        ) : (newParameters ? (<Table size="small">
          <TableHead>
            <TableRow>
              {newParameters.map(p => (
                <TableCell key={p.name} align="left" className={classes.headerCell}>{p.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {compactData.map((row, i) => (
              <TableRow key={i}>
                {newParameters.map(p => (
                  <TableCell
                    align="left"
                    key={p.name}
                    className={classes.cell}
                  >{renderCompactTableValue(row, p)}</TableCell>))}
              </TableRow>
            ))}
          </TableBody>
        </Table>) : null))
      }
      </Fragment>
    );
  }
}

export default withDataProvider(translate(withStyles(styles)(DevicePreviewData)));
