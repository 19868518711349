import React, { useEffect, useState, useCallback } from "react";
import {
  Title,
  ListToolbar,
  GET_ONE,
  GET_LIST,
  withDataProvider,
  useTranslate, TopToolbar
} from "react-admin";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  Button,
  List, ListItem, ListSubheader
} from "@material-ui/core";
import classnames from "classnames";
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import IconAdd from '@material-ui/icons/Add';

import { CustomTitle } from "../custom";
import { DeviceCardStyle } from "../custom/CardDatagrid";
import { TreeView } from "./TreeView";
import ItemView from "./ItemView";
import { TreeFilter } from "./TreeFilter";
import { TreeBreadcrumbs } from "../custom/CustomBreadcrumbs";

export const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  toolbar: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: '0 0 0 12px',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center'

  },
  buttonRoot: {
    display: 'block',
    textAlign: 'left',
  },
  buttonLabel: {
    textOverflow: 'ellipsis',
    maxWidth: '120px',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  main: {
    display: "flex",
    flex: '1 1 auto',
    border: 'none',
    padding: 0
  },
  child: {
    padding: 0,
    '& > div ': {
      boxShadow: 'none',
      background: 'none',
    },
    '& > div > nav': {
      padding: 0,
    }
  },
  actions: {
    zIndex: 2,
    display: "flex",
    justifyContent: "flex-end",
    flexWrap: "wrap"
  }
}));

const ParentSubHeader = props => {
  const translate = useTranslate();

  return (
    <ListSubheader component="div" id="nested-list-subheader">
      {translate("resources.tree.labels.parentDevice")}
    </ListSubheader>
  );
};

const SanitizedFilters = (props) => {
  const {filterValues, noDevices, defaultValues, setFilters, classes, ...rest} = props;
  return (
    <div className={classes.toolbar}>
      {noDevices ? null : (
        <TreeFilter
          resource="devices"
          defaultValues={defaultValues}
          setFilters={setFilters}
          hideFilter={() => {}}
          displayedFilters={{}}
          {...rest}
        />
      )}
    </div>
  )
};

const SanitizedToolbar = ({filterValues, addParams, classes, ...props}) => {
  return (
    <TopToolbar>
      <Button
        className={classes.button}
        component={Link}
        to={{
          pathname: "/devices/create",
          state: { record: addParams }
        }}
        label="Add device"
      >
        <IconAdd />
      </Button>
    </TopToolbar>
  )
};

class QueryTemplate {
  constructor(page, perPage) {
    return {
      filter: {},
      pagination: {
        perPage: perPage,
        page: page
      },
      sort: {
        field: "createdAt",
        order: "DESC"
      }
    };
  }
}

const Tree = props => {
  const { dataProvider, location, noDevices, tableView, basePath } = props;
  const classes = useStyles();
  const history = useHistory();
  const search = location.search;

  const setPage = (resource, uuid, page) => {
    fetchData(false, resource, uuid, page);
  };

  const params = new URLSearchParams(search);

  const [filterParams, setFilterParams] = useState({
    searchName: params.get('name'),
    clientUuid: params.get('clientUuid'),
    objectUuid: params.get('objectUuid'),
    deviceTypeUuid: params.get('deviceTypeUuid'),
    deviceParentUuid: params.get('deviceParentUuid')
  });

  const [parentDevice, setParentDevice] = useState(null);

  const setFilters = value => {
    const { resource, uuid } = record;
    record.filter = value;
    if (record[resource]) {
      record.objectUuid = record[resource].objectUuid;
    }
    setRecord(record);
    if (record.filter) {
      setFilterParams(filterParams => {
        filterParams['searchName'] = record.filter.name;
        filterParams['clientUuid'] = record.filter.clientUuid;
        filterParams['deviceTypeUuid'] = record.filter.deviceTypeUuid;
        return filterParams
      });
      setAddParams(addParams => {
        addParams['clientUuid'] = record.filter.clientUuid;
        return addParams
      });
    }
    fetchData(
      false,
      resource,
      record[resource] && !Array.isArray(record[resource])
        ? uuid
        : null,
      pagination.devices.currentPage
    );
  };

  const [record, setRecord] = useState({});
  const [addParams, setAddParams] = useState({
    objectUuid: params.get('objectUuid'),
    clientUuid: params.get('clientUuid'),
    deviceParentUuid: params.get('deviceParentUuid'),
    parentUuid: null,
  });

  const [loading, setLoading] = useState({
    objects: false,
    devices: false
  });
  const [navStack, setNavStack] = useState([
    { resource: "objects", uuid: null, record: null }
  ]);
  const [pagination, setPagination] = useState({
    objects: {
      currentPage: 1,
      perPage: 100,
      setPage,
      total: 0
    },
    devices: {
      currentPage: 1,
      perPage: 100,
      setPage,
      total: 0
    }
  });

  const navEvent = useCallback(
    (resource, uuid, item) => {
      let navElementIndexInNavStack = null;

      if (
        (resource === "objects" && !uuid) ||
        (navStack.length === 2 && resource === "backToPrevious")
      ) {
        resource = "backToRoot";
      }

      // Searching element in navStack, if user clicked on nav item
      navStack.find((element, index) => {
        if (element.uuid === uuid) {
          navElementIndexInNavStack = index;
          return true;
        }
        return false;
      });

      if (resource === "backToRoot") {
        navStack.splice(1, navStack.length);
        setFilterParams(filterParams => {
          filterParams['objectUuid'] = null;
          filterParams['deviceParentUuid'] = null;
          return filterParams
        });
      } else if (resource === "backToPrevious") {
        navStack.pop();
      } else if (navElementIndexInNavStack) {
        if (navStack.length === 2) {
          setFilterParams(filterParams => {
            filterParams['objectUuid'] = null;
            return filterParams
          });
        } else if (
          navStack.length > 2 &&
          navStack[navStack.length - 2].resource === "objects" &&
          navStack[navStack.length - 1].resource === "devices"
        ) {
          setFilterParams(filterParams => {
            filterParams['deviceParentUuid'] = null;
            return filterParams
          });
        }
        navStack.splice(navElementIndexInNavStack + 1, navStack.length);
      } else if (navElementIndexInNavStack === 0 && resource === "devices") {
        // not adding to stack
      } else {
        navStack.push({
          resource,
          uuid,
          item
        });
      }

      setNavStack(navStack);
    },
    [navStack, setNavStack]
  );

  const fetchObjectsList = async (page) => {
    const resource = "objects";
    const { perPage, currentPage } = pagination[resource];
    const query = new QueryTemplate(page || currentPage, perPage);
    // Get only root items
    query.filter.parentUuid = { $is: null };
    query.filter.embedded = { children: true };

    return dataProvider(GET_LIST, resource, query).then(async response => {
      /*
				response = current data: resource GET_LIST
			*/
      const currentData = response.data;
      pagination[resource].total = response.total;
      pagination[resource].currentPage = page || currentPage;
      return {
        currentData: currentData.map(item => {
          item.resource = resource;
          return item;
        }),
        pagination
      };
    }).catch(error => {
      console.error(error);
    });
  }

  const fetchDevicesList = async (page) => {
    const resource = "devices";
    let clientUuid = getQueryParam('clientUuid');
    let searchName = getQueryParam('searchName');
    const { perPage, currentPage } = pagination[resource];
    const query = new QueryTemplate(page || currentPage, perPage);
    // Get only root items
    query.filter.parentUuid = { $is: null };
    query.filter.objectUuid = getQueryParam('objectUuid');
    setFilterParams(filterParams => {
      filterParams['objectUuid'] = query.filter.objectUuid;
      return filterParams
    });
    if (clientUuid) {
      query.filter.clientUuid = clientUuid;
    }
    if (searchName) {
      query.filter.name = { $iLike: '%' + searchName + '%' };
    }

    return dataProvider(GET_LIST, resource, query).then(async response => {
      /*
				response = current data: resource GET_LIST
			*/
      const currentData = response.data;
      pagination[resource].total = response.total;
      pagination[resource].currentPage = page || currentPage;
      return {
        currentData: currentData.map(item => {
          item.resource = resource;
          return item;
        }),
        pagination
      };
    }).catch(error => {
      console.error(error);
    });
  };

  const fetchDevicesByParent = async (parentUuid, page) => {
    const queue = [];
    const resource = "devices";
    const { perPage, currentPage } = pagination[resource];
    const parentQuery = new QueryTemplate(page || currentPage, perPage);
    const childrenQuery = new QueryTemplate(page || currentPage, perPage);
    let result = null;
    let deviceTypeUuid = getQueryParam('deviceTypeUuid');
    let searchName = getQueryParam('searchName');
    let clientUuid = getQueryParam('clientUuid');

    // Get parent data
    setFilterParams(filterParams => {
      filterParams['deviceParentUuid'] = parentUuid;
      return filterParams
    });
    parentQuery.id = parentUuid;
    parentQuery.filter.embedded = {
      parent: true,
      object: true,
      model: true,
      client: true
    };

    if (deviceTypeUuid) {
      const modelsQuery = new QueryTemplate();
      modelsQuery.filter.deviceTypeUuid = deviceTypeUuid;
      result = await dataProvider(GET_LIST, "devices-models", modelsQuery);
      const modelsData = result.data;
      parentQuery.filter.deviceModelUuid = { $in: modelsData.map(model => model.uuid)};
    }

    if (searchName) {
      parentQuery.filter.name = { $iLike: '%' + searchName + '%' };
    }

    // Get chilren list of parent device by its uuid
    childrenQuery.filter.parentUuid = parentUuid;
    setAddParams(addParams => {
      addParams['parentUuid'] = parentUuid;
      return addParams
    });

    if (clientUuid) {
      childrenQuery.filter.clientUuid = clientUuid;
    }

    queue.push(dataProvider(GET_ONE, resource, parentQuery));
    queue.push(dataProvider(GET_LIST, resource, childrenQuery));

    return Promise.all(queue).then(async response => {
      /*
				response[0] = current data: resource GET_ONE
				response[1] = parent's children data
			*/
      const currentData = response[0].data;
      const childrenData = response[1].data;

      pagination[resource].total = response[1].total;
      pagination[resource].currentPage = page || currentPage;

      // Augment item with resource type
      currentData.resource = resource;

      if (childrenData && childrenData.length) {
        setParentDevice(currentData);
      }

      // If parent exists, set its resource, which is always the same with current item
      if (currentData.parent) {
        currentData.parent.resource = resource;
      }

      // If children exist, set their resource, which is always the same with current item
      if (childrenData !== null) {
        currentData.children = childrenData;
        currentData.children.map(item => {
          item.resource = resource;
          return item;
        });
      }

      return {currentData, pagination};
    }).catch(error => {
      console.error(error);
    });
  }

  const fetchObjectsByParent = async (parentUuid, page) => {
    const queue = [];
    const resource = "objects";
    const { perPage, currentPage } = pagination[resource];
    const parentQuery = new QueryTemplate(page || currentPage, perPage);
    const childrenQuery = new QueryTemplate(page || currentPage, perPage);
    const devicesQuery = new QueryTemplate(page || currentPage, perPage);

    let result = null;
    let searchName = getQueryParam('searchName');
    let deviceTypeUuid = getQueryParam('deviceTypeUuid');
    let clientUuid = getQueryParam('clientUuid');

    // Get parent data
    parentQuery.id = parentUuid;
    parentQuery.filter.embedded = {
      parent: true
    };

    // Get chilren list of parent object by its uuid
    childrenQuery.filter.parentUuid = parentUuid;

    // Get devices list of parent object by its uuid and only first level
    devicesQuery.filter.objectUuid = parentUuid;
    setFilterParams(filterParams => {
      filterParams['objectUuid'] = parentUuid;
      return filterParams
    });
    devicesQuery.filter.parentUuid = { $is: null };

    setAddParams(addParams => {
      addParams['objectUuid'] = parentUuid;
      return addParams
    });

    if (clientUuid) {
      devicesQuery.filter.clientUuid = clientUuid;
    }

    if (deviceTypeUuid) {
      const modelsQuery = new QueryTemplate();
      modelsQuery.filter.deviceTypeUuid = deviceTypeUuid;
      result = await dataProvider(GET_LIST, "devices-models", modelsQuery);
      const modelsData = result.data;
      devicesQuery.filter.deviceModelUuid = { $in: modelsData.map(model => model.uuid)};
    }

    if (searchName) {
      devicesQuery.filter.name = { $iLike: '%' + searchName + '%' };
    }

    queue.push(dataProvider(GET_ONE, resource, parentQuery));
    queue.push(dataProvider(GET_LIST, resource, childrenQuery));
    queue.push(dataProvider(GET_LIST, "devices", devicesQuery));

    return Promise.all(queue).then(async response => {
      /*
        response[0] = current data: resource GET_ONE
        response[1] = parent's children data
        response[2] = parent's devices data (objects case only)
      */
      const currentData = response[0].data;
      let additionalResult = null;
      let childrenData = null;
      let devicesData = null;

      childrenData = response[1].data;
      pagination[resource].total = response[1].total;
      pagination[resource].currentPage = page || currentPage;

      devicesData = response[2].data;
      pagination["devices"].total = response[2].total;
      pagination[resource].currentPage = page || currentPage;

      // Augment item with resource type
      currentData.resource = resource;

      // If parent exists, set its resource, which is always the same with current item
      if (currentData.parent) {
        currentData.parent.resource = resource;
      }

      // If children exist, set their resource, which is always the same with current item
      if (childrenData !== null) {
        currentData.children = childrenData;
        currentData.children.map(item => {
          item.resource = resource;
          return item;
        });
      }

      additionalResult = devicesData.map(item => {
        item.resource = "devices";
        return item;
      });

      return {currentData, additionalResult, pagination};
    }).catch(error => {
      console.error(error);
    });
  };

  const getQueryParam = (name) => {
    const paramName = (name === "searchName") ? "name" : name;
    if (record && record.filter && record.filter[paramName]) {
      return record.filter[paramName]
    }
    return filterParams[name];
  };

  const fetchData = useCallback(
    async (initial, resource, uuid, page, back) => {
      setLoading(loading => {
        loading[resource] = true;
        return { ...loading };
      });

      let deviceParentUuid = filterParams['deviceParentUuid'];

      if (initial) {
        // get both objects and devices
        const objectUuid = getQueryParam('objectUuid');
        let objectsResult;
        let devicesResult;
        if (objectUuid) {
          objectsResult = await fetchObjectsByParent(objectUuid);
          if (objectsResult.currentData.parent) {
            navEvent("objects", objectsResult.currentData.parent.uuid, objectsResult.currentData.parent);
          }
          navEvent("objects", objectUuid, objectsResult.currentData);
        } else {
          objectsResult = await fetchObjectsList();
        }
        if (objectsResult) {
          record["objects"] = objectsResult.currentData;
        }
        if (deviceParentUuid) {
          devicesResult = await fetchDevicesByParent(deviceParentUuid);
          if (devicesResult.parent) {
            navEvent("objects", devicesResult.parent.uuid, devicesResult.parent);
          }
          navEvent("devices", deviceParentUuid, devicesResult.currentData);
          record.uuid = deviceParentUuid;
          record["devices"] = devicesResult.currentData;
        } else if (objectUuid && !noDevices) {
          devicesResult = await fetchDevicesList();
          record["devices"] = devicesResult.currentData;
        }
        if (noDevices) {
          record.resource = "objects";
        } else {
          record.resource = "devices";
        }
      } else {
        let response;
        if (!uuid) {
          response = (resource === "objects") ? await fetchObjectsList(page) : await fetchDevicesList(page);
          if (!page && !back) {
            navEvent(resource, null, response.currentData);
          }
        } else {
          // Get data and assosiations for selected node
          if (resource === "objects") {
            response = await fetchObjectsByParent(uuid, page);
            record.devices = response.additionalResult;
          } else {
            response = await fetchDevicesByParent(uuid, page);
          }
          record.uuid = uuid;
          if (!page && !back) {
            navEvent(resource, uuid, response.currentData);
          }
        }
        record.resource = resource;
        record[resource] = response.currentData;
        setPagination(response.pagination);
        if (back) {
          if (resource === "objects" && navStack[navStack.length - 1].resource === "devices") {
            navStack.pop();
          }
          navStack.pop();
        }
      }
      const params = new URLSearchParams(search);
      Object.keys(filterParams).forEach((key, index) => {
        const paramName = (key === "searchName") ? "name" : key;
        if (!filterParams[key]) {
          params.delete(paramName);
        } else {
          params.set(paramName, filterParams[key])
        }
      });
      const newSearch = `?${params.toString()}`;
      if (search !== newSearch) {
        history.push({search: newSearch});
      }
      setRecord(record);
      setLoading(loading => {
        loading[resource] = false;
        return { ...loading };
      });
    },
    [dataProvider, record, setRecord, navStack, filterParams, navEvent, setLoading, pagination]// eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleClick = (resource, uuid) => {
    fetchData(false, resource, uuid);
  };

  const handleNavClick = (resource, uuid) => {
    fetchData(false, resource, uuid).then(() => {
      if (resource === "objects") {
        if (uuid) {
          if (!noDevices) {
            fetchData(false, "devices");
          }
        } else {
          setRecord({});
        }
      }
    });
  };

  const handleBackClick = (resource, uuid) => {
    setFilterParams(filterParams => {
      filterParams['deviceParentUuid'] = null;
      if (resource === "objects") {
        filterParams['objectUuid'] = null;
      }
      return filterParams
    });
    fetchData(false, resource, uuid, null, true).then(() => {
      if (resource === "objects") {
        if (uuid) {
          fetchData(false, "devices");
        } else {
          setRecord({});
        }
      } else if (resource === "devices") {
        setParentDevice(null);
      }
    });
  };

  useEffect(() => {
    fetchData(true, "objects");
  }, [fetchData]);

  return (
    <div className={classnames("list-page", classes.root)}>
      <TreeBreadcrumbs navStack={navStack} handleNavClick={handleNavClick}/>
      <Title title={<CustomTitle titleKey="resources.tree.name" />} />

      <ListToolbar
        filters={
          <SanitizedFilters
            defaultValues={filterParams}
            setFilters={setFilters}
            classes={classes}
            noDevices={noDevices}
          />
        }
        actions={
          noDevices ? null : <SanitizedToolbar addParams={addParams} classes={classes} />
        }
      />
      <div className={classes.main}>
        <Card className={classes.main}>
          <CardContent className={classes.main}>
            <Grid container alignItems="stretch" spacing={2}>
              {/* TREE VIEW */}
              <Grid item lg={4} sm={12} className={classes.child}>
                <Paper>
                  <TreeView
                    record={record}
                    source="objects"
                    isLoading={loading.objects}
                    handleBackClick={handleBackClick}
                    handleClick={handleClick}
                    navEvent={navEvent}
                    tableView={tableView}
                    pagination={pagination.objects}
                    basePath={basePath}
                  />
                </Paper>
              </Grid>

              {/* ITEMS VIEW */}
              {noDevices ? null : (
                <Grid item lg={8} sm={12} className={classes.child}>
                  {parentDevice ? (
                    <List component="div" subheader={<ParentSubHeader />}>
                      <ListItem key={parentDevice.uuid}>
                        <DeviceCardStyle record={parentDevice} />
                      </ListItem>
                    </List>
                  ) : null}
                  <Paper>
                    {record.devices &&
                    <ItemView
                      record={record}
                      source="devices"
                      isLoading={loading.devices}
                      handleBackClick={handleBackClick}
                      handleClick={handleClick}
                      pagination={pagination.devices}
                    />
                    }
                  </Paper>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default withDataProvider(Tree);
