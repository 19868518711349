import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingShirma from './LoadingShirma';
import { useForm } from 'react-final-form';
import {
  FormTab,
} from 'react-admin';

export const withLazyByPath = (WrappedComponent) => {
  return function (props) {
    const { path, children, showIf } = props;
    const form = useForm();

    const record = form.getState().values;

    const location = useLocation();
    const [isMount, setMount] = useState(false);

    useEffect(() => {
      if (isMount) {
        return;
      }

      const { pathname } = location;

      if (pathname.endsWith(path)) {
        // Uncomment to see difference
        // setTimeout(() => { setMount(true); }, 1000);
        setMount(true);
      }
    // eslint-disable-next-line
    }, [location.pathname]);

    if (showIf && !showIf(record)) {
      return <WrappedComponent />;
    }

    return <WrappedComponent {...props} children={isMount ? children : <LoadingShirma />} />;
  };
};

export default withLazyByPath(FormTab);
