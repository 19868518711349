import RuleIcon from '@material-ui/icons/DeviceHub';
import RuleList from './RuleList';
import RuleEdit from './RuleEdit';
import RuleCreate from './RuleCreate';

const resources = {
  list: RuleList,
  edit: RuleEdit,
  create: RuleCreate,
  icon: RuleIcon
};

export default resources;