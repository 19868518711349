import React from 'react';
import {
  TextInput,
  BooleanInput,
  DateField,
  FormTab,
  TabbedForm
} from 'react-admin';
import {makeStyles} from "@material-ui/styles";

import RolePermissions from './RolePermissions';
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import RoleEditToolbar from './RoleEditToolbar';
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const RoleEdit = props => {
  const classes = useStyles();
  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.role" {...props} />}
      {...props}
    >
      <TabbedForm
        toolbar={<RoleEditToolbar basePath={props.basePath}/>}
        submitOnEnter={false}
        redirect="edit"
      >
        <FormTab label="resources.roles.tabs.role">
          <SanitizedGrid
            justify="flex-start"
            container={true}
            spacing={2}
            className={classes.root}
          >
            <SanitizedGrid item lg={6} md={12}>
              <BooleanInput source="hidden" label="app.labels.hidden"/>
              <TextInput fullWidth source="name" label="app.labels.name"/>
              <TextInput fullWidth source="description" label="app.labels.description"/>
              <DateField source="createdAt" label="app.labels.createdAt"/>
              <DateField source="updatedAt" label="app.labels.updatedAt"/>
            </SanitizedGrid>
          </SanitizedGrid>
        </FormTab>
        <FormTab label="resources.roles.tabs.permissions" path="permissions">
          <RolePermissions
            source="permissions"
            {...props}
          />
        </FormTab>
      </TabbedForm>
    </EditWithBreadcrumbs>
  )
};

export default RoleEdit;
