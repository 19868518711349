import { Button as RAButton } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import React from "react";

const styles = theme => ({
	label: { paddingLeft: '0', display: 'block' },
	button: { display: 'block', textAlign: 'center', fontSize: '11px' },
});

// T5113
const VerticalButton = (props) => (<RAButton {...props}></RAButton>);

export default withStyles(styles)(VerticalButton);
