import React, { useState, useEffect } from "react";
import { Form } from "react-final-form";
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import {
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import IconLaunch from '@material-ui/icons/Launch';
import { makeStyles } from "@material-ui/core/styles";

import {
  useTranslate,
  withDataProvider,
  AutocompleteInput,
  GET_LIST
} from "react-admin";
import { useEffectOnce } from '../custom/utils';
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920

const useStyles = makeStyles(theme => ({
  label: { width: "5em", display: "inline-block" },
  button: { margin: "1em" },
  flexGrow: {
    flexGrow: 1
  },
  flexContainer: {
    display: "flex",
    flexFlow: "column"
  },
  selectContainer: {
    padding: '0 !important',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '600px'
  },
  buttonContainer: {
    padding: '0 !important',
    position: 'absolute',
    left: '650px',
    top: '30px'
  },
  iframeContainer: {
    padding: '0 !important',
    margin: '0 0 0 0'
  },
  emptyContainer: {
    padding: '30px !important'
  },
  rootContainer: {
    position: 'relative'
  },
  side: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch"
  },
  paper: {
    padding: "1em"
  },
  externalButton: {
    marginTop: "-1em"
  },
  externalButtonText: {
    fontSize: "0.5rem"
  },
}));

const GRAFANA_HOME_PAGE = window.sprutConfig.SPRUT_GRAFANA_HOME_PAGE || process.env.REACT_APP_GRAFANA_HOME_PAGE || '/grafana';

const DashboardInput = props => {
  const { resource, record, onChange, filter } = props;
  const [value, setValue] = useState((record && record.name) || "");

  const handleChange = selectedUuid => {
    onChange(selectedUuid);
  };

  const handleInput = value => {
    setValue(value || "");
    return true;
  }

  useEffect(() => {
    setValue((record && record.name) || "");
  }, [setValue, record])

  const handleSubmit = () => {
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form>
          <ReferenceInputWithFilter
            sort={{ field: "isDefault,isPersonal,createdAt", order: "DESC,DESC,DESC" }}
            label="app.labels.selectDashboard"
            resource={resource}
            source="uuid"
            filter={filter}
            reference="dashboards"
            onChange={handleChange}
          >
            <AutocompleteInput
              optionText="name"
              options={{
                value: value,
                fullWidth: true
              }}
              shouldRenderSuggestions={handleInput}
            />
          </ReferenceInputWithFilter>
        </form>
      )} />
  );

};

const OpenDashboard = props => {
  const { dataProvider, type, deviceUuid, objectUuid } = props;
  const translate = useTranslate();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [iframeHeight, setIframeHeight] = useState(window.innerHeight * 6/7);
  const [state, setState] = useState({current: null, dashboards: []});

  let filter = { isActive: true, type };
  if (deviceUuid) {
    filter.deviceUuid = deviceUuid;
  }
  if (objectUuid) {
    filter.objectUuid = objectUuid;
  }
  if (!deviceUuid && !objectUuid) {
    filter.isDefault = true;
    filter.type = { $eq: 'client'} ;
  }
  const fetchData = () => {
    // TODO refactor to fetch all dashboards so we later can change breadcrumbs without new queries
    return dataProvider(GET_LIST, "dashboards", {
      filter,
      sort: { field: "isDefault,isPersonal,createdAt", order: "DESC,DESC,DESC" }
    });
  };

  useEffectOnce(() => {
    const params = new URLSearchParams(location.search);
    const currentUuid = params.get('dashboard');
    fetchData().then(response => {
      const data = response.data;
      let selected = null;
      if (data.length) {
        selected = response.data[0];
        if (!currentUuid) {
          params.set('dashboard', selected.uuid);
          const newSearch = `?${params.toString()}`;
          if (location.search !== newSearch) {
            history.push({search: newSearch});
          }
        } else {
          selected = response.data.find(item => item.uuid === currentUuid);
        }
      }
      if (selected) {
        if (props.setDashboardData) {
          props.setDashboardData(selected);
        }
        setState({
          current: selected,
          dashboards: data,
        });
      }
    });

    // T6445
    const receiver = (e) => {
      console.log("Message from iframe", e.data);
      if (e.data && e.data.goto) {
        document.getElementById('dashboard-iframe').src = e.data.goto;
        const nextDashboards = state.dashboards.filter(({url}) => e.data.goto.endsWith(url));
        if (nextDashboards.length > 0) {
          const newPath = location.pathname.replace(state.current.deviceUuid, nextDashboards[0].deviceUuid);
          history.push({pathname: newPath});
        }
      }
      if (e.data && e.data.resize && iframeHeight < e.data.resize) {
        setIframeHeight(e.data.resize);
      }
    }
    window.addEventListener('message', receiver, false);
  });

  const handleChangeDashboard = (uuid) => {
    const params = new URLSearchParams(location.search);
    const dashboard = state.dashboards.filter(d => d.uuid === uuid)[0];
    setState({current: {...dashboard}, dashboards: state.dashboards});

    params.set('dashboard', uuid);
    const newSearch = `?${params.toString()}`;
    if (location.search !== newSearch) {
      history.push({search: newSearch});
    }
    if (props.setDashboardData) {
      props.setDashboardData(dashboard);
    }
    setIframeHeight(window.innerHeight * 6/7);
  };

  let dashboardUrl = (state && state.current && state.current.url) || '';
  if (!dashboardUrl.startsWith('http')) {
    dashboardUrl = `${GRAFANA_HOME_PAGE}${dashboardUrl}`;
  }

  let embeddedUrl = new URL(dashboardUrl);
  embeddedUrl.searchParams.append('kiosk', 'tv');
  embeddedUrl = embeddedUrl.toString();

  return (
    <Grid component="label" container alignItems="center" spacing={2} className={classes.rootContainer}>
      { state.dashboards.length > 0 ? (
        <React.Fragment>
          <Grid item md={6} className={classes.selectContainer}>
            <DashboardInput resource="dashboards" record={state.current} onChange={handleChangeDashboard} filter={filter} />
          </Grid>
          <Grid item md={1} align="center" className={classes.buttonContainer}>
            <Button className={classes.externalButton} target="_blank" title={state.current.name} href={dashboardUrl}>
              <IconLaunch />
              <Typography className={classes.externalButtonText}>{translate('app.labels.openInNewTab')}</Typography>
            </Button>
          </Grid>
        </React.Fragment>
      ) : null }
      { state.current ? (
        <Grid item md={12} className={classes.iframeContainer}>
          <iframe id="dashboard-iframe" title={state.current.name} src={embeddedUrl} width="100%" height={iframeHeight} frameBorder="0">
          </iframe>
        </Grid>
      ) : <Grid item md={12} className={classes.emptyContainer}>{translate('resources.dashboards.titles.noMainDashboard')}</Grid> }
    </Grid>
  );
};

export default withDataProvider(OpenDashboard);
