import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
} from 'react-admin';
import rowStyle from '../styling/rowStyle';
import UserLogsShow from './UserLogsShow';
import CustomTitle from '../custom/CustomTitle'; // T5275
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";

const listStyles = {
  description: {
    maxWidth: '18em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};

const UserLogsList = ({ classes, ...props }) => (
  <ListWithBreadcrumbs
    filters={<StyledSearchFilter source="login,phone,data,description,response" />} // TODO: add simultaneous iLike filter by login, phone, description, data, response fields
    bulkActionButtons={false} {...props}
    title={<CustomTitle titleKey="resources.users-logs.name" {...props} />}
  >
    <Datagrid expand={<UserLogsShow />} rowStyle={rowStyle('responseCode')}>
      <TextField source="id" />
      <ReferenceField
        label="app.labels.login"
        source="userId"
        reference="users"
        allowEmpty
      >
        <TextField source="login" />
      </ReferenceField>
      <ReferenceField
        label="app.labels.phone"
        source="userId"
        reference="users"
        allowEmpty
      >
        <TextField source="phone" />
      </ReferenceField>
      <TextField source="type" label="app.labels.type" />
      <TextField source="module" label="app.labels.module" />
      <TextField
        source="description"
        label="app.labels.description"
        cellClassName={classes.description}
      />
      <DateField source="createdAt" label="app.labels.createdAt" />
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default withStyles(listStyles)(UserLogsList);
