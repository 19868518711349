import MessageTypeIcon from '@material-ui/icons/Message';
import MessageTypeList from './MessageTypeList';
import MessageTypeEdit from './MessageTypeEdit';
import MessageTypeCreate from './MessageTypeCreate';

const resources = {
  list: MessageTypeList,
  edit: MessageTypeEdit,
  create: MessageTypeCreate,
  icon: MessageTypeIcon
};

export default resources;
