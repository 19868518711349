import React, {Fragment, useCallback, useState} from "react";
import {
  TextInput,
  AutocompleteInput,
  SimpleForm,
  useTranslate,
  showNotification,
  ArrayInput,
  SimpleFormIterator,
  GET_ONE,
  GET_LIST,
  Labeled,
  withDataProvider,
  CREATE, Toolbar,
} from "react-admin";
import { useFormState } from 'react-final-form';

import {makeStyles} from "@material-ui/styles";
import {
  Paper,
  Checkbox,
  FormControlLabel,
  Grid,
  Table, TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

import DateFnsUtils from "@date-io/date-fns";
import ruLocale from "date-fns/locale/ru";

import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { SaveButton } from "../custom/WithUndoable";

const defaultFromTime = () => {
  return (new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)).toISOString().slice(0, 16);
};

const defaultToTime = () => {
  return (new Date().toISOString()).slice(0, 16);
};

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  button: {
    margin: "2em 1em"
  },
  subtext: {
    color: "gray",
    fontSize: "11px",
    marginTop: "1em"
  }
});

const ReportSaveButton = props => {
  const formState = useFormState();
  const handleClick = useCallback(() => {
    props.handleSubmit(formState.values);
  }, [props, formState]);
  return (props.selectedDevices.length || props.selectedObjects.length) ? (<SaveButton handleSubmitWithRedirect={handleClick} />) : null;
}

const ReportCreate = ({dataProvider, hasList, hasEdit, hasShow, hasCreate, ...props}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [allDevicesSelected, setAllDevicesSelected] = useState(false);
  const [allObjectSelected, setAllObjectsSelected] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedObjects, setSelectedObjects] = useState([]);
  const [params, setParams] = useState({
    aggInterval: null,
    reportTemplateUuid: null,
    deviceModelUuid: null,
    messageTypeUuid: null,
    clientUuid: null,
    devices: [],
    objects: [],
    dashboards: [],
  });

  const [fromTime, setFromTime] = useState(defaultFromTime());
  const [toTime, setToTime] = useState(defaultToTime());

  const handleChangeFromTime = selectedDate => {
    setFromTime(selectedDate);
  };
  const handleChangeToTime = selectedDate => {
    setToTime(selectedDate);
  };

  const toggleAllDevicesSelected = () => {
    setSelectedDevices(allDevicesSelected ? [] : params.devices.map(r => r.uuid));
    setAllDevicesSelected(!allDevicesSelected);
  };

  const toggleAllObjectsSelected = () => {
    setSelectedObjects(allObjectSelected ? [] : params.objects.map(r => r.uuid));
    setAllObjectsSelected(!allObjectSelected);
  };


  const toggleDeviceCheckbox = device => {
    let selected;
    if (selectedDevices.indexOf(device.uuid) >= 0) {
      // exclude
      selected = [...selectedDevices.filter(uuid => uuid !== device.uuid)];
    } else {
      // include
      selected = [...selectedDevices, device.uuid];
    }
    setSelectedDevices(selected);
  }

  const toggleObjectCheckbox = object => {
    let selected;
    if (selectedObjects.indexOf(object.uuid) >= 0) {
      // exclude
      selected = [...selectedObjects.filter(uuid => uuid !== object.uuid)];
    } else {
      // include
      selected = [...selectedObjects, object.uuid];
    }
    setSelectedObjects(selected);
  }

  const handleSubmit = (formValues) => {
    if (!selectedDevices.length && !selectedObjects.length) {
      return;
    }
    dataProvider(CREATE, 'reports', { data: {
      aggInterval: params.aggInterval,
      fromTime,
      toTime,
      reportTemplateUuid: params.reportTemplateUuid,
      target: 'devices',
      devices: selectedDevices,
      objects: selectedObjects,
      dashboards: (formValues.dashboards && formValues.dashboards.map(({url}) => url)) || [],
    }})
      .then(response => {
        window.location.hash = '#/reports';
      })
      .catch(err => {
        showNotification(err.message);
        throw err;
      });
    return false;
  };

  const handleReportTemplateChange = (reportTemplateUuid) => {
    const fetchData = {
      clientUuid: params['clientUuid'],
      dashboards: params['dashboards'] || [],
    };
    if (!reportTemplateUuid) {
      setParams(params => {
        params['reportTemplateUuid'] = null;
        params['aggInterval'] = null;
        params['deviceModelUuid'] = null;
        return params
      });
      return;
    }
    dataProvider(GET_ONE, 'report-templates', {
      id: reportTemplateUuid,
      filter: {
        clientUuid: params['clientUuid']
      }
    }).then(result => {
      fetchData['isAlertReport'] = result.data.isAlertReport;
      fetchData['reportTemplateUuid'] = result.data.uuid;
      fetchData['aggInterval'] = result.data.aggInterval;
      fetchData['objects'] = [];
      fetchData['devices'] = [];
      if (fetchData.isAlertReport) {
        return dataProvider(GET_LIST, 'objects', {
          sort:  {
            field: 'updatedAt',
            order: 'desc'
          },
        }).then(result => {
          if (result && result.data && result.data.length) {
            fetchData['objects'] = result.data;
          } else {
            fetchData['objects'] = [];
          }
          setParams(fetchData);
          setSelectedObjects([]);
        });
      } else {
        fetchData['messageTypeUuid'] = result.data.messageTypeUuid;
        return dataProvider(GET_ONE, 'messages-types', {
          id: result.data.messageTypeUuid,
        }).then(result => {
          fetchData['deviceModelUuid'] = result.data.deviceModelUuid;
          return dataProvider(GET_LIST, 'devices', {
            sort:  {
              field: 'updatedAt',
              order: 'desc'
            },
            filter: { deviceModelUuid: result.data.deviceModelUuid }
          }).then(result => {
            if (result && result.data && result.data.length) {
              fetchData['devices'] = result.data;
            } else {
              fetchData['devices'] = [];
            }
            setParams(fetchData);
            setSelectedDevices([]);
          });
        });
      }
    });
  };

  return (
    <Paper>
      <SimpleForm
        {...props}
        toolbar={
          <Toolbar {...props}>
            <ReportSaveButton selectedDevices={selectedDevices} selectedObjects={selectedObjects} handleSubmit={handleSubmit}/>
          </Toolbar>}
      >
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          fullWidth
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12} xs={12}>
            <Fragment>
              <ReferenceInputWithFilter
                label="resources.report-templates.titles.chooseReportTemplate"
                reference="report-templates"
                source="reportTemplateUuid"
                onChange={handleReportTemplateChange}
              >
                <AutocompleteInput
                  optionText="name"
                  options={{
                    fullWidth: true
                  }}
                />
              </ReferenceInputWithFilter>
            </Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
              <Grid
                justify="flex-start"
                container={true}
                spacing={2}
                className={classes.root}
              >
                <Grid item lg={6} md={12}>
                  <Labeled label="app.configurator.fromTime" fullWidth>
                    <DateTimePicker value={fromTime} format="yyyy.MM.dd HH:mm" onChange={handleChangeFromTime} />
                  </Labeled>
                </Grid>
                <Grid item lg={6} md={12}>
                  <Labeled label="app.configurator.toTime" fullWidth>
                    <DateTimePicker value={toTime} format="yyyy.MM.dd HH:mm" onChange={handleChangeToTime} />
                  </Labeled>
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            <ArrayInput source="dashboards">
              <SimpleFormIterator >
                <TextInput label="app.labels.dashboard" source="url" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
            {params.devices.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allDevicesSelected}
                            onChange={e => toggleAllDevicesSelected()}
                            color="primary"
                          />
                        }
                      /> </TableCell>
                    <TableCell align="left">{translate(`app.labels.device`)}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {params.devices.map((device, i) => (
                    <TableRow key={`${i}-${device.uuid}`} hover={true}>
                      <TableCell>
                        <FormControlLabel
                          style={{ marginTop: "-0.4em" }}
                          control={
                            <Checkbox
                              checked={selectedDevices.indexOf(device.uuid) >= 0}
                              onChange={e => toggleDeviceCheckbox(device)}
                              color="primary"
                            />
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <p>{device.name}</p>
                        <p className={classes.subtext}>{device.uuid}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}
            {params.objects.length ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allObjectSelected}
                            onChange={e => toggleAllObjectsSelected()}
                            color="primary"
                          />
                        }
                      />
                    </TableCell>
                    <TableCell align="left">{translate(`app.labels.object`)}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {params.objects.map((object, i) => (
                    <TableRow key={`${i}-${object.uuid}`} hover={true}>
                      <TableCell>
                        <FormControlLabel
                          style={{ marginTop: "-0.4em" }}
                          control={
                            <Checkbox
                              checked={selectedObjects.indexOf(object.uuid) >= 0}
                              onChange={e => toggleObjectCheckbox(object)}
                              color="primary"
                            />
                          }
                        />
                      </TableCell>
                      <TableCell align="left">
                        <p>{object.name}</p>
                        <p className={classes.subtext}>{object.uuid}</p>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}

          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </Paper>
  );
};

export default withDataProvider(ReportCreate);
