import React, { useCallback, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { withDataProvider, showNotification, TextInput, fetchUtils } from 'react-admin';
import { useFormState } from 'react-final-form';
import { connect, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import BooleanTrueIcon from '@material-ui/icons/Check';
import BooleanFalseIcon from '@material-ui/icons/Close';

import { SaveButton } from '../custom/WithUndoable'; // T5095

export const useEffectOnce = fun => useEffect(fun, []);

export const parseJSON = (str, asNull) => {
  if (typeof str == 'object' && str !== null) {
    return str;
  }
  let o = null;
  let catched = false;
  try {
    o = JSON.parse(str);
  } catch(e) {
    catched = true;
  }

  if (typeof o !== 'object' || o === null || catched) {
    if (asNull) {
      o = null;
    } else {
      o = {};
    }
  }

  return o;
};

export const placeholderPayloadSchema = {
  '$id': 'https://example.com/schemas/v01/example.json',
  'type': 'object',
  'title': 'Title',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  'required': [],
  'properties': {
    "input": {
      "type": "integer",
      "description": "input value"
    },
  },
  'description': 'description'
};

const pad = v => {
  const pad = '00';
  return (pad + v).slice(-2)
};

export const dateFormatter = v => {
  if (!(v instanceof Date) || isNaN(v)) return;
  const yy = v.getFullYear().toString();
  const mm = (v.getMonth() + 1).toString();
  const dd = v.getDate().toString();
  const HH = v.getHours().toString();
  const MM = v.getMinutes().toString();
  return `${yy}-${pad(mm)}-${pad(dd)}T${pad(HH)}:${pad(MM)}`;
};

export const dateParser = v => {
  if (!v) return;
  return new Date(v);
};

export const groupByObjProperty = (array, key, property) => {
  return array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    if (obj[property]) {
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(
        obj[property]
      );
    }
    return objectsByKeyValue;
  }, {});
};

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = {};
  }
  if (!(options.headers instanceof Headers)) {
    options.headers = new Headers(options.headers);
  }
  if (localStorage.getItem('token'))

  // add your own headers here
  if (options.authToken || localStorage.getItem('token')) {
    options.user = {
      authenticated: true,
      token: `Bearer ${localStorage.getItem('token')}`
    };
    delete options.authToken;
  }

  options.credentials = 'include';

  return fetchUtils
    .fetchJson(url, options)
    .catch(error => {
      // error is HttpError object
      // {
      //   "errors": [
      //     {
      //       "message": "Bad Request Error",
      //       "value": {
      //         "errors": [
      //           {
      //             "message": "Device already has packet to process",
      //             "path": "devices.rules.run",
      //             "value": "Device already has packet to process - started by scheduler-13295! Please wait!",
      //             "lockMeta": {
      //               "key": {
      //                 "hsetName": "/rules/manual",
      //                 "field": "0e7b0fbc-a346-48d5-a145-3f9fff8c68e1"
      //               },
      //               "create": 1573542142000,
      //               "expire": 1573542172000,
      //               "scheduler": "scheduler-13295"
      //             }
      //           }
      //         ],
      //         "name": "BadRequestError",
      //         "code": 400
      //       }
      //     }
      //   ],
      //   "stack": "InternalSeverError: Internal Sever Error....",
      //   "message": "Internal Sever Error"
      // }

      let statusCode = null;
      const findErrorSource = data => {

        statusCode = data.code || statusCode;

        if (data instanceof Array && data.length > 0) {
          return findErrorSource(data[0]);
        }

        if (data instanceof Object && data.body) {
          return findErrorSource(data.body);
        }

        if (data instanceof Object && data.errors) {
          return findErrorSource(data.errors);
        }

        if (data instanceof Object && data.value instanceof Object && !Array.isArray(data.value)) {
          return findErrorSource(data.value);
        }

        return data;
      };

      const errorSource = findErrorSource(error);
      let resError = new Error();
      resError.status = error instanceof Object ? (error.status || error.statusCode) : 500;
      resError.type = error instanceof Object ? error.message : 'unknown';
      if (errorSource) {
        resError.message = "errors." + errorSource.message.split('.').map(i => slugifylc(i, "_")).join('.');
        resError.errorSource = errorSource;
        resError.status = statusCode || resError.status;
      } else if (error.body instanceof Object) {
        resError.message = "errors." + (error.body.message || error.body._error || 'some_error').map(i => slugifylc(i, "_")).join('.');
      } else {
        resError.message = "errors.unknown_error";
      }
      throw resError;
    });
};


export const PhoneInput = props => {
  return (
    <InputMask
      {...props}
      mask="+9 (999) 999-99-99"
      value={props.record[props.source]}
      onChange={props.onChange}
    >
      {inputProps => <TextInput {...inputProps} type="tel" />}
    </InputMask>
  );
};

const saveWithSideEffect = props => {
  const {
    record,
    resource,
    target,
    dataProvider,
    formState: { values }
  } = props;
  const saveParams = { data: values };

  if (record.id) {
    saveParams.id = record.id;
  }

  // Update entity itself
  return dataProvider(record.id ? 'UPDATE' : 'CREATE', resource, saveParams).then((data) => {
    // Update entity's dependence
    let id = data.data.id;
    record.id = id;
    if (record[target]) {
      let pData = record[target].filter(item => item.enabled);
      return dataProvider('UPDATE_REFERENCES', resource, {
        id: id,
        target: target,
        data: pData
      }).then(() => {
        return record;
      });
    } else {
      return record;
    }
  });
};

const EntityEditSaveView = props => {

  const formState = useFormState();
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    if (!formState.valid) {
      showNotification('ra.message.invalid_form', 'warning');
      return;
    }

    return saveWithSideEffect({ ...props, formState }).then(() => {
      dispatch(showNotification('common.done'));
    });
  }, [props, formState, dispatch]);

  return <SaveButton handleSubmitWithRedirect={handleClick} />;
}

export const AppEditSaveButton = withDataProvider(
  connect(
    null,
    { showNotification, push }
  )(EntityEditSaveView)
);

export const isUuid = (string) => {
  const validUuid = /^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/;
  return validUuid.test(string);
}

export const isJsonString = (string) => {
  try {
    JSON.parse(string);
  } catch (error) {
    return false;
  }
  return true;
}

export const isLengthTwo = val => {
  return val.trim().length > 1;
};

const saveSettings = (settings) => {
  return httpClient(`${window.sprutConfig.SPRUT_API_URL || process.env.REACT_APP_API_URL}/me/settings`, { // TODO: вынести отсюда обращение к env в одну точку желательно в апп, для этого скорее всего для settings надо будет создать отдельный компонент, а не пихать их в утилиты.
      method: 'PUT',
      body: JSON.stringify({ general: settings })
    })
    .then(settings => {
      if (settings.json) {
        localStorage.setItem('settings', JSON.stringify(settings.json.general));
      }

      return getSettings();
    });
};

export const getSettings = () => {
  return parseJSON(localStorage.getItem('settings'));
};

export const changeSettings = (optionName, optionValue) => {
  let settings = getSettings() || {};
  if (optionName instanceof Object) {
    settings = {...settings, ...optionName};
  } else {
    settings[optionName] = optionValue;
  }

  return saveSettings(settings);
};

export const getDeepProperty = (obj, propStr) => {
  const prop = propStr.split('.');
  for (let i = 0; i < prop.length; i++) {
    if (obj instanceof Object) {
      obj = obj[prop[i]];
    } else {
      return undefined;
    }
  }
  return obj;
}

export const valueByPathGetter = p => o =>
    p.reduce((xs, x) =>
      ((xs && xs[x]) !== undefined) ? xs[x] : null, o);

export const setDeepProperty = (obj, pathStr, value, separator = '.') => {
  const properties = Array.isArray(pathStr) ? pathStr : pathStr.split(separator);
  return properties.reduce((prev, curr, index, arr) => {
    if (prev && index < arr.length - 1) {
      return prev[curr];
    } else {
      try {
        prev[curr] = value;
        return prev;
      } catch (err) {
        return err;
      }
    }
  }, obj);
};

const translit = (str, unknownCharReplace) => {
  if (typeof str != 'string') {
    if (typeof str == 'object' && str.toString) {
      str = str.toString();
    } else {
      str = '';
    }
  }
  if (typeof unknownCharReplace == 'undefined') {
    unknownCharReplace = '_';
  }
  var robj = {
    '@': 'a', 'а':'a', 'б':'b', 'в':'v', 'г':'g', 'д':'d', 'е':'e', 'ж':'g', 'з':'z', 'и':'i', 'й':'y', 'к':'k', 'л':'l', 'м':'m', 'н':'n', 'о':'o', 'п':'p', 'р':'r', 'с':'s', 'т':'t', 'у':'u', 'ф':'f', 'ы':'i', 'э':'e',
    'А':'A', 'Б':'B', 'В':'V', 'Г':'G', 'Д':'D', 'Е':'E', 'Ж':'G', 'З':'Z', 'И':'I', 'Й':'Y', 'К':'K', 'Л':'L', 'М':'M', 'Н':'N', 'О':'O', 'П':'P', 'Р':'R', 'С':'S', 'Т':'T', 'У':'U', 'Ф':'F', 'Ы':'I', 'Э':'E',
    'ё':'yo', 'х':'h', 'ц':'ts', 'ч':'ch', 'ш':'sh', 'щ':'shch', 'ъ':'', 'ь':'', 'ю':'yu', 'я':'ya',
    'Ё':'YO', 'Х':'H', 'Ц':'TS', 'Ч':'CH', 'Ш':'SH', 'Щ':'SHCH', 'Ъ':'', 'Ь':'', 'Ю':'YU', 'Я':'YA'
  };
  var newstr = [];
  for (var i = 0; i < str.length; i++) {
    if (str[i].match(/[a-z0-9]/i)) {
      newstr.push(str[i]);
    } else {
      newstr.push(robj[str[i]] || unknownCharReplace);
    }
  }
  return newstr.join('');
}

export const slugify = (str, whiteSpaceReplacer) => {
  return translit(str, whiteSpaceReplacer).replace(/\s+/img, (typeof whiteSpaceReplacer != 'undefined' ? whiteSpaceReplacer : '_'));
}

export const slugifylc = (str, whiteSpaceReplacer) => {
  return slugify(str, whiteSpaceReplacer).toLowerCase();
}

export const setDefaultValues = properties => {
  const res = {};
  Object.keys(properties).forEach(key => {
    const item = properties[key];
    if (!item) {
      return;
    }
    if (item["examples"] && item["examples"].length > 0) {
      res[key] = item["examples"][0];
      return;
    }
    switch (item["type"]) {
      case "string":
        res[key] = "";
        break;
      case "number":
      case "integer":
        res[key] = 0;
        break;
      case "boolean":
        res[key] = false;
        break;
      case "array":
        if (item.items.properties) {
          res[key] = [setDefaultValues(item.items.properties)];
        }
        break;
      case "object":
        if (item.properties) {
          res[key] = setDefaultValues(item["properties"]);
        }
        break;
      default:
        break;
    }
  });
  return res;
};

export const getMonthString = (monthNumber) => {
  let months = ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
  return months[ monthNumber ];
}


export const AGG_INTERVAL_FORMAT_VALUE = {
  '1y': v => v.slice(0, 4),
  '1q': v => v.slice(0, 7),
  '1M': v => v.slice(0, 7),
  '1w': v => v.slice(0, 10),
  '1d': v => v.slice(0, 10),
  '1h': v => v.slice(0, 13).replace('T', ' '),
  '1m': v => v.slice(0, 7),
  '1s': v => v.slice(0, 19).replace('T', ' '),
}
export const AGG_INTERVALS = ['all'].concat(Object.keys(AGG_INTERVAL_FORMAT_VALUE));
export const DEFAULT_AGG_INTERVAL = '1d';

export const AGG_MEASUREMENT_CHOICES = [
  { id: null, name: 'Off' },
  { id: 60 * 1000, name: '1m' },
  { id: 5 * 60 * 1000, name: '5m' },
  { id: 30 * 60 * 1000, name: '30m' },
  { id: 60 * 60 * 1000, name: '1h' },
  { id: 3 * 60 * 60 * 1000, name: '3h' },
  { id: 6 * 60 * 60 * 1000, name: '6h' },
  { id: 12 * 60 * 60 * 1000, name: '12h' },
  { id: 24 * 60 * 60 * 1000, name: '1d' },
  { id: 7 * 24 * 60 * 60 * 1000, name: '7d' },
  { id: 30 * 60 * 60 * 1000, name: '1M' },
  { id: 90 * 24 * 60 * 60 * 1000, name: '3M' },
  { id: 365 * 24 * 60 * 60 * 1000, name: '1Y' },
]

export const LOGS_PARAMETERS = [
  { name: "id", path: "id" },
  { name: "status", path: "status" },
  { name: "source", path: "source" },
  { name: "rulesProcessed", path: "rulesProcessed" },
  { name: "outputLogs", path: "outputLogs" },
  { name: "tasks", path: "tasks" },
  { name: "receivedAt", path: "mqttReceivedAt" },
  { name: "createdAt", path: "createdAt" },
  { name: "finishedAt", path: "finishedAt" },
];

export const NEW_LOGS_PARAMETERS = [
  { name: "timestamp", path: "timestamp", type: 'timestamp', formatTimestamp: true },
  { name: "source", path: "source", },
  // { name: "msgId", path: "id", },
  { name: "duration", path: "duration" },
  { name: "name", path: "name" },
  { name: "status", path: "status" },
  { name: "output", path: "output" },
  { name: "payload", path: "payload" },
];

export const calculateBegin = (interval) => {
  let begin = null;

  switch(interval) {
    case '1y':
      begin = new Date().getTime() - (12 * 30 * 24 * 60 * 60 * 1000) * 2;
      break;
    case '1q':
      begin = new Date().getTime() - (3 * 30 * 24 * 60 * 60 * 1000) * 2;
      break;
    case '1M':
      begin = new Date().getTime() - (30 * 24 * 60 * 60 * 1000) * 2;
      break;
    case '1w':
      begin = new Date().getTime() - (7 * 24 * 60 * 60 * 1000) * 2;
      break;
    case '1d':
      begin = new Date().getTime() - (24 * 60 * 60 * 1000) * 2;
      break;
    case '1h':
      begin = new Date().getTime() - (60 * 60 * 1000) * 2;
      break;
    case '1m':
      begin = new Date().getTime() - (60 * 1000) * 2;
      break;
    case '1s':
      begin = new Date().getTime() - 1000 * 2;
      break;
    default:
      begin = new Date().getTime() - (30 * 24 * 60 * 60 * 1000) * 2;
  }

  return begin;
};

export const renderCompactTableValue = (row, parameter) => {
  let value = row[parameter.path];
  if (parameter.name === 'status') {
    return (<span className={(value === 'OK') ? 'statusOk' : 'statusError'}>{value}</span>);
  }

  if (['isActive', 'dryRun'].indexOf(parameter.path) > -1) {
    return (
      <span className={(value === 'OK') ? 'statusOk' : 'statusError'}>
        {(value === true) ? <BooleanTrueIcon /> : <BooleanFalseIcon />}
      </span>
    )
  }

  if (parameter.name === 'raw') {
    if (!value) {
      return '';
    }
    if (value.payload) {
      return (<div className={'clickable'}><span>Payload:</span><br/>{JSON.stringify(value.payload, null, 0)}</div>);
    }
    if (value.output) {
      return (<div className={'clickable'}><span>Output:</span><br/><pre><code>{value.output}</code></pre></div>);
    }
    return '';
  }

  if (typeof value === 'object') {
    return (<pre><code>{JSON.stringify(value, null, 0)}</code></pre>);
  }

  if (value && parameter.type === 'timestamp' && parameter.formatTimestamp) {
    return (<pre><code>{new Date(value).toISOString()}</code></pre>);
  }
  return value;
}
