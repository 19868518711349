import { withStyles } from "@material-ui/core/styles";
import IconList from "@material-ui/icons/List";
import IconMap from "@material-ui/icons/Map";
import { refreshView as refreshViewAction } from "ra-core";
import React from "react";
import {
  Button,
  CreateButton,
  ExportButton,
  RefreshButton,
  TopToolbar,
} from "react-admin";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

const enhanceObjectListAction = connect(null, {
  refreshView: refreshViewAction,
});

const StyledListActions = withStyles({
  root: {
    paddingTop: "18px",
  },
})(TopToolbar);

export const ObjectsListActions = enhanceObjectListAction((props) => {
  let {
    currentSort,
    exporter,
    location,
    filterValues,
    resource,
    total,
    viewType,
    setViewType,
    refreshView,
  } = props;

  const history = useHistory();

  const handleClick = (event) => {
    event.preventDefault();
    viewType = viewType === "list" ? "map" : "list";
    const params = new URLSearchParams(location.search);
    params.set("view", viewType);

    const newSearch = `?${params.toString()}`;
    if (location.search !== newSearch) {
      history.push({ search: newSearch });
    }
    setViewType(viewType);
    refreshView();
  };

  return (
    <StyledListActions>
      <Button color="primary" label="app.labels.view" onClick={handleClick}>
        {viewType === "list" ? <IconList /> : <IconMap />}
      </Button>
      <CreateButton basePath={"/objects"} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <RefreshButton />
    </StyledListActions>
  );
});

export default ObjectsListActions;
