import simpleRestProvider from './dataProvider';
import elasticDataProvider from './elasticDataProvider';

/**
 * Facade for multiple data providers
 * */
export default (apiUrl) => {

  let simple = simpleRestProvider(apiUrl);
  let elastic = elasticDataProvider(apiUrl);

 /**
  * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
  * @param {String} resource Name of the resource to fetch, e.g. 'posts'
  * @param {Object} params The data request params, depending on the type
  * @returns {Object} { url, options } The HTTP request parameters
  */
  return (type, resource, params) => {
    if(resource === 'data' || resource === 'logs')
      return elastic(type, resource, params);
    return simple(type, resource, params);
  };
};
