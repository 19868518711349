export default {
  ra: {
    page: {
      empty: '',
      invite: ''
    }
  },
  resources: {
    rules: {
      name: "Rule |||| Rules",
      titles: {
        newRule: "New rule"
      },
      tabs: {
        rule: "Rule",
        assignments: "Assignments"
      }
    },
    "rules-assignments": {
      name: "Assignment |||| Assignments",
      titles: {
        newRuleAssignment: "New rule's assignment"
      }
    },
    "dry-run": {
      name: "Dry run",
      titles: {
        dryRunRules: "Dry run rules",
        device: "Device: "
      },
      labels: {
        rulesList: "Rules list",
        rule: "Rule",
        message: "Message",
        rulesExecutionLog: "Rules execution log",
        selectDevice: "Select device in filter above",
        noRulesForSelectedDevice: "No rules for selected device"
      }
    },
    tree: {
      name: "All devices",
      labels: {
        tree: "Tree",
        objectsList: "Objects list",
        devicesList: "Devices list",
        parentDevice: "Parent Device"
      },
      messages: {
        "select-item": "Please, select object in sibling list"
      }
    },
    objects: {
      name: "Object |||| Objects",
      titles: {
        newObject: "New object"
      },
      notifications: {
        managementToggledSuccess: 'Management state updated',
        runGroupSuccess: "Rule group is running",
        runRuleSuccess: "Rule is running"
      },
      dialogs: {
        groupTitle: "Please confirm running these rules",
        ruleTitle: "Please confirm running this rule"
      },
      tabs: {
        object: "Object",
        parameters: "Parameters",
        children: "Children",
        controls: "Controls",
        devices: "Devices",
        logs: "Logs"
      }
    },
    "objects-tree": {
      name: "Object's tree |||| Object's trees"
    },
    "objects-types": {
      name: "Object's type |||| Object's types",
      titles: {
        newObjectType: "New object's type"
      }
    },
    devices: {
      name: "Device |||| Devices",
      tabs: {
        device: "Device",
        children: "Children",
        parameters: "Parameters",
        acl: "Acl",
        data: "Data",
        management: "Management",
        dashboard: "Dashboard",
        logs: 'Logs',
        virtualMapping: "Parameters",
      },
      titles: {
        newDevice: "New device",
        allDevices: "All devices",
        chooseDevice: "Choose device",
        chooseMessageType: "Choose message type",
        allMessageType: "All message types"
      },
      buttons: {
        changeAcl: "Regenerate accesses"
      },
      pages: {
        dialogChangeAcl: "Are you sure?"
      },
      management: {
        switchOn: "on",
        switchOff: "off",
      },
      plashka: {
        never: "Never",
        lastSeen: "Last seen",
        place: "Place",
        primary: "primary",
        secondary: "secondary"
      },
      data: {
        tip:
          'Perhaps there is a delay in the response from the device, you can check the receipt of data yourself by clicking on the "update" button'
      }
    },
    "devices-all": {
      name: "All devices"
    },
    "devices-models": {
      name: "Device model |||| Device models",
      tabs: {
        modelParams: "Model parameters",
        messageTypes: "Message types",
        plateSetting: "Plate setting",
        management: "Management"
      },
      titles: {
        newDeviceModel: "New device model",
        noMessageTypes: "Message types are not set",
        cloneDeviceModel: "Clone device model"
      },
      icon: {
        choose: "Choose icon",
        chooseBtn: "Choose",
        title: "Icon"
      }
    },
    "devices-types": {
      name: "Device type |||| Device types",
      titles: {
        newDeviceType: "New device type"
      }
    },
    "messages-types": {
      name: "Message's type |||| Message's types",
      tabs: {
        messageType: "Message Type",
        configurator: "Configurator"
      },
      titles: {
        newMessageType: "New message's type"
      },
      direction: "Direction",
      directions: {
        in: "From device",
        out: "To device"
      },
      paramsPage: {
        wrongMT: "Selected message type doesn't target to this devices model",
        primary: "Primary parameters",
        secondary: "Secondary parameters",
        non: "Doesn't show in plashka",
        noParams: "Selected messageType hasn't parameters",
        updated: "Parameters view settings saved successfully",
        iconSelect: "Choose icon",
        icon: "Icon",
        add: "Add new parameter"
      }
    },
    vendors: {
      name: "Vendor |||| Vendors",
      titles: {
        newVendor: "New vendor"
      }
    },
    reports: {
      name: "Report |||| Reports",
      titles: {
        newReport: "New report",
        status: "Status",
        baseReport: "Base report",
        downloadReport: "Download report"
      }
    },
    "report-templates": {
      name: "Report Template |||| Report Templates",
      titles: {
        allReportTemplates: "All Report Templates",
        chooseReportTemplate: "Choose Report Template",
        newReportTemplate: "New Report Template",
        downloadTemplate: "Download Template"
      },
      inputs: {
        uploadTemplate: "Upload template file (.odt, .docx)",
        dropFile: "Drop your file here",
      },
      permission: {
        author: "Author",
        all: "All",
        client: "Client",
      }
    },
    "measure-units": {
      name: "Measure Unit |||| Measure Units",
      titles: {
        newMeasureUnit: "New measure unit"
      }
    },
    clients: {
      name: "Client |||| Clients",
      titles: {
        newClient: "New client"
      },
      tabs: {
        client: "Client",
        users: "Users",
        theme: "Theme"
      },
      inputs: {
        brandName: "Brand name",
        logoFileExtension: "Logo file extension",
        uploadLogo: "Upload logo image file (.png, .jpg, .jpeg)",
        headerBackground: "Main background",
        headerTextColor: "Main text color",
        pageBackground: "Page background",
        pageTextColor: "Page text color",
        linkTextColor: "Secondary text color",
        dropLogo: "Drop your image here"
      },
      buttons: {
        addUser: "Add user"
      },
      pages: {
        clientUserAdd: "Add client's user",
        clientUserUpdate: "Update client's user"
      },
      notifications: {
        clientUserCreateSuccess: "Client's user has been created",
        clientUserCreateFail: "Client's user hasn't been created",
        clientUserUpdateSuccess: "Client's user has been updated",
        clientUserUpdateFail: "Client's user hasn't been updated",
        clientUserDeleteSuccess: "Client's user has been deleted",
        clientUserDeleteFail: "Client's user hasn't been deleted"
      }
    },
    journals: {
      name: "Journal |||| Journals",
      titles: {
        journal: "Journal",
        newJournal: "New journal",
        selectObjects: "Select objects",
        selectDistricts: "Select districts",
      },
    },
    dashboards: {
      name: "Dashboard |||| Dashboards",
      titles: {
        newDashboard: "New dashboard",
        noMainDashboard: "Main dashboard is not set",
        main: "Dashboard"
      },
    },
    permissions: {
      name: "Permission |||| Permissions",
      titles: {
        newPermission: "New permission"
      }
    },
    roles: {
      name: "Role |||| Roles",
      tabs: {
        role: "Role",
        permissions: "Permissions"
      },
      titles: {
        newRole: "New role"
      }
    },
    users: {
      name: "User |||| Users",
      tabs: {
        user: "User",
        roles: "Roles"
      },
      titles: {
        newUser: "New user"
      }
    },
    "users-logs": {
      name: "Users logs",
      labels: {
        logDetails: "Log details"
      }
    },
    "typical-schemas": {
      name: "Typical schemas",
      newTypicalSchema: "New typical schema"
    }
  },
  app: {
    name: "Proxia Sprut",
    roles: {
      administrator: "Administrator",
      owner: "Owner",
      developer: "Developer",
      manager: "Manager",
      tester: "Tester"
    },
    labels: {
      home: "Home",
      from: "From",
      to: "To",
      start: "Start",
      finish: "Finish",
      address: "Address",
      lastPacketReceivedAt: "Last packet received at",
      aggInterval: "Agg interval",
      myObjects: "My Objects",
      noData: "No data",
      childs: "Nested",
      client: "Client",
      clients: "Clients",
      clientId: "Client's ID",
      clientName: "Client's name",
      comment: 'Comment',
      dashboards: "Dashboards",
      dashboard: "Dashboard",
      dashboardUrlHelperText: "Leave empty to create new grafana dashboard",
      duration: "Duration",
      original: "Original",
      source: "Source",
      output: "Output",
      user: "User",
      users: "Users",
      role: "Role",
      roles: "Roles",
      permission: "Permission",
      permissions: "Permissions",
      rule: "Rule",
      ruleDraft: "Rule (draft)",
      rules: "Rules",
      runMode: "Run mode",
      debug: "Debug",
      ruleAssignment: "Rule assignment",
      addAssignment: "Add assignment",
      ruleAssignments: "Assignments",
      ruleAssignmentsGroups: "Assignments groups",
      object: "Object",
      objects: "My Objects",
      objectType: "Object's type",
      objectTypes: "Object's types",
      openInNewTab: "New window",
      payloadSchema: "Payload schema",
      itemParamsSchema: "Parameters schema",
      formPreview: "Form preview",
      typeSchemaParams: "Type parameters",
      topic: "Topic",
      topicNameSchema: "Topic schema",
      modelSchemaParams: "Device parameters",
      device: "Device",
      devices: "Devices",
      deviceType: "Device type",
      deviceModel: "Device model",
      deviceTypes: "Device types",
      deviceModels: "Device models",
      deviceGroups: "Device groups",
      messagesFreq: "Messages send frequency",
      messagesInterval: "Messages send interval",
      messageSchema: "Message schema",
      messageType: "Message type",
      addMessageType: "Add message type",
      messageTypes: "Message types",
      url: "Url",
      expand: "Expand",
      action: "Action",
      managementOff: "Management Off",
      isActive: "Active",
      isAlertReport: "Alert report",
      isDefault: "Default",
      isPersonal: "Personal",
      isOnline: "Online",
      isVirtual: "Is virtual",
      virtualMapping: "Parameters",
      virtualParam: "Existed parameter",
      type: "Type",
      model: "Model",
      phone: "Phone",
      code: "Code",
      name: "Name",
      description: "Description",
      editable: "Editable",
      enabled: "Enabled",
      login: "Login",
      password: "Password",
      hidden: "Hidden",
      createdAt: "Created at",
      updatedAt: "Updated at",
      direction: "Direction",
      acl: "ACL",
      parent: "Parent",
      parentDevice: "Parent device",
      parentObject: "Parent object",
      childDevice: "Child device",
      childDevices: "Child devices",
      administration: "Administration",
      settings: "Settings",
      language: "Language",
      version: "Version",
      class: "Class",
      dryRun: "Dry run",
      mode: "Mode",
      print: "Print",
      executionType: "Execution type",
      priority: "Priority",
      schedule: "Schedule",
      before: "Before",
      after: "After",
      system: "System",
      manual: "Manual",
      regular: "Regular",
      payload: "Payload",
      message: "Message",
      ip: "IP",
      userAgent: "UserAgent",
      data: "Data",
      response: "Response",
      responseCode: "Response code",
      module: "Module",
      search: "Search",
      tree: "Tree",
      list: "List",
      view: "View",
      children: "Children",
      back: "Back",
      json: "JSON",
      form: "Form",
      selectDevice: "Select device",
      "n/a": "n/a",
      refresh: "Refresh",
      save: "Save",
      saveMessage: "Save message",
      restoreRule: "Restore rule",
      saveRule: "Save rule",
      saveDraft: "Save draft",
      selectDashboard: "Select dashboard",
      run: "Run",
      nothingFound: "Nothing found",
      cloneMessageTypes: "Clone message types",
      vendor: "Vendor",
      vendors: "Vendors",
      report: "Report",
      reports: "Reports",
      templatePermission: "Template permission",
      downloadReport: "Download report",
      reportTemplate: "Report template",
      reportTemplates: "Report templates",
      measureUnit: "Measure unit",
      measureUnits: "Measure units",
      symbol: "Symbol",
      deviceLockedBy: "Packed send",
      itemsSelected: "items selected",
      breadcrumbs: "Breadcrumbs",
      infrastructure: "Infrastructure",
      configurator: "Configurator",
      management: "Management",
      quickActionTitle: "Quick Action Information",
      isQuickAction: "Is Quick Action",
      isNewSetup: "New Setup",
      quickActionName: "Quick Action Name",
      quickActionPayload: "Quick Action Payload",
      quickActionResult: "Command sent to device",
      quickActionBlocked: "Already have a command from the user",
      quickAction: "Quick Action",
      root: "Root",
      all: "All",
      tableView: "Table view",
      typicalSchema: "Typical schema",
      schema: "Schema",
      selectGroups: "Select groups",
      alias: "Alias",
      open: "Open",
      logs: "Logs",
      datetime: "Datetime",
      event: "Event",
      select: "Select",
      loading: "Request processed",
      status: "Status"
    },
    configurator: {
      chooseDevice: "Choose device to preview",
      chooseMeasureUnit: "Choose measure unit",
      rawData: "Device raw data",
      ranges: "Ranges",
      aggInterval: "Aggregation interval",
      aggType: "Aggregation type",
      fromTime: "From time",
      toTime: "To time",
      receivedAt: "Received at",
      parameters: {
        parameter: "Parameter",
        name: "Name",
        description: "Description",
        path: "JSON Path",
        postProcess: "Post process",
        postProcessHelperText: "mathjs expression with variables `value` as current field value and `row['other field']` to access value of fields in the current row"
      },
      range: {
        name: "Name",
        level: "Level",
        conditionJs: "Condition JS"
      },
      aggIntervalChoices: {
        all: "Without aggregation",
        "1d": "Day",
        "1h": "Hour",
        "1m": "Minute",
        "1s": "Second",
        "1M": "Month",
        "1w": "Week",
        "1q": "Quarter",
        "1y": "Year"
      }
    },
    theme: {
      name: "Theme",
      edit: "Edit",
      light: "Light",
      dark: "Dark",
      client: "Client"
    }
  },
  common: {
    done: "Done",
    logout: "Logout"
  },
  errors: {
    some_error: "Some error occurred",
    auth_error_custom: "AUTH_ERROR_CUSTOM got error without status!",
    version_conflict: "Version conflict! Please reload data!",
    missing_credentials: "Missing credentials!",
    incorrect_username: "Incorrect username",
    incorrect_password: "Incorrect password",
    exceeded_login_attempts_limit: "Exceeded login attempts limit",
    unprocessable_entity: "Unprocessable Entity",
    the_user_is_blocked: "The user is blocked",
    the_user_is_inactive: "The user is inactive",
    got_forbidden: "Got forbidden",
    no_token: "No token",
    no_permissions: "No permissions",
    unknown_method: "Unknown method",
    insufficient_rights_: "Insufficient rights!",
    no_assignments_or_wrong_permissions_:
      "No assignments or wrong permissions!",
    no_device_or_wrong_permissions_: "No device or wrong permissions!",
    insufficient_logs_view_: "Insufficient logs view!",
    device_uuid_undefined_: "device uuid undefined!",
    insufficient_stats_view_: "Insufficient stats view!",
    your_session_has_ended__please_reconnect_:
      "Your session has ended, please reconnect.",
    insufficient_rights_for_embedded__objects_vendor_get:
      "Insufficient rights for getting vendor objects",
    rules_code_cannot_be_null: "Rule code can not be empty",
    insufficient_rights__auth_login: 'Insufficient rights: "auth login"',
    device_already_has_packet_to_process:
      "Device already has packet to process",
    device_model_is_inactive: "Device model is inactive",
    device_model_has_active_devices: "Device model has active devices",
    device_has_active_child_devices: "Device has active child devices",
    objects: {
      clientuuid_cannot_be_null: "Сlient cannot be empty",
      objecttypeuuid_cannot_be_null: "Object`s type cannot be empty",
      typeschemaparams_cannot_be_null: "Type parameters cannot be empty"
    },
    objectstypes: {
      type_cannot_be_null: "Type cannot be empty",
      name_cannot_be_null: "Name cannot be empty",
      itemparamsschema_cannot_be_null: "Parameters schema cannot be empty",
      clientuuid_cannot_be_null: "Client cannot be empty"
    },
    rules: {
      code_cannot_be_null: "Code cannot be empty"
    },
    devices: {
      clientuuid_cannot_be_null: "Client cannot be empty",
      devicemodeluuid_cannot_be_null: "Device model cannot be empty",
      modelschemaparams_cannot_be_null: "Model parameters cannot be empty"
    },
    devicesmodels: {
      name_cannot_be_null: "Name cannot be empty",
      itemparamsschema_cannot_be_null: "Parameters schema cannot be empty",
      clientuuid_cannot_be_null: "Client cannot be empty",
      vendoruuid_cannot_be_null: "Vendor cannot be empty"
    },
    devicestypes: {
      name_cannot_be_null: "Name cannot be empty"
    },
    messagestypes: {
      name_cannot_be_null: "Name cannot be empty",
      clientuuid_cannot_be_null: "Client cannot be empty",
      devicemodeluuid_cannot_be_null: "Device model cannot be empty",
      messageschema_cannot_be_null: "Message schema cannot be empty"
    },
    vendors: {
      name_cannot_be_null: "Name cannot be empty"
    }
  },
  "No token": "No token"
};
