import React from "react";
import { AutocompleteInput, Filter, SearchInput } from "react-admin";
import ReferenceInputWithFilter from "../../custom/ReferenceInputWithFilter";
import { filterStyles } from "../../custom/StyledSearchFilter";

const ObjectsListFilter = ({ dataProvider, ...props }) => {
  const classes = filterStyles();

  return (
    <Filter {...props}>
      <SearchInput
        source="name, type_name"
        alwaysOn
        className={classes.input}
      />
      <ReferenceInputWithFilter
        alwaysOn
        label="app.labels.client"
        source="clientUuid"
        reference="clients"
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInputWithFilter>
      <ReferenceInputWithFilter
        alwaysOn
        label="app.labels.objects"
        source="objectTypeUuid"
        reference="objects-types"
        filter={{}}
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInputWithFilter>
    </Filter>
  );
};

export default ObjectsListFilter;
