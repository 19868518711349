import React, { Fragment } from 'react';
import {
  withDataProvider,
  showNotification,
  Loading,
} from 'react-admin';

import { EditorContainer } from '../custom/CodeEditor';
import { UPDATE_REFERENCES } from "../../providers/dataProvider";

class DeviceRawData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      data: undefined
    };
  }

  fetchData() {
    const { dataProvider, deviceUuid, messageTypeUuid } = this.props;
    if (deviceUuid === undefined) {
      return;
    }
    this.setState({ isLoading: true});
    dataProvider(UPDATE_REFERENCES, 'devices', {
      id: deviceUuid,
      target: 'stats',
      data: {
        offset: 1,
        limit: 1,
        messageTypeUuid: messageTypeUuid,
        order:  {
          field: 'receivedAt',
          order: 'asc'
        },
        parameters: [
          {
            name: "rawData",
            path: "payload"
          }
        ],
      }
    }).then(response => {
      this.setState({ data: response.data.length > 0 ? response.data[0].rawData: {}, isLoading: false});
    }).catch(err => {
      showNotification(err.message);
      throw err;
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.deviceUuid !== prevProps.deviceUuid) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
  };

  render() {
    return (
      <Fragment>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <EditorContainer
              label="app.configurator.rawData"
              placeholder={''}
              mode="json"
              readOnly={true}
              height="200px"
              input={{value: this.state.data}}
              meta={{}}
            />
        )}
      </Fragment>
    );
  }
}

export default withDataProvider(DeviceRawData);
