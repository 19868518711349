import React from 'react';
import {
  TextField,
  BooleanField,
} from 'react-admin';

import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";

const RoleList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.roles" {...props} />}
    filters={<StyledSearchFilter source="name,description" />}
    bulkActionButtons={<BulkDeleteAction />}
    {...props}
  >
    <Datagrid>
      <TextField source="id" />
      <BooleanField source="hidden" label="app.labels.hidden" />
      <TextField source="description" label="app.labels.description" />
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default RoleList;
