import React, { useState, Fragment } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useEffectOnce } from "../custom/utils";
import {
	useTranslate,
	GET_MANY_REFERENCE,
	showNotification,
	Loading,
	withDataProvider, usePermissions,
} from "react-admin";
import {
	Switch,
	Paper,
	Toolbar,
	Typography,
	Collapse,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
	FormControlLabel,
	Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import RunActionIcon from '@material-ui/icons/PlayCircleOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { ModalDialog } from '../custom/ModalDialog';
import LoadingShirma from '../custom/LoadingShirma';
import { UPDATE_CUSTOM, UPDATE_REFERENCES } from "../../providers/dataProvider";
import { LOGS_PARAMETERS } from "../custom/utils";
import {SchemaForm} from "../custom/SchemaForm";

const MAX_FETCH_COUNT = 10;

const tableStyles = makeStyles(theme => ({
	tableContainer: {
		overflowX: 'visible'
	},
	cell: {
		padding: '0 6px 0 0',
	},
	nestedCell: {
		padding: '0 6px',
	},
	actionCell: {
		'&:last-child': {
			padding: '0 8px 0 0'
		},
		whiteSpace: 'nowrap'
	},
	loading: {
		width: '40px',
		height: '40px',
		margin: '0',
		display: 'block',
		'& > div': {
			fontSize: '0',
			margin: '20px 0 0 0',
			'& > div': {
				height: '20px !important',
				width: '20px !important'
			}
		}
	},
	shortCell: {
		padding: '0 6px 0 12px',
		width: '60px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		fontSize: '0.8em',
	},
	parentRow: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	nestedRow: {
		'&:nth-child(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	headerCell: {
		padding: '0 6px',
		fontWeight: 'bold'
	},
	actionHeaderCell: {
		width: '160px',
		fontWeight: 'bold',
		'&:last-child': {
			padding: '0 0 0 6px'
		}
	},
	rowInnerCell: {
		'&:last-child': {
			padding: '0'
		},
		'& > .MuiCollapse-container': {
			padding: '6px !important',
			backgroundColor: '#fafafa',
			border: '1px solid #808080',
		},
		'& > .MuiCollapse-container > .MuiCollapse-wrapper': {
			border: '1px solid #808080',
		}
	},
	rowInnerPaper: {
		padding: '0',
		margin: '0',
		width: '100%',
		backgroundColor: 'white',
		boxShadow: 'none',
	},
	link: {
		textDecoration: 'none',
		color: '#3f51b5',
		'&:hover, &:visited, &:active': {
			textDecoration: 'none',
			color: '#3f51b5',
		}
	}
}));

const ControlsRow = (
	{
		row,
		runningActions,  // third level, rule assignment clicked states
		setRunningActions,
		groupStates,  // first level, device groups
		setGroupStates,
		setLoading,
		object,
		dataProvider,
		showNotification
	}) => {
	const classes = tableStyles();
	const translate = useTranslate();
	const { permissions } = usePermissions();

	const [open, setOpen] = useState({});
	const [payloadContent, setPayloadContent] = useState({});
	const [dialogRule, setDialogRule] = useState({});
	const [showRuleDialog, setShowRuleDialog] = useState(false);
	const [groupState, setGroupState] = useState(groupStates[row.uuid]);
	const toggleGroupManagement = (event) => {
		groupStates[row.uuid] = event.target.checked;
		setGroupState(event.target.checked);
		setGroupStates(groupStates);
		// TODO: show UI notification about success update
		dataProvider(UPDATE_CUSTOM, 'devices', {
			uri: `${row.uuid}/management`,
			data: { managementOff: event.target.checked },
		}).then(result => {
			showNotification('resources.objects.notifications.managementToggledSuccess', 'success');
		}).catch(error => showNotification(error.message, 'error'));
	};
	const onRunClick = (rule) => {
		setDialogRule(rule);
		setShowRuleDialog(true);
	};

	const ruleDialogAction = () => {
		setShowRuleDialog(false);
		setLoading(true);
		setRunningActions(prev => ({...prev, ...{[dialogRule.uuid]: true}}));
		let params = {
			uri: `${dialogRule.uuid}/quickaction/run`,
			data: { payload: payloadContent[dialogRule.uuid] ? payloadContent[dialogRule.uuid] : dialogRule.quickActionPayload }
		};

		const reset = () => {
			setRunningActions(prev => ({...prev, ...{[dialogRule.uuid]: false}}));
			setLoading(false);
		};

		dataProvider(UPDATE_CUSTOM, "devices", params)
			.then(response => {
				showNotification('resources.objects.notifications.runRuleSuccess', 'success');
				setLoading(false);
				if (!response) {
					return;
				}
				localStorage.setItem("pollingToken", response.data.token);
				let dataFetched = false;
				let fetchCount = 0;
				const fetchInterval = setInterval(() => {
					if (dataFetched) {
						clearInterval(fetchInterval);
						reset();
						return;
					}
					if (fetchCount > MAX_FETCH_COUNT) {
						clearInterval(fetchInterval);
						reset();
						return;
					}
					fetchCount++;
					fetchData(dialogRule.uuid, response.data.token).then(response => {
						if (response.data.length > 0 && !dataFetched) {
							clearInterval(fetchInterval);
							dataFetched = true;
							reset();
						}
					})
				}, 1000);
			})
			.catch(error => {
				showNotification((error && error.message) || translate("errors.some_error"), 'error')
				reset();
			});
	};

	const defaultFromTime = () => {
		return (new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)).toISOString().slice(0, 16)
	};

	const handlePayloadContent = (formData, record, source) => {
		if (!formData) {
			return;
		}
		const fieldNames = Object.keys(formData.properties);
		const res = {};
		for (let key in formData) {
			if (fieldNames.indexOf(key) > -1) {
				res[key] = formData[key];
			}
		}
		setPayloadContent(prev => ({...prev, ...{[record.uuid]: res || false}}));
	};

	const fetchData = (deviceUuid, token) => dataProvider(UPDATE_REFERENCES, 'devices', {
		id: deviceUuid,
		target: 'stats',
		data: {
			useLogs: true,
			timeParameterPath: 'mqttReceivedAtTimestamp',
			begin: Date.parse(defaultFromTime()),
			end: null,
			offset: 0,
			limit: 3,
			order: {
				field: 'mqttReceivedAt',
				order: 'desc'
			},
			parameters: LOGS_PARAMETERS.map(p => {
				return {
					name: p.name,
					path: p.path
				};
			}),
			token: token
		}
	});

	return (row.childs.length < 1) ? null : (
		<Fragment>
			<TableRow className={classes.parentRow}>
				<TableCell className={classes.shortCell}>
					<IconButton size="small" onClick={() => setOpen({[row.uuid]: !open[row.uuid]})}>
						{open[row.uuid] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</IconButton>
				</TableCell>
				<TableCell align="left" className={classes.cell}>
					{row.name}
				</TableCell>
				<TableCell className={classes.actionCell}>
					<FormControlLabel control={
						<Switch
							checked={groupState}
							onChange={toggleGroupManagement}
							key={`switch-${row.uuid}`}
						/>
					} label={translate("app.labels.managementOff")} labelPlacement="start" />
				</TableCell>
			</TableRow>
			<TableRow key={`collapse-${row.uuid}`}>
				<TableCell colSpan={3} className={classes.rowInnerCell}>
					<Collapse in={open[row.uuid]} timeout="auto" unmountOnExit>
						<Paper className={classes.rowInnerPaper}>
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell className={classes.headerCell}>{translate('app.labels.name')}</TableCell>
										<TableCell className={classes.headerCell}>{translate('app.labels.messageType')}</TableCell>
										<TableCell className={classes.headerMaxCell}>{translate('app.labels.payload')}</TableCell>
										<TableCell className={classes.headerCell}>{translate('app.labels.device')}</TableCell>
										<TableCell className={classes.actionHeaderCell} />
									</TableRow>
								</TableHead>
								<TableBody>
									{row.childs.map(childRow => (
										<TableRow
											key={`${row.uuid}-${childRow.uuid}`}
											className={classes.nestedRow}
										>
											<TableCell className={classes.nestedCell}>
												<Link
													target="_blank"
													className={classes.link}
													to={`/rules-assignments/${childRow.uuid}`}
												>
													<span>{childRow.quickActionName}</span>
												</Link>
											</TableCell>
											<TableCell className={classes.nestedCell}>
												{childRow.messageType ?
													<Link
														target="_blank"
														className={classes.link}
														to={`/messages-types/${childRow.messageTypeUuid}`}
													>
														<span>{childRow.messageType.name}</span>
													</Link>
												: null}
											</TableCell>
											<TableCell className={classes.nestedMaxCell}>
												{childRow.messageType ?
													<SchemaForm
														source={"messageSchema"}
														record={childRow.messageType}
														callback={(formData, record, source) => handlePayloadContent(formData, childRow, source)}
													/> :
													<SchemaForm
														source={"payloadSchema"}
														record={childRow}
														callback={handlePayloadContent}
													/>}
											</TableCell>
											<TableCell className={classes.nestedCell}>
												<Link
													target="_blank"
													className={classes.link}
													to={`/devices/${childRow.deviceUuid}`}
												>
													<span>{childRow.deviceUuid ? childRow.device.name : null}</span>
												</Link>
											</TableCell>
											<TableCell className={classes.actionCell}>
												{runningActions[childRow.uuid] ?
													<Loading loadingPrimary='' loadingSecondary='' className={classes.loading} /> :
													<IconButton
														onClick={event => onRunClick(childRow)}
													>
														<RunActionIcon />
													</IconButton>
												}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Paper>
					</Collapse>
				</TableCell>
			</TableRow>
			<ModalDialog
				title={translate("resources.objects.dialogs.ruleTitle")}
				showDialog={showRuleDialog}
				handleCloseClick={event => setShowRuleDialog(false)}
				handleActionClick={ruleDialogAction}
				actionBtnLabel={translate("ra.action.confirm")}>
				<Grid
					justify="flex-start"
					container={true}
					spacing={2}
				>
					{dialogRule.device ?
						<Grid container item sm={12} lg={12}>
							<Grid item sm={6} lg={6}>
								<strong>{translate("app.labels.device")}</strong>
							</Grid>
							<Grid item sm={6} lg={6}>{ dialogRule.device.name }</Grid>
						</Grid>
						: null}
					{object ?
						<Grid container item sm={12} lg={12}>
							<Grid item sm={6} lg={6}>
								<strong>{translate("app.labels.object")}</strong>
							</Grid>
							<Grid item sm={6} lg={6}>{ object.name }</Grid>
						</Grid>
						: null}
					{dialogRule.quickActionName || dialogRule.rule ?
						<Grid container item sm={12} lg={12}>
							<Grid item sm={6} lg={6}>
								<strong>{translate("app.labels.quickActionName")}</strong>
							</Grid>
							<Grid item sm={6} lg={6}>
								{dialogRule.quickActionName ? dialogRule.quickActionName : dialogRule.rule.description}
							</Grid>
						</Grid>
						: null}
					{ permissions && permissions['devices.rules.run'] &&
					<Grid item sm={12} lg={12}>
						<pre style={{width: '100%', height:'300px'}}>
							<code>{JSON.stringify(
								payloadContent[dialogRule.uuid] ? payloadContent[dialogRule.uuid] : dialogRule.quickActionPayload
								, null, 2)}
							</code>
						</pre>
					</Grid>
					}
				</Grid>
			</ModalDialog>
		</Fragment>
	)
}

const EnhancedControlsRow = connect(null, { showNotification })(ControlsRow)

const ObjectControlsList = ({dataProvider, record, ...props}) => {
	const classes = tableStyles();
	const translate = useTranslate();
	const [data, setData] = useState([]);
	const [groupStates, setGroupStates] = useState({});
	const [runningActions, setRunningActions] = useState({});
	const [isLoading, setLoading] = useState(false);

  useEffectOnce(() => {
		if (data.length >= 1) {
			return;
		}
		dataProvider(GET_MANY_REFERENCE, 'objects', {
			id: record.uuid,
			target: 'assignments',
			pagination: {
				page: 1,
				perPage: 1,
			},
			sort: {
				field: 'createdAt',
				order: 'DESC',
			},
		}).then(response => {
			const initialStates = {}; // first level toggler states
			const initialRuleStates = {}; // third level button states
			response.data.forEach((item) => {
				initialStates[item.uuid] = item.managementOff;
				item.childs.forEach((rule) => {
					initialRuleStates[rule.uuid] = false;
				})
			});
			setGroupStates(initialStates);
			setRunningActions(initialRuleStates);
			setData(response.data.length > 0 ? response.data : []);
		}).catch(err => {
			showNotification(err.message);
			throw err;
		});
	});

	return (
		<Paper>
			<Toolbar>
				<Typography variant="subtitle1" component="div">
					{translate("app.labels.deviceGroups")}
				</Typography>
			</Toolbar>
			{isLoading ? <LoadingShirma /> : null}
			<TableContainer className={classes.tableContainer}>
				<Table size="small" className={classes.table}>
					<TableBody>
						{data.map((row, i) => (
							<EnhancedControlsRow
								key={`controlsrow-${i}`}
								row={row}
								object={record}
								setLoading={setLoading}
								groupStates={groupStates}
								setGroupStates={setGroupStates}
								runningActions={runningActions}
								setRunningActions={setRunningActions}
								dataProvider={dataProvider}
							/>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Paper>
	)
}

export default withDataProvider(ObjectControlsList);
