import React from 'react';
import {
  Button
} from 'react-admin';
import { makeStyles } from "@material-ui/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";
import IconRun from "@material-ui/icons/PlayCircleOutline";

const useStyles = makeStyles({
  toolbar: {
    marginTop: "30px",
    background: "#f5f5f5"
  }
});

export const ModalDialog = props => {
  const classes = useStyles();
  const { title, showDialog, handleCloseClick, handleActionClick, actionBtnLabel } = props;

  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label={title}
    >
      { title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{props.children}</DialogContent>
      <DialogActions className={classes.toolbar}>
        <Button label="ra.action.cancel" onClick={handleCloseClick}>
          <IconCancel />
        </Button>
        <Button label={actionBtnLabel} onClick={handleActionClick}>
          <IconRun />
        </Button>
      </DialogActions>
    </Dialog>
  )
}