import React from 'react';
import {
  Filter,
  ReferenceInput, SearchInput,
  SelectInput
} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  filterRoot: {
    display: 'flex'
  },
}));

export const TreeFilter = ({setFilters, defaultValues, ...props}) => {
  const classes = useStyles();
  return (
    <div className={classes.filterRoot}>
      <Filter setFilters={setFilters} initialValues={{"name": defaultValues['searchName']}} {...props}>
        <SearchInput resettable source="name" reference="devices" alwaysOn allowEmpty />
      </Filter>
      <Filter setFilters={setFilters} {...props}>
        <ReferenceInput
          label="app.labels.clients"
          reference="clients"
          source="clientUuid"
          sort={{ field: "createdAt", order: "DESC" }}
          alwaysOn
          allowEmpty
        >
          <SelectInput defaultValue={defaultValues['clientUuid']} optionText="name" />
        </ReferenceInput>
      </Filter>
      <Filter setFilters={setFilters} {...props}>
        <ReferenceInput
          label="app.labels.deviceType"
          source="deviceTypeUuid"
          reference="devices-types"
          sort={{ field: "createdAt", order: "DESC" }}
          alwaysOn
          allowEmpty
        >
          <SelectInput defaultValue={defaultValues['deviceTypeUuid']} optionText="name" />
        </ReferenceInput>
      </Filter>
    </div>
  );
}
