import React, { useEffect, useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import { connect } from 'react-redux';
import { calculateBegin, getMonthString, useEffectOnce } from '../custom/utils';
import Icon from '@material-ui/core/Icon';


import {
  EditButton,
  withDataProvider,
  useTranslate,
  Loading,
  Datagrid as RADatagrid
} from 'react-admin';

export const DEFAULT_POLL_TIMEOUT = 10000;

const styles = theme => ({
  deviceCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    borderLeft: '5px solid rgb(76, 175, 80)',
    paddingTop: '16px',
    boxSizing: 'border-box'
  },
  header: {
    display: 'flex',
    height: '150px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingBottom: '8px'
  },
  leftHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  icon: {
    display: 'flex',
    width: '96px',
    height: '96px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: '8px'
  },
  statsPrimary: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingBottom: '8px'
  },
  lastSeen: {
    position: 'absolute',
    right: '16px',
    top: '16px',
    textAlign: 'center'
  },
  lastSeenTitle: {
    fontSize: '12px',
    color: 'gray',
    whiteSpace: 'nowrap',
    marginTop: '4px'
  },
  lastSeenDate: {
    fontSize: '14px',
    whiteSpace: 'nowrap'
  },
  title: {
    fontSize: '16px'
  },
  properties: {
    fontSize: '14px',
    color: 'gray',
    marginTop: '4px'
  },
  border: {
    width: '100%',
    height: '2px',
    borderTop: '1px solid gray',
  },
  bottom: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    padding: '8px',
    height: '50px'
  },
  show: {
    position: 'absolute',
    right: 0,
    bottom: '8px',
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#ffffff'
  },
  statistics: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  statBox: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '20px',
    textAlign: 'center'
  },
  statName: {
    fontSize: '12px',
    color: 'gray'
  },
  statNumber: {
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 'bold',
    marginTop: '2px'
  },
  statNumberBigger: {
    fontWeight: 'bold',
    fontSize: '16px'
  },
  moreIconBtn: {
    color: 'black',
    '& svg': {
      fontSize: '36px'
    }
  },
  moreIcon: {
    color: 'black',
    marginRight: '15px',
    '& svg': {
      fontSize: '36px'
    }
  },
  arrowDown: {
    fontSize: '20px',
    color: 'green',
    verticalAlign: 'top',
    fontWeight: '600'
  },
  arrowUp: {
    fontSize: '20px',
    color: 'red',
    verticalAlign: 'top',
    fontWeight: '600'
  }
});

export function reorganizeResult(arr) {

  let newData = {};

  if (arr.length) {
    arr.forEach(data => {

      if (data.data.length && data.meta.deviceUuid) {

        for (let key in data.data[0]) {
          if (!newData[data.meta.deviceUuid]) {
            newData[data.meta.deviceUuid] = {};
          }

          if ( key === 'aggKey' ) {

            let receivedAt = new Date(Date.parse(data.data[0][key]));
            newData[data.meta.deviceUuid][key] = receivedAt.getDate() + ' ' + getMonthString(receivedAt.getMonth()) + ' ' + receivedAt.getFullYear();

          } else if ( typeof data.data[0][key] === 'object') {

            newData[data.meta.deviceUuid][key] = (<span><span style={{ color: 'red' }}><PriorityHigh /></span><span style={{ fontSize: '14px' }}>{JSON.stringify(data.data[0][key])}</span></span>);

          } else {
            newData[data.meta.deviceUuid][key] = String(data.data[0][key]);
          }
        }
      } else {
        newData[data.meta.deviceUuid] = {};
      }
    });
  }

  return newData;
}

export function updateStatsParams(record, recordsParams, defaultData, demostats, updatedParams) {
  let statsParams = { icon: 'star', primary: [], secondary: [] };

  if (demostats && (demostats.icon.length || demostats.primary.length || demostats.secondary.length)) {
    statsParams = demostats;

    if (!statsParams.icon) {
      statsParams.icon = defaultData.icon;
    }

  } else if (updatedParams) {
    statsParams = updatedParams;
  } else if (record && record.model) {

    if (recordsParams && recordsParams[record.id] && recordsParams[record.id].primary) {
      statsParams.primary = recordsParams[record.id].primary;

    } else if (
      record.model.configurator &&
      record.model.configurator.primary &&
      record.model.configurator.primary.length) {
      statsParams.primary = record.model.configurator.primary;
    }

    if (recordsParams && recordsParams[record.id] && recordsParams[record.id].secondary) {
      statsParams.secondary = recordsParams[record.id].secondary;

    } else if (
      record.model.configurator &&
      record.model.configurator.secondary &&
      record.model.configurator.secondary.length) {
      statsParams.secondary = record.model.configurator.secondary;
    }

    if (recordsParams && recordsParams[record.id] && recordsParams[record.id].lastSeen) {
      statsParams.lastSeen = new Date(Date.parse(recordsParams[record.id].lastSeen));
    }

    if (statsParams.primary.length > 2) {
      statsParams.primary.sort((a, b) => a.order - b.order);
    }

    if (statsParams.secondary.length > 2) {
      statsParams.secondary.sort((a, b) => a.order - b.order);
    }

    if (record.model.configurator && record.model.configurator.icon) {
      statsParams.icon = record.model.configurator.icon;
    }
  }
  return statsParams;
}

export const DeviceCardStyle = withStyles(styles)(({ classes, record, resource, demostats, updatedParams, recordsParams, ...props }) => {

  const translate = useTranslate();
  const defaultData = {
    title: 'Название',
    address: '-',
    titleDevice: 'Название устройства',
    lastSeen: translate('resources.devices.plashka.never'),
    icon: 'star'
  };

  const statsParams = updateStatsParams(record, recordsParams, defaultData, demostats, updatedParams);

  return (
    <Card className={classes.deviceCard}>
      <div className={classes.header}>
        <div className={classes.leftHeader}>
          <div className={classes.icon}>
            <Icon>{statsParams.icon}</Icon>
          </div>
          <div className={classes.main}>
            <div>
              <div className={classes.title}>{ record && record.name ? record.name : defaultData.title }</div>
              <div className={classes.properties}>
                { (record && record.modelSchemaParams && record.modelSchemaParams.location && record.modelSchemaParams.location.address) || defaultData.address }
              </div>
              <div className={classes.properties}>
                { (record && record.model && record.model.name) || defaultData.titleDevice }
              </div>
            </div>
            <div className={classes.statsPrimary}>
              {statsParams.primary && statsParams.primary.map((param) => {
                return (<div key={param.path} className={classes.statBox}>
                  <span className={classes.statName}>{param.name}</span>
                  <span className={classes.statNumberBigger}>{param.value ? param.value : '-'}</span>
                  <span className={classes.statName}>{param.description}</span>
                </div>);
              })}
            </div>
          </div>
        </div>
        <div className={classes.lastSeen}>
          {
            statsParams.lastSeen ?
            <div className={classes.lastSeenDate} title={ statsParams.lastSeen.toLocaleString()} >{ statsParams.lastSeen.toLocaleDateString() }</div>:
            <div className={classes.lastSeenDate}>{ defaultData.lastSeen }</div>
          }
          <div className={classes.lastSeenTitle}>{translate('resources.devices.plashka.lastSeen')}</div>
        </div>
      </div>
      <div className={classes.border} />
      <div className={classes.bottom}>
        <div className={classes.statistics}>
          {statsParams.secondary && statsParams.secondary.map((param) => {
            return (<div key={param.path} className={classes.statBox}>
              <span className={classes.statName}>{param.name}</span>
              <span className={classes.statNumber}>{param.value ? param.value : '-'}</span>
              <span className={classes.statName}>{param.description}</span>
            </div>);
          })}
        </div>
        <div className={classes.show}>
          {
            demostats
              ? <div className={classes.moreIcon}><MoreHoriz /></div>
              : <EditButton
                className={classes.moreIconBtn}
                icon={<MoreHoriz />}
                label={""}
                basePath={"/devices"}
                record={record}
              />
          }
        </div>
      </div>
    </Card>
  )
});

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}


export function fetchDevicesParamsQuery(dataProvider, ids, newRecords) {

  let queries = [];
  ids.forEach(deviceId => {

    let parameters = [];

    if (newRecords[deviceId] && newRecords[deviceId].model) {

      if (newRecords[deviceId].model.configurator.primary) {
        newRecords[deviceId].model.configurator.primary.forEach(param => {
          parameters.push(param);
        });
      }
      if (newRecords[deviceId].model.configurator.secondary) {
        newRecords[deviceId].model.configurator.secondary.forEach(param => {
          parameters.push(param);
        });
      }
    }

    if (parameters.length) {
      parameters.forEach(param => {

        let aggInterval = param.aggInterval || "1d";

        queries.push({
          "aggInterval": aggInterval,
          "begin": calculateBegin(aggInterval),
          "deviceUuid": deviceId,
          "messageTypeUuid": param.mtUuid,
          "limit": 1,
          "parameters": [{
            name: param.name,
            path: param.path,
            aggType: param.aggType
          }]
        });
      });
    } else {
      // build query to get general info about device
      queries.push({
          deviceUuid: deviceId,
      });
    }
  });

  if (queries.length === 0) {
    return Promise.resolve({});
  }

  return dataProvider('UPDATE_NO_ID', 'devices-stats', {
    data: queries
  }).then(result => {

    let recordsParams = [];
    const {general, stats} = result;

    // populate last seen
    if (general && general.lastSeen) {
      for (let [deviceId, lastSeen] of Object.entries(general.lastSeen)) {
        recordsParams[deviceId] = {
          primary: [],
          secondary: [],
          lastSeen,
        };
      }
    }

    if (stats.length) {

      let reorganizedResult = reorganizeResult(stats);

      for (let deviceId in reorganizedResult) {

        if (newRecords[deviceId] && newRecords[deviceId].model) {
          if (newRecords[deviceId].model && newRecords[deviceId].model.configurator.primary && newRecords[deviceId].model.configurator.primary.length) {
            newRecords[deviceId].model.configurator.primary.forEach(primaryParam => {
              if (reorganizedResult[deviceId][primaryParam.name]) {
                primaryParam.value = reorganizedResult[deviceId][primaryParam.name];
              }
              recordsParams[deviceId].primary.push(primaryParam);
            });
          }

          if (newRecords[deviceId].model && newRecords[deviceId].model.configurator.secondary && newRecords[deviceId].model.configurator.secondary.length) {
            newRecords[deviceId].model.configurator.secondary.forEach(secondaryParam => {
              if (reorganizedResult[deviceId][secondaryParam.name]) {
                secondaryParam.value = reorganizedResult[deviceId][secondaryParam.name];
              }
              recordsParams[deviceId].secondary.push(secondaryParam);
            });
          }
        }
      }
    }
    return recordsParams;
  });
};



const CardDatagridTemplate = ({ dataProvider, recordsParams, setRecordsParams, isLoading, ...props }) => {

  const [paramsFetched, setParamsFetched] = useState(false);

  const fetchDevicesParams = () => {
    setParamsFetched(false);
    fetchDevicesParamsQuery(dataProvider, props.ids, props.data).then(recordsParams => {
      setRecordsParams(recordsParams);
      setParamsFetched(true);
    }).catch(err => {
      console.error(err);
    });
  };

  useEffectOnce(() => {
    fetchDevicesParams();
  });

  useInterval(() => {
    fetchDevicesParams();
  }, DEFAULT_POLL_TIMEOUT);


  return (
    <div>
      {
        isLoading === true && !paramsFetched
          ? <Loading />
          : <RADatagrid recordsParams={recordsParams} {...props}>
            <DeviceCardStyle recordsParams={recordsParams} {...props} />
          </RADatagrid>
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.admin.loading > 0
  }
}

export default withDataProvider(connect(
    mapStateToProps,
    {}
  )(CardDatagridTemplate));
