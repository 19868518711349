import React from "react";
import {
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";

import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";

export const BackToPreviousNode = props => {
  const {
    record,
    callback,
    record: { resource }
  } = props;

  const handleClick = () => {
    callback(resource, record.parentUuid);
  }

  return Array.isArray(record) ? null : (
    <ListItem
      key={'back-button'}
      button
      onClick={handleClick}
    >
      <ListItemIcon>
        <ArrowBackIcon />
      </ListItemIcon>
      <ListItemText primary=".." />
    </ListItem>
  );
}
