import React, {Fragment, useState} from "react";
import {
  TextInput,
  SelectInput,
  NumberInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer, ArrayInput, SimpleFormIterator, useTranslate, SimpleForm,
} from 'react-admin';

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useSelector } from 'react-redux';

import CustomTitle from '../custom/CustomTitle'; // T5275
import { EditorWrapper } from "../custom/JSONEditor";
import { CreateWithBreadcrumbs } from "../custom/CustomBreadcrumbs";
import SanitizedGrid from "../custom/SanitizedGrid";
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { SchemaForm } from "../custom/SchemaForm";

const useStyles = makeStyles({
  disabledSchema: {
    width: '100%',
    height: '300px'
  },
  root: {
    width: "100%"
  }
});

const generateOptionText = choice => {
  if (!choice) {
    return '';
  }
  if (choice.vendor && choice.vendor.name) {
    return `${choice.vendor.name} - ${choice.name}`;
  } else {
    return `${choice.name}`;
  }
};

const RuleCreate = props => {
  const classes = useStyles();
  const jsonFormRef = React.createRef();
  const translate = useTranslate();
  const [messageSchema, setMessageSchema] = useState(null);

  const messageTypesState = useSelector(reduxState => {
    if (reduxState.admin.resources['messages-types']) {
      return reduxState.admin.resources['messages-types'].data;
    }
    return {};
  });

  const handleMessageTypeChange = (uuid) => {
    if (!uuid || !messageTypesState[uuid]) {
      if (messageSchema) {
        setMessageSchema(null);
      }
      return;
    }
    setMessageSchema(messageTypesState[uuid].messageSchema);
  };
  return (
    <CreateWithBreadcrumbs
      title={
        <CustomTitle
          titleKey="resources.rules-assignments.titles.newRuleAssignment"
          {...props}
        />
      }
      {...props}
    >
      <SimpleForm>
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <Grid item lg={6} md={12}>
            <FormDataConsumer>
              {({formData}) => {
                if (messageSchema) {
                  formData.messageSchema = messageSchema;
                }
                return (
                  <Fragment>
                    <BooleanInput
                      source="isActive"
                      label="app.labels.isActive"
                    />
                    <BooleanInput source="dryRun" label="app.labels.dryRun" />
                    <TextInput source="uuid" fullWidth />
                    <ReferenceInputWithFilter
                      label="app.labels.rule"
                      source="ruleUuid"
                      filter={{}}
                      reference="rules"
                    >
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                    <SelectInput
                      label="app.labels.mode"
                      source="mode"
                      choices={[
                        { id: "schedule", name: "app.labels.schedule" },
                        { id: "manual", name: "app.labels.manual" },
                        { id: "regular", name: "app.labels.regular" }
                      ]}
                      fullWidth
                    />
                    {formData.mode === "schedule" && (
                      <ArrayInput source="schedule" label="app.labels.schedule">
                        <SimpleFormIterator>
                          <TextInput source="name" label="app.labels.name" fullWidth/>
                          <TextInput source="schedule" label="app.labels.schedule" options={{ placeholder: "*/30 * * * * *" }} fullWidth />
                        </SimpleFormIterator>
                      </ArrayInput>
                    )}
                    <SelectInput
                      label="app.labels.class"
                      source="class"
                      choices={[
                        { id: "system", name: "app.labels.system" },
                        { id: "deviceModel", name: "app.labels.deviceModel" },
                        { id: "device", name: "app.labels.device" }
                      ]}
                      fullWidth
                    />
                    {(formData.class === "system" ||
                      formData.class === "deviceModel") && (
                        <SelectInput
                          label="app.labels.executionType"
                          source="executionType"
                          choices={[
                            { id: "before", name: "app.labels.before" },
                            { id: "after", name: "app.labels.after" }
                          ]}
                          fullWidth
                        />
                      )}
                    <NumberInput
                      source="priority"
                      label="app.labels.priority"
                      options={{ placeholder: "1" }}
                      fullWidth
                    />
                    <ReferenceInputWithFilter
                      label="app.labels.deviceModel"
                      source="deviceModelUuid"
                      reference="devices-models"
                      filter={{ isActive: true }}
                    >
                      <AutocompleteInput
                        optionText={generateOptionText}
                        optionValue="uuid"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                    <ReferenceInputWithFilter
                      label="app.labels.device"
                      source="deviceUuid"
                      reference="devices"
                    >
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                    {formData.class === "device" && ['manual', 'schedule'].indexOf(formData.mode) >= 0 && (
                      <Fragment>
                        <TextInput
                          source="quickActionName"
                          label="app.labels.quickActionName"
                          fullWidth
                        />
                        <EditorWrapper
                          schemaName="quickActionPayload"
                          height="300px"
                          record={formData}
                          defaultValue={{}}
                        />
                      </Fragment>
                    )}
                    {formData.class === "device" || (formData.class === "deviceModel" && formData.deviceModelUuid) ?
                      <Fragment>
                        <ReferenceInputWithFilter
                          label="resources.devices.titles.chooseMessageType"
                          allowEmpty={true}
                          resource="messages-types"
                          reference="messages-types"
                          source="messageTypeUuid"
                          onChange={handleMessageTypeChange}
                          filter={formData.class === "device" ? {
                            direction: {$eq: 'out'}
                          } : {
                            deviceModelUuid: formData.deviceModelUuid,
                            direction: {$eq: 'out'}
                          }}
                        >
                          <AutocompleteInput
                            optionText="name"
                            options={{
                              fullWidth: true
                            }}
                          />
                        </ReferenceInputWithFilter>
                        {messageSchema ?
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                              <span>{translate('app.labels.payload')}:</span><br/>
                              <textarea
                                className={classes.disabledSchema}
                                disabled
                                value={JSON.stringify(messageSchema, null, 2)}
                              />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                              <SchemaForm
                                source="messageSchema"
                                record={formData}
                                ignoreState={true}
                                formRef={jsonFormRef}
                              />
                            </Grid>
                          </Grid> : null
                        }
                      </Fragment>
                      : null
                    }
                    {(formData.class === "system" || !formData.messageTypeUuid) ?
                      <EditorWrapper
                        compactView={true}
                        schemaName="payloadSchema"
                        record={formData}
                        height="300px"
                        defaultValue={{}}
                      /> : null
                    }
                  </Fragment>
                );
              }}
            </FormDataConsumer>
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  );
}

export default RuleCreate;
