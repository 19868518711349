import React from "react";
import {
  TextInput
} from 'react-admin';
import { makeStyles } from "@material-ui/styles";
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import SchemaEditForm from "../custom/SchemaForm"; // T4999
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722


const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const placeholderItemParamsSchema = {
  '$id': 'https://example.com/schemas/v01/example.json',
  'type': 'object',
  'title': 'Title',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  'required': [],
  'properties': {},
  'description': 'description'
};

const TypicalSchemaEdit = props => {
  const classes = useStyles();

  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.schema" {...props} />}
      {...props}
    >
      <SchemaEditForm
        schemaName="schema"
        flattenSchemaName="flattenSchema"
        schemaLabel="app.labels.schema"
        ignoreState={true}
        schemaPlaceholder={placeholderItemParamsSchema}
      >
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            <TextInput fullWidth disabled source="alias" label="app.labels.alias" />
          </SanitizedGrid>
        </SanitizedGrid>
      </SchemaEditForm>
    </EditWithBreadcrumbs>
  );
};

export default TypicalSchemaEdit;
