import React, { useState, useEffect } from 'react';
import { Labeled } from 'react-admin';

import { JsonForm } from '../custom/JsonForm';
import proxiaSprutUtils from 'proxia-sprut-utils';

const ParamsForm = props => {
  const {
    label,
    record,
    source,
    type,
    callback,
  } = props;

  const handleChange = ({ formData }) => {
    if (typeof callback === 'function') {
      callback(formData, record, source);
    }
  };
  let schema = {};
  if (record) {
    if (type === "messageSchema" && record.messageType) {
      schema = record.messageType[type];
    } else if (record[type] && record[type].itemParamsSchema) {
      schema = record[type].itemParamsSchema;
    } else {
      schema = record[source];
    }
  }

  const [flattenSchema, setFlattenSchema] = useState({});
  useEffect(() => {
    proxiaSprutUtils.flattenJSON(schema).then(result => {
      console.log("valid schema result", result)
      setFlattenSchema(result.schema);
    });
  }, [schema]);

  return label ?
    <Labeled label={label} fullWidth>
      <JsonForm
        source={source}
        schema={flattenSchema}
        onChange={handleChange}
        record={record}
        {...props}
      />
    </Labeled> :
    <JsonForm
      source={source}
      schema={flattenSchema}
      onChange={handleChange}
      record={record}
      {...props}
    />;
};

export default ParamsForm;
