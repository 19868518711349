import ReportIcon from '@material-ui/icons/Assignment';
import ReportBaseIcon from '@material-ui/icons/Print';
import ReportEdit from './ReportEdit';
import ReportList from './ReportList';
import ReportCreate from './ReportCreate';

const resources = {
	list: ReportList,
	edit: ReportEdit,
	create: ReportCreate,
	icon: ReportIcon,
	baseIcon: ReportBaseIcon
};

export default resources;

