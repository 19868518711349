import React from 'react';
import {
  TextInput,
  BooleanInput,
  DateField,
  SimpleForm,
} from 'react-admin';

import { PhoneInput } from '../custom/utils';
import CustomTitle from '../custom/CustomTitle'; // T5275
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/styles";

import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

export const GridWithRecord = props => {
  const classes = useStyles();
  return (
    <Grid
      justify="flex-start"
      container={true}
      spacing={2}
      className={classes.root}
    >
      <Grid item lg={6} md={12}>
        <BooleanInput source="enabled" label="app.labels.enabled" />
        <DateField source="createdAt" label="app.labels.createdAt" />
        <DateField source="updatedAt" label="app.labels.updatedAt" />
        <TextInput fullWidth source="login" label="app.labels.login" autoComplete="no" />
        <TextInput
          fullWidth
          autoComplete="new-password"
          source="password"
          type="password"
          label="app.labels.password"
        />
        <PhoneInput fullWidth record={props.record} source="phone" type="tel" label="app.labels.phone" />
      </Grid>
    </Grid>
  );
};

const UserCreate = props => (
  <CreateWithBreadcrumbs
    title={<CustomTitle titleKey="resources.users.titles.newUser" {...props} />}
    {...props}
  >
    <SimpleForm
      record={props.record}
      resource={props.resource}
      submitOnEnter={false}
    >
      <GridWithRecord />
    </SimpleForm>
  </CreateWithBreadcrumbs>
);

export default UserCreate;
