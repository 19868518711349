import React from 'react';
import {
  TextField,
  ReferenceField,
  Datagrid,
  DateField
} from 'react-admin';

import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import DownloadButton from "../custom/DownloadButton"; // T6206
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";

const ReportList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.reports" {...props} />}
    bulkActionButtons={<BulkDeleteAction />}
    filters={<StyledSearchFilter />}
    filter={{
      embedded: {}
    }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="uuid" />
      <ReferenceField
        label="app.labels.reportTemplate"
        source="templateUuid"
        reference="report-templates"
      >
        <TextField source="name" />
      </ReferenceField>
      <DownloadButton source="reportFile" record={props.record} label="resources.reports.titles.downloadReport" />
      <ReferenceField
        label="app.labels.user"
        source="authorId"
        reference="users"
      >
        <TextField source="login" />
      </ReferenceField>
      <DateField source="createdAt" label="app.labels.createdAt" />
      <TextField source="status" label="resources.reports.titles.status" />
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default ReportList;
