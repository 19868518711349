import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useTranslate, useLogin, useNotify, useUpdateLoading, TextInput } from 'react-admin';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = () =>
  createStyles({
    inputs: {
      padding: '0 1em',
      display: 'flex',
      flexFlow: 'column'
    },
    button: {
      marginTop: '1em'
    }
  });

const LoginForm = props => {
  const translate = useTranslate();
  let [username, setUsername] = useState('');
  let [password, setPassword] = useState('');
  const doLogin = useLogin();
  const notify = useNotify();
  const { stopLoading } = useUpdateLoading();

  const handleSubmit = args => {
    const { username, password } = args;
    return doLogin({ username, password })
      .catch(error => notify(error.message));
  }
  const { classes, isLoading, notifications } = props;

  const checkErrorStatus = (notif) => {
    return notif.message === "ra.notification.logged_out";
  }

  if (notifications.length && isLoading) {
    if (notifications.some(checkErrorStatus)) {
      stopLoading();
    }
  }

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div className={classes.inputs}>
            <TextInput
              autoFocus
              id="username"
              name="username"
              onChange={e => {
                username = e.target.value;
                setUsername(username);
              }}
              label={translate('ra.auth.username')}
              disabled={isLoading}
              autoComplete="username"
            />
            <TextInput
              id="password"
              name="password"
              onChange={e => {
                password = e.target.value;
                setPassword(password);
              }}
              label={translate('ra.auth.password')}
              type="password"
              disabled={isLoading}
              autoComplete="current-password"
            />
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={isLoading}
              fullWidth={true}
              className={classes.button}
            >
              {isLoading && (
                <CircularProgress
                  className={classes.icon}
                  size={18}
                  thickness={2}
                />
              )}
              {translate('ra.auth.sign_in')}
            </Button>
          </div>
        </form>
      )}
    ></Form>
  );
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
  notifications: state.admin.notifications
});

const EnhancedLoginForm = connect(mapStateToProps)(withStyles(styles)(LoginForm))

EnhancedLoginForm.propTypes = {
  redirectTo: PropTypes.string
};

export default EnhancedLoginForm;
