import React from 'react';
import {
  TextInput,
  BooleanInput,
  DateField,
  FormTab,
  TabbedForm,
} from 'react-admin';

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import UserRoles from './UserRoles';
import UserEditToolbar from './UserEditToolbar';
import { PhoneInput } from '../custom/utils';
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

export const GridWithRecord = props => {
  const classes = useStyles();
  return (
    <Grid
      justify="flex-start"
      container={true}
      spacing={2}
      className={classes.root}
    >
      <Grid item lg={6} md={12}>
        <TextInput fullWidth disabled source="id"/>
        <BooleanInput source="enabled" label="app.labels.enabled"/>
        <DateField source="createdAt" label="app.labels.createdAt"/>
        <DateField source="updatedAt" label="app.labels.updatedAt"/>
        <TextInput fullWidth source="login" label="app.labels.login" autoComplete="no"/>
        <TextInput
          fullWidth
          autoComplete="new-password"
          source="password"
          type="password"
          label="app.labels.password"
        />
        <PhoneInput record={props.record} fullWidth source="phone" type="tel" label="app.labels.phone" />
      </Grid>
    </Grid>
  );
};

const UserEdit = props => (
  <EditWithBreadcrumbs
    title={<CustomEditTitle titleKey="app.labels.user" {...props} />}
    {...props}
  >
    <TabbedForm
      toolbar={<UserEditToolbar />}
      record={props.record}
      resource={props.resource}
      submitOnEnter={false}
    >
      <FormTab label="resources.users.tabs.user">
        <GridWithRecord />
      </FormTab>
      <FormTab label="resources.users.tabs.roles" path="roles">
        <UserRoles source="roles" />
      </FormTab>
    </TabbedForm>
  </EditWithBreadcrumbs>
);

export default UserEdit;
