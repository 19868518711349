import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Datagrid, ReferenceField, TextField } from "react-admin";
import UuidNameTextField from "../../custom/UuidNameTextField"; // T5113
import {
  ShowChildrenButton,
  ShowDevicesButton,
} from "../helpers/ObjectsTableButtons";

const useStyles = makeStyles({
  uuidField: {
    minWidth: "250px",
    display: "inline-block",
  },
});

const ObjectsListTable = ({ setViewType, viewType, ...props }) => {
  console.log(props);
  const classes = useStyles();
  return (
    <Datagrid {...props}>
      <UuidNameTextField
        source="uuid"
        label="app.labels.name"
        className={classes.uuidField}
      />
      <ReferenceField
        label="app.labels.client"
        source="clientUuid"
        reference="clients"
        basePath={"/clients"}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="app.labels.objectType"
        source="objectTypeUuid"
        reference="objects-types"
      >
        <TextField source="name" />
      </ReferenceField>
      <ShowChildrenButton />
      <ShowDevicesButton />
    </Datagrid>
  );
};

export default ObjectsListTable;
