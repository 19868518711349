import React from 'react';
import {MenuItemLink as RAMenuItemLink, useLogout, useTranslate} from 'react-admin';
import ExitIcon from '@material-ui/icons/PowerSettingsNew';
import {withStyles} from "@material-ui/core/styles";

const styles = theme => {
  const color = (theme.palette.type === "light") ? theme.palette.secondary.main : theme.palette.text.secondary;
  return {
    root: {
      color: color
    },
    icon: {
      color: color
    }
  }
};

const MenuItemLink = withStyles(styles)(RAMenuItemLink);

export const LogoutButton = () => {
  const doLogout = useLogout();
  const translate = useTranslate();
  const handleClick = () => doLogout();

  return (
    <MenuItemLink
      to="/login"
      onClick={handleClick}
      primaryText={translate('common.logout')}
      leftIcon={<ExitIcon />}
    />
  );
};
