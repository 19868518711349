import {Button} from "react-admin";
import {CloudDownload} from "@material-ui/icons";
import React from "react";

const DownloadButton = ({ source, record = {} }) => {
	if (!record[source] || !record[source].title || !record[source].src) {
    return <React.Fragment />;
	}
	const openUrl = () => {
		const extension = record[source].title.split('.').pop();
		let mimeType;
		if (extension === 'odt') {
			mimeType = "application/vnd.oasis.opendocument.text";
		} else if (extension === 'docx') {
			mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  	} else if (extension === 'xlsx') {
			mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
		} else if (extension === 'zip') {
			mimeType = "application/zip"
		}
		const link = document.createElement('a');
		link.target = '_blank';
		link.download = record[source].title;
		link.href = `data:${mimeType};base64,${Buffer.from(record[source].src).toString('base64')}`;
		link.click();
		return false;
	};
	return (
		<Button
			color="primary"
			onClick={openUrl}
			label={record[source].title}
			startIcon={<CloudDownload/>}>
		</Button>
	);
};

export default DownloadButton
