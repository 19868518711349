import { resolveBrowserLocale } from 'react-admin';
import { mergeTranslations } from 'react-admin';
import localemap from './localemap';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { getSettings } from '../components/custom/utils';

const settings = getSettings();
const currentLocaleSaved =
  settings instanceof Object && settings.locale
    ? settings.locale
    : resolveBrowserLocale();
let userCurrentLocale = currentLocaleSaved;
if (currentLocaleSaved.length === 2) {
  userCurrentLocale = localemap.localemap[currentLocaleSaved];
}

const addons = {
  rus: [],
  eng: []
};

const interfaceTranslations = {};
const addonsTranslations = {};
const domainTranslations = {};
const messages = {};

Object.values(localemap.localemap).map(currentLocale => {
  const lang = localemap.languages[currentLocale];

  interfaceTranslations[currentLocale] = require(`../../node_modules/ra-language-${lang}`);
  addonsTranslations[currentLocale] = addons[currentLocale].reduce(
    (prev, curr) => {
      prev = { ...curr };
      return prev;
    },
    {}
  );
  domainTranslations[currentLocale] = require('./' + currentLocale);

  messages[currentLocale] = {
    // ...domainTranslations[currentLocale]['default'],
    ...mergeTranslations(interfaceTranslations[currentLocale], addonsTranslations[currentLocale], domainTranslations[currentLocale]['default'])
  };

  return currentLocale;
});

const i18nProvider = polyglotI18nProvider(
  locale => messages[locale],
  userCurrentLocale,
  {allowMissing: true} // https://github.com/marmelab/react-admin/issues/3903
);

export default i18nProvider;
