import React from 'react';
import { JsonForm } from '../custom/JsonForm';
import { Labeled } from 'react-admin';

const AclForm = ({
  label,
  record,
  source,
  resource,
  callback,
  itemAclSchema,
  ...props
}) => {
  const handleChange = ({ formData }) => {
    callback(formData, record, source);
  };

  const uiSchema = {
    "clientId": {
      "ui:readonly": true
    },
    "user": {
      "ui:readonly": true
    },
    "password": {
      "ui:readonly": true
    }
  };

  const generateAclSchema = ( aclJson ) => {

    let schema = {
      properties: {
        clientId: {type: "string"},
        user: {type: "string"},
        password: {type: "string"}
      },
      required: [],
      title: "Параметры доступа",
      type: "object"
    };

    return schema;
  };

  return (
    <Labeled label={label} fullWidth>
      <JsonForm
        source={source}
        uiSchema={uiSchema}
        schema={generateAclSchema( record[source] )}
        onChange={handleChange}
        record={record}
        {...props}
      />
    </Labeled>
  );
};

export default AclForm;
