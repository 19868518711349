import DeviceIcon from '@material-ui/icons/SettingsInputComponent';
import DeviceList from './DeviceList';
import DeviceEdit from './DeviceEdit';
import DeviceCreate from './DeviceCreate';

const resources = {
  list: DeviceList,
  edit: DeviceEdit,
  create: DeviceCreate,
  icon: DeviceIcon
};

export default resources;

