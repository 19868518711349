import React, { useEffect } from "react";
import { TabbedForm } from "react-admin";

const TabbedFormWithModel = ({stateModel, setStateModel, modelName, ...props}) => {
	useEffect(
		() => {
			const recordUuid = props.record && props.record[modelName] && props.record[modelName].uuid;
			const stateUuid = stateModel && stateModel.uuid;
			if (recordUuid && !stateUuid) {
				setStateModel(props.record[modelName])
			}
		},
		[props.record, modelName, setStateModel, stateModel],
	);
	return (<TabbedForm {...props} />)
}

export default TabbedFormWithModel
