import React, { useState, Fragment } from "react";
import {
  TextInput,
  AutocompleteInput,
  DateField,
  withDataProvider,
  FormTab,
  FormDataConsumer,
  GET_ONE,
  useTranslate,
} from 'react-admin';
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { ChildrenObjectsList } from './ChildrenObjectsList';
import ObjectDevicesList from './ObjectDevicesList';
import ObjectControlsList from './ObjectControlsList';
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import SaveWithSchemaToolbar from '../custom/SaveWithSchemaToolbar'; // T5317
import LazyFormTab from '../custom/LazyFormTab';
import SchemaParamsForm from '../custom/SchemaParamsForm';
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import OpenDashboard from '../dashboards/OpenDashboard';
import TabbedFormWithModel from '../custom/TabbedFormWithModel'
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import AutocompletePlaces from "../custom/AutocompletePlaces";
import JournalList from '../journals/JournalList';

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const ObjectEdit = ({dataProvider, ...props}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [objectType, setObjectType] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const additionalCrumbsHandler = () => {
    return {name: dashboardData? dashboardData.name : null, level: 3, resourceName: props.resource}
  };

  const handleTypeChange = (value) => {
    if (!value) {
      if (objectType) {
        setObjectType(null);
      }
      return;
    }
    if (objectType && objectType.uuid === value) {
      return;
    }
    dataProvider(GET_ONE, 'objects-types', {
      id: value,
    }).then(result => {
      setObjectType(result.data);
    });
  };

  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.object" {...props} />}
      getAdditionalCrumbs={additionalCrumbsHandler}
      {...props}
    >
      <TabbedFormWithModel
        stateModel={objectType}
        setStateModel={setObjectType}
        modelName="type"
        toolbar={
          <SaveWithSchemaToolbar
            schemaFieldName="typeSchemaParams"
            save={props.save}
          />
        }
        redirect="edit"
      >
        <FormTab label="resources.devices.tabs.dashboard">
          <OpenDashboard type={{ $eq: "object" }} objectUuid={props.id} setDashboardData={setDashboardData} />
        </FormTab>
        <FormTab label="resources.objects.tabs.object" path="info">
          <SanitizedGrid
            justify="flex-start"
            container={true}
            spacing={2}
            fullWidth
            className={classes.root}
          >
            <SchemaParamsForm
              schemaType="type"
              schemaName="typeSchemaParams"
              schemaSource={objectType}
              {...props}
            />
            <Grid item lg={6} md={6} xs={12}>
              <FormDataConsumer>
                {({ formData }) => {
                  return (
                    <Fragment>
                      <AutocompletePlaces
                        fieldName="address"
                        initialValue={formData && formData.address ? formData.address.formatted_address : null}
                        label={translate('app.labels.address')}
                      />
                      <TextInput source="uuid" disabled fullWidth />
                      <TextInput
                        source="name"
                        label="app.labels.name"
                        fullWidth
                      />
                      <DateField
                        source="createdAt"
                        label="app.labels.createdAt"
                        fullWidth
                      />
                      <DateField
                        source="updatedAt"
                        label="app.labels.updatedAt"
                        fullWidth
                      />
                      <ReferenceInputWithFilter
                        label="app.labels.client"
                        source="clientUuid"
                        reference="clients"
                      >
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            fullWidth: true
                          }}
                        />
                      </ReferenceInputWithFilter>
                      <ReferenceInputWithFilter
                        label="app.labels.objectType"
                        source="objectTypeUuid"
                        reference="objects-types"
                        onChange={handleTypeChange}
                        filter={{}}
                      >
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            fullWidth: true
                          }}
                        />
                      </ReferenceInputWithFilter>
                      <ReferenceInputWithFilter
                        label="app.labels.parentObject"
                        source="parentUuid"
                        reference="objects"
                        filter={{ uuid: { $ne: props.id } }}
                      >
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            fullWidth: true
                          }}
                        />
                      </ReferenceInputWithFilter>
                    </Fragment>
                  );
                }}
              </FormDataConsumer>
            </Grid>
          </SanitizedGrid>
        </FormTab>
        <LazyFormTab label="resources.objects.tabs.children" path="children">
          <ChildrenObjectsList {...props} />
        </LazyFormTab>
        <LazyFormTab label="resources.objects.tabs.devices" path="devices">
          <ObjectDevicesList {...props} />
        </LazyFormTab>
        <LazyFormTab label="resources.objects.tabs.controls" path="controls">
          <ObjectControlsList {...props} />
        </LazyFormTab>
        <LazyFormTab label="resources.objects.tabs.logs" path="logs">
          {/*<DeviceLogsList deviceUuids={[props.id]} {...props} />*/}
          <JournalList objectUuids={[props.id]} showObject={false} showClient={false} {...props} />
        </LazyFormTab>
      </TabbedFormWithModel>
    </EditWithBreadcrumbs>
  );
};

export default withDataProvider(ObjectEdit);
