import UserLogsIcon from '@material-ui/icons/RemoveRedEye';
import UserLogsShow from './UserLogsShow';
import UserLogsList from './UserLogsList';

const resources = {
  list: UserLogsList,
  show: UserLogsShow,
  icon: UserLogsIcon
};

export default resources;
