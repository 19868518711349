import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { EditButton, Datagrid as RADatagrid } from "react-admin";

import UuidNameTextField from "../custom/UuidNameTextField"; // T5113

const styles = (theme) => ({
  headerCell: {
    padding: "2px",
    "&:last-child": {
      padding: "2px",
    },
  },
  rowCell: {
    padding: "2px",
    "&:last-child": {
      padding: "2px",
    },
  },
});

const EditButtonCheckClientUuid = (props) => {
  if (props.record && props.record.canEdit) {
    return <EditButton {...props} />;
  }
  return null;
};

// T5113
const Datagrid = ({
  checkClientUuid,
  editBtnDisable,
  showFilter,
  setSort,
  setPage,
  setFilters,
  selectedIds,
  perPage,
  onUnselectItems,
  onToggleItem,
  loaded,
  hasBulkActions,
  currentSort,
  defaultTitle,
  displayedFilters,
  filterValues,
  hasCreate,
  hideFilter,
  loading,
  setPerPage,
  classes,
  uuidClassName,
  ...props
}) => {
  let editButton = <EditButton {...props} />;
  if (editBtnDisable) {
    editButton = null;
  } else if (checkClientUuid) {
    editButton = <EditButtonCheckClientUuid {...props} />;
  }

  return (
    <RADatagrid
      showFilter={showFilter}
      setSort={setSort}
      setPage={setPage}
      setFilters={setFilters}
      selectedIds={selectedIds}
      perPage={perPage}
      onUnselectItems={onUnselectItems}
      onToggleItem={onToggleItem}
      loaded={loaded}
      currentSort={currentSort}
      defaultTitle={defaultTitle}
      displayedFilters={displayedFilters}
      filterValues={filterValues}
      hasCreate={hasCreate}
      hideFilter={hideFilter}
      loading={loading}
      setPerPage={setPerPage}
      hasBulkActions={hasBulkActions}
      classes={classes}
      {...props}
    >
      <UuidNameTextField
        source="uuid"
        label="app.labels.name"
        className={uuidClassName}
      />
      {props.children}
      {editButton}
    </RADatagrid>
  );
};

export default withStyles(styles)(Datagrid);
