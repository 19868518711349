import React, { Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export const UuidCellStyle = {
	fontSize: '11px',
	color: 'gray',
	marginTop: '4px'
}

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: '#3f51b5',
    '&:hover, &:visited, &:active': {
      textDecoration: 'none',
      color: '#3f51b5',
    },
  },
	uuid: UuidCellStyle
});

// T5113
export default withStyles(styles)((
	{
		classes,
		source,
		record,
		resource,
		...props
	}) => {
	return (
		source === 'parentUuid' ? 
			<Fragment>
				<Link className={classes.link} to={`/${resource}/${record.parentUuid}`}>
				<span className={classes.container}>
					<span className={classes.name}>{record.parent && record.parent.name}</span>
				</span>
				</Link>
				<div>
					<span className={classes.uuid}>{record.parent && record.parent.uuid}</span>
				</div>
			</Fragment> :
			<Fragment>
				<Link className={classes.link} to={`/${resource}/${record.uuid}`}>
				<span className={classes.container}>
					<span className={classes.name}>{record.name}</span>
				</span>
				</Link>
				<div>
					<span className={classes.uuid}>{record[source]}</span>
				</div>
			</Fragment>
	);
});
