import React from 'react';
import { Toolbar } from "react-admin";
import { AppEditSaveButton } from '../custom/utils';
import { makeStyles } from "@material-ui/styles";
import { DeleteButton } from "../custom/WithUndoable";

const useStyles = makeStyles({
  flex: {
    display: 'flex',
    justifyContent: 'space-between'
  }
});

const UserEditToolbar = props => {
  const classes = useStyles();

  return (
    <Toolbar {...props} className={classes.flex}>
      <AppEditSaveButton
        label="ra.action.save"
        redirect="edit"
        submitOnEnter={true}
        target="roles"
      />
      <DeleteButton />
    </Toolbar>
  );
}

export default UserEditToolbar;
