import React, { Fragment } from "react";
import {
  TextInput,
  NumberInput,
  AutocompleteInput,
  BooleanInput,
  FormDataConsumer,
  SimpleForm,
  Labeled
} from "react-admin";

import { Grid } from "@material-ui/core";

import CustomTitle from '../custom/CustomTitle'; // T5275
import { EditorWrapper } from "../custom/JSONEditor"; // T5711
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';

import { JsonForm } from "../custom/JsonForm";

const placeholderItemParamsSchema = {
  "$id": "https://example.com/schemas/v01/example.json",
  "type": "object",
  "title": "Title",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "required": [],
  "properties": {},
  "definitions": {},
  "description": "description"
};

const DeviceModelCreate = props => {
  return (
    <CreateWithBreadcrumbs
      title={
        <CustomTitle
          titleKey="resources.devices-models.titles.newDeviceModel"
          {...props}
        />
      }
      record={{}}
      {...props}
    >
      <SimpleForm>
        <FormDataConsumer>
          {({ formData }) => (
            <Fragment>
              <Grid container={true} fullWidth>
                <Grid
                  justify="flex-start"
                  container={true}
                  spacing={2}
                  fullWidth
                >
                  <Grid item lg={6} md={12}>
                    <BooleanInput
                      source="isActive"
                      label="app.labels.isActive"
                    />
                    <TextInput
                      source="name"
                      label="app.labels.name"
                      fullWidth
                    />
                    <NumberInput
                      source="messagesFreq"
                      label="app.labels.messagesFreq"
                      fullWidth
                    />
                    <ReferenceInputWithFilter
                      label="app.labels.client"
                      source="clientUuid"
                      reference="clients"
                    >
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                    <ReferenceInputWithFilter
                      label="app.labels.deviceType"
                      source="deviceTypeUuid"
                      reference="devices-types"
                    >
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                    <ReferenceInputWithFilter
                      label="app.labels.vendor"
                      source="vendorUuid"
                      reference="vendors"
                      filter={{}}
                    >
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                  </Grid>
                  <Grid item lg={6} md={12}>
                    <EditorWrapper schemaName="topicNameSchema" record={formData} defaultValue={{ "attributes": [] }} />
                  </Grid>
                </Grid>
                <Grid
                  justify="flex-start"
                  container={true}
                  spacing={2}
                  fullWidth
                >
                  <Grid item lg={6} md={12}>
                    <EditorWrapper schemaName="itemParamsSchema" record={formData} defaultValue={placeholderItemParamsSchema} />
                  </Grid>
                  <Grid item lg={6} md={12}>
                    <Labeled label="app.labels.itemParamsSchema">
                      <JsonForm
                        record={formData}
                        source="itemParamsSchema"
                        label="app.labels.itemParamsSchema"
                        schema={
                          formData && formData.itemParamsSchema
                            ? formData.itemParamsSchema
                            : {}
                        }
                        fullWidth
                        {...props}
                      />
                    </Labeled>
                  </Grid>
                </Grid>
              </Grid>
            </Fragment>
          )}
        </FormDataConsumer>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  );
};

export default DeviceModelCreate;
