import React from 'react';
import { Grid } from "@material-ui/core";

const SanitizedGrid = ({fullWidth, hasCreate, hasShow, hasEdit, hasList, basePath, filterValues, ...props}) => {
	return (
		<Grid {...props} />
	)
};

export default SanitizedGrid;
