import React, {Fragment, useState} from "react";
import { useForm } from 'react-final-form';
import {
  TextInput,
  SelectInput,
  useTranslate,
  SimpleForm
} from 'react-admin';

import { makeStyles } from "@material-ui/styles";
import { Paper } from "@material-ui/core";

import Configurator from './Configurator';
import { Edit } from '../custom/WithUndoable';
import VersionInput from '../custom/VersionInput'; // T5154
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275

import { SchemaEditForm }  from "../custom/SchemaForm"; // T5722

const placeholderMessageTypeSchema = {
  "$id": "https://example.com/schemas/v01/example.json",
  "type": "object",
  "title": "Title",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "required": [],
  "properties": {},
  "definitions": {},
  "description": "description"
};

const ConfiguratorWithForm = props => {
  const form = useForm();
  const formValues = form.getState().values;
  return <Configurator
    {...props}
    form={form}
    data={formValues.configurator || {parameters: []}}
    messageTypeUuid={formValues.id}
    deviceModelUuid={formValues.deviceModelUuid}
    clientUuid={formValues.clientUuid}
  />;
}

const tabsContentStyle = {
  display: 'none'
};

const tabActiveStyle = {
  display: 'block'
};

const paperStyles = makeStyles(theme => ({
  paper: {
    padding: "1em"
  }
}));

const tabStyles = makeStyles({
  customTabs: {
    padding: '0 25px'
  },
  customTab: {
    display: 'inline-block',
    textAlign: 'center',
    width: '190px',
    padding: '25px 0px 15px',
    cursor: 'pointer'
  },
  customTabHr: {
    position: 'relative',
    height: '2px',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  },
  customTabIndicator: {
    position: 'absolute',
    backgroundColor: '#3f51b5',
    width: '190px',
    height: '2px',
    bottom: '0',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    willChange: 'left, width'
  }
});

const CustomTabsHeader = ({setActiveTabNumber, ...props}) => {
  const classes = tabStyles();
  const translate = useTranslate();
  const [indicatorLeft, setIndicatorLeft] = useState('25px');
  const onTabClick = (number) => {
    let newIndicatorLeft = (190 * (number-1) + 25) + "px";
    setIndicatorLeft(newIndicatorLeft);
    setActiveTabNumber(number);
  };

  return (
    <div>
      <div className={classes.customTabs}>
        <span
          className={
            classes.customTab}
          onClick={() => onTabClick(1)}>{translate('resources.messages-types.tabs.messageType')}
        </span>
        <span
          className={classes.customTab}
          onClick={() => onTabClick(2)}>{translate('resources.messages-types.tabs.configurator')}
        </span>
      </div>
      <div className={classes.customTabHr}>
        <span className={classes.customTabIndicator} style={{ left: indicatorLeft }} />
      </div>
    </div>
  )
};

const CustomTabsContent = ({activeTabNumber, save, ...props}) => {
  const translate = useTranslate();
  return (
    <Fragment>
      <div style={activeTabNumber === 1 ? tabActiveStyle : tabsContentStyle}>
        <SchemaEditForm
          label="resources.messages-types.tabs.messageType"
          schemaName="messageSchema"
          flattenSchemaName="flattenMessageSchema"
          schemaLabel="app.labels.messageSchema"
          ignoreState={true}
          inlineChildren={true}
          isTabbed={true}
          schemaPlaceholder={placeholderMessageTypeSchema}
          {...props}
        >
          <VersionInput />
          <TextInput source="uuid" disabled fullWidth />
          <TextInput
            source="name"
            label="app.labels.name"
            fullWidth
          />
          <SelectInput
            source="direction"
            label="app.labels.direction"
            choices={[
              {
                id: "in",
                name: translate(
                  "resources.messages-types.directions.in"
                )
              },
              {
                id: "out",
                name: translate(
                  "resources.messages-types.directions.out"
                )
              }
            ]}
            fullWidth
          />
        </SchemaEditForm>
      </div>
      <div style={activeTabNumber === 2 ? tabActiveStyle : tabsContentStyle}>
        <ConfiguratorWithForm {...props} />
      </div>
    </Fragment>
  );
}

const MessageTypeEdit = ({disableDeleteBtn, ...props}) => {
  const classes = paperStyles();
  const [activeTabNumber, setActiveTabNumber] = useState(1);
  return (
    <Paper className={classes.paper}>
      <CustomTabsHeader setActiveTabNumber={setActiveTabNumber} />
      <Edit
        title={<CustomEditTitle titleKey="app.labels.messageType" {...props} />}
        {...props}
      >
        <SimpleForm redirect={false}>
          <CustomTabsContent activeTabNumber={activeTabNumber} />
        </SimpleForm>
      </Edit>
    </Paper>
  );
};
export default MessageTypeEdit;
