import React from 'react';

import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from 'react-admin';

const useStyles = makeStyles(theme => ({
  root: {
    '& > div': {
      flexFlow: 'row wrap',
      justifyContent: 'flex-end'
    },
    '& > div > div': {
      marginLeft: 0
    }
  }
}));

export const TreePagination = props => {
  const {
    page,
    perPage,
    total,
    setPage,
  } = props;
  const classes = useStyles();

  return (
    <Pagination
      className={classes.root}
      rowsPerPageOptions={[]}
      page={page}
      perPage={perPage}
      setPage={setPage}
      total={total}
    />
  );
};
