export default {
  ra: {
    page: {
      empty: '',
      invite: ''
    },
    message: {
      loading: "Идет загрузка, пожалуйста, подождите...",
    }
  },
  resources: {
    rules: {
      name: "Правило |||| Правила",
      titles: {
        newRule: "Новое правило"
      },
      tabs: {
        rule: "Правило",
        assignments: "Связи"
      }
    },
    "rules-assignments": {
      name: "Связь |||| Связи",
      titles: {
        newRuleAssignment: "Новая связь"
      }
    },
    "dry-run": {
      name: "Тестирование",
      titles: {
        dryRunRules: "Тестирование правил",
        device: "Устройство: "
      },
      labels: {
        rulesList: "Список правил",
        rule: "Правило",
        message: "Сообщение",
        rulesExecutionLog: "Журнал выполнения правил",
        selectDevice: "Выберите устройство в фильтре выше",
        noRulesForSelectedDevice: "Нет правил для выбранного устройства"
      }
    },
    tree: {
      name: "Все устройства",
      labels: {
        tree: "Дерево",
        objectsList: "Список объектов",
        devicesList: "Список устройств",
        parentDevice: "Родительское устройство"
      },
      messages: {
        "select-item": "Выберите объект в соседнем списке"
      }
    },
    objects: {
      name: "Объект |||| Объекты",
      titles: {
        newObject: "Новый объект"
      },
      notifications: {
        managementToggledSuccess: 'Режим обслуживания изменен',
        runGroupSuccess: "Группа правил запущена",
        runRuleSuccess: "Правило запущено"
      },
      dialogs: {
        groupTitle: "Подтвердите список запускаемыхь правил",
        ruleTitle: "Подтвердите запускаемое правило"
      },
      tabs: {
        object: "Объект",
        parameters: "Параметры",
        children: "Дети",
        controls: "Управление",
        devices: "Устройства",
        logs: "Журнал"
      }
    },
    "objects-tree": {
      name: "Дерево объектов |||| Деревья объектов"
    },
    "objects-types": {
      name: "Тип объекта |||| Типы объектов",
      titles: {
        newObjectType: "Новый тип объекта"
      }
    },
    devices: {
      name: "Устройство |||| Устройства",
      tabs: {
        device: "Устройство",
        children: "Дочерние устройства",
        parameters: "Параметры",
        acl: "Доступ",
        data: "Данные",
        management: "Управление",
        dashboard: "Dashboard",
        logs: 'Журнал',
        virtualMapping: "Параметры",
      },
      titles: {
        newDevice: "Новое устройство",
        allDevices: "Все устройства",
        chooseDevice: "Выберите устройство",
        chooseMessageType: "Выберите тип сообщения",
        allMessageType: "Все типы сообщений"
      },
      buttons: {
        changeAcl: "Перегенерировать доступы"
      },
      pages: {
        dialogChangeAcl: "Вы уверены?"
      },
      management: {
        switchOn: "вкл",
        switchOff: "выкл"
      },
      plashka: {
        never: "Никогда",
        lastSeen: "Последний запрос",
        place: "Расположение",
        primary: "ключевое",
        secondary: "второстепенное"
      },
      data: {
        tip:
          'Возможно, есть задержка в ответе от устройства, вы можете проверить поступление данных самостоятельно, нажав на кнопку "обновить"'
      }
    },
    "devices-types": {
      name: "Тип устройства |||| Типы устройств",
      titles: {
        newDeviceType: "Новый тип устройства"
      }
    },
    "devices-models": {
      name: "Модель устройства |||| Модели устройств",
      tabs: {
        modelParams: "Параметры модели",
        messageTypes: "Типы сообщений",
        plateSetting: "Настройка плашки",
        management: "Управление"
      },
      titles: {
        newDeviceModel: "Новая модель устройства",
        noMessageTypes: "Типы сообщений не заданы",
        cloneDeviceModel: "Клонировать устройство"
      },
      icon: {
        choose: "Выбрать иконку",
        chooseBtn: "Выбрать",
        title: "Иконка"
      }
    },
    "messages-types": {
      name: "Тип сообщения |||| Типы сообщений",
      tabs: {
        messageType: "Тип сообщения",
        configurator: "Конфигуратор"
      },
      titles: {
        newMessageType: "Новый тип сообщения"
      },
      direction: "Направление",
      directions: {
        in: "От устройства",
        out: "На устройство"
      },
      paramsPage: {
        wrongMT: "Выбранный тип сообщения не привязан к текущей модели",
        primary: "Ключевые параметры",
        secondary: "Второстепенные параметры",
        non: "Не отображаются в плашке",
        noParams: "У выбранного типа сообщения нет параметров",
        updated: "Настройки отображения параметров успешно сохранены",
        iconSelect: "Выберите иконку",
        icon: "Иконка",
        add: "Добавить новый параметр"
      }
    },
    vendors: {
      name: "Производитель |||| Производители",
      titles: {
        newVendor: "Новый производитель"
      }
    },
    reports: {
      name: "Отчет |||| Отчеты",
      titles: {
        newReport: "Новый отчет",
        status: "Статус",
        baseReport: "Базовый отчет",
        downloadReport: "Скачать отчет"
      }
    },
    "report-templates": {
      name: "Шаблон отчета |||| Шаблоны отчетов",
      titles: {
        allReportTemplates: "Все шаблоны отчетов",
        chooseReportTemplate: "Выберите шаблон отчета",
        newRecordTemplate: "Новый шаблон отчета",
        downloadTemplate: "Скачать файл шаблона"
      },
      inputs: {
        uploadTemplate: "Загрузите файл шаблона (.odt, .docx)",
        dropFile: "Перетяните файл сюда",
      },
      permission: {
        author: "Автор",
        all: "Все",
        client: "Клиент",
      }
    },
    "measure-units": {
      name: "Единица измерения |||| Единицы измерения",
      titles: {
        newMeasureUnit: "Новая единица измерения"
      }
    },
    clients: {
      name: "Клиент |||| Клиенты",
      titles: {
        newClient: "Новый клиент"
      },
      labels: {
        clientId: "ID клиента",
        clientName: "Название клиента",
        user: "Пользователь"
      },
      fields: {
        isActive: "Активен",
        type: "Тип",
        name: "Название",
        description: "Описание",
        createdAt: "Создан",
        updatedAt: "Обновлен"
      },
      tabs: {
        client: "Клиент",
        users: "Пользователи",
        theme: "Тема"
      },
      buttons: {
        addUser: "Добавить пользователя"
      },
      pages: {
        clientUserAdd: "Добавить клиентского пользователя",
        clientUserUpdate: "Обновить клиентского пользователя"
      },
      inputs: {
        brandName: "Название бренда",
        logoFileExtension: "Расширение лого",
        uploadLogo: "Загрузите файл логитпа (.png, .jpg, .jpeg)",
        headerBackground: "Фон основного меню",
        headerTextColor: "Текст основного меню",
        pageBackground: "Фон страницы",
        pageTextColor: "Текст страницы",
        linkTextColor: "Текст ссылок",
        dropLogo: "Перетяните изображение сюда"
      },
      notifications: {
        clientUserCreateSuccess: "Клиентский пользователь создан",
        clientUserCreateFail: "Клиентский пользователь не создан",
        clientUserUpdateSuccess: "Клиентский пользователь обновлен",
        clientUserUpdateFail: "Клиентский пользователь не обновлен",
        clientUserDeleteSuccess: "Клиентский пользователь удален",
        clientUserDeleteFail: "Клиентский пользователь не удален"
      }
    },
    journals: {
      name: "Журнал |||| Журналы",
      titles: {
        journal: "Журнал",
        newJournal: "Новый журнал",
        selectObjects: "Выберите объекты",
        selectDistricts: "Выберите округа",
      },
    },
    dashboards: {
      name: "Dashboard |||| Dashboards",
      titles: {
        newDashboard: "New dashboard",
        noMainDashboard: "Основной dashboard не задан",
        main: "Dashboard"
      },
    },
    permissions: {
      name: "Полномочие |||| Полномочия",
      titles: {
        newPermission: "Новое полномочие"
      }
    },
    roles: {
      name: "Роль |||| Роли",
      tabs: {
        role: "Роль",
        permissions: "Полномочия"
      },
      titles: {
        newRole: "Новая роль"
      }
    },
    users: {
      name: "Пользователь |||| Пользователи",
      tabs: {
        user: "Пользователь",
        roles: "Роли"
      },
      titles: {
        newUser: "Новый пользователь"
      }
    },
    "users-logs": {
      name: "История действий",
      labels: {
        logDetails: "Подробности действия"
      }
    },
    "typical-schemas": {
      name: "Типовые схемы",
      newTypicalSchema: "Новая типовая схема"
    }
  },
  app: {
    name: "Proxia Sprut",
    roles: {
      administrator: "Администратор",
      owner: "Владелец",
      developer: "Разработчик",
      manager: "Менеджер",
      tester: "Тестеровщик"
    },
    labels: {
      home: "Главная",
      from: "От",
      to: "До",
      start: "Начало",
      finish: "Окончание",
      address: "Адрес",
      lastPacketReceivedAt: "Последнее время получения данных",
      aggInterval: "Интервал агрегации",
      myObjects: "Мои объекты",
      noData: "Нет данных",
      client: "Клиент",
      clients: "Клиенты",
      clientId: "ID клиента",
      clientName: "Название клиента",
      comment: 'Комментарий',
      dashboards: "Dashboards",
      dashboard: "Dashboard",
      dashboardUrlHelperText: "Оставьте пустым для создания нового dashboard в Grafana",
      user: "Пользователь",
      users: "Пользователи",
      role: "Роль",
      roles: "Роли",
      permission: "Полномочие",
      permissions: "Полномочия",
      rule: "Правило",
      ruleDraft: "Правило (черновик)",
      rules: "Правила",
      runMode: "Режим запуска",
      debug: "Отладка",
      addAssignment: "Добавить связь",
      ruleAssignment: "Добавить связь",
      ruleAssignments: "Связи",
      ruleAssignmentsGroups: "Группы связей",
      object: "Объект",
      objects: "Мои объекты",
      objectType: "Тип объекта",
      objectTypes: "Типы объекта",
      openInNewTab: "В новом окне",
      payloadSchema: "Схема пейлоада",
      itemParamsSchema: "Схема параметров",
      formPreview: "Превью формы",
      typeSchemaParams: "Параметры типа",
      modelSchemaParams: "Параметры устройства",
      topic: "Топик",
      topicNameSchema: "Схема топика",
      device: "Устройство",
      devices: "Устройства",
      deviceType: "Тип устройства",
      deviceModel: "Модель устройства",
      deviceTypes: "Типы устройств",
      deviceModels: "Модели устройств",
      deviceGroups: "Группы устройств",
      messagesFreq: "Частота отправки данных",
      messagesInterval: "Интервал отправки данных",
      messageSchema: "Схема сообщения",
      messageType: "Тип сообщения",
      messageTypes: "Типы сообщений",
      addMessageType: "Добавить тип сообщения",
      url: "Url",
      expand: "Раскрыть",
      action: "Действие",
      managementOff: "Управление вкл/выкл",
      isActive: "Активен",
      isAlertReport: "Отчет событий",
      isDefault: "По умолчанию",
      isPersonal: "Персональный",
      isOnline: "На связи",
      isVirtual: "Виртуальное",
      virtualMapping: "Параметры",
      virtualParam: "Параметр",
      type: "Тип",
      model: "Модель",
      phone: "Телефон",
      name: "Название",
      code: "Код",
      description: "Описание",
      editable: "Редактируем",
      enabled: "Разрешен",
      login: "Логин",
      password: "Пароль",
      parent: "Родитель",
      parentDevice: "Родительское устройство",
      parentObject: "Родительский объект",
      childDevice: "Дочернее устройство",
      childDevices: "Дочерние устройства",
      administration: "Администрирование",
      settings: "Настройки",
      language: "Язык",
      version: "Версия",
      class: "Класс",
      dryRun: "Тестирование",
      mode: "Режим",
      print: "Распечатать",
      executionType: "Тип исполнения",
      priority: "Приоритет",
      schedule: "Расписание",
      before: "До",
      after: "После",
      system: "Системный",
      manual: "Ручной",
      regular: "Регулярный",
      payload: "Нагрузка",
      message: "Сообщение",
      ip: "IP",
      userAgent: "UserAgent",
      data: "Данные",
      response: "Ответ",
      responseCode: "Код ответа",
      module: "Модуль",
      search: "Поиск",
      tree: "Дерево",
      list: "Список",
      view: "Вид",
      children: "Дети",
      back: "Назад",
      json: "JSON",
      form: "Форма",
      selectDevice: "Выбрать устройство",
      "n/a": "н/д",
      refresh: "Обновить",
      save: "Сохранить",
      saveMessage: "Сохранить сообщение",
      saveRule: "Сохранить правило",
      restoreRule: "Восстановить правило",
      saveDraft: "Сохранить черновик",
      selectDashboard: "Выберите dashboard",
      run: "Запустить",
      nothingFound: "Ничего не найдено",
      cloneMessageTypes: "Клонировать типы сообщений",
      vendor: "Производитель",
      vendors: "Производители",
      report: "Отчет",
      reports: "Отчеты",
      templatePermission: "Права на шаблон",
      downloadReport: "Скачать отчет",
      reportTemplate: "Шаблон отчета",
      reportTemplates: "Шаблоны отчетов",
      measureUnit: "Единица измерения",
      measureUnits: "Единицы измерения",
      symbol: "Обозначение",
      deviceLockedBy: "Пакет отправил",
      itemsSelected: "выбрано",
      breadcrumbs: "Хлебные крошки",
      infrastructure: "Инфраструктура",
      configurator: "Конфигуратор",
      management: "Управление",
      quickActionTitle: "Информация по действию",
      isQuickAction: "Является действием",
      isNewSetup: "Новые установки",
      quickActionName: "Название действия",
      quickActionPayload: "Содержимое действия",
      quickActionResult: "Команда отправлена на устройство",
      quickActionBlocked: "Уже есть команда от пользователя",
      quickAction: "Выполнить действие",
      root: "Корень",
      all: "Все",
      tableView: "Табличный вид",
      typicalSchema: "Типовая схема",
      schema: "Схема",
      selectGroups: "Выбрать группы",
      alias: "Alias",
      open: "Открыть",
      logs: "Журнал",
      datetime: "Время",
      event: "Событие",
      select: "Выбрать",
      loading: "Запрос обрабатывается",
      source: "Источник",
      duration: "Длительность",
      output: "Вывод",
      original: "Оригинал",
      childs: "Вложенные",
      status: "Статус"
    },
    configurator: {
      chooseDevice: "Для просмотра выберите устройство",
      chooseMeasureUnit: "Выберите единицу измерения",
      rawData: "Сырые данные устройства",
      ranges: "Диапазоны",
      aggInterval: "Интервал агрегации",
      aggType: "Тип агрегации",
      fromTime: "Время с",
      toTime: "Время по",
      receivedAt: "Время получения данных",
      parameters: {
        parameter: "Параметр",
        name: "Имя",
        description: "Описание",
        path: "JSON Path",
        postProcess: "Post process",
        postProcessHelperText: "mathjs выражение с переменным `value` как значение текущего поля и `row['other field']` для получения значение других полей текущей строки"
      },
      range: {
        name: "Имя",
        level: "Уровень",
        conditionJs: "Условие JS"
      },
      aggIntervalChoices: {
        all: "Без агрегации",
        "1d": "День",
        "1h": "Час",
        "1m": "Минута",
        "1s": "Секунда",
        "1M": "Месяц",
        "1w": "Неделя",
        "1q": "Квартал",
        "1y": "Год"
      }
    },
    theme: {
      name: "Тема",
      edit: "Редактировать",
      light: "Светлая",
      dark: "Темная",
      client: "Клиентская"
    }
  },
  common: {
    done: "Готово",
    logout: "Выйти"
  },
  errors: {
    some_error: "Произошла ошибка",
    auth_error_custom: "AUTH_ERROR_CUSTOM получил ошибку без статуса!",
    missing_credentials: "Заполните форму авторизации!",
    incorrect_username: "Неверное имя пользователя",
    incorrect_password: "Неверный пароль",
    exceeded_login_attempts_limit:
      "Превышено допустимое количество попыток авторизации",
    unprocessable_entity: "Необработанный объект",
    the_user_is_blocked: "Пользователь заблокирован",
    the_user_is_inactive: "Пользователь неактивен",
    got_forbidden: "Нет доступа",
    no_token: "Нет токена",
    no_permissions: "Не хватает прав",
    unknown_method: "Неизвестный метод",
    insufficient_rights_: "У Вас нет прав на это действие!",
    no_assignments_or_wrong_permissions_:
      "Нет назначений или неверные права доступа!",
    no_device_or_wrong_permissions_:
      "Нет устройства или неверные права доступа!",
    insufficient_logs_view_: "Недостаточно прав для просмотра журнала!",
    device_uuid_undefined_: "не передан uuid устройства!",
    insufficient_stats_view_: "Недостаточно прав для просмотра статистики!",
    insufficient_deletion_: "Недостаточно прав, чтобы удалить запись из базы!",
    your_session_has_ended__please_reconnect_:
      "Ваша сессия истекла, пожалуйста, авторизуйтесь заново.",
    insufficient_rights_for_embedded__objects_vendor_get:
      "Недостаточно прав для получения объектов продавца",
    rules_code_cannot_be_null: "Код правила не может быть пустым",
    insufficient_rights__auth_login: "Недостаточно прав для авторизации",
    device_already_has_packet_to_process:
      "Устройство уже имеет пакет для обработки",
    device_model_is_inactive: "Модель устройства неактивна",
    device_model_has_active_devices:
      "Модель уйстройства имеет активные устройства",
    device_has_active_child_devices:
      "Устройство имеет активные устройства потомки",
    objects: {
      clientuuid_cannot_be_null: "поле Клиент не может быть пустым",
      objecttypeuuid_cannot_be_null: "поле Тип объекта не может быть пустым",
      typeschemaparams_cannot_be_null:
        "поле Параметры типа не может быть пустым"
    },
    objectstypes: {
      type_cannot_be_null: "поле Тип не может быть пустым",
      name_cannot_be_null: "поле Название не может быть пустым",
      itemparamsschema_cannot_be_null:
        "поле Схема параметров не может быть пустым",
      clientuuid_cannot_be_null: "поле Клиент не может быть пустым"
    },
    rules: {
      code_cannot_be_null: "поле Код не может быть пустым"
    },
    devices: {
      clientuuid_cannot_be_null: "поле Клиент не может быть пустым",
      devicemodeluuid_cannot_be_null:
        "поле Модель устройства не может быть пустым",
      modelschemaparams_cannot_be_null:
        "поле Параметры модели не может быть пустым"
    },
    devicesmodels: {
      name_cannot_be_null: "поле Название не может быть пустым",
      itemparamsschema_cannot_be_null:
        "поле Схема параметров не может быть пустым",
      clientuuid_cannot_be_null: "поле Клиент не может быть пустым",
      vendoruuid_cannot_be_null: "поле Производитель не может быть пустым"
    },
    devicestypes: {
      name_cannot_be_null: "поле Название не может быть пустым"
    },
    messagestypes: {
      name_cannot_be_null: "поле Название не может быть пустым",
      clientuuid_cannot_be_null: "поле Клиент не может быть пустым",
      devicemodeluuid_cannot_be_null:
        "поле Модель устройства не может быть пустым",
      messageschema_cannot_be_null: "поле Схема сообщения не может быть пустым"
    },
    vendors: {
      name_cannot_be_null: "поле Название не может быть пустым"
    }
  },
  "No token": "Ваш токен устарел"
}
