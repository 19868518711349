import React, { useState, useEffect, useCallback } from 'react';
import {
  GET_LIST,
  GET_ONE,
  withDataProvider,
  showNotification,
  Datagrid,
  DatagridBody,
  TextField,
  ArrayField,
  Loading
} from 'react-admin';
import { useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  withStyles,
  createStyles,
} from '@material-ui/core/styles';

const linkPermissions = (allPermissions, rolePermissions, state, setState) => {
  allPermissions.reduce((prev, perm) => {
    rolePermissions.some(rolePerm => {
      if (perm.id === rolePerm.id) {
        perm.enabled = true;
        perm.RolesToPermissions = rolePerm.RolesToPermissions;
        return true;
      } else {
        perm.enabled = false;
        return false;
      }
    });

    prev.push(perm);

    return prev;
  }, []);

  allPermissions.forEach(perm => {
    state[perm.name] = perm.enabled;
  });

  setState(state);

  return allPermissions;
};

const AppDatagridRow = props => {
  const {
    record,
    resource,
    id,
    children,
    basePath,
    state: {state, setState}
  } = props;

  const onToggleItem = record => event => {
    state[record.name] = record.enabled = event.target.checked;
    setState({...state});
  };

  return (
    <TableRow key={record.id}>
      {/* first column: selection checkbox */}
      <TableCell padding="none">
        <Switch
          checked={state[record.name]}
          value={record.name}
          onChange={onToggleItem(record)}
        />
      </TableCell>
      {/* data columns based on children */}
      {React.Children.map(children, field => (
        <TableCell key={`${id}-${field.props.source}`}>
          {React.cloneElement(field, {
            record,
            basePath,
            resource
          })}
        </TableCell>
      ))}
    </TableRow>
  );
};

const AppDatagridBody = props => (
  <DatagridBody
    {...props}
    row={<AppDatagridRow state={props.state} />}
  />
);

const datagridStyles = theme =>
  createStyles({
    headerRow: {
      '& th:first-child > span': {
        display: 'none'
      }
    },
    headerCell: {
      padding: '0 24px',
      '&:last-child': {
        padding: '0 24px',
      },
    },
  }
);

const AppDatagrid = withStyles(datagridStyles)(props => (
  <Datagrid
    {...props}
    body={<AppDatagridBody state={props.state} />}
  />
));

const RolePermissions = props => {
  const { source, resource, dataProvider, record } = props;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isPermsFetched, setIsPermsFetched ] = useState(false);
  const [ state, setState ] = useState({});
  const dispatch = useDispatch();

  const fetchPermissions = useCallback(() => {
    const resourceId = record.id;
    let [allPermissions, rolePermissions] = [[], []];

    // First get all permissions list
    let promises = [];

    promises.push(
      dataProvider(GET_LIST, source, {
        pagination: { perPage: -1 }
      })
    );
    if (resourceId) {
      promises.push(dataProvider(
        GET_ONE, resource, {
          id: resourceId,
          filter: { embedded: { permissions: true } }
        }
      ));
    }

    return Promise
      .all(promises)
      .then(response => {
        allPermissions = response[0].data;
        rolePermissions = response[1] ? response[1].data.permissions : [];
        // Combine allPermissions and rolePermissions together to form linked list
        const linkedPermissions = linkPermissions(
          allPermissions,
          rolePermissions,
          state,
          setState
        );

        return linkedPermissions;
      })
      .catch(err => {
        dispatch(showNotification(err.message));
        throw err;
      });
  }, [dataProvider, dispatch, resource, source, record, state, setState]);

  useEffect(() => {
    if (isPermsFetched) {
      return;
    }

    setIsLoading(true);
    fetchPermissions().then(perms => {
      record.permissions = perms;
      setIsPermsFetched(true);
      setIsLoading(false);
    });
  }, [record, fetchPermissions, isLoading, setIsLoading, isPermsFetched, setIsPermsFetched]);

  return (
    <section>
      {isLoading ? (
        <Loading />
      ) : (
        <ArrayField record={record} source="permissions">
          <AppDatagrid state={{state, setState}}>
            <TextField source="id" />
            <TextField source="name" />
          </AppDatagrid>
        </ArrayField>
      )}
    </section>
  );
};

export default withDataProvider(RolePermissions);
