import React from 'react';
import {
  ReferenceField,
  TextField,
  // SearchInput,
  // Filter,
  List,
  DateField
} from 'react-admin';

import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194

// const DeviceFilter = props => (
//   <Filter {...props}>
//     <SearchInput source="name" alwaysOn />
//   </Filter>
// );

export const ChildrenObjectsList = ({record, ...props }) => {
  return (
    <List
    bulkActionButtons={<BulkDeleteAction />}
    filter={{
      parentUuid: record.id
    }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
    hasCreate={false}
    hasEdit={false}
    title=" "
    style={{ width: '100%' }}
    perPage={25}
    >
      <Datagrid editBtnDisable={true}>
        <ReferenceField
          label="app.labels.client"
          source="clientUuid"
          reference="clients"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="app.labels.objectType"
          source="objectTypeUuid"
          reference="objects-types"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="createdAt" label="app.labels.createdAt" />
        <DateField source="updatedAt" label="app.labels.updatedAt" />
      </Datagrid>
    </List>
  );
}
