import React from "react";
// import Typography from '@material-ui/core/Typography';
import { BooleanField, List, ReferenceField, TextField } from "react-admin";

import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import { OnlineField } from "../custom/OnlineField";
import ChildrenButton from "../custom/ChildrenButton";
import Datagrid from "../custom/ClickableDatagrid"; // T5113

export const ChildDevicesList = (props) => {
  return (
    <List
      bulkActionButtons={<BulkDeleteAction />}
      filter={{
        parentUuid: props.id,
        name: undefined,
      }}
      sort={{ field: "createdAt", order: "DESC" }}
      {...props}
      hasCreate={false}
      hasEdit={false}
      title=" "
      style={{ width: "100%" }}
      perPage={25}
    >
      <Datagrid>
        <ReferenceField
          label="app.labels.object"
          source="objectUuid"
          reference="objects"
        >
          <TextField source="name" />
        </ReferenceField>
        <OnlineField source="isOnline" label="app.labels.isOnline" />
        <BooleanField source="isActive" label="app.labels.isActive" />
        <ReferenceField
          label="app.labels.client"
          source="clientUuid"
          reference="clients"
        >
          <TextField source="name" />
        </ReferenceField>
        <ChildrenButton />
      </Datagrid>
    </List>
  );
};
