import React, { useState, useEffect } from 'react';
import { Field } from 'react-final-form';
import { Labeled } from "react-admin";
import AceEditor from 'react-ace';
import {
  getDeepProperty,
  setDeepProperty
} from "../../components/custom/utils";

import 'brace/mode/markdown';
import 'brace/mode/javascript';
import 'brace/mode/xml';
import 'brace/mode/json';

import 'brace/theme/github';
import 'brace/theme/xcode';
import 'brace/theme/monokai';
import 'brace/theme/terminal';

export const EditorContainer = props => {
  let {
    label,
    mode,
    theme,
    placeholder,
    record,
    source,
    callback,
    recordValue,
    setRecordValue,
    input: { name, value, onBlur },
    width,
    height,
    readOnly,
    validateCallback
  } = props;

  // T5150 set as value recordValue or value
  // it's necessary for direct usage without react-admin record
  value = recordValue || value;

  const handleChange = newValue => {
    value = newValue;
    handleBlur();
  };
  // T4999
  const handleValidate = (errors) => {
    if (typeof validateCallback === 'function') {
      validateCallback(errors, value);
    }
  };

  const handleBlur = () => {
    if (!value) {
      return;
    }
    if (mode === 'json') {
      try {
        value = JSON.parse(value);
      } catch (error) {
        value = {};
        return;
      }
    }
    if (onBlur) {
      recordValue = value;
      setRecordValue(recordValue);
      if (record) {
        setDeepProperty(record, source, recordValue);
      }
      onBlur(value);
    }
    if (typeof callback === 'function') {
      callback(value, record, source);
    }
  };

  if (typeof value === 'object') {
    value = JSON.stringify(value, null, 2); // ReactAce cant parce js objects, so any object should be pre-JSON.stringified
  }

  return (
    <Labeled label={label} fullWidth={true}>
      <AceEditor
        mode={mode || 'markdown'}
        theme={theme || 'github'}
        onChange={handleChange}
        onBlur={handleBlur}
        onValidate={handleValidate}
        name={name}
        value={value}
        placeholder={placeholder}
        editorProps={{ $blockScrolling: true }}
        height={height}
        readOnly={readOnly}
        width={width}
      />
    </Labeled>
  );
};

export const CodeEditor = props => {
  const {
    source,
    label,
    record,
    placeholder,
    mode,
    theme,
    callback,
    width,
    height,
    validateCallback
  } = props;

  // T4999
  const handleValidate = (errors, value) => {
    if (typeof validateCallback === 'function') {
      validateCallback(errors, value);
    }
  };

  let value = getDeepProperty(record, source);
  let [recordValue, setRecordValue] = useState(value);

  useEffect(() => {
    setRecordValue((value) ? value : {}); // T5483
  }, [setRecordValue, value, mode]);

  return (
    <Field
      recordValue={recordValue}
      setRecordValue={setRecordValue}
      name={source}
      label={label}
      component={EditorContainer}
      validateCallback={handleValidate}
      placeholder={placeholder}
      mode={mode}
      theme={theme}
      callback={callback}
      record={record}
      source={source}
      width={width}
      height={height}
    />
  );
};
