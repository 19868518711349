import React, { Component } from 'react';
import PropTypes from 'prop-types';
import JSONEditor from 'jsoneditor/dist/jsoneditor-minimalist';
import jsonMap from 'json-source-map';  // T5806
import 'jsoneditor/dist/jsoneditor.css';
import proxiaSprutUtils from 'proxia-sprut-utils';
import { useForm } from 'react-final-form';
import {Box} from "@material-ui/core";
import {useTranslate} from "react-admin";

/**
 * @typedef {{
 * tree: string,
 * view: string,
 * form: string,
 * code: string,
 * text: string,
 * allValues: Array<string>
 * }} TJsonEditorModes
 */
const modes = {
	tree: 'tree',
	view: 'view',
	form: 'form',
	code: 'code',
	text: 'text'
};

const values = Object.values(modes);

modes.allValues = values;

/**
 * @type {object}
 * @property {object} [value]
 * @property {object} [defaultValue]
 * @property {string} [mode='tree'] - Set the editor mode.
 * @property {string} [name=undefined] - Initial field name for the root node
 * @property {object} [schema] - Validate the JSON object against a JSON schema.
 * @property {object} [schemaRefs] - Schemas that are referenced using
 * the $ref property
 * @property {Function} [onChange] - Set a callback function
 * triggered when the contents of the JSONEditor change.
 * Called without parameters. Will only be triggered on changes made by the user.
 * Return new json.
 * @property {Function} [onError] - Set a callback function triggered when an error occurs.
 * Invoked with the error as first argument.
 * The callback is only invoked for errors triggered by a users action,
 * like switching from code mode to tree mode or clicking
 * the Format button whilst the editor doesn't contain valid JSON.
 * @property {Function} [onModeChange] - Set a callback function
 * triggered right after the mode is changed by the user.
 * @property {object} [ace] - Provide a version of the Ace editor.
 * Only applicable when mode is code
 * @property {object} [ajv] - Provide a instance of ajv,
 * the library used for JSON schema validation.
 * @property {string} [theme] - Set the Ace editor theme,
 * uses included 'ace/theme/jsoneditor' by default.
 * @property {boolean} [history=false] - Enables history,
 * adds a button Undo and Redo to the menu of the JSONEditor. Only applicable when
 * mode is 'tree' or 'form'
 * @property {boolean} [navigationBar=true] - Adds navigation bar to the menu
 * the navigation bar visualize the current position on the
 * tree structure as well as allows breadcrumbs navigation.
 * @property {boolean} [statusBar=true] - Adds status bar to the buttom of the editor
 * the status bar shows the cursor position and a count of the selected characters.
 * Only applicable when mode is 'code' or 'text'.
 * @property {boolean} [search=true] - Enables a search box in
 * the upper right corner of the JSONEditor.
 * @property {Array<string>} [allowedModes] - Create a box in the editor menu where
 * the user can switch between the specified modes.
 * @property {(string|PropTypes.elementType)} [tag='div'] - Html element, or react element to render
 * @property {object} [htmlElementProps] - html element custom props
 * @property {Function} [innerRef] - callback to get html element reference
 */
export default class Editor extends Component {
	static propTypes = {
		//  jsoneditor props
		value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		mode: PropTypes.oneOf(values),
		name: PropTypes.string,
		schema: PropTypes.object,
		schemaRefs: PropTypes.object,

		onChange: PropTypes.func,
		onError: PropTypes.func,
		onModeChange: PropTypes.func,

		ace: PropTypes.object,
		ajv: PropTypes.object,
		theme: PropTypes.string,
		history: PropTypes.bool,
		navigationBar: PropTypes.bool,
		statusBar: PropTypes.bool,
		search: PropTypes.bool,
		allowedModes: PropTypes.arrayOf(PropTypes.oneOf(values)),

		//  custom props
		tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
		htmlElementProps: PropTypes.object,
		innerRef: PropTypes.func,
	};

	static defaultProps = {
		tag: 'div',
		mode: modes.tree,
		history: false,
		search: true,
		navigationBar: true,
		statusBar: true,
	};

	/**
	 * @type TJsonEditorModes
	 */
	static modes = modes;

	QUERY_TIMEOUT = 500;
	queryInProgress;
	lastUpdateTime;
	queryJSON;

	constructor(props) {
		super(props);

		this.htmlElementRef = null;
		this.jsonEditor = null;

		this.handleChange = this.handleChange.bind(this);
		this.handleValidate = this.handleValidate.bind(this);  // T5806
		this.setRef = this.setRef.bind(this);
		this.collapseAll = this.collapseAll.bind(this);
		this.expandAll = this.expandAll.bind(this);
		this.focus = this.focus.bind(this);
	}

	componentDidMount() {
		const {
			allowedModes,
			innerRef,
			htmlElementProps,
			tag,
			onChange,
			...rest
		} = this.props;

		this.createEditor({
			...rest,
			modes: allowedModes
		});
	}

	UNSAFE_componentWillReceiveProps({
		allowedModes,
		schema,
		name,
		theme,
		schemaRefs,
		innerRef,
		htmlElementProps,
		tag,
		onChange,
		...rest
	}) {
		if (this.jsonEditor) {
			if (theme !== this.props.theme) {
				this.createEditor({
					...rest,
					theme,
					modes: allowedModes
				});
			} else {
				if (schema !== this.props.schema
					|| schemaRefs !== this.props.schemaRefs
				) {
					this.jsonEditor.setSchema(schema, schemaRefs);
				}

				if (name !== this.jsonEditor.getName()) {
					this.jsonEditor.setName(name);
				}
			}
		}
	}

	shouldComponentUpdate({ htmlElementProps }) {
		return htmlElementProps !== this.props.htmlElementProps;
	}

	componentWillUnmount() {
		if (this.jsonEditor) {
			this.jsonEditor.destroy();
			this.jsonEditor = null;
		}
	}

	setRef(element) {
		this.htmlElementRef = element;
		if (this.props.innerRef) {
			this.props.innerRef(element);
		}
	}

	createEditor({ value, defaultValue, ...rest }) {
		if (this.jsonEditor) {
			this.jsonEditor.destroy();
		}

		this.jsonEditor = new JSONEditor(this.htmlElementRef, {
			onChange: this.handleChange,
			onValidate: this.handleValidate,
			...rest
		});

		if (!value && defaultValue) {
			this.jsonEditor.set(defaultValue);
			this.props.onChange(defaultValue);  // T5723
		} else {
			const stringified = JSON.stringify(value);
			const originalJson = JSON.parse(stringified);
			return this.resolveSchemaRefs(value, this.lastUpdateTime).then((result) => {
				if (this.jsonEditor) {
					this.jsonEditor.set(originalJson);
				}
				this.props.onChange(value, originalJson);
			});
		}
  }

 	// T6060
	resolveSchemaRefs(currentJson, timestamp) {
    return new Promise((resolve, reject) =>
      proxiaSprutUtils.flattenJSON(currentJson)
        .catch(error => resolve({error, timestamp}))
        .then(success => resolve({error: null, timestamp}))
    );
  }

	// T5535
	validateSchema(currentJson, timestamp) {
		return new Promise((resolve, reject) =>
			proxiaSprutUtils.validateSchema(currentJson)
				.catch(error => resolve({error, timestamp}))
				.then(success => resolve({error: null, timestamp}))
		);
	}

	handleChange() {
		if (this.props.onChange) {
			try {
				const text = this.jsonEditor.getText();
				if (text === '') {
					this.props.onChange(null, null);
				}
			} catch (err) {
				this.err = err;
			}
		}
	}

	// T5535
	handleValidate(currentJson) {
		if (this.props.value === currentJson) {
			return;
		}
		this.lastUpdateTime = new Date().getTime();
		if (this.queryInProgress) {
			this.queryJSON = null;
			clearTimeout(this.queryInProgress);
		}
		return new Promise(resolve => {
			this.queryInProgress = setTimeout(() => {
				this.queryJSON = currentJson;
				const stringified = JSON.stringify(currentJson);
				const originalJson = JSON.parse(stringified);
				return this.validateSchema(currentJson, this.lastUpdateTime).then((res) => {
					this.queryInProgress = null;
					if (this.lastUpdateTime !== res.timestamp) {
						return resolve([]);
					}
					if (!res.error) {
            return this.resolveSchemaRefs(this.queryJSON, this.lastUpdateTime).then((result) => {
              this.props.onChange(this.queryJSON, originalJson);
              return resolve([]);
            });
					}
					let message = res.error.message;
					// T5806
					if (res.error.message.indexOf('schema is invalid: ') === 0) {
						try {
							const possiblePath = res.error.message.replace('schema is invalid: data', '').split(' ')[0];
							const jsmap = jsonMap.parse(JSON.stringify(currentJson, null, 2));
							const pointer = jsmap.pointers[possiblePath];
							message = `Ln ${pointer.key.line}: ${message}`;
						} catch (err) {
							console.error('parse err', err);
						}
					}
					this.err = res.error;
					return resolve([{path: [], message: message}]);
				});
			}, this.QUERY_TIMEOUT)
		});
	}

	collapseAll() {
		if (this.jsonEditor) {
			this.jsonEditor.collapseAll();
		}
	}

	expandAll() {
		if (this.jsonEditor) {
			this.jsonEditor.expandAll();
		}
	}

	focus() {
		if (this.jsonEditor) {
			this.jsonEditor.focus();
		}
	}

	render() {
		const {
			htmlElementProps,
			tag
		} = this.props;

		return React.createElement(
			tag,
			{
				...htmlElementProps,
				ref: this.setRef
			}
		);
	}
}

export const EditorWrapper = ({record, schemaName, flattenSchemaName, defaultValue, innerRef, changeCallback, compactView, ...props}) => {
	const form = useForm();
	const translate = useTranslate();
	const height = (props.height) ? props.height : '500px';
	const handleEditorChange = (flattened, data) => {
		if (!record) {
			return;
		}
		record[schemaName] = data;
		if (flattenSchemaName) {
			record[flattenSchemaName] = flattened;
		}
		form.change(schemaName, data);
		if (flattenSchemaName) {
			form.change(flattenSchemaName, flattened);
		}
		if (changeCallback) {
			changeCallback(form, record);
		}
	};

	return compactView ?
		<Editor
			htmlElementProps={{style: { height: height }}}
			value={record[schemaName]}
			defaultValue={defaultValue}
			onChange={handleEditorChange}
			ref={innerRef}
			mode='code'
		/> :
		(
		<Box mb={1} mt={1}>
			<p>{translate(`app.labels.${schemaName}`)}</p>
			<Editor
				htmlElementProps={{style: { height: height }}}
				value={record[schemaName]}
				defaultValue={defaultValue}
				onChange={handleEditorChange}
				ref={innerRef}
				mode='code'
			/>
		</Box>
	);
}
