import React from 'react';
import {
  SimpleForm,
  TextInput,
} from 'react-admin';
import {makeStyles} from "@material-ui/styles";

import CustomTitle from '../custom/CustomTitle'; // T5275
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const VendorCreate = props => {
  const classes = useStyles();
  return (
    <CreateWithBreadcrumbs
      title={<CustomTitle titleKey="resources.vendors.titles.newVendor" {...props} />}
      record={{}}
      {...props}
    >
      <SimpleForm>
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            <TextInput fullWidth source="name" label="app.labels.name" />
            <TextInput fullWidth source="description" label="app.labels.description" />
          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  );
}

export default VendorCreate;
