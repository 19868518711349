import ObjectIcon from '@material-ui/icons/Business';
import ObjectList from './ObjectList';
import ObjectEdit from './ObjectEdit';
import ObjectCreate from './ObjectCreate';

const resources = {
  list: ObjectList,
  edit: ObjectEdit,
  create: ObjectCreate,
  icon: ObjectIcon
};

export default resources;
