import React, { Fragment } from "react";
import {
  TextInput,
  AutocompleteInput,
  FormDataConsumer,
  SimpleForm, FileInput, SelectInput, useTranslate, BooleanInput
} from "react-admin";

import {makeStyles} from "@material-ui/styles";

import CustomEditTitle from "../custom/CustomTitle"; // T5275

import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import {AGG_INTERVALS} from "../custom/utils"; // T6206
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  downloadLink: {
    display: 'inline-block'
  },
  formPreview: {
    border: "1px solid rgba(0, 0, 0, 0.09)",
    borderRadius: "4px",
    paddingTop: "10px",
    paddingLeft: "10px",
    boxSizing: "border-box"
  }
});

const ReportTemplateEdit = props => {
  const classes = useStyles();

  const translate = useTranslate();
  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.reportTemplate" {...props} />}
      {...props}
    >
      <SimpleForm>
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            <FormDataConsumer>
              {({ formData }) => {
                let downloadHref;
                let templateTitle;
                if (formData.templateFile && formData.templateFile.src) {
                  const extension = formData.templateFile.title.split('.').pop();
                  if (formData.templateFile.src.type === "Buffer") {
                    let mimeType;
                    if (extension === 'odt') {
                      mimeType = "application/vnd.oasis.opendocument.text"
                    } else if (extension === 'docx') {
                      mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    } else if (extension === 'xlsx') {
                      mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    }
                    if (!mimeType) {
                      console.error('wrong file extension', formData.templateFile.title);
                      return;
                    }
                    const blob = new Blob([Buffer.from(formData.templateFile.src)], {type: mimeType});
                    downloadHref = window.URL.createObjectURL(blob);
                  } else {
                    downloadHref = formData.templateFile.src;
                  }
                  templateTitle = formData.templateFile.title;
                }
                return (
                  <Fragment>
                    <BooleanInput source="isActive" label="app.labels.isActive"/>
                    <BooleanInput source="isAlertReport" label="app.labels.isAlertReport"/>
                    <TextInput
                      source="name"
                      label="app.labels.name"
                      fullWidth
                    />
                    <FileInput
                      record={formData}
                      source="templateFile"
                      placeholder={translate("resources.report-templates.inputs.dropFile")}
                      multiple={false}
                      label={translate("resources.report-templates.inputs.uploadTemplate")}
                      accept="application/vnd.oasis.opendocument.text, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    >
                      <a source="src" href={downloadHref} download={templateTitle} title="title" target="_blank" rel="noopener noreferrer">
                        {templateTitle}
                      </a>
                    </FileInput>
                    <SelectInput
                      source="permission"
                      label="app.labels.templatePermission"
                      choices={[
                        {
                          id: "author",
                          name: translate(
                            "resources.report-templates.permission.author"
                          )
                        },
                        {
                          id: "all",
                          name: translate(
                            "resources.report-templates.permission.all"
                          )
                        },
                        {
                          id: "client",
                          name: translate(
                            "resources.report-templates.permission.client"
                          )
                        }
                      ]}
                      fullWidth
                    />
                    <SelectInput
                      label="app.configurator.aggInterval"
                      source="aggInterval"
                      choices={AGG_INTERVALS.map(item => (
                        {id: item, name: translate(`app.configurator.aggIntervalChoices.${item}`)}
                      ))}
                    >
                    </SelectInput>
                    {formData.permission === "client" ? (
                      <ReferenceInputWithFilter
                        label="app.labels.client"
                        source="clientUuid"
                        reference="clients"
                      >
                        <AutocompleteInput
                          optionText="name"
                          options={{
                            fullWidth: true
                          }}
                        />
                      </ReferenceInputWithFilter>
                    ) : null}
                    <ReferenceInputWithFilter
                      label="app.labels.messageType"
                      source="messageTypeUuid"
                      reference="messages-types"
                      filter={{}}
                    >
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                  </Fragment>
                )}
              }
            </FormDataConsumer>
          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </EditWithBreadcrumbs>
  );
}

export default ReportTemplateEdit;
