import React, {Fragment} from "react";
import { Edit as RAEdit, SaveButton as RASaveButton, DeleteButton as RADeleteButton, BulkDeleteButton as RABulkDeleteButton } from "react-admin";
//import { default as RASaveButton } from "ra-ui-materialui/esm/button/SaveButton";

// T5095
const setUndoable = (props) => {
	const {undoable, ...rest} = props;
	const _undoable = (typeof undoable === 'undefined') ? false : undoable;
	return {undoable: _undoable, ...rest}
};

export const Edit = (props) => RAEdit(setUndoable(props));

export const SaveButton = (props) => (<RASaveButton {...setUndoable(props)}></RASaveButton>);

export const DeleteButton = (props) => RADeleteButton(setUndoable(props));

export const BulkDeleteButton = (props) => (<RABulkDeleteButton {...setUndoable(props)}></RABulkDeleteButton>);

export const BulkDeleteAction = (props) => (<Fragment><BulkDeleteButton {...props} /></Fragment>);
