import React, { useState, useEffect, useCallback } from 'react';
import {
  GET_LIST,
  GET_MANY_REFERENCE,
  withDataProvider,
  showNotification,
  Datagrid,
  DatagridBody,
  TextField,
  ArrayField,
  Loading
} from 'react-admin';
import { useDispatch } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

const linkRoles = (allRoles, userRoles, state, setState) => {
  allRoles.reduce((prev, role) => {
    userRoles.some(userRole => {
      if (role.id === userRole.id) {
        role.enabled = true;
        role.UsersToRoles = userRole.UsersToRoles;
        return true;
      } else {
        role.enabled = false;
        return false;
      }
    });

    prev.push(role);

    return prev;
  }, []);

  allRoles.forEach(role => {
    state[role.name] = role.enabled;
  });

  setState(state);

  return allRoles;
};

const AppDatagridRow = props => {
  const {
    record,
    resource,
    id,
    children,
    basePath,
    state: { state, setState }
  } = props;

  const onToggleItem = record => event => {
    state[record.name] = record.enabled = event.target.checked;
    setState({ ...state });
  };

  return (
    <TableRow key={record.id}>
      {/* first column: selection checkbox */}
      <TableCell padding="none">
        <Switch
          checked={state[record.name]}
          value={record.name}
          onChange={onToggleItem(record)}
        />
      </TableCell>
      {/* data columns based on children */}
      {React.Children.map(children, field => (
        <TableCell key={`${id}-${field.props.source}`}>
          {React.cloneElement(field, {
            record,
            basePath,
            resource
          })}
        </TableCell>
      ))}
    </TableRow>
  );
}

const AppDatagridBody = props => (
  <DatagridBody {...props} row={<AppDatagridRow state={props.state} />} />
);
const AppDatagrid = props => (
  <Datagrid {...props} body={<AppDatagridBody state={props.state} />} />
);

const UserRoles = props => {
  const { source, resource, dataProvider, record } = props;
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isRolesFetched, setIsRolesFetched ] = useState(false);
  const [ state, setState ] = useState({});
  const dispatch = useDispatch();

  const fetchRoles = useCallback(() => {
    const resourceId = record.id;
    let [allRoles, userRoles] = [[], []];

    // First get all permissions list
    const getAllRoles = dataProvider(GET_LIST, source, {
      pagination: { perPage: -1 }
    });
    const getUserRoles = resourceId
      ? dataProvider(GET_MANY_REFERENCE, resource, {
          id: resourceId,
          target: source,
          pagination: { perPage: -1 }
        })
      : null;

    return Promise.all([getAllRoles, getUserRoles]).then(response => {
      allRoles = response[0].data;
      userRoles = response[1] ? response[1].data : [];

      // Combine allRoles and userRoles together to form linked list
      const linkedRoles = linkRoles(allRoles, userRoles, state, setState);
      return linkedRoles;

    }).catch(err => {
      dispatch(showNotification(err.message));
      throw err;
    });
  }, [dataProvider, dispatch, resource, source, record, state, setState]);

  useEffect(() => {
    if (isRolesFetched && record.roles && record.roles.length) {
      return;
    }

    setIsLoading(true);
    fetchRoles().then(roles => {
      record.roles = roles;
      setIsRolesFetched(true);
      setIsLoading(false);
    });
  }, [record, fetchRoles, setIsLoading, isRolesFetched, setIsRolesFetched]);

  return (
    <section>
      {isLoading ? (
        <Loading />
      ) : (
        <ArrayField record={record} source="roles">
          <AppDatagrid state={{state, setState}}>
            <TextField source="name" />
          </AppDatagrid>
        </ArrayField>
      )}
    </section>
  );

}

export default withDataProvider(UserRoles);
