import React from "react";

import SignalWifi4BarIcon from "@material-ui/icons/SignalWifi4Bar";
import SignalWifi0BarIcon from "@material-ui/icons/SignalWifi0Bar";
import WarningIcon from "@material-ui/icons/Warning";
import PropTypes from "prop-types";
import { Button } from "react-admin";
import {makeStyles} from "@material-ui/styles";

const buttonStyles = makeStyles({
	button: {
		color: "rgba(0, 0, 0, 0.87)",
	}
});

export const OnlineField = ({ source, record = {} }) => {

  if (!record.lastPacketReceivedAt) {
    return null;
  }

	const isOnline = record[source];
	const classes = buttonStyles();
	let icon;
	if (isOnline === true) {
		icon = <SignalWifi4BarIcon />;
	} else if (isOnline === false) {
		icon = <SignalWifi0BarIcon />;
	} else {
		icon = <WarningIcon />;
	}

	const text = new Date(record.lastPacketReceivedAt).toLocaleString();
	return (
		<Button
			className={classes.button}
			label={text}
      title={record.lastPacketReceivedAt}
      align="left"
		>
			{icon}
		</Button>
	);
};

OnlineField.propTypes = {
	record: PropTypes.object,
	source: PropTypes.string.isRequired,
};
