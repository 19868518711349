import React, { useState, Fragment } from 'react';
import Box from '@material-ui/core/Box';
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import proxiaSprutUtils from 'proxia-sprut-utils';
import { useForm } from 'react-final-form';
import {
  FormTab,
  TextInput,
  AutocompleteInput,
  DateField,
  BooleanInput,
  useTranslate,
  withDataProvider,
  GET_ONE,
  SelectInput,
  usePermissions,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
} from "react-admin";

import SchemaParamsForm from '../custom/SchemaParamsForm';
import SaveWithSchemaToolbar from '../custom/SaveWithSchemaToolbar'; // T5317
import VersionInput from '../custom/VersionInput'; // T5154
import ChangeAclButton from './ChangeAclButton';
import AclForm from '../custom/AclForm';
import { ToggleParamsEditorViewButton } from '../custom/ToggleParamsEditorViewButton';
import LazyFormTab from '../custom/LazyFormTab';

import { ChildDevicesList } from './ChildDevicesList';
import DeviceData from './DeviceData';
import VirtualDeviceData from './VirtualDeviceData';
import { EditorWrapper } from "../custom/JSONEditor"; // T5711
import DeviceRuleAssignments from "./DeviceRuleAssignments" // T6065
import { AGG_MEASUREMENT_CHOICES } from "../custom/utils";
import OpenDashboard from '../dashboards/OpenDashboard';
import SanitizedGrid from "../custom/SanitizedGrid";
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import TabbedFormWithModel from '../custom/TabbedFormWithModel'
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import DeviceLogsList from './logs/DeviceLogsList';
import DeviceDataPreview from './data/DeviceDataPreview';

const DeviceTitle = props => {
  const { record } = props;
  const translate = useTranslate();
  return (
    <span>
      {translate('app.labels.device')} {record && `"${record.name}"`}
    </span>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const DevicePreviewData = withDataProvider(props => {
  const translate = useTranslate();
  const { record, history, match } = props;
  const classes = useStyles();

  const [messageType, setMessageType] = useState(null);

  const handleMessageTypeChange = (value) => {
    if (!value) {
      setMessageType(null);
      return;
    }
    props.dataProvider(GET_ONE, 'messages-types', {
      id: value,
    }).then(result => {
      setMessageType(result.data);
    });
  }
  const parameters = (messageType &&
    messageType.configurator &&
    messageType.configurator.parameters) || [
    { name: 'rawData', path: 'payload' },
    { name: 'receivedAt', path: 'receivedAt', type: 'timestamp', formatTimestamp: true }
  ];

  return (
    <Fragment>
      <Grid
        justify="flex-start"
        container={true}
        spacing={2}
        className={classes.root}
      >
        <Grid item lg={6} md={12}>
          <Box component="div" display="div">
            <ReferenceInputWithFilter
              label="resources.devices.titles.chooseMessageType"
              allowEmpty={true}
              emptyText={translate('resources.devices.titles.allMessageType')}
              resource="messages-types"
              reference="messages-types"
              source="_messageTypeUuid"
              filter={{
                deviceModelUuid: record.deviceModelUuid
              }}
              onChange={handleMessageTypeChange}
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
          </Box>
          <Box component="div" display="div">
            <DeviceData
              tableView={false}
              debounceDelay={300}
              source="stats"
              deviceUuid={record.id}
              messageTypeUuid={messageType && messageType.uuid}
              parameters={parameters}
              history={history}
              match={match}
              perPage={50}
              sort={{ field: 'receivedAt', order: 'desc' }}
            />
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
});

const AclTab = props => {

  const classes = useStyles();
  const form = useForm();
  const [aclEditorView, setAclEditorView] = useState('form');

  const record = form.getState().values;

  let [schemaValue, setSchemaValue] = useState(record.acl || {});

  const handleChange = (value, record, source) => form.change(source, value);

  const handleRegenerate = (value, source) => {
    form.change(source, value);
    setSchemaValue(value);
  }

  // calculate topic on each form change
  const topic = proxiaSprutUtils.topicNameGenerator(
    record,
    {
      pattern: 'fd/{mqttId}{attributesArray}',
      attributesDelimiter: '/',
      logger: console
    }
  );

  // update topic only if it changed
  if (record.topic !== topic) {
    console.info('DEVICE TOPIC', topic); // by STM пусть в консоль вылезает на всякий пожарный
    form.change('topic', topic);
  }

  if (record.parentUuid) {
    return (
      <TextInput fullWidth disabled source="topic" label="app.labels.topic" />
    );

  } else {

    return (
      <Grid
        justify="flex-start"
        container={true}
        spacing={2}
        className={classes.root}
      >
        <Grid item lg={6} md={12}>
          <ToggleParamsEditorViewButton
            value={aclEditorView}
            handleStateFn={setAclEditorView}
          />
          {aclEditorView === 'json' ? (
            <EditorWrapper
              schemaName="acl"
              record={record}
              defaultValue={{ "user": "", "password": "" }}
            />
          ) : (
            <AclForm
              source="acl"
              label="app.labels.acl"
              record={record}
              callback={handleChange}
              schemaValue={schemaValue}
              {...props}
            />
          )}
          <TextInput
            disabled
            fullWidth
            source="topic"
            label="app.labels.topic"
          />
          <ChangeAclButton {...props} onChange={handleRegenerate}/>
        </Grid>
      </Grid>
    );
  }
}

const DeviceEdit = ({
  hasCreate,
  hasShow,
  hasList,
  hasEdit,
  dataProvider,
  ...props
}) => {
  const { permissions } = usePermissions();
  const [deviceModel, setDeviceModel] = useState(null);
  const handleModelChange = (value) => {
    if (!value) {
      if (deviceModel) {
        setDeviceModel(null);
      }
      return;
    }
    if (deviceModel && deviceModel.uuid === value) {
      return;
    }
    dataProvider(GET_ONE, 'devices-models', {
      id: value,
    }).then(result => {
      setDeviceModel(result.data);
    });
  }

  const translate = useTranslate();

  const classes = useStyles();

  const notAdmin = false;

  let virtualMapParamInitialValue = `${new Date().getTime()}`;

  return (
    <EditWithBreadcrumbs
      title={<DeviceTitle translate={translate} />}
      {...props}
    >
      <TabbedFormWithModel
        stateModel={deviceModel}
        setStateModel={setDeviceModel}
        modelName="model"
        toolbar={
          <SaveWithSchemaToolbar
            schemaFieldName="modelSchemaParams"
          />
        }
        redirect="edit"
      >
          <FormTab label="resources.devices.tabs.device">
          <SanitizedGrid
            justify="flex-start"
            container={true}
            spacing={2}
            className={classes.root}
          >
            <SchemaParamsForm
              schemaType="model"
              schemaName="modelSchemaParams"
              schemaSource={deviceModel}
              {...props}
            />
            <Grid item lg={6} md={6} xs={12}>
              <VersionInput fullWidth />
              <TextInput source="uuid" disabled fullWidth />
              <BooleanInput
                disabled={notAdmin}
                source="isActive"
                label="app.labels.isActive"
              />
              <BooleanInput
                disabled={notAdmin}
                source="isVirtual"
                label="app.labels.isVirtual"
              />
              <SelectInput
                source="messagesFreq"
                label="app.labels.messagesInterval"
                fullWidth
                choices={AGG_MEASUREMENT_CHOICES} />
              <TextInput
                source="name"
                label="app.labels.name"
                fullWidth
              />
              <DateField
                source="createdAt"
                label="app.labels.createdAt"
                fullWidth
              />
              <DateField
                source="updatedAt"
                label="app.labels.updatedAt"
                fullWidth
              />
              <ReferenceInputWithFilter
                label="app.labels.client"
                source="clientUuid"
                reference="clients"
              >
                <AutocompleteInput
                  optionText="name"
                  options={{
                    fullWidth: true
                  }}
                />
              </ReferenceInputWithFilter>
              <ReferenceInputWithFilter
                label="app.labels.deviceModels"
                source="deviceModelUuid"
                reference="devices-models"
                onChange={handleModelChange}
                perPage={1}
              >
                <AutocompleteInput
                  disabled
                  optionText="name"
                  optionValue="uuid"
                  options={{
                    fullWidth: true
                  }}
                />
              </ReferenceInputWithFilter>
              <ReferenceInputWithFilter
                allowEmpty={true}
                label="app.labels.object"
                source="objectUuid"
                reference="objects"
                filter={{}}
              >
                <AutocompleteInput
                  optionText="name"
                  options={{
                    fullWidth: true
                  }}
                />
              </ReferenceInputWithFilter>
              <ReferenceInputWithFilter
                allowEmpty={true}
                label="app.labels.parentDevice"
                source="parentUuid"
                reference="devices"
                filter={{ uuid: { $ne: props.id } }}
              >
                <AutocompleteInput
                  optionText="name"
                  options={{
                    fullWidth: true
                  }}
                />
              </ReferenceInputWithFilter>
            </Grid>
          </SanitizedGrid>
        </FormTab>
        <LazyFormTab label="resources.devices.tabs.acl" path="acl" showIf={(r) => !r.isVirtual}>
          <AclTab {...props} />
        </LazyFormTab>
        <LazyFormTab label="resources.devices.tabs.children" path="children" showIf={(r) => !r.isVirtual}>
          <ChildDevicesList hasList={hasList} hasShow={hasShow} {...props} />
        </LazyFormTab>
        { permissions && permissions['devices.stats.get'] &&
        <LazyFormTab label="resources.devices.tabs.data" path="data" showIf={(r) => !r.isVirtual}>
          <DeviceDataPreview deviceUuids={[props.id]} {...props} />
          {/*<DevicePreviewData />*/}
        </LazyFormTab>
        }
        <LazyFormTab label="resources.devices.tabs.management" path="management" showIf={(r) => !r.isVirtual}>
          <DeviceRuleAssignments {...props} />
        </LazyFormTab>
        <LazyFormTab label="resources.devices.tabs.dashboard" path="dashboard">
          <OpenDashboard type={{ $eq: "device" }} deviceUuid={props.id} />
        </LazyFormTab>
        <LazyFormTab label="resources.devices.tabs.logs" path="logs" showIf={(r) => !r.isVirtual}>
          <DeviceLogsList deviceUuids={[props.id]} {...props} />
        </LazyFormTab>
        <LazyFormTab label="resources.devices.tabs.virtualMapping" path="virtualmapping" showIf={(r) => r.isVirtual}>
          <FormDataConsumer>
          {({formData}) => {
          if (formData.configurator.virtualMapping && formData.configurator.virtualMapping.find(i => i && i.id === virtualMapParamInitialValue)) {
            virtualMapParamInitialValue = `${new Date().getTime()}`;
          }
          return (
          <ArrayInput source="configurator.virtualMapping" label="app.labels.virtualMapping">
            <SimpleFormIterator>
              <TextInput source="id" label="app.labels.Id" initialValue={virtualMapParamInitialValue} style={{display: 'none'}} />
              <TextInput source="name" label="app.labels.name" fullWidth/>
              <BooleanInput source="isLinkToParam" label="app.labels.isLinkToParam" fullWidth initialValue={true}  style={{display: 'none'}} />

              <ReferenceInputWithFilter
                label="app.labels.virtualParam"
                source="existedParam"
                allowEmpty={true}
                reference="configurator-parameters"
                fullWidth
                filterToQuery={text => ({
                  name: text,
                  deviceUuid: formData.parentUuid,
                  objectUuid: formData.objectUuid,
                })}
              >
                <AutocompleteInput
                  optionText="name"
                  optionValue="id"
                  options={{
                    fullWidth: true
                  }}
                />
              </ReferenceInputWithFilter>

              <TextInput source="postProcessJs" label="app.configurator.parameters.postProcess" helperText={translate('app.configurator.parameters.postProcessHelperText')} fullWidth />

            </SimpleFormIterator>
          </ArrayInput>
          );}}
          </FormDataConsumer>
        </LazyFormTab>
        <LazyFormTab label="resources.devices.tabs.data" path="virtualdevicedata" showIf={(r) => r.isVirtual}>
          <FormDataConsumer>
            {({formData}) => (
                <VirtualDeviceData
                  debounceDelay={300}
                  deviceUuid={props.id}
                  parameters={formData.configurator.virtualMapping}
                  perPage={50}
                  sort={{ field: 'receivedAt', order: 'desc' }}
                />
            )}
          </FormDataConsumer>
        </LazyFormTab>
      </TabbedFormWithModel>
    </EditWithBreadcrumbs>
  );
}


export default withDataProvider(DeviceEdit);
