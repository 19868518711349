import React from 'react';
import {
  TextField,
} from 'react-admin';

import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";

const TypicalSchemaList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.typical-schema" {...props} />}
    bulkActionButtons={<BulkDeleteAction />}
    filters={<StyledSearchFilter source="name,description" />}
    filter={{
      embedded: {}
    }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="alias" />
      <TextField source="description" />
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default TypicalSchemaList;
