import TypicalSchemaIcon from '@material-ui/icons/Timeline';
import TypicalSchemaList from './TypicalSchemaList';
import TypicalSchemaEdit from './TypicalSchemaEdit';
import TypicalSchemaCreate from './TypicalSchemaCreate';

const resources = {
  list: TypicalSchemaList,
  edit: TypicalSchemaEdit,
  create: TypicalSchemaCreate,
  icon: TypicalSchemaIcon
};

export default resources;