import React from 'react';
import {
  SimpleForm,
  BooleanInput,
  TextInput
} from 'react-admin';
import {makeStyles} from "@material-ui/styles";

import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const MeasureUnitEdit = props => {
  const classes = useStyles();
  return (
  <EditWithBreadcrumbs
    title={<CustomEditTitle titleKey="app.labels.measureUnit" {...props} />}
    {...props}
  >
    <SimpleForm redirect="edit">
      <SanitizedGrid
        justify="flex-start"
        container={true}
        spacing={2}
        className={classes.root}
      >
        <SanitizedGrid item lg={6} md={12}>
          <BooleanInput source="isActive" label="app.labels.isActive" />
          <TextInput fullWidth source="name" label="app.labels.name" />
          <TextInput fullWidth source="symbol" label="app.labels.symbol" />
          <TextInput fullWidth source="description" label="app.labels.description" />
          <TextInput fullWidth source="type" label="app.labels.type" />
        </SanitizedGrid>
      </SanitizedGrid>
    </SimpleForm>
  </EditWithBreadcrumbs>
)};

export default MeasureUnitEdit;
