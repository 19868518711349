import React from 'react';
import {
  TextInput,
  AutocompleteInput,
  SimpleForm,
  FormDataConsumer
} from "react-admin";

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { CodeEditor } from '../custom/CodeEditor';
import CustomTitle from '../custom/CustomTitle'; // T5275
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const RuleCreate = props => {
  const classes = useStyles();

  return (
    <CreateWithBreadcrumbs
      title={
        <CustomTitle titleKey="resources.rules.titles.newRule" {...props} />
      }
      record={{}}
      {...props}
    >
      <SimpleForm>
        <Grid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <Grid item lg={6} md={12}>
            <FormDataConsumer>
              {formDataProps => {
                return (
                  <div>
                    <TextInput source="uuid" fullWidth />
                    <TextInput source="name" label="app.labels.name" fullWidth />
                    <TextInput
                      source="description"
                      label="app.labels.description"
                      fullWidth
                    />
                    <ReferenceInputWithFilter
                      label="app.labels.client"
                      source="clientUuid"
                      reference="clients"
                    >
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                    <CodeEditor
                      record={formDataProps.formData}
                      width="100%"
                      source="code"
                      label="app.labels.code"
                      placeholder="rule RuleName { when { ... } then { ... } }"
                      mode="markdown"
                    />
                  </div>
                );
              }}
            </FormDataConsumer>
          </Grid>
        </Grid>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  );
}

export default RuleCreate;
