import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  SimpleShowLayout,
  TextField,
  DateField
} from 'react-admin';

const styles = {
  response: { maxWidth: '70%' }
};

const UserLogsShow = ({ record, classes, ...props }) => (
  <SimpleShowLayout record={record} {...props}>
    <TextField source="type" label="app.labels.type" />
    <TextField source="login" label="app.labels.login" />
    <TextField source="phone" label="app.labels.phone" />
    <TextField source="ip" label="app.labels.ip" />
    <TextField source="userAgent" label="app.labels.userAgent" />
    <DateField source="createdAt" label="app.labels.createdAt" />
    <TextField source="description" label="app.labels.description" />
    <TextField source="data" label="app.labels.data" />
    <TextField
      source="response"
      label="app.labels.response"
      className={classes.response}
    />
    <TextField source="responseCode" label="app.labels.responseCode" />
  </SimpleShowLayout>
);

export default withStyles(styles)(UserLogsShow);