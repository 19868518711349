import React, { Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  withDataProvider,
  Loading,
  translate,
  Labeled,
  showNotification
} from 'react-admin';
import {Grid} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import {
  DateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import { UPDATE_REFERENCES } from '../../providers/dataProvider'

const AGG_INTERVAL_FORMAT_VALUE = {
  '1y': v => v.slice(0, 4),
  '1q': v => v.slice(0, 7),
  '1M': v => v.slice(0, 7),
  '1w': v => v.slice(0, 10),
  '1d': v => v.slice(0, 10),
  '1h': v => v.slice(0, 13).replace('T', ' '),
  '1m': v => v.slice(0, 7),
  '1s': v => v.slice(0, 19).replace('T', ' '),
}
const AGG_INTERVALS = ['all'].concat(Object.keys(AGG_INTERVAL_FORMAT_VALUE));
const DEFAULT_AGG_INTERVAL = '1d';

const defaultFromTime = () => {
  return (new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000)).toISOString().slice(0, 16)
};

const styles = {
  root: {
    width: "100%"
  },
  cell: {
    '& .statusOk': {
      color: 'green'
    },
    '& .statusError': {
      color: 'red'
    }
  },
  headerCell: {
    fontWeight: 'bold'
  }
};

class VirtualDevicePreviewData extends React.Component {
  constructor(props) {
    super(props);
    this.debounceDelay = props.debounceDelay || 600;
    this.handleChangePage = this.handleChangePage.bind(this);
    this.optionsDateFormat = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'};
    this.handleChangeAggInterval = this.handleChangeAggInterval.bind(this);
    this.handleChangeFromTime = this.handleChangeFromTime.bind(this);
    this.handleChangeToTime = this.handleChangeToTime.bind(this);

    this.state = {
      isLoading: false,
      page: 0,
      total: 0,
      tableView: props.tableView,
      aggInterval: props.messageTypeUuid ? DEFAULT_AGG_INTERVAL : 'all',
      fromTime: defaultFromTime(),
      toTime: null,
      data: [],
      parametersJSON: JSON.stringify(props.parameters || []),
    };
  }

  handleChangePage (e, page) {
    this.setState({page});
    this.fetchDataDebounce();
  }

  reset() {
    this.startForCheckingMls = 0;
    this.setState({ isLoading: false });
  };

  fetchDataDebounce() {
    if (this.debounceId) {
      clearTimeout(this.debounceId);
    }
    this.debounceId = setTimeout(() =>
      this._fetchData()
      .then(response => {
        if (response.data.length > 0 ){
          this.setState({ data: response.data, total: response.total });
        }

        if (!this.startForCheckingMls) {
          this.setState({ isLoading: false });
        }
      })
      .catch(err => {
        showNotification(err.message);
        throw err;
      }), this.debounceDelay);
  }

  _fetchData() {
    const { page, aggInterval, fromTime, toTime } = this.state;
    const {
      timeParameterPath,
      perPage,
      dataProvider,
      deviceUuid,
      sort,
    } = this.props;
    if (deviceUuid === undefined) {
      return;
    }
    this.setState({ isLoading: true });
    return dataProvider(UPDATE_REFERENCES, 'devices', {
      id: deviceUuid,
      target: 'stats',
      data: {
        timeParameterPath,
        aggInterval: aggInterval !== 'all' ? aggInterval : null,
        begin: fromTime ? Date.parse(fromTime) : null,
        end: toTime ? Date.parse(toTime) : null,
        offset: page * perPage,
        limit: perPage,
        order:  sort || {
          field: 'receivedAt',
          order: 'asc'
        },
        parameters: []
      }
    })
    .catch(err => {
      showNotification(err.message);
      throw err;
    });
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.deviceUuid !== prevProps.deviceUuid) {
      this.setState({ page: 0 });
      this.fetchDataDebounce();
      return null;
    }

    return null;
  }

  doRefresh(options) {
    this._fetchData().then(response => {
      if (response.data.length > 0 ){
        this.setState({ data: response.data, total: response.total });
      }

      if (!this.startForCheckingMls) {
        this.setState({ isLoading: false });
      }
    });
  }

  componentDidUpdate() {}

  componentDidMount() {
    this.fetchDataDebounce();

    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  compactData(data) {
    const res = [];
    data.forEach(item => {
      const tasksByRule = {};

      item.tasks.forEach(task => {
        if (tasksByRule[task.taskProperties.source.invokedByRuleName]) {
          tasksByRule[task.taskProperties.source.invokedByRuleName].push(task);
        } else {
          tasksByRule[task.taskProperties.source.invokedByRuleName] = [task];
        }
      });

      item.rulesProcessed.forEach(rule => {

        res.push({
          id: item.id,
          source: rule.source,
          status: rule.status,
          deviceId: item.deviceId,
          timestamp: rule.startedAt,
          duration: rule.executionTime,
          name: rule.ruleName,
          output: rule.outputLogs && rule.outputLogs.map(i => `[${i.status}] ${i.message}`).join('\n'),
          payload: '',
        });

        const tasks = tasksByRule[rule.ruleName];
        if (tasks) {
          tasks.forEach(task => {
            res.push({
              id: item.id,
              source: task.source,
              status: task.status,
              deviceId: item.deviceId,
              timestamp: rule.startedAt,
              duration: rule.executionTime,
              name: task.taskName,
              output: '',
              payload: task.taskProperties.payload,
            });
          });
        }
      });

      const started = new Date(item.createdAt).getTime();
      const finished = new Date(item.finishedAt).getTime();

      res.push({
        id: item.id,
        status: item.status,
        source: item.source,
        deviceId: item.deviceId,
        objectId: item.objectId,
        timestamp: finished,
        duration: finished - started,
        name: 'engine output',
        output: item.outputLogs && item.outputLogs.map(i => `[${i.status}] ${i.message}`).join('\n'),
        payload: '',
      });

    });
    return res;
  }

  renderAggKeyValue(row, aggInterval) {
    const f = AGG_INTERVAL_FORMAT_VALUE[aggInterval]
    if (f !== undefined && row.aggKey) {
      return f(row.aggKey);
    }
    return row.aggKey;
  }

  logEntryToTable(row) {
  }

  renderValue(row, parameter) {
    let value = row[parameter.name];

    if (typeof value === 'object') {
      return (
        <pre>
          <code>{JSON.stringify(value, null, 4)}</code>
        </pre>
      );
    }

    if (value && parameter.type === 'timestamp' && parameter.formatTimestamp) {
      return (
        <pre>
          <code>{new Date(value).toISOString()}</code>
        </pre>
      );
    }

    return value;
  }

  handleChangeAggInterval(e, item) {
    this.setState({ aggInterval: item.key }, () => {
      this.doRefresh();
    });
  }

  handleChangeFromTime(selectedDate) {
    this.setState({ fromTime: selectedDate }, () => {
      this.doRefresh();
    });
  }

  handleChangeToTime(selectedDate) {
    this.setState({ toTime: selectedDate }, () => {
      this.doRefresh();
    });
  }

  render() {
    const { data, total, page, aggInterval, fromTime, toTime } = this.state;
    const { parameters, perPage, translate, classes } = this.props;

    // const compactData = this.state.tableView ? this.compactData(data) : [];

    return (
      <Fragment>
        <Labeled label="app.configurator.aggInterval">
          <Select
            name="aggType"
            value={aggInterval || ''}
            onChange={this.handleChangeAggInterval}
            inputProps={{ name: 'aggType' }}
          >
            {AGG_INTERVALS.map( item => (
              <MenuItem key={item} value={item || ''}>{item ? translate(`app.configurator.aggIntervalChoices.${item}`) : null }</MenuItem>
            ))}
          </Select>
        </Labeled>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <Grid
            justify="flex-start"
            container={true}
            spacing={2}
            className={classes.root}
          >
            <Grid item lg={6} md={12}>
              <Labeled label="app.configurator.fromTime" fullWidth>
                <DateTimePicker value={fromTime} format="yyyy.MM.dd HH:mm" onChange={this.handleChangeFromTime} />
              </Labeled>
            </Grid>
            <Grid item lg={6} md={12}>
              <Labeled label="app.configurator.toTime" fullWidth>
                <DateTimePicker value={toTime} format="yyyy.MM.dd HH:mm" onChange={this.handleChangeToTime} />
              </Labeled>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
        {this.state.isLoading ? (
          <Loading />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                { aggInterval !== 'all'
                  ? <TableCell align="left">{translate(`app.configurator.aggIntervalChoices.${aggInterval}`)}</TableCell>
                  : <TableCell align="left">{translate(`app.configurator.receivedAt`)}</TableCell>
                }
                {(parameters || []).map(p => (
                  <TableCell key={p.name} align="left">
                    {p.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <TableRow key={`${i}-${row.uuid}`}>
                  { aggInterval !== 'all'
                    ? <TableCell key="aggKey" align="left">{this.renderAggKeyValue(row, aggInterval)}</TableCell>
                    : <TableCell key="aggKey" align="left">
                        <pre>
                          <code>{new Date(row['receivedAt']).toLocaleDateString('ru-RU', this.optionsDateFormat)}</code>
                        </pre>
                      </TableCell>
                  }
                  {(parameters || []).map(p => (
                    <TableCell style={{maxWidth: '300px', overflow: 'auto'}} align="left" key={p.name}>
                      {this.renderValue(row, p)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={3}
                  count={total}
                  page={page}
                  rowsPerPage={perPage}
                  rowsPerPageOptions={[]}
                  onChangePage={this.handleChangePage}
                  labelDisplayedRows={
                    aggInterval !== 'all'
                    ? ({ from, to, count }) => `${from}-${to === -1 ? count : to}`
                    : ({ from, to, count }) => `${from}-${to === -1 ? count : to} of ${count}`
                  }
                />
              </TableRow>
            </TableFooter>
          </Table>
        )
      }
      </Fragment>
    );
  }
}

export default 
  withDataProvider(
    translate(
      withStyles(styles)
        (VirtualDevicePreviewData)));
