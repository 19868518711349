import React, { Fragment, useState } from "react";
import { List, withDataProvider } from "react-admin";
import { TreeBreadcrumbs } from "../custom/CustomBreadcrumbs";
import ObjectGridActions from "./helpers/ObjectsListActions";
import ObjectsListFilter from "./helpers/ObjectsListFilter";
import ObjectsListMap from "./views/ObjectsListMap";
import ObjectsListTable from "./views/ObjectsListTable";

const ObjectList = ({ ...props }) => {
  const [viewType, setViewType] = useState("list");

  return (
    <Fragment>
      <TreeBreadcrumbs {...props} />
      <List
        {...props}
        
        bulkActionButtons={false}
        filter={{embedded:{type:true}}}
        filters={<ObjectsListFilter {...props} />}
        sort={{ field: "createdAt", order: "DESC" }}
        actions={
          <ObjectGridActions
            {...props}
            viewType={viewType}
            setViewType={setViewType}
          />
        }
      >
        {viewType === "list" ? (
          <ObjectsListTable
            {...props}
            viewType={viewType}
            setViewType={setViewType}
          />
        ) : (
          <ObjectsListMap
            {...props}
            viewType={viewType}
            setViewType={setViewType}
          />
        )}
      </List>
    </Fragment>
  );
};

export default withDataProvider(ObjectList);
