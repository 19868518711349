import PermissionIcon from '@material-ui/icons/Gavel';
import PermissionList from './PermissionList';
import { PermissionCreate } from "./PermissionCreate";
import { PermissionEdit } from "./PermissionEdit";

const resources = {
  list: PermissionList,
  edit: PermissionEdit,
  create: PermissionCreate,
  icon: PermissionIcon
};

export default resources;
