import React from "react";
import withStyles from '@material-ui/core/styles/withStyles';
import {
	List,
  Datagrid,
  TextField,
  DateTimeInput,
  DateField,
  FunctionField,
  Filter,
  SearchInput,
  AutocompleteInput
} from 'react-admin';
import rowStyle from '../styling/rowStyle';
import JournalDetails from './JournalDetails';
import ReferenceInputWithFilter from "../custom/ReferenceInputWithFilter";

const styles = {
  narrow: {
    maxWidth: '4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  normal: {
  	maxWidth: '6em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  wide: {
  	maxWidth: '12em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
};


function msToTime(duration) {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
}


const JournalList = ({ classes, objectUuids, showDevice = true, showObject = true, showClient = true, ...props }) => {

	return (
		<List resource='logs'
				basePath='/'
				style={{ width: "100%" }} 
				sort={{ field: "start", order: "DESC" }}
				hasShow={false} hasEdit={false} hasCreate={false} hasList={false}
				filters={ 
			    <Filter {...props} resource='logs'>
		        <SearchInput source="rulesProcessed_outputLogs_message" alwaysOn className={classes.input} />
		        <DateTimeInput source="mqttReceivedAt_gte" label="app.labels.from" options={{mode: "portrait", locale: "ru"}} />
		        <DateTimeInput source="mqttReceivedAt_lte" label="app.labels.to" options={{mode: "portrait", locale: "ru"}} />
            {showClient && <ReferenceInputWithFilter label="app.labels.client" source="clientUuid" reference="clients">
              <AutocompleteInput optionText="name" />
            </ReferenceInputWithFilter>}
            {showObject && <ReferenceInputWithFilter label="app.labels.object" source="objectUuid" reference="objects" filter={{}}>
              <AutocompleteInput optionText="name" />
            </ReferenceInputWithFilter>}
            {showDevice && <ReferenceInputWithFilter label="app.labels.devices" source="deviceUuid" reference="devices" >
              <AutocompleteInput optionText="name" />
            </ReferenceInputWithFilter>}
			    </Filter>
				}
        filter={{ 
          objectUuid: objectUuids, 
          collapse: 'serie.keyword',
          embedded: { 
            device: true, 
            object: true 
          } 
        }}>
		  <Datagrid rowStyle={rowStyle('status')} expand={<JournalDetails />}>
		  	<TextField source="id" cellClassName={classes.narrow} sortable={false}/>
		  	<DateField showTime={true} source="createdAt" sortBy="start"
		  		label="app.labels.start" cellClassName={classes.normal}/>
		  	<DateField showTime={true} source="finishedAt" sortBy="finish"
		  		label="app.labels.finish" cellClassName={classes.normal}/>
        <FunctionField label="app.labels.duration" cellClassName={classes.narrow}
            render={record => `${msToTime(record.duration)}`}/>
		    <TextField source="source" label="app.labels.source"
		    	cellClassName={classes.narrow} sortable={false} />
        {showDevice && <TextField source="device.name" label="app.labels.device"
          cellClassName={classes.narrow} sortable={false}/>
        }
        {showObject && <TextField source="object.name" label="app.labels.object"
          cellClassName={classes.normal} sortable={false}/>
        }
        <FunctionField label="app.labels.output" cellClassName={classes.wide}
            render={record => `${record.rulesProcessed
              .filter(rule => rule.outputLogs.length > 0)
              .map(rule => JSON.stringify(rule.outputLogs)) }`}/>
		  </Datagrid>
		</List>);
}

export default withStyles(styles)(JournalList);
