import React from 'react';
import {
  SelectInput,
  ReferenceInput,
  SearchInput,
  TextField,
  BooleanField,
  Filter
} from 'react-admin';

import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import { filterStyles } from "../custom/StyledSearchFilter";

const UserList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.users" {...props} />}
    bulkActionButtons={<BulkDeleteAction />}
    filters={<UserFilter />}
    filter={{
      embedded: {
        profile: true,
        options: true,
        stats: true,
        roles: true
      }
    }}
    {...props}
  >
    <Datagrid>
      <TextField source="id" />
      <BooleanField source="enabled" label="app.labels.enabled" />
      <TextField source="login" label="app.labels.login" />
      <TextField source="phone" label="app.labels.phone" />
    </Datagrid>
  </ListWithBreadcrumbs>
);

const UserFilter = props => {
  const classes = filterStyles();
  return (
    <Filter {...props} className={classes.root}>
      <SearchInput source="login" alwaysOn className={classes.input} />
      <ReferenceInput
        label="User id"
        source="id"
        reference="users"
        allowEmpty
      >
        <SelectInput optionText="id" />
      </ReferenceInput>
    </Filter>
  )
};

export default UserList;
