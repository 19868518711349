import React from 'react';
import {
  BooleanField,
  useTranslate,
} from 'react-admin';

import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import DownloadButton from "../custom/DownloadButton";
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";

const AggIntervalField = props => {
  const translate = useTranslate();
  const { record, source } = props;
  return translate(`app.configurator.aggIntervalChoices.${record[source]}`);
};

const ReportTemplateList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.reportTemplates" {...props} />}
    bulkActionButtons={<BulkDeleteAction />}
    filters={<StyledSearchFilter />}
    filter={{
      embedded: {}
    }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <BooleanField source="isActive" label="app.labels.isActive" />
      <AggIntervalField source="aggInterval" label="app.labels.aggInterval" />
      <DownloadButton source="templateFile" record={props.record} label="resources.report-templates.titles.downloadTemplate" />
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default ReportTemplateList;
