import React, { Fragment } from "react";
import {
  TextInput,
  AutocompleteInput,
  FormDataConsumer,
} from "react-admin";

import {makeStyles} from "@material-ui/styles";
import CustomTitle from "../custom/CustomTitle"; // T5275

import SchemaEditForm from "../custom/SchemaForm"; // T5722
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920

const placeholderItemParamsSchema = {
  "$id": "http://example.com/example.json",
  "type": "object",
  "title": "Schema Title",
  "$schema": "http://json-schema.org/draft-07/schema#",
  "required": [],
  "properties": {},
  "definitions": {}
};

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  formPreview: {
    border: "1px solid rgba(0, 0, 0, 0.09)",
    borderRadius: "4px",
    paddingTop: "10px",
    paddingLeft: "10px",
    boxSizing: "border-box"
  }
});


const ObjectTypeCreate = props => {
  const classes = useStyles();

  return (
    <CreateWithBreadcrumbs
      title={
        <CustomTitle
          titleKey="resources.objects-types.titles.newObjectType"
          {...props}
        />
      }
      record={{}}
      {...props}
    >
      <SchemaEditForm
        schemaName="itemParamsSchema"
        schemaLabel="app.labels.itemParamsSchema"
        ignoreState={true}
        schemaPlaceholder={placeholderItemParamsSchema}
      >
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          fullWidth
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12} xs={12}>
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  <Fragment>
                    <TextInput
                      source="name"
                      label="app.labels.name"
                      fullWidth
                    />
                    <ReferenceInputWithFilter
                      label="app.labels.client"
                      source="clientUuid"
                      reference="clients"
                    >
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                  </Fragment>
                )}}
            </FormDataConsumer>
          </SanitizedGrid>
        </SanitizedGrid>
      </SchemaEditForm>
    </CreateWithBreadcrumbs>
  );
};

export default ObjectTypeCreate;
