import React, { forwardRef, Fragment } from "react";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/styles';
import PropTypes from "prop-types";
import Form from 'react-jsonschema-form';

import { Theme as MuiTheme } from 'rjsf-material-ui';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { cleanNulls } from '../../providers/dataProvider';
import {
  useLocale
} from 'react-admin';


// T5881
function ErrorListTemplate(props) {
  return (<Fragment />);
}

// replace withTheme from react-jsonschema-form to custom to enable ref forwarding https://github.com/rjsf-team/react-jsonschema-form/issues/1405
function withTheme(themeProps) {
  return forwardRef(({ fields, widgets, ...directProps }, ref) => (
    <Form
      {...themeProps}
      {...directProps}
      fields={{ ...themeProps.fields, ...fields }}
      widgets={{ ...themeProps.widgets, ...widgets }}
      ErrorList={ErrorListTemplate}
      ref={ref}
    />
  ));
}

withTheme.propTypes = {
  widgets: PropTypes.object,
  fields: PropTypes.object,
};

const useStyles = makeStyles({
  root: {
    marginTop: 10,
    position: 'relative',
    width: "100%"
  },
});

// copy-paste of rjsf-material-ui ObjectFieldTemplate, need to override useStyles
const ObjectFieldTemplate = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  uiSchema,
  idSchema,
}) => {
  const classes = useStyles();

  return (
    <div>
      {(uiSchema['ui:title'] || title) && (
        <TitleField
          id={`${idSchema.$id}-title`}
          title={title}
          required={required}
        />
      )}
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}
      <Grid container={true} spacing={2} className={classes.root}>
        {properties.map((element, index) => (
          <Grid
            item={true}
            xs={12}
            key={index}
            style={{ marginBottom: '10px' }}
          >
            {element.content}
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

const TextWidget = ({
  id,
  required,
  readonly,
  disabled,
  label,
  name,
  value,
  onChange,
  onBlur,
  onFocus,
  autofocus,
  options,
  schema,
}) => {
  const _onChange = ({target: { value }}) => onChange(value === '' ? options.emptyValue : value);
  const _onBlur = ({ target: { value } }) => onBlur(id, value);
  const _onFocus = ({target: { value }}) => onFocus(id, value);

  return (
    <FormControl
      fullWidth={true}
      required={required}
    >
      <TextField
        id={id}
        label={label || schema.title}
        autoFocus={autofocus}
        required={required}
        disabled={disabled || readonly}
        name={name}
        type={schema.type}
        value={value === 0 ? '0' : (value ? value : '')}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
      />
    </FormControl>
  );
};

MuiTheme.widgets['TextWidget'] = TextWidget;

const MuiForm = withTheme(MuiTheme);

// T5148 override ObjectFieldTemplate to control element position
const defaultUiSchema = {
  "ui:ObjectFieldTemplate": ObjectFieldTemplate
};

export const JsonForm = props => {
  const {record, source, onChange, _uiSchema, ignoreState } = props;
  const locale = useLocale();
  const changeHandler = (data) => {
    if (ignoreState) {
      return;
    }
    for (let field in data.formData) {
      record[source][field] = data.formData[field];
    }
    return onChange({formData: record[source]});
  };

  const uiSchema = {...(_uiSchema || {}), ...(((record || {})[source] || {}).customUI || {}), ...((props.schema || {}).customUI || {})};

  // T5645
  // deep-copy object
  let schema = JSON.parse(JSON.stringify(cleanNulls(props.schema)));

  // translate description and titles
  const recursiveUpdateTranslation = (obj, key, term, trans) => {
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] == "object") {
          recursiveUpdateTranslation(obj[property], key, term, trans);
        } else {
          if (property === key && obj[key].toLowerCase() === term.toLowerCase()) {
            obj[key] = trans;
          }
        }
      }
    }
  };
  Object.entries(uiSchema['@translation'] || {}).forEach(item => {
    const [term, transDict] = item;
    const trans = transDict[locale];
    if (trans) {
      ['title', 'description'].forEach(key => {
        recursiveUpdateTranslation(schema, key, term, trans);
      });
    }
  });

  return (
    <MuiForm
      schema={schema}
      ref={props.formRef}
      uiSchema={Object.assign(defaultUiSchema, uiSchema || {})}
      formData={record ? record[source] : {}}
      tagName="div"
      liveValidate={true}
      onChange={changeHandler}
    >
      <div>{/* To hide submit button */}</div>
    </MuiForm>
  );
};
