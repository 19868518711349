import React from 'react';
import { Link } from 'react-router-dom';
import IconEdit from '@material-ui/icons/Edit';
import { Button } from 'react-admin';

const EditAssignmentButton = ({ record }) => (
  <Button
    component={Link}
    to={`/rules-assignments/${record.id}/edit`}
    label="ra.action.edit"
  >
    <IconEdit />
  </Button>
);

export default EditAssignmentButton;
