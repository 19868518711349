import React from 'react';
import {
  SimpleForm,
  TextInput,
  BooleanInput,
  AutocompleteInput,
  DateField
} from 'react-admin';
import { makeStyles } from "@material-ui/styles";

import VersionInput from '../custom/VersionInput'; // T5154
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const DeviceTypeEdit = props => {
  const classes = useStyles();
  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.deviceType" {...props} />}
      {...props}
    >
      <SimpleForm redirect="edit">
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            <VersionInput fullWidth/>
            <TextInput fullWidth disabled={true} source="uuid"/>
            <BooleanInput source="isActive" label="app.labels.isActive"/>
            <TextInput fullWidth source="name" label="app.labels.name"/>
            <DateField source="createdAt" label="app.labels.createdAt"/>
            <DateField source="updatedAt" label="app.labels.updatedAt"/>
            <ReferenceInputWithFilter
              label="app.labels.client"
              source="clientUuid"
              reference="clients"
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </EditWithBreadcrumbs>
  )
};

export default DeviceTypeEdit;
