import MeasureUnitIcon from '@material-ui/icons/LinearScale';
import MeasureUnitList from './MeasureUnitList';
import MeasureUnitEdit from './MeasureUnitEdit';
import MeasureUnitCreate from './MeasureUnitCreate';

const resources = {
  list: MeasureUnitList,
  edit: MeasureUnitEdit,
  create: MeasureUnitCreate,
  icon: MeasureUnitIcon
};

export default resources;
