import { useEffect } from "react";
import { useTranslate } from "react-admin";

const setTitle = ({ record, translate }) => {
  const deviceName = record ? record.name : null;
  const naMark = translate("app.labels.n/a");
  let title = translate("resources.dry-run.titles.device");

  if (deviceName) {
    title = title + deviceName;
  } else {
    title = title + naMark;
  }

  return title;
};

export const CustomTitle = props => {
  const { record } = props;
  const translate = useTranslate();

  useEffect(() => {
    document.title = setTitle({ record, translate });
  });

  return setTitle({ record, translate });
};