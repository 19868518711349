import React, {useState, Fragment, useCallback} from 'react';
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-final-form';

import {
	GET_LIST,
	withDataProvider,
	Button,
	SimpleForm,
	useTranslate,
	AutocompleteInput,
	BooleanInput,
	TextInput,
	SelectInput,
	ArrayInput,
	SimpleFormIterator,
	NumberInput,
	showNotification,
	Toolbar,
	linkToRecord,
} from "react-admin";

import {
	Collapse,
	Checkbox,
	Grid,
	IconButton,
	Paper,
	Tabs,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";

import IconAdd from '@material-ui/icons/Add';
import IconCancel from "@material-ui/icons/Cancel";
import IconContentCreate from "@material-ui/icons/Create";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import IconRun from "@material-ui/icons/PlayCircleOutline";

import {
	renderCompactTableValue,
	useEffectOnce,
	LOGS_PARAMETERS,
	NEW_LOGS_PARAMETERS,
} from "../custom/utils";

import ReferenceInputWithFilter from "../custom/ReferenceInputWithFilter";
import { CodeEditor } from "../custom/CodeEditor";
import { EditorWrapper } from "../custom/JSONEditor";
import DeviceData from "../devices/DeviceData";
import { UPDATE_CUSTOM } from "../../providers/dataProvider";
import { SaveButton } from '../custom/WithUndoable';
import { InlineArrayEditField } from "./InlineArrayEditFIeld";
import { SchemaForm } from "./SchemaForm";

const RULE_ASSIGNMENTS_FIELDS = [
	{ name: "Active" , path: "isActive"},
	{ name: "Dry Run" , path: "dryRun"},
	{ name: "Class", path: "class" },
	{ name: "Mode", path: "mode" },
	{ name: "Uuid", path: "uuid" },
	{ name: "Quick Action name", path: "quickActionName" },
	{ name: "Execution Type", path: "executionType" },
	{ name: "Is new setup", path: "isNewSetup" },
]

const inlineEditorStyles = makeStyles({
	disabledSchema: {
		width: '100%',
		height: '300px'
	},
	schedule: {
		'& li': {
			alignItems: 'center',
			'& > p': {
				paddingTop: '8px'
			},
			'& > span': {
				paddingTop: '8px'
			},
			'& > section': {
				display: 'flex',
				justifyContent: 'space-between',
				'& .ra-input:first-child': {
					margin: '0 10px'
				},
				'& .MuiFormHelperText-contained': {
					display: 'none'
				}
			},
		},
	},
});

const RuleAssignmentInlineEditor = (
	{
		withDeviceSelect,
		pollingToken,
		handleRun,
		record,
		rules,
		deviceUuid,
		setDeviceUuid,
		setPayloadContent
	}) => {
	const history = useHistory();
	const translate = useTranslate();
	const form = useForm();
	const theme = useTheme();
	const classes = inlineEditorStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const jsonFormRef = React.createRef();
  let message = {};
  let ruleUuid = record.rule ? record.rule.uuid : null;
  if (record.messageType) {
  	record.messageSchema = record.messageType.messageSchema;
	}
  try {
  	const storageMessage = localStorage.getItem("message");
  	if (storageMessage && storageMessage !== 'undefined') {
    	message = JSON.parse(storageMessage);
		}
  } catch (err) {
    console.log("Error on parse message from local storage", err);
  }

	let [deviceLockedBy, setDeviceLockedBy] = useState(null);
	let [deviceDataRef, setDeviceDataRef] = useState(null);
	const [recordMode, setRecordMode] = useState(record.mode);
	const [rule, setRule] = useState(record.rule ? rules[ruleUuid] : null);
	const [selectedTab, setSelectedTab] = useState(0);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	}

	const handleDeviceChange = (value) => {
		if (!withDeviceSelect) {
			return;
		}
		setDeviceUuid(value);
	};

	const handleRuleChange = (uuid) => {
		form.change('rule', rules[uuid]);
		form.change('ruleUuid', uuid);
		setRule(rules[uuid]);
		ruleUuid = uuid;
	};

	const handleCodeChange = (newCode) => {
		form.change('rule.code', newCode);
		record.rule.code = newCode;
	};

	const handleRecordMode = (event) => {
		form.change('mode', event.target.value);
		setRecordMode(event.target.value);
	};

	const handlePayloadContent = (formData, record, source) => {
		if (!formData) {
			return;
		}
		const fieldNames = Object.keys(formData.properties);
		const res = {};
		for (let key in formData) {
			if (fieldNames.indexOf(key) > -1) {
				res[key] = formData[key];
			}
		}
		setPayloadContent(prev => ({...prev, ...{[record.uuid]: res || false}}));
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} hidden={!isMobile}>
				<Tabs
					value={selectedTab}
					indicatorColor="primary"
					textColor="primary"
					onChange={handleTabChange}
					aria-label="disabled tabs example"
				>
					<Tab label={translate("app.labels.rule")} index={0} />
					<Tab label={translate("app.labels.runMode")} index={1} />
					<Tab label={translate("app.labels.debug")} index={2} />
				</Tabs>
			</Grid>
			<Grid item xs={12} lg={6} hidden={selectedTab !== 0 && isMobile}>
				<ReferenceInputWithFilter
					label="app.labels.rule"
					source="ruleUuid"
					resource="rules-assignments"
					reference="rules"
					value={ruleUuid}
					onChange={handleRuleChange}
					filter={{}}
				>
					<AutocompleteInput
						optionText={(ruleRecord) => ruleRecord.name}
						options={{
							fullWidth: true
						}}
					/>
				</ReferenceInputWithFilter>
				<TextInput
					source="rule.description"
					label="app.labels.description"
					fullWidth
				/>
				<TextInput
					source="quickActionName"
					label="app.labels.quickActionName"
					fullWidth
				/>
				<BooleanInput
					source="isNewSetup"
					label="app.labels.isNewSetup"
					fullWidth
				/>
				<CodeEditor
					record={rule}
					width="100%"
					callback={handleCodeChange}
					source="code"
					label="app.labels.code"
					placeholder="rule RuleName { when { ... } then { ... } }"
					mode="markdown"
				/>
			</Grid>
			<Grid item xs={12} lg={6} hidden={selectedTab !== 1 && isMobile}>
				<Grid container spacing={2}>
					<Grid item xs={12} lg={12}>
						<Grid container spacing={2}>
							<Grid item xs={12} lg={6}>
								<NumberInput
									source="priority"
									label="app.labels.priority"
									options={{ placeholder: "1" }}
									fullWidth
								/>
							</Grid>
							<Grid item xs={12} lg={6}>
								<SelectInput
									label="app.labels.mode"
									source="mode"
									choices={[
										{ id: "schedule", name: "app.labels.schedule" },
										{ id: "manual", name: "app.labels.manual" },
										{ id: "regular", name: "app.labels.regular" }
									]}
									onChange={handleRecordMode}
									disabled={withDeviceSelect}
									fullWidth
								/>
							</Grid>
						</Grid>
					</Grid>
					{!withDeviceSelect && recordMode === "schedule" ?
						<Grid item xs={12} lg={12} className={classes.schedule}>
							<ArrayInput source="schedule" label="app.labels.schedule">
								<SimpleFormIterator>
									<TextInput source="name" label="app.labels.name" />
									<TextInput source="schedule" label="app.labels.schedule" options={{ placeholder: "*/30 * * * * *" }} />
								</SimpleFormIterator>
							</ArrayInput>
						</Grid> : null
					}
					<Grid item xs={12} lg={12}>
						{record.messageType || record.payloadSchema ? (
							<SchemaForm
								source={record.messageType ? "messageSchema" : "payloadSchema"}
								record={record}
								formRef={jsonFormRef}
								callback={(formData, record, source) => handlePayloadContent(formData, record, source)}
								schemaValue={record.messageType ? record.messageSchema : record.payloadSchema}
							/>
						) : (
							<EditorWrapper
								compactView={true}
								schemaName="quickActionPayload"
								record={record}
								height="300px"
								defaultValue={{}}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} lg={12} hidden={selectedTab !== 2 && isMobile}>
				<Grid container spacing={2} alignItems="flex-start">
					<Grid item md={6} lg={6}>
						<ReferenceInputWithFilter
							label="app.labels.selectDevice"
							resource="rules-assignments"
							source="deviceUuid"
							reference="devices"
							filter={{}}
							onChange={handleDeviceChange}
						>
							<AutocompleteInput
								optionText="name"
								options={{
									disabled: !withDeviceSelect,
									fullWidth: true
								}}
							/>
						</ReferenceInputWithFilter>
					</Grid>
					<Grid item md={3} lg={3}>
						<Button
							label="app.labels.run"
							disabled={!deviceUuid}
							onClick={event => handleRun(record, deviceDataRef, setDeviceLockedBy)}
						>
							<IconRun />
						</Button>
					</Grid>
					{record.quickActionName
						? null :
						<Grid item xs={12} lg={4}>
							<EditorWrapper
								compactView={true}
								schemaName="message"
								record={{message: message}}
								height="300px"
								defaultValue={message}
								changeCallback={data => localStorage.setItem("message", JSON.stringify(data.message))}
							/>
						</Grid>
					}
					<Grid item xs={12} lg={record.quickActionName ? 12 : 8}>
						{deviceUuid ?
							<DeviceData
								source="stats"
								useLogs={true}
								timeParameterPath="mqttReceivedAtTimestamp"
								debounceDelay={300}
								deviceUuid={deviceUuid}
								history={history}
								record={record}
								token={pollingToken}
								perPage={3}
								tableView={true}
								sort={{
									field: "createdAt",
									order: "desc"
								}}
								parameters={LOGS_PARAMETERS}
								newParameters={NEW_LOGS_PARAMETERS}
								onRef={ref => {
									deviceDataRef = ref;
									return setDeviceDataRef(deviceDataRef);
								}}
							/> : null
						}
						{deviceLockedBy && (
							<Typography paragraph>
								{translate("app.labels.deviceLockedBy")} {":"} <b>{deviceLockedBy}</b>
							</Typography>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

const InlineAssignmentToolbar = ({onCancel, onSave, classes, record}) => {
	const form = useForm();

	const handleClick = useCallback(
		redirect => {
			if (redirect === void 0) {
				redirect = 'edit';
			}

			if (typeof onSave === 'function') {
				onSave(form.getState().values);
			}
		},
		[form, onSave]
	);

	return (
		<Toolbar
			className={classes.flex}
			children={[
				<Button label="ra.action.cancel" onClick={onCancel}>
					<IconCancel />
				</Button>,
				<Button
					component={Link}
					to={linkToRecord("/rules-assignments", record && record.id)}
					label="ra.action.edit"
					onClick={e => e.stopPropagation()}
				>
					{<IconContentCreate />}
				</Button>,
				<SaveButton handleSubmitWithRedirect={handleClick} />
			]}
		/>
	);
};

const useStyles = makeStyles({
	root: {
		width: "100%"
	},
	innerGrid: {
		padding: "8px"
	},
	tglText: {
		height: "38px",
		lineHeight: "38px",
		marginRight: "10px"
	},
	toggle: {
		height: "38px",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center"
	},
	'& .statusOk': {
		color: 'green'
	},
	'& .statusError': {
		color: 'red'
	},
	headerCell: {
		padding: '0 6px 0 0',
		fontWeight: 'bold'
	},
	shortHeaderCell: {
		width: '60px',
		padding: '0 6px 0 12px',
		fontWeight: 'bold'
	},
	shortCell: {
		padding: '0 6px 0 12px',
		width: '60px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		fontSize: '0.8em',
	},
	actionCell: {
		'&:last-child': {
			padding: '0'
		}
	},
	rowInnerCell: {
		'&:last-child': {
			padding: '0'
		},
		'& > .MuiCollapse-container': {
			padding: '6px !important',
			backgroundColor: '#fafafa',
			border: '1px solid #808080',
		},
		'& > .MuiCollapse-container > .MuiCollapse-wrapper': {
			border: '1px solid #808080',
		}
	},
	parentRow: {
		'& > *': {
			borderBottom: 'unset',
		},
	},
	rowInnerPaper: {
		padding: '10px',
		margin: '0',
		backgroundColor: 'white',
		boxShadow: 'none',
	},
	flex: {
		display: "flex",
		justifyContent: "space-between"
	}
});

const RuleAssignmentsTab = (
	{
		dataProvider,
		showNotification,
		handleCreateRule,
		handleAssignmentsFilter,
		withDeviceSelect,
		withGroupSelect,
		setAllGroups,
		allGroups,
		deviceId,
		id,
	}) => {
	const withDebugCheckbox = withDeviceSelect;
	const classes = useStyles();
	const translate = useTranslate();

	const [payloadContent, setPayloadContent] = useState({});
	const [ruleAssignments, setRuleAssignments] = useState([]);
	const [debugList, setDebugList] = useState([]);
	const [deviceUuid, setDeviceUuid] = useState(withDeviceSelect ? '' : deviceId);
	const [open, setOpen] = useState({});
	const [rules, setRules] = useState({});
	const [debugMode, setDebugMode] = useState(false);
	const [pollingToken, setPollingToken] = useState(
		localStorage.getItem("pollingToken")
	);

	const toggleAssignmentDebug = (num, uuid, checked) => {
		if (checked) {
			if (!debugMode) {
				setDebugMode(true);
			}
			if (debugList.indexOf(uuid) === -1) {
				setDebugList(debugList.concat([uuid]))
			}
		} else {
			if (open[num]) {
				setOpen({[num]: false});
			}
			const filtered = debugList.filter(item => item !== uuid);
			if (debugList.indexOf(uuid) > -1) {
				setDebugList(filtered)
			}
			if (filtered.length === 0) {
				setDebugMode(false);
			}
		}
	};

	const toggleAssignmentOpen = (num, uuid) => {
		const wasOpened = open[num];
		setOpen({[num]: !wasOpened});
		toggleAssignmentDebug(num, uuid, !wasOpened);
	};

	const addAssignmentItem = (event) => {
		const newNum = ruleAssignments.length;
		const newRule = handleCreateRule(id);
		setRuleAssignments(ruleAssignments.concat(newRule));
		setOpen({[newNum]: true});
	};

	const handleAssignmentSave = (data) => {
		return dataProvider(UPDATE_CUSTOM, "rules-assignments", {
			uri: 'inline/save',
			data: data
		}).then(response => {
			showNotification("common.done", "info")
		}).catch(error => {
			showNotification((error && error.message) || "errors.some_error", "warning")
		});
	};

	const onGroupsUpdate = (newGroups, ruleUuid) => {
		ruleAssignments.forEach(rule => {
			if (rule.uuid === ruleUuid) {
				rule.groups = newGroups;
			}
		});
		setRuleAssignments(ruleAssignments);
	};

	const handleRun = (record, deviceDataRef, setDeviceLockedBy) => {
    let message = {};
    try {
      message = JSON.parse(localStorage.getItem("message"));
    } catch (err) {
      console.log("Error on parse message from local storage", err);
    }

		let params = {
			uri: `${deviceUuid}/rules/run`,
			data: {
				message: record.quickActionName ? null : message,
				payload: payloadContent[record.uuid] ? payloadContent[record.uuid] : record.quickActionPayload,
				queue: debugList,
        // dryRun: true
			}
		};

		return dataProvider(UPDATE_CUSTOM, "devices", params)
			.then(response => {
				if (response && response.data && response.data.token) {
					localStorage.setItem("pollingToken", response.data.token);
					setPollingToken(response.data.token);

					setDeviceLockedBy(null);

					setTimeout(() => {
						deviceDataRef && deviceDataRef.startCheckingData();
					}, 1000);
				}
			})
			.catch(error => {
				if (params.errorSource && params.errorSource.lockMeta) {
					const lockMeta = params.errorSource.lockMeta;
					const username =
						(lockMeta.user && lockMeta.user.login) || lockMeta.scheduler || "n/a";
					setDeviceLockedBy(username);
				}

				showNotification((error && error.message) || "errors.some_error", "warning")
			});
	};

	useEffectOnce(() => {
		dataProvider(GET_LIST, 'rules-assignments', {
			sort: {field: 'priority', order: 'ASC'},
			filter: handleAssignmentsFilter(id)
		}).then(response => {
			setRuleAssignments(response.data);
			const openState = {};
			ruleAssignments.forEach((row, i) => {
				openState[i] = false;
			});
			setOpen(openState);
		});
	});

	useEffectOnce(() => {
		dataProvider(GET_LIST, 'rules', {
			sort: {field: 'createdAt', order: 'DESC'},
		}).then(response => {
			const data = {};
			response.data.forEach(rule => { data[rule.uuid] = rule });
			setRules(data);
		});
	});

	return (
		<Grid
			justify="flex-start"
			container={true}
			spacing={0}
			className={classes.root}
		>
			<Grid item lg={12} xs={12} className={classes.innerGrid}>
				<Button
					className={classes.button}
					variant="contained"
					label="ra.action.add"
					onClick={addAssignmentItem}
				>
					<IconAdd />
				</Button>
			</Grid>
			<Grid item lg={12} xs={12} className={classes.innerGrid}>
				<Table size="small" className={classes.table}>
					<TableHead>
						<TableRow>
							{RULE_ASSIGNMENTS_FIELDS.map(p => (
								<TableCell key={p.name} align="left" className={classes.headerCell}>{p.name}</TableCell>
							))}
							{withGroupSelect ?
								<TableCell className={classes.headerCell}>
									{translate("app.labels.selectGroups")}
								</TableCell>: null
							}
							<TableCell className={classes.shortHeaderCell}>
								{debugMode ? translate("app.labels.select") : translate("app.labels.open")}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{ruleAssignments.map((row, i) => (
							<Fragment key={`debugrow-${i}`}>
								<TableRow className={classes.parentRow}>
									{RULE_ASSIGNMENTS_FIELDS.map(p => (
										<TableCell
											align="left"
											key={`rootrow-${row.uuid}-${p.name}`}
											className={classes.cell}
										>
											{renderCompactTableValue(row, p)}
										</TableCell>
									))}
									{withGroupSelect ?
										<TableCell className={classes.cell}>
											<InlineArrayEditField
												record={row}
												dataProvider={dataProvider}
												allGroups={allGroups}
												setAllGroups={setAllGroups}
												onGroupsUpdate={onGroupsUpdate}
												modelName={"rules-assignments-groups"}
											/>
										</TableCell> : null
									}
									<TableCell className={classes.shortCell}>
										{debugMode && withDebugCheckbox ?
											<Checkbox
												checked={debugList.indexOf(i) > -1}
												onChange={event => toggleAssignmentDebug(i, row.uuid, event.target.checked)}
											/> :
											<IconButton size="small" onClick={event => toggleAssignmentOpen(i, row.uuid)}>
												{open[i] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
											</IconButton>
										}
									</TableCell>
								</TableRow>
								<TableRow key={`collapse-${row.uuid}`}>
									<TableCell
										colSpan={RULE_ASSIGNMENTS_FIELDS.length + 1 + ((withGroupSelect) ? 1 : 0)}
										className={classes.rowInnerCell}
									>
										<Collapse in={open[i]} timeout="auto" unmountOnExit>
											<Paper className={classes.rowInnerPaper}>
												<SimpleForm
													record={row}
													toolbar={
														<InlineAssignmentToolbar
															classes={classes}
															record={row}
															onCancel={event => toggleAssignmentOpen(i, row.uuid)}
															onSave={handleAssignmentSave} />
													}
												>
													<RuleAssignmentInlineEditor
														withDeviceSelect={withDeviceSelect}
														handleRun={handleRun}
														record={row}
														rules={rules}
														pollingToken={pollingToken}
														deviceUuid={deviceUuid}
														setDeviceUuid={setDeviceUuid}
														setPayloadContent={setPayloadContent}
													/>
												</SimpleForm>
											</Paper>
										</Collapse>
									</TableCell>
								</TableRow>
							</Fragment>
						))}
					</TableBody>
				</Table>
			</Grid>
		</Grid>
	)
};

export default withDataProvider(connect(null, { showNotification })(RuleAssignmentsTab))
