import React from "react";
import withStyles from '@material-ui/core/styles/withStyles';
import { 
  ListGuesser, 
  DateTimeInput, 
  Filter, 
  SearchInput, 
  SelectInput, 
  ReferenceInput,
  useRefresh 
} from 'react-admin';
//import rowStyle from '../../styling/rowStyle';

const styles = {
  data: {
    '& th': {
      'textAlign': 'center',
      '& span': {
        '& span': {
          writingMode: 'vertical-rl',
          maxHeight: '110px',
          transform: 'rotate(-180deg)',
        }
      }
    },
    '& .column-id': {
      maxWidth: '6em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  }
};

const DeviceDataPreview = ({ classes, basePath, deviceUuids, ...props }) => {

  // TODO: dirty hack 2 force ListGuesser 2 refresh data on message type switched
  const refresh = useRefresh();

  return (
    <ListGuesser resource='data' className={classes.data}
      basePath={basePath}
      filter={{  deviceUuid: deviceUuids,  pretty: true }}
      hasShow={false} hasEdit={false} hasCreate={false} hasList={false} 
      filters={
        <Filter {...props} resource='logs/collapse/extra' style={{width:'80%'}}>
          <SearchInput source="rulesProcessed_outputLogs_message" alwaysOn className={classes.input} />
          <ReferenceInput label="resources.devices.titles.chooseMessageType"
            reference="messages-types" source="messageTypeUuid" 
            onChange={() => setTimeout(refresh,1500)}
            filter={{deviceModelUuid: props.record.model.uuid}}
            sort={{ field: 'createdAt', order: 'DESC' }} alwaysOn>
            <SelectInput optionText="name" optionValue="uuid" />
          </ReferenceInput>
          <DateTimeInput source="mqttReceivedAt_gte" label="app.labels.from" options={{mode: "portrait", locale: "ru"}} />
          <DateTimeInput source="mqttReceivedAt_lte" label="app.labels.to" options={{mode: "portrait", locale: "ru"}} />
          <SelectInput source="_histogram" choices={[
              { id: '1h', name: '1h' },
              { id: '3h', name: '3h' },
              { id: '6h', name: '6h' },
              { id: '12h', name: '12h' },
              { id: '1d', name: '1d' },
              { id: '1d', name: '1w' },
            ]} />
        </Filter>
      }
      sort={{ field: "mqttReceivedAt", order: "DESC" }}/>);
};

export default withStyles(styles)(DeviceDataPreview);