import React, { useState, useEffect, Fragment } from 'react';

import {
  UPDATE,
  withDataProvider,
  Toolbar,
  Button,
  useNotify,
  AutocompleteInput,
  Labeled,
  SimpleForm,
  useTranslate
} from 'react-admin';

//import MaterialUiIconPicker from 'react-material-ui-icon-picker';
import { useForm } from 'react-final-form';

import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { SaveButton } from '../custom/WithUndoable';
import { DeviceCardStyle } from '../custom/CardDatagrid';
import { DEFAULT_AGG_INTERVAL, AGG_INTERVALS, calculateBegin } from '../custom/utils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import DeleteIcon from '@material-ui/icons/Delete';
import SanitizedGrid from "../custom/SanitizedGrid";
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920

const paramsListStyle = {
  listStyle: 'none',
  paddingLeft: 0
};

const paramsItemStyle = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  maxWidth: '500px',
  padding: '10px'
};

const spaceBetween = {
  marginTop: '10px',
  marginBottom: '10px'
};

const sortParamsArrows = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '40px'
};

const paramCardTitle = {
  fontSize: '12px',
  color: '#848484'
}

const buttonIcon = {
  width: '32px',
  minWidth: '32px',
  padding: 0
};

const iconSort = {
  fontSize: '30px'
};

const paramNamePlace = {
  width: '180px',
  textAlign: 'left'
};

const nameMessageType = {
  fontSize: '12px'
};

const paramsBlock = {
  padding: '10px',
  width: '100%',
  backgroundColor: '#fafafa',
  marginBottom: '40px',
  boxSizing: 'border-box'
};

const paramsBlockTitle = {
  marginTop: 0
};

const spaceTop = {
  marginTop: '20px'
};


function clearDemoValues(demoData) {
  for (let i in demoData) {
    if (Array.isArray(demoData[i])) {
      demoData[i].forEach(obj => {
        delete obj.value
      });
    }
  }
  return demoData;
};

function isParameterNotSaved(messageUuid, paramPath, paramsSaved) {

  let result = true;

  ["primary", "secondary"].forEach(blockType => {
    if (paramsSaved[blockType]) {
      paramsSaved[blockType].forEach(paramSaved => {
        if (paramSaved.mtUuid === messageUuid && paramSaved.path === paramPath) {
          result = false;
        }
      });
    }
  });

  return result;
}

const DeviceEditToolbar = () => {
  return (<Toolbar style={{backgroundColor: '#ffffff'}} children={<Fragment />} />);
};

const ParamsIterator = props => {

  const {
    paramsArr,
    paramsBlockType,
    handleArrowUpClick,
    handleArrowDownClick,
    handleSelectPlaceClick,
    handleAggIntervalSelect,
    translate
  } = props;

  return (
    <ul style={paramsListStyle}>
      { paramsArr.map((param, index) => {
        return (<li key={param.path} style={{ marginTop: '10px' }}>
          <Card style={paramsItemStyle}>
            <div style={sortParamsArrows}>
              <Button style={buttonIcon} color="primary" onClick={(event) => handleArrowUpClick(index, paramsBlockType)}>
                <ArrowDropUpIcon style={iconSort} />
              </Button>
              <Button style={buttonIcon} color="primary" onClick={(event) => handleArrowDownClick(index, paramsBlockType)}>
                <ArrowDropDownIcon style={iconSort} />
              </Button>
            </div>
            <div style={paramNamePlace}>
              <div><span style={paramCardTitle}>{translate('app.configurator.parameters.parameter')}</span></div>
              <div><span>{param.name}</span></div>
              <div style={spaceBetween}>
                <div><span style={paramCardTitle}>{translate('app.configurator.aggType')}</span></div>
                <div><span>{param.aggType ? param.aggType : translate('app.configurator.aggIntervalChoices.all')}</span></div>
              </div>
              <div><span style={paramCardTitle}>{translate('app.labels.messageType')}</span></div>
              <div><span style={nameMessageType}>{param.mtName}</span></div>
            </div>

            <div>
              {paramsBlockType !== "non" && (
                <div style={paramNamePlace}>
                  <Labeled label="app.configurator.aggInterval">
                    <Select
                      name="aggInterval"
                      value={param.aggInterval || DEFAULT_AGG_INTERVAL}
                      onChange={(event) => handleAggIntervalSelect(event.target.value, index, paramsBlockType)}
                      inputProps={{ name: 'aggInterval' }}
                    >
                      {AGG_INTERVALS.map( item => {
                        return (
                          <MenuItem key={item} value={item || ''}>{item ? translate(`app.configurator.aggIntervalChoices.${item}`) : null }</MenuItem>
                        )
                      })}
                    </Select>
                  </Labeled>
                </div>
              )}

              <div style={spaceTop}>
                <div><span style={paramCardTitle}>{translate('resources.devices.plashka.place')}</span></div>
                <div>
                  <select name="placeSelect" onChange={(event) => handleSelectPlaceClick(event.target.value, index, paramsBlockType)} value={paramsBlockType}>
                    <option value="non">{''}</option>
                    <option value="primary">{translate('resources.devices.plashka.primary')}</option>
                    <option value="secondary">{translate('resources.devices.plashka.secondary')}</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <Button style={buttonIcon} onClick={() => handleSelectPlaceClick("non", index, paramsBlockType)}>
                <DeleteIcon color={paramsBlockType === "non" ? "disabled" : "error"} />
              </Button>
            </div>
          </Card>
        </li>);
      })}
    </ul>
  )
};

const SaveParametersOrder = ({ handleSubmitWithRedirect, handleSaveSettings, ...props }) => {

  const handleClick = () => {
    handleSaveSettings();
  };

  return <SaveButton {...props} handleSubmitWithRedirect={handleClick} />;
};

export const ParamsList = withDataProvider(
  ({ messageTypes, dataProvider, flagToRefresh, setFlagToRefresh, submitOnEnter, ...props }) => {
    const form = useForm();
    const formValues = form.getState().values;
    const [demostats, setDemostats] = useState({icon: '', primary: [], secondary: []});
    const [paramsArr, setParamsArr] = useState([]);
    const [messagesTypesFetched, setMessagesTypesFetched] = useState(false);
    const notify = useNotify();
    const translate = useTranslate();

    useEffect(() => {
      if (!formValues.id) {
        setParamsArr([]);
        return;
      }
      if (messageTypes && messageTypes.length && !messagesTypesFetched) {
        setMessagesTypesFetched(true);
        let paramsArrTemp = [];

        if (formValues.configurator && formValues.configurator.primary && formValues.configurator.secondary) {
          setDemostats(formValues.configurator);
        }

        messageTypes.forEach(message => {

          let objMessage = {};
          if (message.configurator && message.configurator.parameters && message.configurator.parameters.length) {

            message.configurator.parameters.forEach(param => {

              if (isParameterNotSaved(message.uuid, param.path, formValues.configurator)) {
                objMessage = {
                  mtName: message.name,
                  mtUuid: message.uuid,
                  name: param.name,
                  path: param.path,
                  aggType: param.aggType,
                  description: param.description,
                  aggInterval: "1d",
                  order: 0
                };
                paramsArrTemp.push(objMessage);
              }
            });
          } else {
            notify(translate("resources.messages-types.paramsPage.noParams") + ": " + message.name, "info");
          }
        });

        setParamsArr(paramsArrTemp);
      }
    }, [formValues, paramsArr, notify, translate, demostats, messageTypes, messagesTypesFetched, setMessagesTypesFetched]);

    const moveParamUp = (paramIndex, blockType) => {
      let tempParam = {};

      if (paramIndex > 0) {
        switch(blockType) {
          case "primary":
            tempParam = demostats.primary[paramIndex];
            demostats.primary[paramIndex - 1].order++;
            demostats.primary[paramIndex] = demostats.primary[paramIndex - 1];
            tempParam.order--;
            demostats.primary[paramIndex - 1] = tempParam;
            break;
          case "secondary":
            tempParam = demostats.secondary[paramIndex];
            demostats.secondary[paramIndex - 1].order++;
            demostats.secondary[paramIndex] = demostats.secondary[paramIndex - 1];
            tempParam.order--;
            demostats.secondary[paramIndex - 1] = tempParam;
            break;
          default:
            console.warn("Unknown block type");
        }
      }

      setDemostats(demostats);
      setFlagToRefresh(!flagToRefresh);
      return false;
    };

    const moveParamDown = (paramIndex, blockType) => {
      let tempParam = {};

      switch(blockType) {
        case "primary":
          if (demostats.primary.length - 1 > paramIndex) {
            tempParam = demostats.primary[paramIndex];
            demostats.primary[paramIndex + 1].order--;
            demostats.primary[paramIndex] = demostats.primary[paramIndex + 1];
            tempParam.order++;
            demostats.primary[paramIndex + 1] = tempParam;
          }
          break;
        case "secondary":
          if (demostats.secondary.length - 1 > paramIndex) {
            tempParam = demostats.secondary[paramIndex];
            demostats.secondary[paramIndex + 1].order--;
            demostats.secondary[paramIndex] = demostats.secondary[paramIndex + 1];
            tempParam.order++;
            demostats.secondary[paramIndex + 1] = tempParam;
          }
          break;
        default:
          console.warn("Unknown block type");
      }

      setDemostats(demostats);
      setFlagToRefresh(!flagToRefresh);
      return false;
    };

    const handleAggIntervalSaving = (newAggInterval, paramIndex, place) => {
      demostats[place][paramIndex].aggInterval = newAggInterval;
      setDemostats(demostats);
      setFlagToRefresh(!flagToRefresh);
    };

    const handleMoving = (to, paramIndex, from) => {

      if (to === from) {
        return false;
      }

      let tempParam = {};
      let lastIndex = 0;

      switch(from) {
        case "non":

          tempParam = paramsArr[paramIndex];
          paramsArr.splice(paramIndex, 1);
          setParamsArr(paramsArr);

          lastIndex = demostats[to].length;
          tempParam.order = lastIndex;
          demostats[to].push(tempParam);
          setDemostats(demostats);

          break;
        case "primary":

          tempParam = demostats[from][paramIndex];
          demostats[from].splice(paramIndex, 1);
          demostats[from].map((param, index) => {
            if (index >= paramIndex) {
              param.order--;
            }
            return param;
          });
          setDemostats(demostats);

          if (to === "non") {
            paramsArr.push(tempParam);
            setParamsArr(paramsArr);

          } else if (to === "secondary") {
            lastIndex = demostats[to].length;
            tempParam.order = lastIndex;
            demostats[to].push(tempParam);
            setDemostats(demostats);
          }
          break;
        case "secondary":

          tempParam = demostats[from][paramIndex];
          demostats[from].splice(paramIndex, 1);
          demostats[from].map((param, index) => {
            if (index >= paramIndex) {
              param.order--;
            }
            return param;
          });
          setDemostats(demostats);

          if (to === "non") {
            paramsArr.push(tempParam);
            setParamsArr(paramsArr);

          } else if (to === "primary") {
            lastIndex = demostats[to].length;
            tempParam.order = lastIndex;
            demostats[to].push(tempParam);
            setDemostats(demostats);
          }
          break;
        default:
          console.error("unknown place");
      }

      setFlagToRefresh(!flagToRefresh);
    };

    const handleSaveSettings = () => {
      formValues.configurator = clearDemoValues(demostats);

      dataProvider(UPDATE, 'devices-models', {
        id: formValues.uuid,
        data: formValues
      }).then(result => {
        notify('resources.messages-types.paramsPage.updated', "success");
      });
    };

    const handleOnDeviceChange = (deviceUuid) => {

      let queryArr = [];

      demostats.primary.forEach(param => {
        queryArr.push({
          "aggInterval": param.aggInterval || "1d",
          "begin": calculateBegin(param.aggInterval),
          "deviceUuid": deviceUuid,
          "messageTypeUuid": param.mtUuid,
          "limit": 1,
          "parameters": [{
            "name": param.name,
            "path": param.path,
            "aggType": param.aggType
          }]
        });
      });

      demostats.secondary.forEach(param => {
        queryArr.push({
          "aggInterval": param.aggInterval || "1d",
          "begin": calculateBegin(param.aggInterval),
          "deviceUuid": deviceUuid,
          "messageTypeUuid": param.mtUuid,
          "limit": 1,
          "parameters": [{
            "name": param.name,
            "path": param.path,
            "aggType": param.aggType
          }]
        });
      });

      if (queryArr.length < 1) {
        return;
      }

      dataProvider('UPDATE_NO_ID', 'devices-stats', {
        data: queryArr
      })
        .then(response => {
          if (response.length) {
            response.forEach(obj => {

              if (obj.data.length) {
                demostats.primary.forEach(param => {
                  if (obj.data[0][param.name]) {
                    param.value = obj.data[0][param.name];
                  }
                });

                demostats.secondary.forEach(param => {
                  if (obj.data[0][param.name]) {
                    param.value = obj.data[0][param.name];
                  }
                });
              }
            });

            setDemostats(demostats);
            setFlagToRefresh(!flagToRefresh);
          }
        })
        .catch(err => {
          notify(err.message);
          throw err;
        });
    };

    // eslint-disable-next-line
    const saveChoosedIcon = (icon) => {
      demostats.icon = icon.name;
      setDemostats(demostats);
      setFlagToRefresh(!flagToRefresh);
    };

    return (
      <SimpleForm
        toolbar={<DeviceEditToolbar submitOnEnter={submitOnEnter} {...props} />}>
        <SanitizedGrid
          container
          fullWidth
          justify="space-between"
          {...props}
        >
          <SanitizedGrid item lg={6} md={12} style={{padding: "20px"}}>

            <div style={paramsBlock}>
              <h4 style={paramsBlockTitle}>{translate('resources.messages-types.paramsPage.primary')}</h4>

              <ParamsIterator
                paramsBlockType={"primary"}
                paramsArr={demostats.primary}
                handleArrowUpClick={moveParamUp}
                handleArrowDownClick={moveParamDown}
                handleSelectPlaceClick={handleMoving}
                handleAggIntervalSelect={handleAggIntervalSaving}
                translate={translate}
              />
            </div>

            <div style={paramsBlock}>
              <h4 style={paramsBlockTitle}>{translate('resources.messages-types.paramsPage.secondary')}</h4>

              <ParamsIterator
                paramsBlockType={"secondary"}
                paramsArr={demostats.secondary}
                handleArrowUpClick={moveParamUp}
                handleArrowDownClick={moveParamDown}
                handleSelectPlaceClick={handleMoving}
                handleAggIntervalSelect={handleAggIntervalSaving}
                translate={translate}
              />
            </div>

            <div style={paramsBlock}>
              <h4 style={paramsBlockTitle}>{translate('resources.messages-types.paramsPage.non')}</h4>

              <ParamsIterator
                paramsBlockType={"non"}
                paramsArr={paramsArr}
                handleArrowUpClick={() => void 0}
                handleArrowDownClick={() => void 0}
                handleTrashClick={() => void 0}
                handleSelectPlaceClick={handleMoving}
                handleAggIntervalSelect={handleAggIntervalSaving}
                translate={translate}
              />
            </div>

            
            {/*<MaterialUiIconPicker
              onPick={saveChoosedIcon}
              label={translate('resources.devices-models.icon.choose')}
              pickLabel={translate('resources.devices-models.icon.chooseBtn')}
              cancelLabel={translate('ra.action.close')}
              modalTitle={translate('resources.devices-models.icon.title')}
            />*/}

            <SaveParametersOrder
              label="ra.action.save"
              style={{marginTop: '40px'}}
              saving={false}
              handleSaveSettings={handleSaveSettings}
            />
          </SanitizedGrid>
          <Grid item lg={6} md={12} style={{padding: "20px"}}>
            <Grid item lg={12} md={12} style={{marginBottom: "40px", maxWidth: "500px"}}>
              <ReferenceInputWithFilter
                label="app.configurator.chooseDevice"
                resource="devices"
                source="name"
                reference="devices"
                filter={{ isActive: true, deviceModelUuid: formValues.uuid, clientUuid: formValues.clientUuid }}
                onChange={handleOnDeviceChange}
              >
                <AutocompleteInput
                  optionText="name"
                  options={{
                    fullWidth: true
                  }}
                />
              </ReferenceInputWithFilter>
            </Grid>
            <Grid item lg={12} md={12}>
              <DeviceCardStyle demostats={demostats} record={{model: formValues}}/>
            </Grid>
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    );
  }
);
