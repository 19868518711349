import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  useTranslate,
  Title,
  useSetLocale,
  useLocale,
  withDataProvider,
  GET_LIST,
} from 'react-admin';

import {
  Card,
  CardContent,
  Button,
  Typography,
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";

import { changeTheme } from '../../actions/themeActions';
import AppVersion from '../custom/AppVersion';
import {changeSettings, getSettings, useEffectOnce} from '../custom/utils';
import localemap from '../../i18n/localemap';

const styles = {
  label: { width: '5em', display: 'inline-block' },
  button: { margin: '1em' }
};

const Settings = ({theme, changeTheme, classes, dataProvider, ...props}) => {
  const translate = useTranslate();
  const setLocale = useSetLocale();
  const locale = useLocale();
  const [clientThemes, setClientThemes] = useState([]);

  const fetchClientThemes = () => {
    return dataProvider(GET_LIST, 'themes', {
    }).then(response => {
      return setClientThemes(response.data);
    }).catch((e) => {
      console.error(e);
    });
  }

  useEffectOnce(() => {fetchClientThemes()});

  const changeOptionFn = async (optionName, optionValue) => {
    if (optionName === 'theme') {
      if (['light', 'dark'].indexOf(optionValue) === -1) {
        optionValue = JSON.stringify(optionValue);
      }
    }
    await changeSettings(optionName, optionValue);
    if (optionName === 'theme') {
      changeTheme(optionValue);
    } else if (optionName === 'locale') {
      setLocale(optionValue);
    }
  };

  const setCustomTheme = async (theme) => {
    await changeSettings({
      theme: 'customTheme',
      'customTheme': theme,
    });
    await changeTheme('theme', theme);
  }

  const currentSettings = getSettings();

  return (
    <Card>
      <Title title={translate('app.labels.settings')} />
      <CardContent>
        <Typography variant="button" className={classes.label}>
          {translate('app.theme.name')}
        </Typography>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'light' ? 'primary' : 'default'}
          onClick={() => changeOptionFn('theme', 'light')}
        >
          {translate('app.theme.light')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color={theme === 'dark' ? 'primary' : 'default'}
          onClick={() => changeOptionFn('theme', 'dark')}
        >
          {translate('app.theme.dark')}
        </Button>
        {clientThemes.map(theme => (
          <Button
            variant="contained"
            className={classes.button}
            color={(currentSettings.theme === 'customTheme' && currentSettings.customTheme.brandName === theme.brandName) ? 'primary' : 'default'}
            onClick={() => setCustomTheme(theme)}
          >
              {theme.brandName}
          </Button>
        ))}
      </CardContent>
      <CardContent>
        <Typography variant="button" className={classes.label}>
          {translate('app.labels.language')}
        </Typography>

        {Object.values(localemap.localemap).map((loopLocale) => {
          return (
            <Button
              key={loopLocale}
              variant="contained"
              className={classes.button}
              color={locale === loopLocale ? 'primary' : 'default'}
              onClick={() => changeOptionFn('locale', loopLocale)}
            >
              {loopLocale}
            </Button>
          );
        })}
      </CardContent>
      <CardContent>
        <AppVersion variant="caption" />
      </CardContent>
    </Card>
  );
}

const mapStateToProps = state => ({
  theme: state.theme
});

export default connect(
    mapStateToProps, 
    { changeTheme }
  )(withDataProvider(withStyles(styles)(Settings)));
