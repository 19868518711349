import RuleAssignmentIcon from '@material-ui/icons/Assignment';
import RuleAssignmentList from './RuleAssignmentList';
import RuleAssignmentEdit from './RuleAssignmentEdit';
import RuleAssignmentCreate from './RuleAssignmentCreate';

const resources = {
  list: RuleAssignmentList,
  edit: RuleAssignmentEdit,
  create: RuleAssignmentCreate,
  icon: RuleAssignmentIcon
};

export default resources;