import React from "react";
import Select2 from "react-select2-wrapper";
import { useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/styles";
import { Box } from "@material-ui/core";

import { UPDATE_CUSTOM } from "../../providers/dataProvider";

const selectStyles = makeStyles({
	root: {
		width: '300px',
		'& .select2-container': {
			width: '100% !important',
			display: 'block'
		},
		'& .select2-search__field': {
			width: '100% !important'
		},
		'& .select2-selection--multiple': {
			border: 'none',
			backgroundColor: 'transparent',
			borderBottom: '1px solid black',
			borderRadius: 0
		},
		'& .select2-container--default.select2-container--focus .select2-selection--multiple': {
			border: 'none',
			borderBottom: '1px solid black',
			borderRadius: 0
		},
		'& .select2-selection--multiple .select2-selection__choice': {
			borderRadius: '16px',
			border: 'none',
			height: '32px',
			padding: '0 12px',
			display: 'inline-flex',
			alignItems: 'center'
		},
	}
})

export const InlineArrayEditField = (
	{
		dataProvider,
		setAllGroups,
		allGroups,
		record,
		modelName,
		onGroupsUpdate
	}) => {
	const recordGroups = (record && record.groups) ? record.groups : [];
	let groupsIds = recordGroups.map(({uuid}) => uuid);
	const translate = useTranslate();
	const classes = selectStyles();

	const selectHandler = (event) => {
		const addId = event.params.data.id;
		let groupsNames = [];
		let newName = false;
		if (allGroups.filter((group) => group.id === addId || group.text === addId).length) {
			// id exists
			groupsIds = groupsIds.concat([addId]);
			groupsNames = allGroups.reduce((res, item) => {
				if (groupsIds.some(id => id === item.id)) { res.push(item.text) } return res
			}, []);
		} else {
			// new item with no id
			groupsNames = allGroups.reduce((res, item) => {
				if (groupsIds.some(id => id === item.id)) { res.push(item.text) } return res
			}, [addId]);
			newName = true;
		}

		dataProvider(UPDATE_CUSTOM, modelName, {
			uri: `${record.uuid}/groups`,
			data: { groups: groupsNames }
		}).then(result => {
			if (!result.data) {
				return;
			}
			if (typeof onGroupsUpdate === 'function') {
				const newGroups = result.data.allGroups.reduce((res, item) => {
					if (result.data.groups.some(id => id === item.name)) { res.push(item) } return res
				}, [])
				onGroupsUpdate(newGroups);
			}
			if (newName) {
				setAllGroups(result.data.allGroups.map(group => ({'text': group.name, 'id': group.uuid})))
			}
		}).catch(error => {

		})
	};

	const unselectHandler = (event) => {
		const removeId = event.params.data.id;
		groupsIds = groupsIds.filter(uuid => uuid !== removeId);
		dataProvider(UPDATE_CUSTOM, modelName, {
			uri: `${record.uuid}/groups`,
			data: { groups:
				allGroups.reduce((res, item) => {
					if (groupsIds.some(id => id === item.id)) { res.push(item.text) } return res
				}, [])
			}
		}).then(result => {
			if (!result.data) {
				return;
			}
			if (typeof onGroupsUpdate === 'function') {
				onGroupsUpdate(result.data.groups);
			}
		})
	};

	return (
		<Box className={classes.root}>
			<Select2
				multiple
				value={groupsIds}
				data={allGroups || []}
				onSelect={selectHandler}
				onUnselect={unselectHandler}
				id={record.uuid}
				options={{
					tags: true,
					placeholder: translate("app.labels.selectGroups")
				}}
			/>
		</Box>
	)
}
