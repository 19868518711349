import React from 'react';
import {
  TextField,
  ReferenceField,
  useTranslate
} from 'react-admin';

import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";

const TranslatedTextField = props => {
  const translate = useTranslate();
  const { source, record } = props;
  const translated = translate(`resources.messages-types.directions.${record[source]}`);
  return (<div>{translated}</div>);
}

const MessageTypeList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.messageTypes" {...props} />}
    bulkActionButtons={<BulkDeleteAction />}
    filters={<StyledSearchFilter />}
    filter={{
      embedded: {}
    }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid checkClientUuid={true}>
      <TranslatedTextField source="direction" label="resources.messages-types.direction" {...props} />
      <ReferenceField
        label="app.labels.client"
        source="clientUuid"
        reference="clients"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="app.labels.deviceModel"
        source="deviceModelUuid"
        reference="devices-models"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default MessageTypeList;
