import { omit/*, isEmpty*/ } from "lodash";
import { httpClient } from "../components/custom/utils";

/**
 * Maps react-admin queries to a simple REST API
 *
 **/
export default (apiUrl) => {

  /**
  * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
  * @param {String} resource Name of the resource to fetch, e.g. 'posts'
  * @param {Object} params The data request params, depending on the type
  * @returns {Object} { url, options } The HTTP request parameters
  */
  let buildRequest = (type, resource, params) => {

    let query = {
      filter: convertFilter(omit(params.filter,
        // ignore "special" params
        ["messageTypeUuid","pretty","_histogram","collapse"])), 
      embedded: params.filter.embedded ? convertEmbedded(params.filter.embedded) : {},
      ...convertPagination(params.pagination),
      collapse: params.filter.collapse ? 'serie.keyword' : false,
      order: convertSorting({ // collapsed logs have different sorting field
        field : params.filter.collapse ? 'start' : params.sort.field,
        order: params.sort.order
      })
    };

    return {
      url: conconvertUrl(resource, params, apiUrl, query)
    };
  };

  let parseResponse = (response, type, resource, params) => {

    let { headers, json } = response;

    if (!headers.has("content-range"))
      console.warn(`The Content-Range header is missing in the HTTP Response. 
        The simple REST data provider expects responses for lists of resources 
        to contain this header with the total number of results to build the 
        pagination. If you are using CORS, did you declare Content-Range in 
        the Access-Control-Expose-Headers header?`);

    try {
      return {
        total: headers.has("content-range") ? parseInt(headers.get("content-range").split("/").pop(), 10) : 0,
        data: json
      };
    } catch(err) {
      console.error(err);
      return {
        total: 0,
        data: json
      };            
    }
  };

  let convertFilter = (filter) => {

    let parseCondition = (key, value) => {

      if(key.endsWith('_gte')) {
        key = key.trim().replaceAll('_gte','').replaceAll('_','.');
        value = { $gte: value };
      } else if(key.endsWith('_gt')) {
        key = key.trim().replaceAll('_gt','').replaceAll('_','.');
        value = { $gt: value };
      } else if(key.endsWith('_lte')) {
        key = key.trim().replaceAll('_lte','').replaceAll('_','.');
        value = { $lte: value };
      } else if(key.endsWith('_lt')) {
        key = key.trim().replaceAll('_lt','').replaceAll('_','.');
        value = { $lt: value };
      } else {
        key = (key.indexOf('_') > -1 ? `$${key.trim().replaceAll('_','.')}$` : key.trim());
      }

      switch(typeof value) {
        case "undefined":
          return undefined;
        case "boolean": 
          return { [key] : value};
        case "number" : 
          return { [key] : value};
        case "string" : 
          return { // check if we got find by uuid query
            [key] : RegExp('^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$')
                            .test(value) ? value : { $iLike: `%${value}%`}
          };
        default: return { // object like {$ne: "aeed04e7-4eb9-47fd-8a37-db464d4243bc"}
          [key]: value
        };
      }
    };

    let conditions = Object.keys(omit(filter, ["embedded","withOnlineFlag"]))
      .map( fname => fname.indexOf(',') > -1 ? 
        { $or: fname.split(',').map(string => parseCondition(string, filter[fname]))} 
        : parseCondition(fname, filter[fname]));

    return (conditions.length > 1 ? { $and: conditions } : conditions[0]);
  };

  let convertSorting = (sort) => sort.field.split(",").map((f)=>[f,sort.order]);

  let convertEmbedded = (embedded) => omit(embedded, ["children","rules"]);

  let convertPagination = (pagination) => {
    return { 
      offset: pagination.page ? (pagination.page - 1) * (pagination.perPage ? pagination.perPage : 25) : 0, 
      limit: (pagination.perPage ? pagination.perPage : 25)
    };
  };

  let conconvertUrl = (resource, params, apiUrl, query) => {
    switch(resource) {
      case 'logs': 
        //return `${apiUrl}/${params.filter.collapse ? 'logs/collapse/serie.keyword':'logs'}?${encodeURIComponent(JSON.stringify(query))}`;
        return `${apiUrl}/logs?${encodeURIComponent(JSON.stringify(query))}`;
      default:  // data
        return `${apiUrl}/data/${params.filter.messageTypeUuid}/${params.filter.pretty?'pretty':'raw'}?${encodeURIComponent(JSON.stringify(query))}`;
    }
  };

  // return async (type, resource, params) => httpClient(buildRequest(type, resource, params))
  //   .then(response => parseResponse(response, type, resource, params))
  //   .catch((error) => {
  //     // T5289 pass error source to input params
  //     if (params && error.errorSource)
  //       params.errorSource = error.errorSource;
  //     throw error;
  //   });

  return (type, resource, params) => {
    let { url } = buildRequest(type, resource, params);
    return httpClient(url).then(
        (response) => parseResponse(response, type, resource, params)
      ).catch((error) => {
        // T5289 pass error source to input params
        if (params && error.errorSource)
          params.errorSource = error.errorSource;
        throw error;
      });
  }

}
