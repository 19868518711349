import React from 'react';
import {
  TextInput,
  BooleanInput,
  DateField,
  SimpleForm,
} from 'react-admin';
import {makeStyles} from "@material-ui/styles";

import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';
import CustomTitle from '../custom/CustomTitle'; // T5275
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const RoleCreate = props => {
  const classes = useStyles();
  return (
    <CreateWithBreadcrumbs
      title={<CustomTitle titleKey="resources.roles.titles.newRole" {...props} />}
      record={{}}
      {...props}
    >
      <SimpleForm
        submitOnEnter={false}
        redirect="edit"
      >
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            <BooleanInput source="hidden" label="app.labels.hidden"/>
            <TextInput fullWidth source="name" label="app.labels.name"/>
            <TextInput fullWidth source="description" label="app.labels.description"/>
            <DateField source="createdAt" label="app.labels.createdAt"/>
          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  )
};

export default RoleCreate;
