import React from 'react';
import {
  SimpleForm,
  TextInput,
  AutocompleteInput,
  BooleanInput,
} from 'react-admin';
import { makeStyles } from "@material-ui/styles";

import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';
import CustomTitle from '../custom/CustomTitle'; // T5275
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

const DeviceTypeCreate = props => {
  const classes = useStyles();
  return (
    <CreateWithBreadcrumbs
      title={<CustomTitle titleKey="resources.devices-types.titles.newDeviceType" {...props} />}
      record={{}}
      {...props}
    >
      <SimpleForm>
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            <BooleanInput source="isActive" label="app.labels.isActive"/>
            <TextInput fullWidth source="name" label="app.labels.name"/>
            <ReferenceInputWithFilter
              label="app.labels.client"
              source="clientUuid"
              reference="clients"
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  )
};

export default DeviceTypeCreate;
