import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  Paper,
  Typography,
  FormControl, useMediaQuery
} from "@material-ui/core";
import { Form } from "react-final-form";
import {
  useTranslate,
  useRedirect,
  Title,
  Button,
  withDataProvider,
  ListToolbar,
  GET_ONE, Button as RAButton
} from "react-admin";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import IconEdit from '@material-ui/icons/Edit';
import IconRefresh from "@material-ui/icons/Refresh";
import IconSave from "@material-ui/icons/Save";
import IconRestore from "@material-ui/icons/RestorePage";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import { SelectDeviceInput } from "./SelectDeviceInput";
import { CustomTitle } from "./CustomTitle";
import { RulesListBulkActionButtons } from "./RulesListBulkActionButtons";
import { CodeEditor } from "../custom/CodeEditor";
import { LOGS_PARAMETERS, NEW_LOGS_PARAMETERS, useEffectOnce, setDeepProperty } from "../custom/utils";
import DeviceData from "../devices/DeviceData"; // T4950
import { EditorWrapper } from "../custom/JSONEditor";
import {CustomBreadcrumbs} from "../custom/CustomBreadcrumbs"; // T5711

const useStyles = makeStyles(theme => ({
  label: { width: "5em", display: "inline-block" },
  button: { margin: "1em" },
  flexGrow: {
    flexGrow: 1
  },
  flexContainer: {
    display: "flex",
    flexFlow: "column"
  },
  side: {
    display: "flex",
    flexFlow: "column",
    alignItems: "stretch"
  },
  paper: {
    padding: "1em"
  },
  toolbarContainer: {
    '& .MuiToolbar-root': {
      padding: '0.75rem',
      width: '100%',
      flexWrap: 'wrap'
    },
    '& .MuiFormHelperText-contained': {
      display: 'none'
    },
  },
  actionsContainer: {
    display: 'flex',
    flexGrow: '1',
    alignItems: 'center',
  }
}));

const verticalButtonStyles = theme => ({
  label: { paddingLeft: '0', display: 'block' },
  button: { width: '120px', display: 'inline-block', textAlign: 'center', fontSize: '11px' },
});

// T5113
const VerticalButton = withStyles(verticalButtonStyles)(
  (props) => (
    <RAButton {...props} variant="text"></RAButton>
  ));

const handleSaveMessage = message => {
  localStorage.setItem("message", JSON.stringify(message));
};

const DryRun = props => {
  const { history, match, location, dataProvider } = props;
  const redirect = useRedirect();
  const translate = useTranslate();
  const classes = useStyles();

  const resourceState = useSelector(reduxState => {
    return (
      (reduxState.admin.resources.devices &&
        reduxState.admin.resources.devices.list) ||
      {}
    );
  });
  const [selectedIds, setSelectedIds] = useState(resourceState.selectedIds
    ? resourceState.selectedIds
    : []);
  let [deviceDataRef, setDeviceDataRef] = useState(null);
  const resource = "devices";
  const [pollingToken, setPollingToken] = useState(
    localStorage.getItem("pollingToken")
  );
  const [record, setRecord] = useState(null);

  const [allRulesSelected, setAllRulesSelected] = useState(true);
  const [selectedRules, setSelectedRules] = useState([]);

  const storageMessage = localStorage.getItem("message");
  const message = (storageMessage && storageMessage !== 'undefined') ? JSON.parse(storageMessage) : {};
  const [dryRun, setDryRun] = useState({
    message: message,
    queue: [],
    payload: {},
    deviceUuid: ""
  });

  const handleChange = (data, record, source) => {
    setDeepProperty(record, source, data);
  };

  // T5314
  const handleSubmit = () => {
    return false;
  };

  const toggleRuleCheckbox = rule => {
    let selected;
    if (selectedRules.indexOf(rule.uuid) >= 0) {
      // exclude
      selected = [...selectedRules.filter(uuid => uuid !== rule.uuid)];
    } else {
      // include
      selected = [...selectedRules, rule.uuid];
    }
    setSelectedRules(selected);
  }

  const toggleAllRulesSelected = () => {
    if (allRulesSelected) {
      setSelectedRules([]);
    } else {
      setSelectedRules(record.rules.map(r => r.uuid));
    }
    setAllRulesSelected(!allRulesSelected);
  };

  useEffect(() => {
    if (!record) {
      return;
    }
    setAllRulesSelected(selectedRules.length === record.rules.length);
    const ids = record.rules.filter(r => selectedRules.indexOf(r.uuid) >= 0).map(rule => rule.RulesAssignments.uuid);
    setSelectedIds(ids);
    dryRun.queue = ids;
    dryRun.deviceUuid = record.uuid;
    setDryRun(dryRun);
  }, [selectedRules, setSelectedIds, dryRun, record]);

  const rulesRowClick = rule => {
    setRecord({...record, selectedRule: {rule}});
  };

  const fetchData = useCallback(async () => {
    // Fetch one by uuid from uri
    if (location.search) {
      let deviceUuid = null;
      try {
        deviceUuid = location.search.match(/deviceUuid=(.*)/i)[1];
      } catch (err) {
        deviceUuid = null;
      }
      const record = await dataProvider(GET_ONE, "devices", {
        id: deviceUuid,
        filter: { embedded: { rules: true, model: { embedded: { rules: true } } } }
      }).then(response => {
        // T5849 concat device rules and model rules
        const device = response.data;
        const deviceModelBodyBefore = device.model.rules.filter(r => r.RulesAssignments.executionType === "before");
        const deviceRulesBody = device.rules;
        const deviceModelBodyAfter = device.model.rules.filter(r => r.RulesAssignments.executionType === "after");

        // check priority
        const sortByPriority = (a, b) => {
          return a.RulesAssignments.priority - b.RulesAssignments.priority;
        };
        deviceModelBodyBefore.sort(sortByPriority);
        deviceRulesBody.sort(sortByPriority);
        deviceModelBodyAfter.sort(sortByPriority);

        // ordrer rules list by execution priority
        device.rules = [];
        let processedRules = [];
        [
          ...deviceModelBodyBefore,
          ...deviceRulesBody,
          ...deviceModelBodyAfter,
        ].forEach(item => {
          if (processedRules.indexOf(item.RulesAssignments.uuid) >= 0) {
            return;
          }
          processedRules.push(item.RulesAssignments.uuid);
          device.rules.push(item);
        });
        return device;
      });
      setRecord(record);
    } else {
      return;
    }
  }, [location, dataProvider, setRecord]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // // Unmount case
  // useEffect(() => () => selectedIds.splice(0, selectedIds.length), [
  //   selectedIds
  // ]);

  // T5884
  let editorSourceCode = 'selectedRule.rule.code';
  let editorLabel = 'app.labels.rule';
  if (record && record.selectedRule && record.selectedRule.rule && record.selectedRule.rule.draftCode) {
    editorSourceCode = 'selectedRule.rule.draftCode';
    editorLabel = 'app.labels.ruleDraft';
  }

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <CustomBreadcrumbs />
          <Title record={record} title={<CustomTitle record={record} />} />
          <Grid container spacing={2} className={classes.toolbarContainer} alignItems="center">
            <ListToolbar
              filters={
                <DryRunFilters
                  history={history}
                  dryRun={dryRun}
                  setDryRun={setDryRun}
                  location={location}
                  record={record}
                />
              }
              actions={
                <DryRunActions
                  selectedRules={selectedRules}
                  dryRun={dryRun}
                  setDryRun={setDryRun}
                  selectedIds={selectedIds}
                  pollingToken={pollingToken}
                  setPollingToken={setPollingToken}
                  deviceDataRef={deviceDataRef}
                />
              }
            />
          </Grid>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <Grid container alignItems="stretch" spacing={2}>
                <Grid item md={12} lg={5} className={classes.side}>
                  <Paper
                    className={[classes.paper, classes.flexGrow].join(" ")}
                  >
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      paragraph
                    >
                      {translate("resources.dry-run.labels.rulesList")}
                    </Typography>
                    {record ? (
                      <div>
                        {record.rules.length ? (
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={allRulesSelected}
                                        onChange={e => toggleAllRulesSelected()}
                                        color="primary"
                                      />
                                    }
                                  />
                                </TableCell>
                                <TableCell align="left">{translate(`app.labels.name`)}</TableCell>
                                <TableCell align="left">{translate(`app.labels.class`)}</TableCell>
                                <TableCell align="left">{translate(`app.labels.priority`)}</TableCell>
                                <TableCell align="left"></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {record.rules.map((rule, i) => (
                                <TableRow key={`${i}-${rule.uuid}`} hover={true}>
                                  <TableCell>
                                    <FormControlLabel
                                      style={{ marginTop: "-0.4em" }}
                                      control={
                                        <Checkbox
                                          checked={selectedRules.indexOf(rule.uuid) >= 0}
                                          onChange={e => toggleRuleCheckbox(rule)}
                                          color="primary"
                                        />
                                      }
                                    />
                                  </TableCell>
                                  <TableCell align="left" onClick={() => rulesRowClick(rule)}>{rule.name}</TableCell>
                                  <TableCell align="left">{rule.RulesAssignments.class}</TableCell>
                                  <TableCell align="left">{rule.RulesAssignments.priority}</TableCell>
                                  <TableCell align="left">
                                    <Button color="secondary" onClick={() => redirect(`/rules-assignments/${rule.RulesAssignments.uuid}`)}>
                                      <IconEdit />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        ) : (
                          <Typography variant="caption" paragraph>
                            {translate("resources.dry-run.labels.selectDevice")}
                          </Typography>
                        )}
                      </div>) : null}
                  </Paper>
                </Grid>
                <Grid item md={12} lg={7}>
                  <Grid container wrap="nowrap" direction="column" spacing={2}>
                    {record && (
                      <Grid item className={classes.flexGrow}>
                        <Paper className={classes.paper}>
                          <FormControl fullWidth>
                            <CodeEditor
                              record={record}
                              callback={handleChange}
                              source={editorSourceCode}
                              label={editorLabel}
                              placeholder="rule RuleName { when { ... } then { ... } }"
                              mode="markdown"
                              width="100%"
                              height="300px"
                            />
                          </FormControl>
                        </Paper>
                      </Grid>
                    )}
                    <Grid item className={classes.flexGrow}>
                      <Paper className={classes.paper}>
                        <FormControl fullWidth>
                          {dryRun && (
                            <EditorWrapper
                              schemaName="message"
                              record={dryRun}
                              height="300px"
                              defaultValue={{}}
                            />
                          )}
                        </FormControl>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container spacing={2}>
                <Grid item xs>
                  <Paper className={classes.paper}>
                    <Grid container justify="flex-end">
                      <div>
                        <Button
                          label="app.labels.refresh"
                          onClick={() =>
                            deviceDataRef &&
                            deviceDataRef.doRefresh(pollingToken)
                          }
                        >
                          <IconRefresh />
                        </Button>
                      </div>
                    </Grid>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      paragraph
                    >
                      {translate("resources.dry-run.labels.rulesExecutionLog")}
                    </Typography>
                    {record && (
                      <DeviceData
                        source="stats"
                        useLogs={true}
                        timeParameterPath="mqttReceivedAtTimestamp"
                        debounceDelay={300}
                        deviceUuid={record.id}
                        history={history}
                        match={match}
                        record={record}
                        resource={resource}
                        token={pollingToken}
                        perPage={3}
                        tableView={true}
                        sort={{
                          field: "createdAt",
                          order: "desc"
                        }}
                        parameters={LOGS_PARAMETERS}
                        newParameters={NEW_LOGS_PARAMETERS}
                        onRef={ref => {
                          deviceDataRef = ref;
                          return setDeviceDataRef(deviceDataRef);
                        }}
                      />
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

const DryRunFilters = props => {
  const {
    record,
    location,
    dryRun,
    setDryRun,
    history
  } = props;

  // ugly hack to prevent - VM1852 0.chunk.js:489883 Uncaught Error: Cannot fetch a reference to "devices" (unknown resource).
  let [hackDelayedRender, setHackDelayedRender] = useState(false);
  useEffectOnce(() => {
    setTimeout(() => {
      setHackDelayedRender(true);
    }, 300);
  });

  return (
    <Grid item xs={12} md={12} lg={3}>
      {hackDelayedRender && <SelectDeviceInput
        aria-label="app.labels.selectDevice"
        record={record}
        location={location}
        dryRun={dryRun}
        setDryRun={setDryRun}
        history={history}
      />}
    </Grid>
  )
}

const DryRunActions = props => {
  const {
    selectedRules,
    deviceDataRef,
    pollingToken,
    setPollingToken,
    selectedIds,
    dryRun,
    setDryRun,
    dataProvider,
    record,
    setRecord,
    editorSourceCode
  } = props;
  const classes = useStyles();
  const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const handleSave = type => {

    if (record && record.selectedRule && record.selectedRule.rule) {

      const sourceCode = editorSourceCode.indexOf('draftCode') >= 0
        ? record.selectedRule.rule.draftCode
        : record.selectedRule.rule.code;

      const saveParams = {
        id: record.selectedRule.rule.uuid
      };

      if (type === "save") {
        saveParams.data = {
          code: sourceCode,
          // T5874 reset draft code on rule save
          draftCode: null
        };
      } else if (type === "draft") {
        saveParams.data = {
          draftCode: sourceCode,
        };
      } else if (type === "restore") {
        saveParams.data = {
          draftCode: null
        };
      } else {
        console.error("Unknown action");
        return;
      }

      return dataProvider("UPDATE", "rules", saveParams).then(response => {
        record.selectedRule.rule = response.data;
        return setRecord({...record});
      });
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      lg={9}
      className={classes.actionsContainer}
      justify={isLarge ? "flex-end" : "flex-start"}
    >
      {selectedRules.length ? (
        <Grid item>
          <RulesListBulkActionButtons
            dryRun={dryRun}
            setDryRun={setDryRun}
            dataProvider={dataProvider}
            deviceDataRef={deviceDataRef}
            pollingToken={pollingToken}
            setPollingToken={setPollingToken}
            selectedIds={selectedIds}
          />
        </Grid>
      ) : null}
      <Grid item>
        <VerticalButton
          onClick={() =>
            handleSave("save")
          }
          label="app.labels.saveRule"
        >
          <IconSave />
        </VerticalButton>
        <VerticalButton
          onClick={() =>
            handleSaveMessage(dryRun.message)
          }
          label="app.labels.saveMessage"
        >
          <IconSave />
        </VerticalButton>
        <VerticalButton
          onClick={() =>
            handleSave("restore")
          }
          label="app.labels.restoreRule"
        >
          <IconRestore />
        </VerticalButton>
        <VerticalButton
          onClick={() =>
            handleSave("draft")
          }
          label="app.labels.saveDraft"
        >
          <IconSave />
        </VerticalButton>
      </Grid>
    </Grid>
  )
}

export default withDataProvider(DryRun);
