import React from 'react';
import { useTranslate } from "react-admin";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import Tree from "../tree/Tree";

export const ObjectsDashboard = (props) => {

  const user = JSON.parse(localStorage.getItem('user'));
  const translate = useTranslate();

  if (!user || !user.id) {
    return null;
  }

  return (
    <Card>
      <CardHeader title={translate("app.labels.myObjects")} />
      <CardContent>
        <Tree {...props} noDevices={true} tableView={true} basePath={"/objects"} />
      </CardContent>
    </Card>
  );
};
