import React, { useState, useEffect, useMemo, useRef } from 'react';
import { TextField, Grid, Typography, makeStyles } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {useLocale} from "react-admin";
import { useForm } from 'react-final-form';

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const REACT_APP_GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyAvrRaSaD7jNbjYEkZwVXvnGTWa8aWYDeU';

const googleServices = { autocompleteService: null, placesService: null, placesServiceStatus: null };

const parseLocation = (location) => {
  if (!location) {
    return false;
  }
  let lat = null;
  let lng = null;
  const partsBySpace = location.split(' ');
  const partsByComma = location.split(',');
  if (partsBySpace.length === 2) {
    if (partsBySpace[0].split('.').length === 2 && partsBySpace[1].split('.').length === 2) {
      // location entered as 60.601648 56.869652
      lat = partsBySpace[0];
      lng = partsBySpace[1];
    } else if (partsBySpace[0].split(',').length === 2 && partsBySpace[1].split(',').length === 2) {
      // location entered as 60,601648 56,869652
      lat = partsBySpace[0];
      lng = partsBySpace[1];
    }
  } else {
    if (partsByComma.length === 2) {
      // location entered as 60.601648,56.869652
      if (partsByComma[0].split('.').length === 2 && partsByComma[1].split('.').length === 2) {
        lat = partsByComma[0];
        lng = partsByComma[1];
      }
    }
  }
  if (lat === null || lng === null) {
    return false;
  }
  lat = lat.replace(',', '.');
  lng = lng.replace(',', '.');
  if (isNaN(lat) || isNaN(lng)) {
    return false;
  }
  if (!isFinite(lat) || Math.abs(lat) > 90) {
    return false;
  }
  if (!isFinite(lng) || Math.abs(lng) > 180) {
    return false;
  }
  if (!window.google) {
    return;
  }
  return new window.google.maps.LatLng(lat, lng);
};

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}));

const AutocompletePlaces = ({label, fieldName, initialValue, ...props}) => {
  const classes = useStyles();
  const locale = useLocale();
  const form = useForm();
  const [value, setValue] = useState(initialValue);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const lang = locale === 'rus' ? 'ru' : 'en';
  const countryName = locale === 'rus' ? 'Россия' : 'Russia';

  // https://maps.googleapis.com/maps/api/js?key=AIzaSyAvrRaSaD7jNbjYEkZwVXvnGTWa8aWYDeU&libraries=places&callback=initMap&channel=GMPSB_addressselection_v1_cABC

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_API_KEY}&libraries=places&language=${lang}&region=RU"`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const stripAddress = (obj) => {
    const terms = obj.terms;
    if (terms.length > 2 && terms[terms.length - 1].value === countryName) {
      terms.pop();
      if (obj.structured_formatting.secondary_text.split(',').length > 2) {
        terms.pop();
      }
    }
    return terms.map(term => term.value);
  };

  const fetch = useMemo(
    () =>
      throttle((request, origin, callback) => {
        if (origin === 'autocomplete') {
          googleServices.autocompleteService.getPlacePredictions(request, callback);
        } else if (origin === 'nearby') {
          googleServices.placesService.nearbySearch(request, callback);
        }
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    if (!googleServices.autocompleteService && window.google) {
      googleServices.autocompleteService = new window.google.maps.places.AutocompleteService();
      const map = new window.google.maps.Map(document.createElement('div'));
      googleServices.placesService = new window.google.maps.places.PlacesService(map);
      googleServices.placesServiceStatus = window.google.maps.places.PlacesServiceStatus;
    }
    if (!googleServices.autocompleteService) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    let settings = {
      options: { types: ['address'] },
      input: inputValue
    };
    const location = parseLocation(inputValue);
    let origin = 'autocomplete';
    if (location) {
      settings = {
        location: location,
        radius: '20',
        type: ['address']
      };

      origin = 'nearby';
    }

    fetch(settings, origin, results => {
      if (!active) {
        return;
      }
      let newOptions = [];

      if (value) {
        newOptions = [value];
      }

      if (results) {
        newOptions = [...newOptions, ...results];
      }

      setOptions(newOptions);
    });

    return () => {active = false};
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id={fieldName}
      getOptionLabel={(option) => {
        let value = option;
        if (typeof value === 'string') {
          return value;
        }
        if (value.vicinity) {
          return value.vicinity
        }
        const strippedAddress = stripAddress(option);
        return strippedAddress.join(', ');
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (!newValue) {
          return;
        }
        if (newValue.vicinity) {
          form.change(fieldName, {
            lat: newValue.geometry.location.lat(),
            lng: newValue.geometry.location.lng(),
            formatted_address: newValue.vicinity,
            source: newValue
          });
        } else if (googleServices.placesService) {
          const strippedAddress = stripAddress(newValue);
          googleServices.placesService.getDetails({ placeId: newValue.place_id }, (place, status) => {
            if (status === googleServices.placesServiceStatus.OK) {
              form.change(fieldName, {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
                formatted_address: strippedAddress.join(', '),
                source: newValue
              });
            } else {
              form.change(fieldName, {
                ...form.getState().values[fieldName],
                ...{formatted_address: strippedAddress.join(', ')}
              });
            }
          })
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" fullWidth />
      )}
      renderOption={(option) => {
        if (option.vicinity) {
          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                <span style={{ fontWeight: 700 }}>
                  {option.vicinity}
                </span>
                <Typography variant="body2" color="textSecondary">
                  {option.name}
                </Typography>
              </Grid>
            </Grid>
          );
        }
        if (!option.structured_formatting) {
          return;
        }
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );
        const strippedAddress = stripAddress(option);
        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {strippedAddress[strippedAddress.length - 1]}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

export default AutocompletePlaces
