import React from 'react';
import { Button } from 'react-admin';
import { connect } from 'react-redux';
import { goBack } from 'connected-react-router';
import { ArrowBack } from '@material-ui/icons';

const BackButton = props => {
  const { goBack } = props;

  const handleClick = () => {
    goBack();
  };

  return (
    <Button
      variant="text"
      size="small"
      color="primary"
      startIcon={<ArrowBack />}
      onClick={handleClick}
      label="app.labels.back"
    />
  );
};

export default connect(
  null,
  { goBack }
)(BackButton);