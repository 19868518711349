import * as React from "react";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import get from 'lodash/get';

const PreField = (props) => {
  
  const { className, source, record = {}, emptyText, ...rest } = props;
  const value = source ? get(record, source) : record;

	return (
	    <Typography
	        component="pre"
	        variant="body2"
	        className={className}
	        {...rest}
	    >
	        {value != null && typeof value !== 'string'
	            ? JSON.stringify(value, null, 2)
	            : value || emptyText}
	    </Typography>
	);
}

PreField.defaultProps = {
    addLabel: true,
};

PreField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

export default PreField;