import React, {Fragment, useCallback, useState} from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import {
    UPDATE,
    Button,
    withDataProvider,
    useTranslate,
} from 'react-admin';

import PersonAdd from '@material-ui/icons/PersonAdd';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { fade } from '@material-ui/core/styles/colorManipulator';

import { SaveButton } from '../custom/WithUndoable'; // T5095

const styles = theme =>
    createStyles({
        button: {
          marginTop: '1em'
        },
        resetButton: {
          color: theme.palette.error.main,
          '&:hover': {
            backgroundColor: fade(theme.palette.error.main, 0.12),
            // Reset on mouse devices
            '@media (hover: none)': {
              backgroundColor: 'transparent',
            },
          },
        },
        buttonsRow: {
          justifyContent: 'space-between'
        }
    });

const ChangeAclButton = props => {
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  const { classes } = props;

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSaveClick = useCallback(
    () => {
      let { record, dataProvider } = props;

      setShowDialog(false);
      record.acl = {};

      dataProvider(UPDATE, 'devices', {
        id: record.uuid,
        data: record,
      }).then(result => {
        record.acl = result.data['acl'];
        props.onChange(record.acl, 'acl');
        return record.acl;
      });
    },
    [props]
  );

  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label="resources.devices.buttons.changeAcl"
        className={classes.button}
      >
        <PersonAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="resources.devices.pages.dialogChangeAcl"
      >
        <DialogTitle>
          {translate('resources.devices.pages.dialogChangeAcl')}
        </DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <SaveButton
            label="ra.boolean.true"
            saving={false}
            handleSubmitWithRedirect={handleSaveClick}
          />
          <Button
            label="ra.action.cancel"
            onClick={handleCloseClick}
          >
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default withDataProvider(withStyles(styles)(ChangeAclButton));
