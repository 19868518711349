import React, { useState, useEffect, Fragment } from 'react';
import {
  DateField,
  BooleanInput,
  TopToolbar,
  AutocompleteInput,
  TextInput,
  TextField,
  useTranslate,
  Datagrid, GET_LIST, DELETE,
  withDataProvider, Button,
  Confirm, TabbedForm, FormTab
} from 'react-admin';
import { Link } from 'react-router-dom';
import { useFormState } from 'react-final-form';

import MessageTypeIcon from '@material-ui/icons/Message';

import { makeStyles } from '@material-ui/core';

import VersionInput from '../custom/VersionInput'; // T5154
import BackButton from '../custom/BackButton';
import CloneInstanceButton from "../custom/CloneDialog"; // T5032
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import { ParamsList } from './ParamsList';
import { SchemaEditForm }  from "../custom/SchemaForm"; // T5722
import { EditorWrapper } from "../custom/JSONEditor"; // T5711
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import MessageTypeEdit from "../messages-types/MessageTypeEdit";
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs";
import RuleAssignmentsTab from "../custom/RuleAssignmentsTab";

const placeholderItemParamsSchema = `
{
  '$id': 'https://example.com/schemas/v01/example.json',
  'type': 'object',
  'title': 'Title',
  '$schema': 'http://json-schema.org/draft-07/schema#',
  'required': [],
  'properties': {},
  'description': 'description'
}
`;

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  listMargin: {
    margin: '1em'
  }
});


const WithCloneTopToolbar = ({ basePath, data, ...props }) => (
  <TopToolbar>
    <BackButton />
    <CloneInstanceButton basePath={basePath} record={data} {...props} />
  </TopToolbar>
);

const GridWithFormState = props => {
  const classes = useStyles();
  const formState = useFormState();
  const formValues = formState.values;
  return (
    <SanitizedGrid
      justify="flex-start"
      container={true}
      spacing={2}
      className={classes.root}
    >
      <SanitizedGrid item lg={6} md={12}>
        <VersionInput fullWidth />
        <TextInput fullWidth disabled source="uuid" />
        <BooleanInput source="isActive" label="app.labels.isActive" />
        <TextInput fullWidth source="name" label="app.labels.name" />
        <DateField fullWidth source="createdAt" label="app.labels.createdAt" />
        <DateField fullWidth source="updatedAt" label="app.labels.updatedAt" />
        <ReferenceInputWithFilter
          label="app.labels.client"
          source="clientUuid"
          reference="clients"
        >
          <AutocompleteInput
            optionText="name"
            options={{
              fullWidth: true
            }}
          />
        </ReferenceInputWithFilter>
        <ReferenceInputWithFilter
          label="app.labels.deviceType"
          source="deviceTypeUuid"
          reference="devices-types"
        >
          <AutocompleteInput
            optionText="name"
            options={{
              fullWidth: true
            }}
          />
        </ReferenceInputWithFilter>
        <ReferenceInputWithFilter
          label="app.labels.vendor"
          source="vendorUuid"
          reference="vendors"
          filter={{}}
          perPage={1}
        >
          <AutocompleteInput
            disabled
            optionText="name"
            optionValue="uuid"
            options={{
              fullWidth: true
            }}
          />
        </ReferenceInputWithFilter>
      </SanitizedGrid>
      <SanitizedGrid item lg={6} md={12}>
        <EditorWrapper schemaName="topicNameSchema" record={formValues} defaultValue={{ "attributes": [] }} />
      </SanitizedGrid>
    </SanitizedGrid>
  );
}

const ButtonWithFormState = props => {
  const classes = useStyles();
  const formState = useFormState();
  const record = formState.values;
  return (
    <Button
      className={classes.listMargin}
      variant="contained"
      component={Link}
      to={`/messages-types/create?source={"deviceModelUuid":"${record.id}", "clientUuid":"${record.clientUuid}"}`}
      label="app.labels.addMessageType"
    >
      <MessageTypeIcon />
    </Button>
  );
}

const DeviceModelEdit = ({dataProvider, basePath, resource, ...props}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [messageTypes, setMessageTypes] = useState([]);
  const [messageTypesSort, setMessageTypesSort] = useState({ field: 'updatedAt', order: 'DESC' });
  const [flagToRefresh, setFlagToRefresh] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [delMsgTypeUuid, setDelMsgTypeUuid] = useState(null);

  const messagesById = () => {
    const res = {};
    messageTypes.forEach(messageType => {
      res[messageType.uuid] = messageType
    });
    return res;
  };

  const setSort = (field, order) => {
    const newOrder = order ? order : (messageTypesSort.order === 'DESC') ? 'ASC' : 'DESC';
    setMessageTypesSort({
      field: field,
      order: newOrder
    })
  };

  const closeConfirm = (e) => {
    setConfirmOpen(false);
    setDelMsgTypeUuid(null);
    e.stopPropagation();
  }

  const deleteMessageType = (smth) => {
    setConfirmOpen(false);

    dataProvider(DELETE, 'messages-types', {
      id: delMsgTypeUuid
    }).then(response => {
      const updatedMessageTypesList = messageTypes.filter(messageType => messageType.uuid !== delMsgTypeUuid);
      setMessageTypes(updatedMessageTypesList);
      setDelMsgTypeUuid(null);
    });
  }

  const deleteMsgHandle = (messageTypeUuid) => {
    setDelMsgTypeUuid(messageTypeUuid);
    if (messageTypeUuid) {
      setConfirmOpen(true);
    }
  }

  const handleCreateRule = (id) => {
    return {
      class: "deviceModel",
      mode: "regular",
      executionType: 'before',
      deviceModelUuid: id,
      rule: null,
      groups: [],
      priority: null,
      isActive: true,
      dryRun: true,
      uuid: null,
      quickActionName: null,
    }
  };

  const handleAssignmentsFilter = (id) => {
    return { deviceModelUuid: id, embedded: { rule: true, groups: true } }
  }

  useEffect(() => {
    if (!props.id) {
      return;
    }
    dataProvider(GET_LIST, 'messages-types', {
      filter: { deviceModelUuid: props.id },
      // hack to view all messages types without pagination
      pagination: {
        page: 1,
        perPage: 1000,
      },
      sort: messageTypesSort
    }).then(response => {
      setMessageTypes(response.data);
    });
  }, [props.id, messageTypesSort, dataProvider]);

  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.deviceModel" {...props} />}
      actions={
        <WithCloneTopToolbar
          referenceLabel="resources.devices-models.titles.cloneDeviceModel"
          referenceModel="devices-models"
          referenceId="uuid"
          referenceAction="devices-models-create"
          relatedReferenceModel="messages-types"
          relatedReferenceLabel="app.labels.cloneMessageTypes"
          relatedReferenceId="deviceModelUuid"
          {...props}
        />
      }
      basePath={basePath}
      resource={resource}
      {...props}
    >
      <TabbedForm redirect="edit">
        <SchemaEditForm
          label="resources.devices-models.tabs.modelParams"
          schemaName="itemParamsSchema"
          schemaLabel="app.labels.itemParamsSchema"
          ignoreState={true}
          isTabbed={true}
          className={classes.root}
          schemaPlaceholder={placeholderItemParamsSchema}
          flattenSchemaName="flattenedSchema"
        >
          <GridWithFormState />
        </SchemaEditForm>
        <FormTab label="resources.devices-models.tabs.messageTypes" path="message-types">
          <SanitizedGrid
            justify="flex-start"
            container={true}
            spacing={0}
            className={classes.root}
          >
            <SanitizedGrid item lg={12} md={12}>
              <Confirm
                isOpen={confirmOpen}
                title={translate('ra.message.delete_title', { name: "message type", id: delMsgTypeUuid })}
                content='ra.message.delete_content'
                onConfirm={deleteMessageType}
                onClose={closeConfirm}
              />
              {messageTypes.length > 0 ? (
                <Datagrid
                  data={messagesById()}
                  ids={messageTypes.map(({ uuid }) => uuid)}
                  currentSort={messageTypesSort}
                  setSort={setSort}
                  rowClick="expand"
                  resource="messages-types"
                  expand={
                    <MessageTypeEdit
                      basePath={basePath}
                      disableDeleteBtn={deleteMsgHandle}
                      redirect={false}
                      location="devices-models"
                    />
                  }
                >
                  <TextField
                    source="name"
                    label="app.labels.name"
                  />
                </Datagrid>
              ) : (
                <Fragment>
                  <div className={classes.listMargin}>
                    {translate('resources.devices-models.titles.noMessageTypes')}
                  </div>
                </Fragment>
              )}
              <ButtonWithFormState />
            </SanitizedGrid>
          </SanitizedGrid>
        </FormTab>
        <FormTab label="resources.devices-models.tabs.plateSetting" path="plate-settings">
          <ParamsList
            flagToRefresh={flagToRefresh}
            setFlagToRefresh={setFlagToRefresh}
            messageTypes={messageTypes}
            className={classes.root}
            {...props}
          />
        </FormTab>
        <FormTab label="resources.devices-models.tabs.management" path="management">
          <RuleAssignmentsTab
            handleCreateRule={handleCreateRule}
            handleAssignmentsFilter={handleAssignmentsFilter}
            withDeviceSelect={true}
            {...props}
          />
        </FormTab>
      </TabbedForm>
    </EditWithBreadcrumbs>
  );
};
export default withDataProvider(DeviceModelEdit);
