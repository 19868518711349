import { makeStyles } from "@material-ui/core";
import { default as IconChildren } from "@material-ui/icons/AccountTree";
import DeviceIcon from "@material-ui/icons/SettingsInputComponent";
import React from "react";
import { Button, Link } from "react-admin";

const counterStyles = makeStyles((theme) => ({
  item: {
    width: 40,
    height: 40,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    textAlign: "center",
    // lineHeight: 40,
    paddingTop: 12,
    paddingRight: 6,
    paddingLeft: 6,
    borderRadius: 3,
    fontSize: 24,
    color: "#fff",
    borderWidth: 1,
    borderColor: "#0c0",
  },
  ok: {
    backgroundColor: "#090",
  },
  error: {
    backgroundColor: "orange",
  },
  fatal: {
    backgroundColor: "#c00",
  },
}));

export const ObjectsListCounter = ({ count }) => {
  const classes = counterStyles();
  const level =
    count === 0 ? classes.ok : count <= 5 ? classes.error : classes.fatal;
  return <div className={`${classes.item} ${level}`}>{count}</div>;
};

export const ShowChildrenButton = ({ record }) => {
  return record && record.children && record.children.length ? (
    <Button
      variant="text"
      component={Link}
      to={`/objects/${record.uuid}/children`}
      label="app.labels.children"
    >
      <IconChildren />
    </Button>
  ) : null;
};

export const ShowDevicesButton = ({ record }) => {
  return record && record.devices && record.devices.length ? (
    <Button
      variant="text"
      component={Link}
      to={`/objects/${record.uuid}/devices`}
      label="app.labels.devices"
    >
      <DeviceIcon />
    </Button>
  ) : null;
};
