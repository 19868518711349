import React, {useState} from "react";
import {
  List,
  ListSubheader,
  ListItem,
  makeStyles,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { useTranslate, withDataProvider } from "react-admin";

import { DeviceCardStyle, fetchDevicesParamsQuery, useInterval, DEFAULT_POLL_TIMEOUT } from "../custom/CardDatagrid";
import { BackToPreviousNode } from "./BackToPreviousNode";
import { TreePagination } from "./TreePagination";
import { useEffectOnce } from '../custom/utils';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4)
  },
  marginHorizontal: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

const ListSubHeader = props => {
  const translate = useTranslate();

  return (
    <ListSubheader component="div" id="nested-list-subheader">
      {translate("resources.tree.labels.devicesList")}
    </ListSubheader>
  );
};

const ItemView = props => {
  const {
    record,
    source,
    handleBackClick,
    handleClick,
    isLoading,
    navEvent,
    dataProvider,
    pagination: { currentPage, perPage, setPage, total }
  } = props;
  let item = null;
  let isItemsList = false;
  // const recordsParams = {};
  const [recordsParams, setRecordsParams] = useState({});
  const translate = useTranslate();
  const classes = useStyles();

  if (record && record[source]) {
    if (Array.isArray(record[source]) && record[source].length > 0) {
      item = record[source];
      isItemsList = true;
    }

    if (!Array.isArray(record[source])) {
      item = record[source];
    }
  }

  const fetchDevicesParams = () => {
    if (!item) {
      return;
    }
    let records = (item && item.children) ? item.children : item;
    if (records.length === 0) {
      records = [item];
    }
    fetchDevicesParamsQuery(dataProvider, records.map(i => i.id), records).then(recordsParams => {
      setRecordsParams(recordsParams);
    });
  };

  useEffectOnce(() => {
    fetchDevicesParams();
  });

  useInterval(() => {
    fetchDevicesParams();
  }, DEFAULT_POLL_TIMEOUT);

  return (
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      subheader={<ListSubHeader />}
    >
      {isLoading ? (
        <CircularProgress
          className={classes.marginHorizontal}
          size={30}
          thickness={2}
        />
      ) : (
        <div>
          {item && total ? (
            <div className={classes.marginHorizontal}>
              <TreePagination
                className={classes.marginHorizontal}
                rowsPerPageOptions={[10, 25, 50, 100]}
                page={currentPage}
                perPage={perPage}
                setPage={page =>
                  setPage(
                    source,
                    isItemsList ? null : item.uuid,
                    page
                  )
                }
                total={total}
              />
            </div>
          ) : null}
          {item && (
            <BackToPreviousNode
              record={item}
              callback={handleBackClick}
              navEvent={navEvent}
            />
          )}
          {item ? (
            <List component="div">
              {isItemsList ? (
                item.map(_item => (
                  <ListItem
                    key={_item.uuid}
                    button
                    onClick={() =>
                      handleClick(_item.resource, _item.uuid, _item)
                    }
                  >
                    <DeviceCardStyle recordsParams={recordsParams} record={_item} />
                  </ListItem>
                ))
              ) : item ? (
                <div key={item.uuid}>
                  {/* PARENT NODE */}
                  {(item.children &&
                  item.children.length > 0) ? null : (
                    <ListItem key={item.uuid}>
                      <DeviceCardStyle recordsParams={recordsParams} record={item} />
                    </ListItem>
                  )}
                  {/* CHILDREN NODES */}
                  <List
                    component="div"
                    disablePadding
                    className={classes.nested}
                  >
                    {item.children &&
                      item.children.length > 0 &&
                      item.children.map(_item => (
                        <ListItem
                          key={_item.uuid}
                          button
                          onClick={() =>
                            handleClick(_item.resource, _item.uuid, _item)
                          }
                        >
                          <DeviceCardStyle recordsParams={recordsParams} record={_item} />
                        </ListItem>
                      ))}
                  </List>
                </div>
              ) : null}
            </List>
          ) : (
            <Typography variant="caption" className={classes.marginHorizontal}>
              {translate("resources.tree.messages.select-item")}
            </Typography>
          )}
          {item && total ? (
            <div className={classes.marginHorizontal}>
              <TreePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                page={currentPage}
                perPage={perPage}
                setPage={page =>
                  setPage(
                    source,
                    isItemsList ? null : item.uuid,
                    page
                  )
                }
                total={total}
              />
            </div>
          ) : null}
        </div>
      )}
    </List>
  );
};

export default withDataProvider(ItemView);
