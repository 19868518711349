import React from 'react';
import Button from '@material-ui/core/Button';
import { useSetLocale, useLocale } from 'react-admin';

import { changeSettings } from '../custom/utils';
import localemap from '../../i18n/localemap';

const LocaleSwitcher = () => {
  const setLocale = useSetLocale();
  const locale = useLocale();

  return (
    <div>
      <Button
        color="inherit"
        onClick={() => {
          let newLocale = '';
          let indexCurrentLocale = -1;
          const localemapArray = Object.values(localemap.localemap);

          localemapArray.forEach((loopLocale, index) => {
            if (locale === loopLocale) {
              indexCurrentLocale = index;
            }
          });

          indexCurrentLocale++;

          if (localemapArray.length > indexCurrentLocale) {
            newLocale = localemapArray[indexCurrentLocale];
          } else {
            newLocale = localemapArray[0];
          }

          changeSettings('locale', newLocale);
          setLocale(newLocale);
        }}
      >
        {locale}
      </Button>
    </div>
  );
}



export default LocaleSwitcher;
