import React from 'react';
import {
  TextInput,
  AutocompleteInput,
  Datagrid,
  FormTab,
  TabbedForm,
  ReferenceManyField,
  TextField,
  NumberField,
  DateField,
  BooleanField,
  ReferenceField,
  FormDataConsumer,
  usePermissions
} from "react-admin";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import EditAssignmentButton from './EditAssignmentButton';
import AddAssignmentButton from './AddAssignmentButton';
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import { CodeEditor } from '../custom/CodeEditor';
import VersionInput from '../custom/VersionInput';
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
});

const RuleEdit = props => {
  const classes = useStyles();
  const { permissions } = usePermissions();

  return (
    <EditWithBreadcrumbs
      title={<CustomEditTitle titleKey="app.labels.rule" {...props} />}
      {...props}
    >
      <TabbedForm redirect="edit">
        <FormTab label="resources.rules.tabs.rule">
          <Grid
            justify="flex-start"
            container={true}
            spacing={2}
            className={classes.root}
          >
            <Grid item lg={6} md={12}>
              <FormDataConsumer>{formDataProps => {
                // T5874 reset draft code
                formDataProps.formData.draftCode = null;
                return (
                  <div>
                    <VersionInput />
                    <TextInput source="uuid" disabled fullWidth />
                    <TextInput
                      source="name"
                      label="app.labels.name"
                      disabled
                      fullWidth
                    />
                    <TextInput
                      source="description"
                      label="app.labels.description"
                      disabled
                      fullWidth
                    />
                    <ReferenceInputWithFilter
                      label="app.labels.client"
                      source="clientUuid"
                      reference="clients"
                    >
                      <AutocompleteInput
                        optionText="name"
                        options={{
                          fullWidth: true
                        }}
                      />
                    </ReferenceInputWithFilter>
                    <CodeEditor
                      record={formDataProps.formData}
                      width="100%"
                      source="code"
                      label="app.labels.code"
                      placeholder="rule RuleName { when { ... } then { ... } }"
                      mode="markdown"
                    />
                  </div>
                );
              }}
              </FormDataConsumer>
            </Grid>
          </Grid>
        </FormTab>
        { permissions && permissions['rules.assignments.list'] &&
        <FormTab label="resources.rules.tabs.assignments" path="assignments">
          <ReferenceManyField
            addLabel={false}
            sort={{ field: "createdAt", order: "DESC" }}
            reference="rules"
            target="assignments"
          >
            <Datagrid>
              <BooleanField source="isActive" label="app.labels.isActive" />
              <BooleanField source="dryRun" label="app.labels.dryRun" />
              <TextField source="uuid" />
              <ReferenceField
                label="app.labels.rule"
                source="ruleUuid"
                reference="rules"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField label="app.labels.mode" source="mode" />
              <TextField label="app.labels.class" source="class" />
              <TextField
                label="app.labels.executionType"
                source="executionType"
              />
              <NumberField source="priority" label="app.labels.priority" />
              <TextField source="schedule" label="app.labels.schedule" />
              <ReferenceField
                label="app.labels.deviceModel"
                source="deviceModelUuid"
                reference="devices-models"
                allowEmpty={true}
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="app.labels.deviceType"
                source="deviceTypeUuid"
                reference="devices-types"
                allowEmpty={true}
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="app.labels.device"
                source="deviceUuid"
                reference="devices"
                allowEmpty={true}
              >
                <TextField source="name" />
              </ReferenceField>
              <DateField source="createdAt" label="app.labels.createdAt" />
              <DateField source="updatedAt" label="app.labels.updatedAt" />
              <EditAssignmentButton />
            </Datagrid>
          </ReferenceManyField>
          <AddAssignmentButton />
        </FormTab>
        }
      </TabbedForm>
    </EditWithBreadcrumbs>
  );
}


export default RuleEdit;
