import React, { useState, useCallback,  Fragment } from 'react';
import {
  SimpleForm,
  Toolbar,
  Labeled,
  FormTab,
  useTranslate,
  Button
} from 'react-admin';
import { useFormState, useForm } from 'react-final-form';
import { connect } from 'react-redux';
import { showNotification } from 'ra-core';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import IconDelete from '@material-ui/icons/Delete';

import { JsonForm } from '../custom/JsonForm';
import Editor from "../custom/JSONEditor";
import { SaveButton, DeleteButton } from '../custom/WithUndoable';
import { getDeepProperty, useEffectOnce } from "./utils";

let codeEditorErrors = [];

// T4999
const ToolbarWithErrors = props => {
  const { showNotification, save, disableDeleteBtn, ...rest } = props;
  const formState = useFormState();

  const handleClick = useCallback(
    redirect => {
      if (redirect === void 0) {
        if (typeof props.redirect === 'undefined') {
          redirect = 'edit';
        } else {
          redirect = props.redirect;
        }
      }

      if (codeEditorErrors.length || !formState.valid) {
        showNotification('ra.message.invalid_form', 'warning');
        return;
      }
      return save(formState.values, redirect);
    },
    [showNotification, save, formState, props.redirect]
  );

  if (disableDeleteBtn) {
    return (
      <Toolbar
        children={[
          <SaveButton handleSubmitWithRedirect={handleClick} />,
          <Button
            label="ra.action.delete"
            style={{color: "#f44336"}}
            onClick={() => disableDeleteBtn(props.record.uuid)}
          >
            <IconDelete />
          </Button>
        ]}
        {...rest}
      />
    );
  }

  return (
    <Toolbar
      children={[
        <SaveButton handleSubmitWithRedirect={handleClick} />,
        <DeleteButton />
      ]}
      {...rest}
    />
  );
};

const SchemaToolbar =
  // T5159
  connect(null, { showNotification })
    (withStyles(theme => ({desktopToolbar: { justifyContent: 'space-between' }}))
      (ToolbarWithErrors));

// T4999
export const SchemaEditForm = props => {
  const {
    schemaPlaceholder,
    schemaName,
    flattenSchemaName,
    schemaLabel,
    ignoreState,
    save,
    label,
    children,
    inlineChildren,
    isTabbed,
    ...rest
  } = props;
  return isTabbed ? (
    <FormTab {...rest} label={label}>
      <SchemaEditFormInner {...props} />
    </FormTab>
  ) : (
    <SimpleForm {...rest} toolbar={<SchemaToolbar save={save} />}>
      <SchemaEditFormInner {...props} />
    </SimpleForm>
  );
}

export const SchemaEditFormInner = props => {
  const {
    schemaPlaceholder,
    schemaName,
    flattenSchemaName,
    schemaLabel,
    ignoreState,
    save,
    label,
    children,
    inlineChildren,
    ...rest
  } = props;
  const [state, setState] = useState({ errors: [] });

  const handleValidate = () => {
    state.error = codeEditorErrors;
    setState({ ...state });
  };

  return inlineChildren ? (
    <JsonSchemaEditor
      schemaName={schemaName}
      flattenSchemaName={flattenSchemaName}
      ignoreState={ignoreState}
      schemaLabel={schemaLabel}
      schemaPlaceholder={schemaPlaceholder}
      validationCallback={handleValidate}
      reverseEditorPosition={true}
      record={rest.record}
    >
      {children}
    </JsonSchemaEditor>
  ) : (
    <Fragment>
      {children}
      <JsonSchemaEditor
        schemaName={schemaName}
        flattenSchemaName={flattenSchemaName}
        schemaLabel={schemaLabel}
        ignoreState={ignoreState}
        schemaPlaceholder={schemaPlaceholder}
        validationCallback={handleValidate.bind(this)}
        record={rest.record}
      />
    </Fragment>
  );
};

export const SchemaForm = props => {
  const { label, record, source, callback, formRef, ignoreState } = props;
  const _formRef = formRef || React.createRef();

  // T4999
  const handleChange = ({ formData }) => {
    if (ignoreState) {
      return;
    }
    if (callback && typeof callback === 'function') {
      callback(formData, record, source);
    }
  };

  return (
    <Labeled label={label || "app.labels.schema"}>
      <JsonForm
        fullWidth
        source={source}
        formRef={_formRef}
        ignoreState={ignoreState}
        label={label}
        schema={
          record && record[source] ? record[source] : {}
        }
        onChange={handleChange}
        record={record}
        {...props}
      />
    </Labeled>
  );
};

export const JsonSchemaEditor = props => {
  const {
    basePath,
    schemaName,
    flattenSchemaName,
    schemaLabel,
    schemaPlaceholder,
    validationCallback,
    reverseEditorPosition,
    ignoreState,
    children,
    ...rest
  } = props;

  const form = useForm();
  const jsonFormRef = React.createRef();
  const translate = useTranslate();
  let value = getDeepProperty(rest.record, schemaName);
  if (!value && schemaPlaceholder) {
    value = schemaPlaceholder
  }
  let [schemaValue, setSchemaValue] = useState(value || {});

  const handleChange = (data, record, source) => {
    if (ignoreState) {
      return;
    }
    record[source] = data;
  };

  // T4999
  const handleValidation = useEffectOnce(
    errors => {
      if (errors) {
        codeEditorErrors = errors;
      }
      validationCallback();
    },
    []
  );

  const handleEditorChange = (flattened, data) => {
    if (flattenSchemaName) {
      rest.record[flattenSchemaName] = flattened;
      rest.record[schemaName] = data;
    } else {
      rest.record[schemaName] = flattened;
    }
    setSchemaValue(flattened);
    if (flattenSchemaName) {
      form.change(flattenSchemaName, flattened);
      form.change(schemaName, data);
    } else {
      form.change(schemaName, flattened);
    }
  };

  return (
    <Grid
      style={{ minWidth: '100%' }}
      container
      spacing={5}
      justify="space-between"
      {...rest}
    >
      <Grid item lg={6} md={12}>
        {!reverseEditorPosition ? children : null}
        <p>{translate(`app.labels.${schemaName}`)}</p>
        <Editor
          htmlElementProps={{style: { height: '500px' }}}
          value={value}
          onChange={handleEditorChange}
          onValidationError={handleValidation}
          mode='code'
        />
        {reverseEditorPosition ? children : null}
      </Grid>
      <Grid item lg={6} md={12}>
        <SchemaForm
          source={flattenSchemaName ? flattenSchemaName : schemaName}
          label={schemaLabel}
          ignoreState={ignoreState}
          callback={handleChange}
          formRef={jsonFormRef}
          schemaValue={schemaValue}
          {...rest}
        />
      </Grid>
    </Grid>
  );
};

export default SchemaEditForm;
