import React from 'react';
import {withStyles} from "@material-ui/core/styles";

import {getSettings} from '../components/custom/utils';

const styles = theme => {
	if (theme.palette.type === "light") {
		return {
			stopColorOne: {
				stopColor: '#ff4f12',
			},
			stopColorTwo: {
				stopColor: '#000',
			},
			pathInner: {
				fill: '#ff4f12'
			},
			pathOuter: {
				fill: '#7800ff'
			},
			text: {
				fill: '#000000'
			}
		}
	}
	return {
		stopColorOne: {
			stopColor: '#F05223',
		},
		stopColorTwo: {
			stopColor: '#b9350f',
		},
		pathInner: {
			fill: '#F05223'
		},
		pathOuter: {
			fill: '#7b929b'
		},
		text: {
			fill: '#fff'
		}
	}
}

const Logo = props => {
	const { classes } = props;

  const settings = getSettings();
  if (settings.theme === 'customTheme' && settings.customTheme) {
    const logoFile = settings.customTheme.logoFile;
    if (!logoFile) {
      return null;
    }
    if (!logoFile.src) {
      return null;
    }
    if (!logoFile.src.data || logoFile.src.data.length === 0) {
      return null;
    }

    function toBase64(arr) {
      return btoa(
          arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
      );
    }
    const extension = (settings.customTheme || {}).logoFileExtension || 'png';
    let mimeType;
    if (extension === 'jpeg' || extension === 'jpg') {
      mimeType = "image/jpeg"
    } else if (extension === 'png') {
      mimeType = "image/png"
    } else if (extension === 'svg') {
      mimeType = "image/svg+xml"
    }
    if (!mimeType) {
      console.error('wrong file extension', logoFile.title);
      return null;
    }
    let src = `data:${mimeType};base64,${toBase64(logoFile.src.data)}`;
    return <img width="96px" height="25px" src={src} alt="logo" />;
  }

  if (true) {
    return null;
  }

	return (
	<svg width="96px" height="25px" style={{position: 'absolute', left: 'calc(50% - 48px)', right: 'calc(50% - 48px)'}} viewBox="0 0 193 50" xmlns="http://www.w3.org/2000/svg">
		<defs>
			<linearGradient name="logoShadow" id="logo-9" x1="55.883%" x2="14.167%" y1="73.973%" y2="0%">
				<stop offset="0%" className={classes.stopColorOne}></stop>
				<stop offset="100%" className={classes.stopColorTwo}></stop>
			</linearGradient>
		</defs>
		<g id="logo" name="logo" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" transform="translate(0, 0)">
			<path name="innerSide" pid="0"
				d="M22,12 C13.7296802,3.99981632 9.59442802,-0.000183678141 9.59424346,0 L1.50568374,8 L1.50568374,8 C0.67361982,9.0972718 0.888613285,10.6613074 1.98588509,11.4933713 C2.41936453,11.8220799 2.94845379,12 3.49247009,12 C3.50604423,12 3.51961837,12 3.53319251,12 C9.68879501,12 15.8443975,12 22,12 Z"
				transform="translate(0.000000, 38.000000)" className={classes.pathInner}></path>
			<path name="outerSide" pid="1"
			d="M30,18 L12,0 L1.95028875,10.4684492 C0.698836027,11.7720458 9.5486605e-15,13.5091031 9.76996262e-15,15.316172 L3.55271368e-15,46 C3.82325518e-15,48.209139 1.790861,50 4,50 L4,50 L4,50 C2.63136806,50 1.52187266,48.8905046 1.52187266,47.5218727 C1.52187266,46.8629999 1.78425529,46.2312512 2.25103343,45.7662443"
				className={classes.pathOuter}></path>
		</g>
		<g id="logo_text" name="logo_text" transform="translate(33.000000, 25.000000) scale(1)" className={classes.text} fillRule="evenodd">
			<polygon
			points="125.272633 6.17408992 120.944198 6.17408992 115.773594 11.4029891 115.773594 6.17408992 112.113191 6.17408992 112.113191 20.582524 115.773594 20.582524 115.773594 14.6275473 121.031516 20.582524 125.44702 20.582524 118.649377 12.8553922"></polygon>
			<path d="M9.12153798,9.91461705 L3.83434419,9.91461705 L3.83434419,3.52379535 L9.12153798,3.52379535 C11.7939101,3.52379535 13.0141271,4.80181085 13.0141271,6.71908217 C13.0141271,8.63635349 11.7939101,9.91461705 9.12153798,9.91461705 M9.23763101,0.0958263566 L4.96124031e-05,0.0958263566 L4.96124031e-05,20.430462 L3.83434419,20.430462 L3.83434419,13.342338 L9.23763101,13.342338 C13.8275225,13.342338 16.8486698,10.9894698 16.8486698,6.71908217 C16.8486698,2.44869457 13.8275225,0.0958263566 9.23763101,0.0958263566"></path>
			<path d="M25.5039256,17.5614264 C23.3544682,17.5614264 21.3498791,16.0217054 21.3498791,13.3781085 C21.3498791,10.7347597 23.3544682,9.19528682 25.5039256,9.19528682 C27.653631,9.19528682 29.6579721,10.7347597 29.6579721,13.3781085 C29.6579721,16.0217054 27.653631,17.5614264 25.5039256,17.5614264 M25.5039256,5.76731783 C21.5532899,5.76731783 17.6314295,8.32384496 17.6314295,13.3781085 C17.6314295,18.4331163 21.5532899,20.9891473 25.5039256,20.9891473 C29.4548093,20.9891473 33.3764217,18.4331163 33.3764217,13.3781085 C33.3764217,8.32384496 29.4548093,5.76731783 25.5039256,5.76731783"></path>
			<path d="M42.7212403,17.5614264 C40.5134884,17.5614264 38.8866977,15.9636589 38.8866977,13.3781085 C38.8866977,10.8218295 40.5715349,9.19528682 42.7212403,9.19528682 C44.0285271,9.19528682 45.1614264,9.80527132 46.0618915,11.1706047 L49.1991318,9.36942636 C48.1535504,7.1324031 45.8875039,5.76731783 42.7502636,5.76731783 C38.6542636,5.76731783 35.1682481,8.78846512 35.1682481,13.3781085 C35.1682481,17.968 38.480124,20.9891473 42.7502636,20.9891473 C45.8294574,20.9891473 47.891845,19.5950388 49.3444961,17.1258295 L46.3233488,15.4700155 C45.451907,16.6899845 44.5221705,17.5614264 42.7212403,17.5614264"></path>
			<path d="M81.6933953,14.3371659 C81.5192558,16.5446698 81.0829147,17.0388093 80.3275659,17.0388093 C79.8115969,17.0388093 79.5689922,16.981507 79.2157519,16.8889798 L79.2341085,20.6178481 C79.2341085,20.6178481 79.6890543,20.7567628 80.5314729,20.7567628 C83.6396899,20.7567628 84.8016124,18.7524217 85.0923411,14.4822822 L85.4408682,9.4567938 L89.5656434,9.4567938 L89.5656434,20.5536 L93.2260465,20.5536 L93.2260465,6.17418915 L82.3033798,6.17418915 L81.6933953,14.3371659 Z"></path>
			<path d="M67.3025488,11.4612093 C67.8254636,9.8344186 69.2778667,8.55615504 71.195138,8.55615504 C73.2287504,8.55615504 74.5940837,9.66003101 74.9426109,11.4612093 L67.3025488,11.4612093 Z M71.3114791,5.76744186 C67.1284093,5.76744186 63.7294636,8.8176124 63.7294636,13.3782326 C63.7294636,17.9391008 67.1284093,20.9892713 71.1370915,20.9892713 C74.3036031,20.9892713 76.3079442,19.7983256 77.7603473,17.3291163 L74.855293,15.8767132 C73.9548279,17.0096124 73.1414326,17.8517829 71.195138,17.8517829 C69.0456806,17.8517829 67.4769364,16.4576744 67.2445023,14.2499225 L78.3413085,14.2499225 L78.3413085,12.9716589 C78.3413085,8.75956589 75.4365023,5.76744186 71.3114791,5.76744186 L71.3114791,5.76744186 Z"></path>
			<path d="M98.848124,11.4612093 C99.3710388,9.8344186 100.823442,8.55615504 102.740713,8.55615504 C104.774078,8.55615504 106.139411,9.66003101 106.488186,11.4612093 L98.848124,11.4612093 Z M102.857054,5.76744186 C98.6737364,5.76744186 95.2750388,8.8176124 95.2750388,13.3782326 C95.2750388,17.9391008 98.6737364,20.9892713 102.682667,20.9892713 C105.84893,20.9892713 107.853271,19.7983256 109.305922,17.3291163 L106.401116,15.8767132 C105.500403,17.0096124 104.687008,17.8517829 102.740713,17.8517829 C100.591008,17.8517829 99.0222636,16.4576744 98.7900775,14.2499225 L109.886884,14.2499225 L109.886884,12.9716589 C109.886884,8.75956589 106.981829,5.76744186 102.857054,5.76744186 L102.857054,5.76744186 Z"></path>
			<polygon
			points="50.4830512 9.36940155 54.782462 9.36940155 54.782462 20.5825488 58.442369 20.5825488 58.442369 9.36940155 62.7420279 9.36940155 62.7420279 6.17411473 50.4830512 6.17411473"></polygon>
			<path d="M132.713898,17.5615504 C130.564192,17.5615504 128.559851,16.0218295 128.559851,13.3782326 C128.559851,10.7348837 130.564192,9.19541085 132.713898,9.19541085 C134.863603,9.19541085 136.867944,10.7348837 136.867944,13.3782326 C136.867944,16.0218295 134.863603,17.5615504 132.713898,17.5615504 M132.713898,5.76744186 C128.763262,5.76744186 124.841402,8.32372093 124.841402,13.3782326 C124.841402,18.4329922 128.763262,20.9892713 132.713898,20.9892713 C136.664533,20.9892713 140.586394,18.4329922 140.586394,13.3782326 C140.586394,8.32372093 136.664533,5.76744186 132.713898,5.76744186"></path>
			<polygon
			points="155.150313 6.17136124 151.177848 16.262276 147.205135 6.17136124 142.421011 6.17136124 142.421011 20.5537488 145.900329 20.5537488 145.900329 11.4486326 149.756949 20.5537488 152.598747 20.5537488 156.455119 11.4486326 156.455119 20.5537488 159.934685 20.5537488 159.934685 6.17136124"></polygon>
		</g>
	</svg>)
};

export default withStyles(styles)(Logo);
