import React from 'react';
import { Toolbar } from 'react-admin';
import { AppEditSaveButton } from '../custom/utils';
import { makeStyles } from "@material-ui/styles";
import { DeleteButton } from "../custom/WithUndoable";

const useStyles = makeStyles({
  flex: {
    display: "flex",
    justifyContent: "space-between"
  }
});

const RoleEditToolbar = props => {
  const classes = useStyles();

  return (
    <Toolbar {...props} className={classes.flex}>
      <AppEditSaveButton
        basePath={props.basePath}
        label="ra.action.save"
        redirect="edit"
        target="permissions"
      />
      <DeleteButton />
    </Toolbar>
  );
}

export default RoleEditToolbar;
