import React, { useEffect, useState, Fragment } from "react";
import { useForm } from 'react-final-form';
import {
  TextInput,
  SelectInput,
  NumberInput,
  DateField,
  AutocompleteInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  SimpleForm,
  useTranslate,
} from "react-admin";
import { useSelector } from 'react-redux';

import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import VersionInput from '../custom/VersionInput'; // T5154
import { CustomEditTitle } from '../custom/CustomTitle'; // T5275
import { EditWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import { EditorWrapper } from "../custom/JSONEditor";
import SanitizedGrid from "../custom/SanitizedGrid";
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { SchemaForm } from "../custom/SchemaForm";

const useStyles = makeStyles({
  disabledSchema: {
    width: '100%',
    height: '300px'
  },
  root: {
    width: "100%"
  }
});

const FormWithMessageType = ({record, ...props}) => {
  const classes = useStyles();
  const jsonFormRef = React.createRef();
  const translate = useTranslate();
  const form = useForm();
  const formValues = form.getState().values;

  const [messageSchema, setMessageSchema] = useState(
    record.messageType ? record.messageType.messageSchema : null
  );
  const [showMessageType, setShowMessageType] = useState(
    record.class === "device" || (record.class === "deviceModel" && record.deviceModelUuid)
  )

  const messageTypesState = useSelector(reduxState => {
    if (reduxState.admin.resources['messages-types']) {
      return reduxState.admin.resources['messages-types'].data;
    }
    return {};
  });

  useEffect(() => {
    if (formValues.messageTypeUuid && !formValues.messageSchema && formValues.messageType && formValues.messageType.messageSchema) {
      form.change('messageSchema', formValues.messageType.messageSchema);
    }
    setShowMessageType(formValues.class === "device" || (formValues.class === "deviceModel" && formValues.deviceModelUuid));
  }, [formValues, form]);

  const handleMessageTypeChange = (uuid) => {
    if (!uuid || !messageTypesState[uuid]) {
      if (messageSchema) {
        setMessageSchema(null);
        form.change('messageSchema', null);
      }
      return;
    }
    form.change('messageSchema', messageTypesState[uuid].messageSchema);
		setMessageSchema(messageTypesState[uuid].messageSchema);
  };
  return (
    <SanitizedGrid
      justify="flex-start"
      container={true}
      spacing={2}
      className={classes.root}
    >
      <Grid item lg={6} md={12}>
        <VersionInput />
        <BooleanInput
          source="isActive"
          label="app.labels.isActive"
        />
        <BooleanInput source="dryRun" label="app.labels.dryRun" />
        <TextInput source="uuid" disabled fullWidth />
        <DateField
          source="createdAt"
          label="app.labels.createdAt"
        />
        <DateField
          source="updatedAt"
          label="app.labels.updatedAt"
          fullWidth
        />
        <ReferenceInputWithFilter
          label="app.labels.rule"
          source="ruleUuid"
          reference="rules"
          filter={{}}
        >
          <AutocompleteInput
            optionText="name"
            options={{
              fullWidth: true
            }}
          />
        </ReferenceInputWithFilter>
        <SelectInput
          label="app.labels.mode"
          source="mode"
          choices={[
            { id: "schedule", name: "app.labels.schedule" },
            { id: "manual", name: "app.labels.manual" },
            { id: "regular", name: "app.labels.regular" }
          ]}
          fullWidth
        />
        {record.mode === "schedule" && (
          <ArrayInput source="schedule" label="app.labels.schedule">
            <SimpleFormIterator>
              <TextInput source="name" label="app.labels.name" fullWidth/>
              <TextInput source="schedule" label="app.labels.schedule" options={{ placeholder: "*/30 * * * * *" }} fullWidth />
            </SimpleFormIterator>
          </ArrayInput>
        )}
        <SelectInput
          label="app.labels.class"
          source="class"
          choices={[
            { id: "system", name: "app.labels.system" },
            { id: "deviceModel", name: "app.labels.deviceModel" },
            { id: "device", name: "app.labels.device" }
          ]}
          fullWidth
        />
        {(record.class === "system" ||
          record.class === "deviceModel") && (
          <SelectInput
            label="app.labels.executionType"
            source="executionType"
            choices={[
              { id: "before", name: "app.labels.before" },
              { id: "after", name: "app.labels.after" }
            ]}
            fullWidth
          />
        )}
        <NumberInput
          source="priority"
          label="app.labels.priority"
          options={{ placeholder: "1" }}
          fullWidth
        />
        <ReferenceInputWithFilter
          label="app.labels.deviceModel"
          source="deviceModelUuid"
          reference="devices-models"
          filter={{ isActive: true }}
        >
          <AutocompleteInput
            optionText="name"
            options={{
              fullWidth: true
            }}
          />
        </ReferenceInputWithFilter>
        <ReferenceInputWithFilter
          label="app.labels.device"
          source="deviceUuid"
          reference="devices"
        >
          <AutocompleteInput
            optionText="name"
            options={{
              fullWidth: true
            }}
          />
        </ReferenceInputWithFilter>
        {record.class === "device" && ['manual', 'schedule'].indexOf(record.mode) >= 0 && (
          <Fragment>
            <TextInput
              source="quickActionName"
              label="app.labels.quickActionName"
              fullWidth
            />
            <EditorWrapper
              schemaName="quickActionPayload"
              height="300px"
              record={record}
              defaultValue={{}}
            />
          </Fragment>
        )}
        {showMessageType ?
          <Fragment>
            <ReferenceInputWithFilter
              label="resources.devices.titles.chooseMessageType"
              allowEmpty={true}
              resource="messages-types"
              reference="messages-types"
              source="messageTypeUuid"
              onChange={handleMessageTypeChange}
              filter={formValues.class === "device" ? {
                direction: {$eq: 'out'}
              } : {
                deviceModelUuid: formValues.deviceModelUuid,
                direction: {$eq: 'out'}
              }}
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
            {messageSchema ?
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <span>{translate('app.labels.payload')}:</span><br/>
                  <textarea
                    className={classes.disabledSchema}
                    disabled
                    value={JSON.stringify(messageSchema, null, 2)}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SchemaForm
                    source="messageSchema"
                    record={formValues}
                    ignoreState={true}
                    formRef={jsonFormRef}
                    schemaValue={messageSchema}
                  />
                </Grid>
              </Grid> : null
            }
          </Fragment>
          : null
        }
        {(formValues.class === "system" || !formValues.messageTypeUuid) ?
          <EditorWrapper
            compactView={true}
            schemaName="payloadSchema"
            record={record}
            height="300px"
            defaultValue={{}}
          /> : null
        }
      </Grid>
    </SanitizedGrid>
  )
}

const RuleEdit = props => {
  return (
    <EditWithBreadcrumbs
      title={
        <CustomEditTitle titleKey="app.labels.ruleAssignment" {...props} />
      }
      {...props}
    >
      <SimpleForm>
        <FormWithMessageType/>
      </SimpleForm>
    </EditWithBreadcrumbs>
  );
}

export default RuleEdit;
