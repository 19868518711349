import React from 'react';
import {
  TextField,
  ReferenceField,
} from 'react-admin';

import Datagrid from '../custom/ClickableDatagrid'; // T5113
import { BulkDeleteAction } from "../custom/WithUndoable"; // T5194
import CustomTitle from '../custom/CustomTitle'; // T5275
import { ListWithBreadcrumbs } from "../custom/CustomBreadcrumbs"; // T6336
import StyledSearchFilter from "../custom/StyledSearchFilter";

const RuleList = props => (
  <ListWithBreadcrumbs
    title={<CustomTitle titleKey="app.labels.rules" {...props} />}
    bulkActionButtons={<BulkDeleteAction />}
    filters={<StyledSearchFilter />}
    filter={{
      embedded: {}
    }}
    sort={{ field: 'createdAt', order: 'DESC' }}
    {...props}
  >
    <Datagrid>
      <TextField source="description" />
      <ReferenceField
        label="app.labels.client"
        source="clientUuid"
        reference="clients"
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </ListWithBreadcrumbs>
);

export default RuleList;
