import React, { cloneElement } from 'react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import {
  UserMenu,
  MenuItemLink,
  toggleSidebar,
  useTranslate,
  HideOnScroll,
  RefreshIconButton
} from 'react-admin';

import {
  AppBar as MuiAppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'

import SettingsIcon from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from "@material-ui/core/styles";
import LocaleSwitcher from "../components/custom/LocaleSwitcher";

import Logo from './Logo';
// import { LogoutButton } from "../components/custom/LogoutButton";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(
  theme => ({
    menu: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center"
    },
    root: {
      backgroundColor: (theme.palette.type === "light") ? "#efefef" : theme.palette.secondary.main,
      color: (theme.palette.type === "light") ? theme.palette.secondary.main : theme.palette.secondary.contrastText
    },
    toolbar: {
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: '0.5em',
      marginRight: '0.5em',
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    spacer: {
      flex: 1
    },
    title: {
      flex: 1,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
  { name: 'RaAppBar' }
);

const SettingsMenuItemLink = withStyles(theme => {
  const color = (theme.palette.type === "light") ? theme.palette.secondary.main : theme.palette.text.secondary;
  return {
    root: {
      color: color
    },
    icon: {
      color: color
    }
  }
})(MenuItemLink);

const CustomUserMenu = props => {
  const { classes } = props;
  const translate = useTranslate();
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <div className={classes.menu}>
      <UserMenu {...props}>
        <SettingsMenuItemLink
          to="/settings"
          primaryText={translate('app.labels.settings')}
          leftIcon={<SettingsIcon />}
        />
        {/*<LogoutButton />*/}
      </UserMenu>
      {user && (
        <span>
            <Typography variant="body1" color="inherit">
              {user.login || user.phone}
            </Typography>
          </span>
      )}
    </div>
  );
};

const loadingStyles = makeStyles(
  {
    loader: {
      margin: 14,
    },
  },
  { name: 'RaLoadingIndicator' }
);

const LoadingIndicator = props => {
  const { classes: classesOverride, className, ...rest } = props;
  const loading = useSelector(state => state.admin.loading > 0);
  const classes = loadingStyles(props);
  return loading ? (
    <CircularProgress
      className={classNames('app-loader', classes.loader, className)}
      color="inherit"
      size={18}
      thickness={5}
      {...rest}
    />
  ) : (
    <RefreshIconButton />
  );
};

const AppBar = props => {
  const {
    children,
    color = 'secondary',
    logo,
    logout,
    open,
    title,
    ...rest
  } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const translate = useTranslate();

  return (
    <HideOnScroll>
      <MuiAppBar className={`${classes.root} hiddenOnPrint`} color={color} {...rest}>
        <Toolbar
          disableGutters
          variant={isXSmall ? 'regular' : 'dense'}
          className={classes.toolbar}
        >
          <Tooltip
            title={translate(
              open
                ? 'ra.action.close_menu'
                : 'ra.action.open_menu',
              {
                _: 'Open/Close menu',
              }
            )}
            enterDelay={500}
          >
            <IconButton
              color="inherit"
              onClick={() => dispatch(toggleSidebar())}
              className={classNames(classes.menuButton)}
            >
              <MenuIcon
                classes={{
                  root: open
                    ? classes.menuButtonIconOpen
                    : classes.menuButtonIconClosed,
                }}
              />
            </IconButton>
          </Tooltip>
          <Typography
            variant="body1"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
          { false ? null : <Logo /> /* T6423 + T4635 */}
          <span className={classes.spacer} />
          <LocaleSwitcher />
          <LoadingIndicator />
          {cloneElement(<CustomUserMenu classes={classes} {...props} />, { logout })}
        </Toolbar>
      </MuiAppBar>
    </HideOnScroll>
  );
};

export default AppBar;
