import React from "react";
import Select2 from "react-select2-wrapper";
import { useTranslate } from "react-admin";
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";

const selectStyles = makeStyles({
	root: {
		width: '300px',
		'& .select2-container': {
			width: '100% !important',
			display: 'block'
		},
		'&.hidden': {
			display: 'none'
		}
	}
});

export const GroupsFilterForm = ({
		groupsFilter,
		setGroupsFilter,
		selectPlaceholder,
		queryName,
		allGroups,
		location,
		selectId,
		ids
	}) => {
	const translate = useTranslate();
	const classes = selectStyles();
	const history = useHistory();
	const search = location.search;
	const params = new URLSearchParams(search);
	let groupsNames = [];
	const selectNodes = document.querySelectorAll(`#${selectId}`);
	if (selectNodes.length === 2) {
		const selectClass = 'select2-hidden-accessible';
		const first = selectNodes[0];
		const second = selectNodes[1];
		if (first.classList.contains(selectClass) && !second.classList.contains(selectClass)) {
			second.classList.add('hidden');
		} else if (second.classList.contains(selectClass) && !first.classList.contains(selectClass)) {
			first.classList.add('hidden');
		}
	}
	if (params.get(queryName)) {
		groupsNames = params.get(queryName).split(',');
	}
	if (params.get('filter')) {
		const filters = params.get('filter');
		try {
			if (filters) {
				const queryValue = JSON.parse(filters)[queryName];
				if (queryValue && typeof queryValue !== 'boolean') {
					groupsNames = queryValue;
				}
			}
		} catch (error) {
			console.error('failed on parsing filters query', error);
		}
	}
	if (typeof groupsFilter !== 'boolean') {
		groupsNames = groupsFilter;
	}
	let groupsIds = allGroups.reduce((res, item) => {
		if (groupsNames.some(name => name === item.text)) { res.push(item.id) } return res
	}, []);

	const selectHandler = (event) => {
		const addId = event.params.data.id;
		const params = new URLSearchParams(search);

		groupsIds = groupsIds.concat([addId]);
		groupsNames = allGroups.reduce((res, item) => {
			if (groupsIds.some(id => id.toString() === item.id.toString())) { res.push(item.text) } return res
		}, []);
		params.set(queryName, groupsNames);
		setGroupsFilter(groupsNames);

		const newSearch = `?${params.toString()}`;
		if (search !== newSearch) {
			history.push({search: newSearch});
		}
	}

	const unselectHandler = (event) => {
		const params = new URLSearchParams(search);
		const removeId = event.params.data.id;
		groupsIds = groupsIds.filter(uuid => uuid.toString() !== removeId.toString());
		groupsNames = allGroups.reduce((res, item) => {
			if (groupsIds.some(id => id.toString() === item.id.toString())) { res.push(item.text) } return res
		}, []);
		if (groupsNames.length < 1) {
			params.delete(queryName);
			setGroupsFilter(true);
		} else {
			params.set(queryName, groupsNames);
			setGroupsFilter(groupsNames);
		}
		const newSearch = `?${params.toString()}`;
		if (search !== newSearch) {
			history.push({search: newSearch});
		}
	}

	return (ids && ids.length > 0 ?
		<Select2
			className={classes.root}
			multiple
			value={groupsIds}
			data={allGroups}
			onSelect={selectHandler}
			onUnselect={unselectHandler}
			id={selectId}
			options={{
				tags: true,
				placeholder: translate(selectPlaceholder ? selectPlaceholder : "app.labels.selectGroups")
			}}
		/> : null
	)
}
