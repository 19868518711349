import JournalIcon from '@material-ui/icons/LibraryBooks';
import JournalList from './JournalList';

const resources = {
  list: JournalList,
  icon: JournalIcon
};

export default resources;

