import React, { Fragment } from "react";
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const styles = theme => ({
  main: {
    maxWidth: '4em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    firstLetter: {
      fontSize: "200%"
    }
  },
  ts: {
    fontSize: '11px',
    color: 'gray',
    marginTop: '4px'
  },
  ok: {
    color: 'green'
  },
  warn: {
    color: 'orange'
  },
  error: {
    color: 'red'
  }
});

const time_ago = (time) => {

  let formats = [
    [60, 'seconds', 1], // 60
    [120, '1 minute ago', '1 minute from now'], // 60*2
    [3600, 'minutes', 60], // 60*60, 60
    [7200, '1 hour ago', '1 hour from now'], // 60*60*2
    [86400, 'hours', 3600], // 60*60*24, 60*60
    [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
    [604800, 'days', 86400], // 60*60*24*7, 60*60*24
    [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
    [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
    [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
    [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
    [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ];

  var seconds = (+new Date() - new Date(time)) / 1000;

  if (seconds === 0)
    return 'Just now';

  // eslint-disable-next-line
  let [secondsPerUnit, before, after] = formats
    .find(([secondsPerUnit, before, after]) => seconds < secondsPerUnit);

  return typeof after === 'string' ? 
    (seconds > 0 ? before : after) : 
    `${Math.floor(seconds/after)} ${before} ${(seconds > 0) ? 'ago' : 'from now'}`
};

const DeviceStatusField = ({ classes, record }) => { 
	return (
		<Fragment>
      <Tooltip title={record.status && record.status.msg}>
  			<span className={classes.main}>
        {record.isOnline ? 
          (record.status ? `${record.status.status.substring(0,2)}: ${record.status.msg}`:'online' ): 
          'offline'}
  			</span>
      </Tooltip>
			<div>
				<span className={classes.ts}>{record.lastPacketReceivedAt ? time_ago(record.lastPacketReceivedAt) : ''}</span>
			</div>
		</Fragment> 
	);
}

export default withStyles(styles)(DeviceStatusField);
