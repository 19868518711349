import React from "react";
import {
  SimpleForm,
  TextInput,
  DateField,
  BooleanInput
} from "react-admin";
import { makeStyles } from "@material-ui/styles";

import CustomTitle from "../custom/CustomTitle"; // T5275
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722

const useStyles = makeStyles({
  root: {
    width: "100%"
  }
});

export const PermissionCreate = props => {
  const { record, resource, save } = props;
  const classes = useStyles();

  return (
    <CreateWithBreadcrumbs
      title={
        <CustomTitle
          titleKey="resources.permissions.titles.newPermission"
          {...props}
        />
      }
      record={{}}
      {...props}
    >
          <SimpleForm
        record={record}
        resource={resource}
        submitOnEnter={false}
        redirect="edit"
        save={save}
      >
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          className={classes.root}
        >
          <SanitizedGrid item lg={6} md={12}>
            {record && record.id ? (
              <TextInput fullWidth disabled source="id" />
            ) : (
              ""
            )}
            <BooleanInput source="hidden" label="app.labels.hidden" />
            <TextInput fullWidth source="name" label="app.labels.name" />
            <TextInput
              fullWidth
              source="description"
              label="app.labels.description"
            />
            <DateField source="createdAt" label="app.labels.createdAt" />
            <DateField source="updatedAt" label="app.labels.updatedAt" />
          </SanitizedGrid>
        </SanitizedGrid>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  );
}
