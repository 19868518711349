import React, { useState } from "react";
import {
  SimpleForm,
  TextInput,
  AutocompleteInput,
  withDataProvider,
  GET_ONE,
} from "react-admin";
import { Grid } from "@material-ui/core";

import CustomTitle from '../custom/CustomTitle'; // T5275
import SanitizedGrid from "../custom/SanitizedGrid"; // T5722
import ReferenceInputWithFilter from '../custom/ReferenceInputWithFilter'; // T5920
import { CreateWithBreadcrumbs } from '../custom/CustomBreadcrumbs';
import SchemaParamsForm from "../custom/SchemaParamsForm";

const ObjectCreate = ({dataProvider, ...props}) => {
  const [objectType, setObjectType] = useState(null);
  const handleTypeChange = (value) => {
    if (!value) {
      if (objectType) {
        setObjectType(null);
      }
      return;
    }
    if (objectType && objectType.uuid === value) {
      return;
    }
    dataProvider(GET_ONE, 'objects-types', {
      id: value,
    }).then(result => {
      setObjectType(result.data);
    });
  }

  return (
    <CreateWithBreadcrumbs
      title={
        <CustomTitle titleKey="resources.objects.titles.newObject" {...props} />
      }
      {...props}
    >
      <SimpleForm>
        <SanitizedGrid
          justify="flex-start"
          container={true}
          spacing={2}
          fullWidth
        >
          <SchemaParamsForm
            schemaType="type"
            schemaName="typeSchemaParams"
            schemaSource={objectType}
            {...props}
          />
          <Grid item lg={6} md={6} xs={12}>
            <TextInput source="uuid" fullWidth />
            <TextInput
              source="name"
              label="app.labels.name"
              fullWidth
            />
            <ReferenceInputWithFilter
              label="app.labels.client"
              source="clientUuid"
              reference="clients"
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
            <ReferenceInputWithFilter
              label="app.labels.objectType"
              source="objectTypeUuid"
              onChange={handleTypeChange}
              reference="objects-types"
              filter={{}}
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
            <ReferenceInputWithFilter
              label="app.labels.parentObject"
              source="parentUuid"
              reference="objects"
              filter={{}}
            >
              <AutocompleteInput
                optionText="name"
                options={{
                  fullWidth: true
                }}
              />
            </ReferenceInputWithFilter>
          </Grid>
        </SanitizedGrid>
      </SimpleForm>
    </CreateWithBreadcrumbs>
  );
};

export default withDataProvider(ObjectCreate);
