
import React from 'react';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslate } from 'react-admin';

export const ToggleParamsEditorViewButton = props => {
  const { value, handleStateFn, leftJson } = props;
  const translate = useTranslate();

  return (
    <Typography component="span">
      <Grid component="label" container alignItems="center" spacing={0}>
        <Grid>{leftJson ? translate('app.labels.json') : translate('app.labels.form')}</Grid>
        <Grid>
          <Switch
            onClick={() => {
              handleStateFn(value === 'json' ? 'form' : 'json');
            }}
            value={value}
            label="app.lables.switchView"
          ></Switch>
        </Grid>
        <Grid>{leftJson ? translate('app.labels.form') : translate('app.labels.json')}</Grid>
      </Grid>
    </Typography>
  );
}