import { green, red, grey, blue, orange } from '@material-ui/core/colors';

const createRowStyle = (discriminator) => (record, defaultStyle = {}) => {
	switch(typeof record[discriminator]) {
		// isActive for dashboard, models, types lists
		case 'boolean': return {
				borderLeftColor: fromEnabled(record[discriminator]),
				borderLeftWidth: 5,
	      borderLeftStyle: 'solid',
				...defaultStyle,
			};
		case 'string': {
			// status code for device logs 
			if(fromLogStatus([record[discriminator]]))
				return {
					borderLeftColor: fromLogStatus([record[discriminator]]),
					borderLeftWidth: 5,
		      borderLeftStyle: 'solid',
					...defaultStyle,
				};
			// status code for user action logs 
			else 				
				return {
					borderLeftColor: fromHttpStatusCode(record[discriminator]),
					borderLeftWidth: 5,
		      borderLeftStyle: 'solid',
					...defaultStyle,
				};
		}
		case 'undefined':  
			return {
				borderLeftColor: fromMultipleFields(record),//Number(record[discriminator]) > 399 ? red[500] : green[500],
				borderLeftWidth: 5,
	      borderLeftStyle: 'solid',
				...defaultStyle,
			};

		default: return defaultStyle;
	}
};

let fromHttpStatusCode = (status) => Number(status) > 399 ? red[500] : green[500];
let fromLogStatus = (status) => ({Error:red[500],Warn:orange[500],OK:green[500]})[status];
let fromEnabled = (status) => status ? green[500] : grey[500];
let fromMultipleFields = (record) => {
	if(!(record.isActive && record.isOnline)) return grey[500];
	if(!record.status && record.isOnline) return blue[500];
	return fromLogStatus(record.status.status);
}

export default createRowStyle;
