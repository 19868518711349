import React, { Fragment, useState } from "react";
import {List, Create, TopToolbar, useTranslate, CreateButton, ExportButton} from "react-admin";
import { useSelector } from 'react-redux';

import {Breadcrumbs, Chip, makeStyles} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";

import { Edit } from '../custom/WithUndoable';
import BackButton from "./BackButton";

export const MAP_RESOURCE_TO_DASHBOARD = {
	'devices': 'deviceUuid',
	'objects': 'objectUuid'
}

export const breadcrumbsStyles = makeStyles(theme => ({
	root: {
		margin: '1.5em 1.5em 0 1em',
		position: 'relative',
		zIndex: '2'
	},
	noMargin: {
		margin: '0 1.5em 0 1em',
		position: 'relative',
		zIndex: '2'
	},
	chip: {
		backgroundColor: theme.palette.grey[100],
		height: theme.spacing(3),
		color: theme.palette.grey[800],
		fontWeight: theme.typography.fontWeightRegular,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.grey[300],
		},
		'&:active': {
			boxShadow: theme.shadows[1],
			backgroundColor: 'gray',
		},
	},
}));

const StyledListActions = withStyles({
	root: {
		paddingTop: 0,
	},
})(TopToolbar);

const StyledEditActions = withStyles({
	root: {
		justifyContent: 'space-between'
	},
})(TopToolbar);


const handleBRClick = ({...props}) => {
	console.log('handleBRClick', props);
}

const handleBRExpandClick = ({...props}) => {
	console.log('handleBRExpandClick', props);
}

export const CustomBreadcrumbs = withBreadcrumbs()(
	({noMargin, breadcrumbs, ...props}) => {
		const classes = breadcrumbsStyles();
		const translate = useTranslate();
		const [crumbsData, setCrumbsData] = useState({crumbs: [], uuid: null, resourceName: null});
		let additionalCrumbsData = null;
		if (props.getAdditionalCrumbs && typeof props.getAdditionalCrumbs === 'function') {
			additionalCrumbsData = props.getAdditionalCrumbs();
		}
		const translateLabel = (labelText) => {
			const key = `app.labels.${labelText}`;
			const translated = translate(key);
			if (translated !== key) {
				return translated
			}
			return labelText;
		}
		useSelector(function (state) {
			let uuid = crumbsData.uuid;
			let resourceName = crumbsData.resourceName;
			const updatedData = {crumbs: [], uuid: null, resourceName: null};
			updatedData.crumbs = breadcrumbs.map(({breadcrumb, key, location, match}, i) => {
				const labelParts = breadcrumb.props.children.split('-');
				const res = {
					url: key,
					crumbsCount: key.split('/').length,
					labelText: ''
				};
				if (crumbsData.crumbs[i] && crumbsData.crumbs[i].labelText && crumbsData.crumbs[i].labelText !== 'dashboard') {
					res.labelText = crumbsData.crumbs[i].labelText;
					return res;
				}
				if (labelParts.length === 5) {
					if (!uuid) {
						uuid = breadcrumb.props.children.toLowerCase();
					}
					if (!resourceName && uuid) {
						resourceName = key.replace(`/${uuid}`, '').split('/').pop();
					}

					const resource = state.admin.resources[resourceName];
					if (!resource || !resource['data'] || !resource['data'][uuid]) {
						return res;
					}
					const item = resource['data'][uuid];
					if (!item) {
						return res;
					}
					res.labelText = (item.name) ? item.name : document.title;
					return res;
				}
				if (labelParts.length === 1) {
					const title = breadcrumb.props.children.toLowerCase();
					if (title !== 'dashboard') {
						res.labelText = title;
						return res;
					}
					if (!resourceName || !uuid) {
						return res;
					}
					const resource = state.admin.resources['dashboards'];
					if (!resource || !resource['data']) {
						return res;
					}
					const dashboardUuids = Object.keys(resource['data']);
					if (dashboardUuids.length < 1) {
						res.labelText = title;
						return res;
					}
					const gotRelation = dashboardUuids.some(key => {
						const item = resource['data'][key];
						const relatedField = MAP_RESOURCE_TO_DASHBOARD[resourceName];
						if (item[relatedField] === uuid && item.name) {
							res.labelText = item.name;
							return true;
						}
						return false;
					});
					if (!gotRelation) {
						res.labelText = title;
					}
					return res;
				}
				res.labelText = labelParts.join(' ');
				return res;
			});
			updatedData.uuid = uuid;
			updatedData.resourceName = resourceName;
			if (JSON.stringify(crumbsData) !== JSON.stringify(updatedData) ) {
				setCrumbsData(updatedData);
			}
		});
		const lastCrumbs = crumbsData.crumbs[crumbsData.crumbs.length - 1];
		return (
			<Breadcrumbs className={(noMargin) ? classes.noMargin : classes.root}>
				{crumbsData.crumbs.map(({url, crumbsCount, labelText}, i) => (
					<Chip
						component="a"
						href={`#${url}`}
						key={`breadcrumbs-${url}`}
						className={classes.chip}
						label={translateLabel(labelText)}
						icon={(url === '/') ? <HomeIcon fontSize="small" /> : null }
						deleteIcon={crumbsCount > 4 ? <ExpandMoreIcon /> : null }
						onClick={handleBRClick}
						onDelete={crumbsCount > 4 ? handleBRExpandClick : null }
					/>
				))}
				{(additionalCrumbsData &&
					additionalCrumbsData.name &&
					additionalCrumbsData.resourceName === crumbsData.resourceName &&
					additionalCrumbsData.level === crumbsData.crumbs.length
				) ? (
					<Chip
						component="a"
						href={`#${lastCrumbs.url}`}
						key={`additional-breadcrumbs`}
						className={classes.chip}
						label={additionalCrumbsData.name}
						icon={null}
						deleteIcon={null}
						onClick={handleBRClick}
						onDelete={null}
					/>
				) : null}
			</Breadcrumbs>
		)
});

export const TreeBreadcrumbs = props => {
	const { navStack, handleNavClick } = props;
	const classes = breadcrumbsStyles();
	const translate = useTranslate();
	return (
		<Breadcrumbs aria-label="breadcrumb" className={classes.root}>
			<Chip
				key={`breadcrumbs-/`}
				icon={<HomeIcon fontSize="small"/>}
				href="#/"
				label="Home"
				className={classes.chip}
			/>
			{Array.isArray(navStack) && navStack.length ? (
				navStack.map(node => {
					return (
						<Chip
							key={node.uuid || "root"}
							className={classes.chip}
							onClick={() =>
								handleNavClick(node.resource, node.uuid, node.item)
							}
							label={!node.uuid
								? translate("app.labels.all")
								: `${!Array.isArray(node.item) &&
								node.item.name}`}
						/>
					)
				})) : null}
		</Breadcrumbs>
	)
}

export const FiltersWithBreadcrumbs = ({children, ...rest}) => {
	return (
		<Fragment>
			<CustomBreadcrumbs />
			{React.cloneElement(children, rest)}
		</Fragment>
	);
}

export const ListActionsWithBreadcrumbs = ({children, ...rest}) => {
	return (
		<StyledListActions>
			{children ? React.cloneElement(children, rest) :
				<TopToolbar>
					<CreateButton basePath={rest.basePath} />
					<ExportButton
						disabled={rest.total === 0}
						resource={rest.resource}
						sort={rest.currentSort}
						filter={rest.filterValues}
						exporter={rest.exporter}
					/>
				</TopToolbar>
			}
		</StyledListActions>
	);
}

export const EditActionsWithBreadcrumbs = ({children, getAdditionalCrumbs, ...rest}) => {
	return (
		<StyledEditActions>
			<CustomBreadcrumbs noMargin={true} getAdditionalCrumbs={getAdditionalCrumbs} />
			<BackButton/>
			{/* Add your custom actions */}
		</StyledEditActions>
	)
}

export const ListWithBreadcrumbs = ({filters, ...props}) => {
	const defaultPerPage = 25;
	return <List {...props} perPage={props.perPage || defaultPerPage} filters={<FiltersWithBreadcrumbs children={filters} />} actions={<ListActionsWithBreadcrumbs children={props.actions} />}/>
}

export const EditWithBreadcrumbs = props => {
	return <Edit {...props} actions={<EditActionsWithBreadcrumbs getAdditionalCrumbs={props.getAdditionalCrumbs} children={props.actions} />}/>
}

export const CreateWithBreadcrumbs = props => {
	return <Create {...props} actions={<EditActionsWithBreadcrumbs children={props.actions} />}/>
}
